
import tags from "../../../services/tags";
import common from '../../../services/commonService';

class Outline {
  constructor() {

  }

  setOutline(outline: any) {
    tags.vehicle.outline = outline;
    tags.updateObject();
  }

  clear = () => {
    try {
      this.setOutline(null);
      tags.undoAdd();
    } catch (ex) {
      console.error('failed to clear:', ex);
    }
  };


  click = (evt: any) => {
    try {

    } catch(ex) {
      console.error('failed on click: ', ex);
    }
  };

  mouseDown = (evt: any) => {
    try {
      tags.resizeIndex = null;
      if (tags.hover && tags.vehicle.outline) {
        const pts = tags.getPoints(tags.vehicle.outline);
        for (let i = 0; i < pts.length; i++)
          if (tags.pointsEqual(pts[i], tags.hover)) {
            tags.resizeIndex = i;
            return;
          }
      }

      tags.dragAnchor = tags.getPoint(evt);
    } catch(ex) {
      console.error('failed on mouseDown: ', ex);
    }
  };



  mouseMove = (evt: any) => {
    try {

      if (tags.resizeIndex !== null) {
        const pt = tags.getPoint(evt);
        const pts = tags.getPoints(tags.vehicle.outline);
        switch(tags.resizeIndex) {
          case 0: {
            const l = pt.x;
            const t = pt.y;
            const w = pts[2].x - pt.x;
            const h = pts[2].y - pt.y;
            this.setOutline([l,t,w,h]);
            }
            break;

          case 1: {
            const l = pts[0].x;
            const t = pt.y;
            const w = pt.x - pts[0].x;
            const h = pts[2].y - pt.y;
            this.setOutline([l,t,w,h]);
          }
          break;
          case 2: {
            const l = pts[0].x;
            const t = pts[0].y;
            const w = pt.x - l;
            const h = pt.y - t;
            this.setOutline([l,t,w,h]);
         }
          break;

          case 3: {
            const l = pt.x;
            const t = pts[1].y;
            const w = pts[1].x - l;
            const h = pt.y - t;
            this.setOutline([l,t,w,h]);
          }
          break;

        }

        return;
      }

      if (tags.dragAnchor) {
        const pt0 = tags.dragAnchor;
        const pt = tags.getPoint(evt);
        const left = Math.min(pt.x, pt0.x);
        const top = Math.min(pt.y, pt0.y);
        const right = Math.max(pt.x, pt0.x);
        const bottom = Math.max(pt.y, pt0.y);
        this.setOutline([left, top, right - left, bottom - top]);
        return;
      }

      const ptMouse = tags.getPoint(evt);
      tags.hover = null;
      const pts = tags.getPoints(tags.vehicle.outline);
      pts.forEach(pt => {
        if (tags.getDistance(pt, ptMouse) < 5) {
          tags.hover = pt;
          return;
        }
      });

    } catch(ex) {
      console.error('failed on mouseMove: ', ex);
    }
  };

  mouseUp = (evt: any) => {
    try {
      tags.dragAnchor = null;
      tags.resizeIndex = null;
      tags.undoAdd();
      common.notify('InsideTagsAdded');
    } catch(ex) {
      console.error('failed on mouseUp: ', ex);
    }
  };
}

const outline: Outline = new Outline();

export default outline;