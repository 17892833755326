import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import { Box } from '@material-ui/core'
import { useHotkeys } from 'react-hotkeys-hook';
import ResizeObserver from 'react-resize-observer';

/**
 * vehicle classes component
 * @param props 
 * @returns 
 */
export function VehicleClasses(props: any) {

  const [value, setValue] = useState(0); // integer state

  const [panelHeight, setPanelHeight] = useState(200);

  const classes = useStyles();
  useHotkeys('esc', () => hotkeyEsc());

  const hotkeyEsc = () => {
    try {
      common.axles.showVehiclesPanel = false;
      common.notify('AxlesPanelChanged');
      // common.notify("AxlesSelectTab", 0 as any);
    } catch (ex) {
      console.error('failed on hotkeyEsc:', ex);
    }
  }

  const handleClassSelection = (index: number) => {
    try   {
      if (!common.axles.sequence)
        return;
      const cls = common.axles.vehicleClasses[index];
      const cat = cls?.Filename?.replace('.png', '');
      common.axles.sequence.vehicleCat = cat;
      setValue(v => v + 1);
      common.axles.showVehiclesPanel = false;
      common.axles.pendingCategorySelection = true;
      common.notify('AxlesPanelChanged');
      common.axles.dirty = true;
      common.notify('AxlesDirtyChanged');
  
    } catch (ex) {
      console.error('failed to handle class selection:', ex);
    }
  }

  /**
   * generic changes handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      switch(key) {
        case "ImageClick":
          handleClassSelection(value);
          break;

        case "AxleTagsChanged":
          setValue(v => v + 1);
          break;
        
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  /**
   * events listener
   */
  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);



  /**
   * small rectangle on the seq list
   * @param vc 
   * @returns 
   */
  const getColor = (vc: any) => {
    try {
      const selectedClass = common.axles.sequence?.vehicleCat;
      const selected = selectedClass === vc.Filename;
      const axleCount = common.axles.sequence?.axleCount || 0;
      const inRange = axleCount >= vc.minAxles && axleCount <= vc.maxAxles;
      return  selected  ? 'lightgreen' : inRange ? 'lightgreen' : 'silver';
    } catch (ex) {
      return 'transparent';
    }
  }


  const getBorder = (vc: any) => {
    try {
      const selectedClass = common.axles.sequence?.vehicleCat;
      const selected = selectedClass === vc.Filename;
      return selected ? 'solid black 2px' : 'solid transparent 2px';
    } catch (ex) {
      return 'transparent';
    }
  }

  const vehicles = common.axles.vehicleClasses;

  return (
    <Box display="flex" flex="1" flexDirection="column" style={{background:'white', height:'500px', overflow:'scroll'}}>

    <Box display="flex" flexWrap="wrap" flexGrow={false}  flex="1"  >

  

    {vehicles?.map((vc: any, index: number) => 
      ( 
          <Box display="flex" flexDirection="column"  margin="2px" style={{background:getColor(vc), border:getBorder(vc)}}>
        <img alt="" src={vc.path} width={100} onClick={() => handleChanges("ImageClick", index)}></img> 
          </Box>
      ))

}
  </Box>
  </Box>

  )
  // <Box display="flex" flex="1" flexDirection="row" 
  // style={{backgroundColor:'gainsboro',border:"black solid 1px", margin: 4}}>
  //   <Box display="flex" flex="1" flexDirection="column" ></Box>

  //   <ResizeObserver onResize={(rect) => {
  //       setPanelHeight(rect.height - 20);
  //       }} />
  
  // <Box display="flex" flex="1" height={panelHeight} maxHeight={panelHeight} overflow="scroll" style={{ width:'100%', minWidth:'100%'}} >
  //   <Box display="flex" flexDirection="row" flexWrap="wrap">
  //       {vehicles?.map((vc: any, index: number) => 
  //     ( 
  //         <Box  margin={1} style={{background:getColor(vc)}}>
  //         <img alt="" src={vc.base64} width={100} onClick={() => handleChanges("ImageClick", index)}></img> 
  //         </Box>
  //     ))
  //   }
  //   </Box>
  // </Box>
  // </Box>
}

const useStyles = makeStyles((theme: Theme) => ({
}));
