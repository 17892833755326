import { Box, makeStyles, Paper, Theme, Tooltip } from "@material-ui/core";
import { Divider, Drawer as DrawerMui, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import * as React from "react";
import { history } from "../configureStore";
import HomeIcon from "@material-ui/icons/Home";
import {  FileCopy } from "@material-ui/icons";
import { useLocation } from 'react-router-dom';
import common  from '../services/commonService';

interface Props {
	size: number;
	color: "red" | "blue" | string;
}



export function Drawer() {

  const location = useLocation();

  const [value, setValue] = React.useState(0); 
  const [width, setWidth] = React.useState('0px');

React.useEffect(() => {
  const subscription = common.notifier$.subscribe (msg => {
    switch(msg.name) {
      case "PlateSettingsChanged":
        setValue(v => v + 1);
        break;

      case 'LoggedIn':
        setWidth('60px');
        setValue(v => v + 1);
        break;
    }
  });


  return (() => {
    subscription.unsubscribe();
  });
}, []);

 

	return (
    <div style={{ background: '#29367d', color: 'white', width: width }}>
		<div >
	 		<div />
		
		 {common.loggedIn && 	<List>


        <Box height={40}></Box>

      
        <ListItem button  
          style={{background: location.pathname === '/plates' ? 'cornflowerblue' : '#29367d'}} 
        onClick={() => history.push("/plates")}>
        <Tooltip title={<big>License plate tagging</big>}  placement="right">
          <img width={30} height={30} src="icons/license-plate3.png" alt="" ></img>
          </Tooltip>
				</ListItem>

      { common.plates.settings.debug && 
        <ListItem button  
         style={{background: location.pathname === '/axles' ? 'cornflowerblue' : '#29367d'}} 
        onClick={() => history.push("/axles")}>
        <Tooltip title={<big>Axle counter</big>}  placement="right">
          <img width={30} height={30} src="icons/wheel2.png" alt="" ></img>
          </Tooltip>
				</ListItem>

        }

        { common.plates.settings.debug && 
        <ListItem button  
         style={{background: location.pathname === '/inside2' ? 'cornflowerblue' : '#29367d'}} 
        onClick={() => history.push("/inside2")}>
        <Tooltip title={<big>Inside inspection</big>} placement="right">
        <img width={30} height={30} src="icons/driver.png" alt="" ></img>
        </Tooltip>
					<ListItemText primary="" />
				</ListItem>
      }


      
      { common.plates.settings.debug && 
        <ListItem button  
          style={{background: location.pathname === '/video' ? 'cornflowerblue' : '#29367d'}} 
        onClick={() => history.push("/video")}>
        <Tooltip title="Video analysis" placement="right">
          <img width={30} height={30} src="icons/film2.png" alt="" ></img>
        </Tooltip>
          </ListItem>
      }

          { false && 
          <ListItem button  
          style={{background: location.pathname === '/raw' ? 'cornflowerblue' : '#29367d'}} 
        onClick={() => history.push("/raw")}>
        <Tooltip title="Raw calls (debug)" placement="right">
          <img width={30} height={30} src="icons/debug.png" alt="" ></img>
        </Tooltip>
          </ListItem>
}

{ false && 

        <ListItem button  
        style={{background: location.pathname === '/admin' ? 'cornflowerblue' : '#29367d'}} 
        onClick={() => history.push("/admin")}>
        <Tooltip title="Admin (debug)" placement="right">
          <img width={30} height={30} src="icons/user.png" alt="" ></img>
        </Tooltip>
          </ListItem>
}

     
      
        

        {/* <ListItem button  onClick={() => history.push("/inside2")}>
        <Tooltip title="Inside 2" placement="right">
          <img width={30} height={30} src="icons/debug.png" ></img>
        </Tooltip>
        </ListItem> */}


        
			</List>

    }
    </div>

    </div>
  );
}

export function Navigator(props: Props) {
	const { size, ...other } = props;
	const classes = useStyles(props);

	return (
		<Paper className={classes.box} {...other}>
						
		</Paper>
	);
}

const styledBy = (property: string, props: any, mapping: any): string =>
	mapping[props[property]];
const useStyles = makeStyles((theme: Theme) => ({
	box: (props: Props) => ({
		display: "flex",
		alignItems: "center",
		borderRadius: 0,
		background: styledBy("color", props, {
			red: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
      blue: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
      dark: "#29367d",
		}),
		height: props.size,
		width: "100%",
	}),

	text: {
		color: "white",
	},
}));
