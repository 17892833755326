// prettier-ignore
import { Switch } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';

export const PurpleSwitch = withStyles({
  switchBase: {
    color: blue[50],
    '&$checked': {
      color: blue[900],
    },
    '&$checked + $track': {
      backgroundColor: blue[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

