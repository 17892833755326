
import common from './commonService';
import { history } from "../configureStore";

class LoginService {

  constructor() {
    common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'ApplicationRendered':
          this.handleApplicationRendered();
          break;
      }
    })
  }

  async initialize () {

  }

  async handleApplicationRendered () {
    try {
      const location = window.location;
      if (location.search) 
        return this.handleQueryMode(location.search);

      if (common.launchLogin) {
        history.push('/plates');
        common.launchLogin = false;
        common.notify('LaunchLoginDialog');
      }

    } catch (ex) {
      console.error('failed to handle application rendered:', ex);
    }
  }

  /**
   * application was launched with a search string
   * @param search 
   */
  async handleQueryMode(search: string) {
    try {
      const params = new URLSearchParams(search);
      const loginToken = params.get('token') || '';
      if (!loginToken) throw(new Error('No login token'));
      const datasetId = params.get('dataset') || '';

      // check the token

      common.loginToken = loginToken;
      common.imageToken = `user-authorization=${common.loginToken}`;
      common.loggedIn = true;
      common.LoginFailed = false;
      common.notify('LoggedIn');  
      history.push('/plates');
      // for debug
      await common.delay(1000);
      common.notify('PlatesLoadDataset', {id: datasetId, entry: null} as any);
    } catch (ex) {
      const message = (ex as any).message;
      console.error(`failed to handle query mode ${message} ${search}`);
    }
  }

}
const loginService: LoginService = new LoginService();
export default loginService;