import React, {useEffect, useState } from 'react';
import {Typography, Box, Button, Dialog, DialogTitle, DialogContent, LinearProgress, Tooltip, IconButton, TextField, Select, MenuItem } from '@material-ui/core'
import { Search,  PieChart as PieChartIcon } from '@material-ui/icons';
import common from '../../services/commonService';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { StatisticsPanel } from './statisticsPanel';


/**
 * select dataset, show dataset statistics
 * @param props 
 * @returns 
 */
export function DatasetsDialog (props:any) {

  const[value, setValue] = useState(0);
  const[selectedRow, setSelectedRow] = useState(null);
  const[searchMode, setSearchMode] = useState(false);

  const shortNameFormatter = (params:any) => {
    const dir = params.data.Header.ImagesDirectory;
    return dir.length > 50 ? '...' + dir.substring(dir.length - 50) : dir;
  }

  const sequenceCountFormatter = (params:any) => {
    return params.data.Sequences.length.toString();
  }

  /**
   * fields
   */
  const datasetsColumns =  [
    {headerName: 'Name', field: 'alias', width:220, resizable: true},
    {headerName: 'Count', field: 'count', width:80},
    {headerName: 'country', field: 'country', width: 80},
  ];

  // from props
  const { onClose, open } = props;
/**
 * stash gridApi
 * @param params 
 */
  function onGridReady(params:any) {
    common.plates.datasetsGridApi = params.api;
}

/**
 * a dataset has been selected, notify and close
 * @returns 
 */
const handleSelect = () => {
  try {
    const selectedRows = common.plates.datasetsGridApi.getSelectedRows();
    if (selectedRows.length === 0)
      return;

    const selectedRow = selectedRows[0];
    common.plates.selectedRow = selectedRow;
    const axleCounter = selectedRow.axleCounter;
    common.notify(axleCounter ? 'AxlesLoadDataset' : 'PlatesLoadDataset', selectedRow.id);
    handleClose();
  } catch (ex) {
    console.error('failed to handle select:', ex);
  }
}

/**
 * perform statistics on selected dataset
 * @returns 
 */
const handleStatistics = () => {
  try {

    if (common.plates.showStatisticsPanel) {
      common.plates.showStatisticsPanel = false;
      setValue(v => v + 1);
      common.notify('StopPlatesStatistics');
      return;
    }

    const selectedRows = common.plates.datasetsGridApi.getSelectedRows();
    if (selectedRows.length === 0)
      return;

    const selectedRow = selectedRows[0];
    common.notify('StartPlateStatistics', selectedRow.id);
    common.plates.showStatisticsPanel = true;
    setValue(v => v + 1);
  } catch (ex) {
    console.error('failed to handle select:', ex);
  }
}

/**
 * dispatcher
 */
const handleCallback = (arg: string, value: any) => {
  try {
    switch(arg) {
     
      case "Select":
        handleSelect();
        break;
        
      case "RowDoubleClicked":
        const axleCounter = value.data?.axleCounter;
        common.notify(axleCounter ? 'AxlesLoadDataset' : 'PlatesLoadDataset', value.data.id);
        handleClose();
        break;

      case "Refresh":
        break;

      case "SelectionChanged":
        const selectedRows = common.plates.datasetsGridApi.getSelectedRows();
        const selectedRow = selectedRows && selectedRows.length > 0 ? selectedRows[0] : null;
        setSelectedRow(selectedRow);
        if (common.plates.showStatisticsPanel)
          common.notify('StartPlateStatistics', selectedRow.id);
        break;

      case "Statistics":
        handleStatistics();
        break;

      case "PlateStatisticsChanged":
        refreshCells();
        break;

      case "StatisticsProgressChanged":
        setValue(v => v + 1);
        break;

      case "ToggleSearch":
        setSearchMode(!searchMode);
        break;

      case "CountryFilter":
        common.plates.datasetsFilter.country = value;
        common.notify('PlatesDatasetsFilterChanged');
        setValue(v => v + 1);
        break;

      case "NameFilter":
        common.plates.datasetsFilter.nameFilter = value;
        common.notify('PlatesDatasetsFilterChanged');
        setValue(v => v + 1);
        break;

      case "DatasetsDialogOpened":
        handleOpen();
        break;

        case "PlatesDatasetsArrived":
          handleDataset();
          break;

        case "GetDatasetsPercentageChanged":
        case "GettingDatasetsChanged":
          setValue(v => v + 1);
          break;

    }

  } catch (ex) {
    console.error('failed on handleCallback');
  }
}

const handleOpen = () => {
  try {
    setSearchMode(false);
    common.plates.datasetsFilter.nameFilter = '';
    common.plates.datasetsFilter.country = 'ALL';
    common.notify('PlatesGetDatasets');
  } catch (ex) {
    console.log('failed to handle open:', ex);
  }
}

const handleDataset = () => {
  try {
    refreshCells();
  } catch (ex) {
    console.error('failed to handle datasets arrival:', ex);
  }
}

/**
 * refresh grid cells
 */
const refreshCells = () => {
  try {
    if (common.plates.datasetsGridApi)
      common.plates.datasetsGridApi.refreshCells();
  } catch (ex) {
    console.error('failed to update validation state:', ex);
  }
}

/**
 * close the dialog
 */
  const handleClose = () => {
    setSelectedRow(null);
    common.notify('StopPlatesStatistics');
    // in case closed while getting datasets
    common.plates.abortGettingDatasets = true;
    common.plates.showStatisticsPanel = false;
    onClose();
  };

  const handleListItemClick = (value:any) => {
    onClose(value);
  };

  const classes = useStyles();

  /**
   * subscribe, unsubscribe to events
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallback(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  const stats = common.plates.stats;

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xl" disableBackdropClick={true} >
      <DialogTitle id="Plates datasets">
        <Box display="flex" flexDirection="row" alignItems="center"> 
        <Box display="inline">Datasets selector</Box> 
        <Box width={4}></Box>
        <Box display= {common.plates.gettingDatasets ? "flex" : "none" } flexDirection="row" alignItems="center">
        <LinearProgress variant="determinate"  value={common.plates.getDatasetsPercentage}  style={{width:100, height:12, margin: 2}} />  
        <Box width={4}></Box> 
        <Typography variant="caption">Loading ...</Typography>
        </Box>
 
        </Box>
      </DialogTitle>

      <DialogContent>
      { true &&
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" overflow="hidden" >
          <Box display="flex" flexDirection="column" className="ag-theme-balham" style={{ height: '400px', width: '400px' }}>
            <AgGridReact 
              suppressCellSelection={common.plates.gettingDatasets}
              suppressRowClickSelection={common.plates.gettingDatasets}
              rowSelection={'single'}
              onGridReady={onGridReady}
              columnDefs={datasetsColumns}
              onSelectionChanged={ (e:any) => handleCallback("SelectionChanged", e)}
              onRowDoubleClicked={ (params) => handleCallback("RowDoubleClicked", params)}
              rowData={common.plates.datasets || []}>
            </AgGridReact>
          </Box>
          <Box display="flex" flex="1" flexDirection="column">
          <Box display="flex" flex="1" flexDirection="row" alignItems="center">
          <Tooltip title= "Toggle dataset search" placement="bottom">
            <span>
              <Button disabled={common.plates.gettingDatasets} variant="contained" startIcon={<Search/>} onClick={() => handleCallback("ToggleSearch", null)}
              className={classes.button} style={{maxWidth: '40px', minWidth: '40px'}}>
              </Button>
              </span>
              </Tooltip>
              <Tooltip title= "Show selected dataset statistics" placement="bottom">
                <span>
              <Button startIcon={<PieChartIcon/>} style={{maxWidth: '40px', minWidth: '40px'}}
              disabled={!selectedRow} className={classes.button} variant="contained" onClick={() => handleCallback("Statistics", null)}></Button>
              </span>
              </Tooltip>
              <Box display={common.plates.showStatisticsPanel ? "flex" : "none"} flexDirection="row">
              <LinearProgress variant="determinate"  value={common.plates.statisticsPercentage}  style={{width:100, height:12, margin: 2}} />   
              {common.plates.statisticsPercentage}%
          </Box>
          <Box display="flex" flex="1" flexDirection="row" justifyContent="flex-end" >
              <Button disabled={!selectedRow} className={classes.button} variant="contained" onClick={() => handleCallback("Select", null)}>Select</Button>
              <Button className={classes.button} variant="contained" onClick={() => handleClose()}>Close</Button>
          </Box>
        </Box>
          <Box display={searchMode ? "flex":"none"} alignItems="flex-end">
            
          <TextField type="search" label="Search dataset by name" value={common.plates.datasetsFilter.nameFilter} onChange={(e) => handleCallback("NameFilter", e.target.value) }></TextField>

          <Box style={{margin:5, marginRight:10}}>Country:</Box>
          <Select className={classes.combo}   label="Country"
          value={common.plates.datasetsFilter.country || 'ALL'} 
          onChange={e =>handleCallback("CountryFilter", e.target.value)}>
              {common.plates.datasetsFilter.countries?.map((c:any) => ( <MenuItem value={c}>{c}</MenuItem>))}
        </Select>   
          </Box>
        
        </Box>
      
      </Box>

      <Box flexDirection="column" display={common.plates.showStatisticsPanel   ? "flex" : "none"}  >

      <Box display={common.plates.statisticsState === 'running'  ? "flex" : "none"} flex="1" justifyContent="center" alignItems="center"  minWidth={600}>
        <h3>Collecting statistics ...</h3>
      </Box>
        
      <Box  flexDirection="column" display={common.plates.statisticsState !== 'running'  ? "flex" : "none"}  >
        <StatisticsPanel/>
      </Box>
    
    
    </Box>
    </Box>
    }
  

    </DialogContent>
</Dialog>
  );
}

DatasetsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme: Theme) => ({ 
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  combo: {
    width: 150,
  },
  grid: {

  },
}));