import React, {useState, useEffect} from 'react';
import {Box, Button  } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import ResizeObserver from 'react-resize-observer';

/**
 * tai keyboard
 * @param props 
 * @returns 
 */
export function KeyboardPanel(props: any) {

  // eslint-disable-next-line
  const [value, setValue] = useState(0); 
  const [chars, setChars] = useState<string[]>([]);
  const [serbChars, setSerbChars] = useState<string[]>([]);
  
  const [keyboardHeight, setKeyboardHeight] = useState(100);

  /**
   * central handler
   * @param arg 
   * @param value 
   */
  const handleCallback = (arg: string, value: any) => {
    try {
      switch (arg) {
        case "ChangeLogLoaded":
          setValue(value => value + 1);
          break;

        case "PlatesSelectedTabChanged":
          setValue(value => value + 1);
          break;

        case "CharClicked":
          common.notify('PlatesKeyboardChar', value);
          break;

        case "Backspace":
          common.notify("PlatesKeyboardChar", "Backspace" as any);
          break;

        case "Clear":
          common.notify("PlatesKeyboardChar", "Clear" as any);
          break;

          case "AnnotationChanged":
            setValue(v => v + 1);
            break;
     
      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  const initSerbChars = () => {
    try {
      let chars = [];
      chars.push('Ć');
      chars.push('Č');
      chars.push('Ð');
      chars.push('Š');
      chars.push('Ž');
      setSerbChars(chars);


    } catch (ex) {
      console.error('failed to init serbian characters:', ex);
    }
  }

  /**
   * tai characters
   */
  const initCharacers = () => {
    try {

      let chars = [];
      for(let i = 3585; i <= 3632; i++)
          chars.push(i);
      for (let i = 3648; i <= 3654; i++)
          chars.push(i);
      for (let i = 3663; i <= 3675; i++)
          chars.push(i);
      for (let i = 48; i <= 57; i++)
          chars.push(i);

      chars = chars.map(ch => String.fromCharCode(ch));
      setChars(chars);

    } catch (ex) {
      console.error('failed to init characters:', ex);
    }

  }

  /**
   * register to callbacks
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallback(msg.name as string, msg.data);
      });
      initCharacers();
      initSerbChars();
      return (() => {
        subscription.unsubscribe()});
  }, []);

  // selected nation
  const nation = common.plates.selectedAnnotation?.Nation?.value;

  /**
   * determines wether a keyboard exist for selected nation;
   */
  const keyboardExist = () => {
    try {
      // currently, on this nation/7
      return nation === 'THA' || nation === 'SRB';
    } catch (ex) {
      console.error('failed on keyboardExist:', ex);
      return false;
    }
  }

  const classes = useStyles();
  return (
    <Box display="flex" flex="1" flexDirection="column" style={{background:'white'}}>

    <Box display="flex" flex="1" flexDirection="column" style={{background:'white', overflow:'auto'}} >
      <Box display="flex-wrap" flexGrow={false}  flex="1" height={keyboardHeight} maxHeight={keyboardHeight} >

      {!keyboardExist() && 
            <Box display="flex" flex="1" alignItems="center" justifyContent="center">
            <h3>No keyboard for the selected annotation</h3>
          </Box>
      }

      { nation === 'THA' &&
        chars.map(ch => (<Button style={{fontSize:'24px'}}  onClick={() => handleCallback('CharClicked', ch)}>{ch}</Button> ))
      }

      { nation === 'SRB' &&
        serbChars.map(ch => (<Button style={{fontSize:'24px'}}  onClick={() => handleCallback('CharClicked', ch)}>{ch}</Button> ))
      }
  
      </Box>
    </Box>
    { keyboardExist() && 
        <Box display="flex" flexDirection="row"  >
        <Button className={classes.button} onClick={() => handleCallback('Clear', null)}>Clear</Button>
        <Button className={classes.button} onClick={() => handleCallback('Backspace', null)}>Backspace</Button>
      </Box>
    }
    </Box>
  );

}

const useStyles = makeStyles({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
},
checkbox: {
  margin: 0,
  padding: 0,
},

formControl: {
  margin: 0,
  padding: 0,
}}


);