import common from './commonService';
const axios = require ('axios');



export class InsideService  {

  setDatasets: any;
  datasets: any;
  callbacks: any = {};
  selectedRowIndex: number = 0;

  constructor() {
    this.callbacks = {};
    this.selectedRowIndex = 0;
   
  }

  register = (name: string, cb: any) => {
    this.callbacks[name] = cb;
  }

  notify = (name: string, data: any) => {
    try {

      for (const prop in this.callbacks) {
        if (this.callbacks.hasOwnProperty(prop)) {
          this.callbacks[prop](name, data);
        }
      }  
    } catch (ex) {
      console.error('failed to notify:', ex);
    }
  }

  run(arg: any): void {
    console.log(`running:  arg: ${arg}`);
  }
  fetch() {
    return fetch( `${common.rest}/inside/0`);
  }


  getRecord = (datasetName:string, recordId: string) => {
    try {
      var dataset  = encodeURIComponent(datasetName);
      return fetch(`${common.rest}/inside/${dataset}/${recordId}`);
    } catch (e) {
      console.error('failed to get datasets: ')
      return Promise.reject("failed to get dataset record");
    }
  };

  saveRecord(datasetName:string, record: any) {
    try {
      var dataset  = encodeURIComponent(datasetName);
      const recordId = record.id;
      return axios.post(`${common.rest}/inside/${dataset}/${recordId}`, record);
    } catch (exception) {
      console.error('failed to save current record: ', exception);
    }
  }

    /**
   * returns a dataset for the specified path
   * @param path 
   * @returns 
   */
     getDataset = (path: string) => {
      try {
        const max = 100;
        path = encodeURIComponent(path);
        return fetch(`${common.rest}/inside/${path}?max=${max}`);
        } catch (e) {
        console.error('failed to get datasets: ')
        return Promise.reject("failed to get dataset");
      }
    };
    

}

const insideService: InsideService = new InsideService();
export default insideService;

