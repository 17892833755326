import React, {useState, useEffect } from 'react';
import {Box, Button, Checkbox, Dialog, DialogTitle, FormControlLabel, Select, MenuItem } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import common from '../services/commonService';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import copy from 'copy-to-clipboard';
import { useHistory } from "react-router-dom";

const  dateFormatter = (params:any) => {
  const time = params.data.time;
  const tm = new Date(time);
  return tm.toLocaleTimeString();
}

export function TestsDialog (props:any) {
  const { onClose, open } = props;

  const [value, setValue] = useState(0); 
  const classes = useStyles();

  const history = useHistory();

  const columnDefs = [
    {headerName: 'Time', field: 'time', valueFormatter: dateFormatter, width:70},
    {headerName: 'S', 
      field: 'severity',
      width: 40, 
      cellRenderer: (data:any) => {
        let color = 'gray';
        if (data && data.value) {
          const status = data.value as number;
          switch (data.value as string) {
            case 'Warning' : color = 'orange'; break;
            case 'Info': color = 'lawngreen'; break;
            case 'Error': color = 'red'; break;
          }
        }
        return '<div style="background-color:' + color + ';display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px" ></div>';
    }},
    {headerName: 'Message', field: 'message', width:2000},
  ];



  const handleChanges = (key: string, value: any ) => {
    try {
      switch(key) {
        case "SelectedScript":
          common.notify('ScriptSelection', value);
          setValue(v => v + 1);
           break;
        case "StartScript":
          common.notify('ScriptStart');
          break;

          case "ScriptNavigate":
            scriptNavigate(value);
            break;

          case "ScriptTabChange":
            common.scripts.tab = value;
            setValue(v => v + 1);
            break;

          case "ScriptLogChanged":
            common.scripts.logs.gridApi.setRowData(common.scripts.logs.logs);
            setValue(v => v + 1);
          break;

      
      }
    } catch (ex) {
      console.error('failed on handle changes:', ex);
    }

  }

  const scriptNavigate = (path: string) => {
    try {

      history.push(path);
    } catch (ex) {
      console.log('failed on scriptNavigate:', ex);
    }
  }

  const handleClose = () => {
    onClose();
  };


  const handleListItemClick = (value:any) => {
    onClose(value);
  };

  function onGridReady(params:any) {
    common.scripts.logs.gridApi = params.api;
}

  const scripts = common.scripts;

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  
  }, []);
  

  return (
    <Dialog onClose={handleClose} fullWidth={true}
    aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="Application logger">

      <Box display="flex" flex="1" flexDirection="row">


    <Select style={{width:120, margin:4}} value={scripts?.selectedScript?.name || ''}
      onChange={e =>handleChanges("SelectedScript", e.target.value)}>
      {scripts?.data?.scripts?.map(s => ( <MenuItem value={s.name}>{s.name}</MenuItem>))}
  </Select> 


  <Button  className={classes.button}
          style={{margin:4,fontSize:10}} variant="contained" onClick={() => handleChanges("StartScript", null)}>Start</Button>


<   Select  style={{width:120}}
      value={scripts.tab}
      onChange={e =>handleChanges("ScriptTabChange", e.target.value)}>
      <MenuItem value="Code">Code</MenuItem>
      <MenuItem value="Log">Log</MenuItem>
    </Select>

    <Box display="flex" flex="1"></Box>

      <Button  className={classes.button} 
          style={{margin:4,fontSize:10}} variant="contained" onClick={() => handleClose()}>Close</Button>
      </Box>

   

      </DialogTitle>




      <Box display="flex" flex="1" style={{height:'500px', minHeight: '500px'}} >


      <Box display={common.scripts.tab  === 'Code' ? 'flex': 'none'} flex="1" >
      <textarea   value={common.scripts.text as string} spellCheck="false" style={{width: '100%'}}>
      </textarea>
      </Box>

      <Box display={common.scripts.tab  === 'Log' ? 'flex': 'none'} flex="1" flexDirection="column"
                className="ag-theme-balham"
                style={{ height: '500px', width: '100%' }}>
      <AgGridReact
                    rowSelection={'single'}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    rowData={common.scripts.logs.logs || []}>
              </AgGridReact>
      </Box>
    
      </Box>

    </Dialog>
  );
}

TestsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
}));