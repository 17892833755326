import React, {useState, useEffect} from 'react';

import Box from '@material-ui/core/Box'
import common from '../../services/commonService';
import { Button } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { GridApi } from 'ag-grid-community';
import { deepStrictEqual } from 'assert';
const axios = require ('axios');




export function Raw(props: any) {

  const [value, setValue] = useState(0); // integer state


   const datasetsColumns = [
    {headerName: 'id', field: 'id', width: 50},
    {headerName: 'path', field: 'path'},
  ];

  const itemsColumns = [
    {headerName: 'id', field: '_id', width: 200},
  ];

   const classes = useStyles();


   function onGridReady(params:any) {
      common.raw.gridApi = params.api;
  }

  function onGridReady2(params:any) {
    common.raw.gridApiItems = params.api;
}

  const getDatasets = () => {
    try {
        axios.get('http://172.25.0.160:4000/api/dataset')
        .then ((response:any) => {
          var datasets = response.data;
          // adapter
          datasets = datasets.map((ds:any, index:number) => ({ id: ds._id,  path: "yet to be included"}));
          common.raw.datasets = datasets;
          common.raw.gridApi.refreshRows();
          setValue(v => v + 1);
        })
        .catch((reason:any) => {
          console.error(reason);
        });

      } catch (ex) {
      console.error('failed on getDatasets:', ex);
    }
  }

  const getSelectedDataset = () => {
    try {
      const id = common.raw.selectedDataset.id;
      const url = `http://172.25.0.160:4000/api/dataset/${id}`;
        axios.get(url)
        .then ((response:any) => {
          var data = response.data;
          data.images = data.images.map((d:string) => ({_id: d}))
          common.raw.dataset = data;
          common.raw.gridApi.refreshRows();
          setValue(v => v + 1);
        })
        .catch((reason:any) => {
          console.error(reason);
        });

      } catch (ex) {
      console.log('failed on getDatasets:', ex);
    }
  }

  const getSelectedItem = () => {
    try {
      var id = common.raw.selectedItem._id;
      var url = `http://172.25.0.160:4000/api/items/${id}`;
      common.raw.imgData =  `http://172.25.0.160:4000/api/items/${id}?raw`;
      // 'http://172.25.0.160:4000/api/items/608bc1d459cfe7351acf129b?raw';
      // common.raw.imgData = url;
      setValue(v => v + 1);

      axios.get(url)
      .then ((response:any) => {
        common.raw.itemDetails = JSON.stringify(response.data);
        setValue(v => v + 1);
      })
      .catch((reason:any) => {
        console.error(reason);
      });


    } catch (ex) {
      console.error('failed to get selected item:', ex);
    }
  }






const handleCallback = (arg: string, value: any) => {
  try {
    switch (arg) {
      case "GetDatasets":
        common.raw.datasets = [];
        setValue(v => v + 1);
        getDatasets();
        break;

      case "DatasetSelection":
        const rows = common.raw.gridApi.getSelectedRows();
        common.raw.selectedDataset = rows[0] || null;
        setValue(v => v + 1);
        getSelectedDataset();
        break;

      case "ItemSelection":
        const selectedItems = common.raw.gridApiItems.getSelectedRows();
        common.raw.selectedItem = selectedItems[0] || null; 
        getSelectedItem();
        break;


    }

  } catch (ex) {
    console.error('failed to handle callback: ', ex);
  }
}





useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);


const ds = common.raw?.dataset?.dataset;

return <Box display="flex" flex="1" flexDirection="row"  >


  <Box display="flex" flexDirection="column" width="300px">

    <Box display="flex" flexDirection = "row" alignItems="center"  style={{background:'gainsboro'}}>
      All datasets

      <Box flex="1"></Box>


    <Button className={classes.button} onClick={ () => 
      handleCallback("GetDatasets", null)}>Refresh</Button>
    </Box>
   

<div className="ag-theme-balham" style={{ height: '100%', width: '100%' }}>
    <AgGridReact
      rowSelection={'single'}
      onGridReady={onGridReady}
      columnDefs={datasetsColumns}
      onSelectionChanged={ (e:any) => handleCallback("DatasetSelection", e)}
      rowData={common.raw?.datasets || []}>
    </AgGridReact>

</div>

  </Box>


<Box display="flex" flexDirection="column" width="400px" >

<Box display="flex" flexDirection = "row" alignItems="center" style={{background:'gainsboro'}}>
      Dataset {common.raw.selectedDataset?.id}

    <Box flex="1"></Box>
    <Button className={classes.button} onClick={ () => 
      handleCallback("RefreshDataset", null)}>Refresh
    </Button>
  </Box>

  <Box display="flex" flexDirection="column">

      <Box flexDirection="row">alias: {ds?.alias}</Box>
      <Box flexDirection="row">query: {ds?.query}</Box>
      <Box flexDirection="row">application: {ds?.application}</Box>
      <Box flexDirection="row">grouping: {ds?.grouping}</Box>
      <Box flexDirection="row">limit: {ds?.limit}</Box>
      <Box flexDirection="row">dynamic: {ds?.dynamic}</Box>
      
  </Box>

      <div className="ag-theme-balham" style={{ height: '100%', width: '100%' }}>
    <AgGridReact
      rowSelection={'single'}
      onGridReady={onGridReady2}
      columnDefs={itemsColumns}
      onSelectionChanged={ (e:any) => handleCallback("ItemSelection", e)}
      rowData={common.raw?.dataset?.images || []}>
    </AgGridReact>

</div>

</Box>


<Box display="flex" flexDirection="column" width="600px" >

<Box display="flex" flexDirection = "row" alignItems="center" style={{background:'gainsboro'}}>
      Item {common.raw.selectedItem?.id}

    <Box flex="1"></Box>
    <Button className={classes.button} onClick={ () => 
      handleCallback("RefreshItem", null)}>Refresh
    </Button>
  </Box>

      <img src={common.raw.imgData} width="600" height="400" style={{background:'gray'}}></img>

      <Box display="flex" flexDirection = "row" alignItems="center" style={{background:'gainsboro'}}>
        Item properties:
      </Box>

      
      <textarea   value={common.raw.itemDetails as string} spellCheck="false" style={{width: '100%', height:'100%'}}>
      </textarea>


</Box>

</Box>
}

const useStyles = makeStyles({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
},
checkbox: {
  margin: 0,
  padding: 0,
},

formControl: {
  margin: 0,
  padding: 0,
}}


);