import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import {Box, Select, Button, MenuItem, Checkbox, RadioGroup, Radio,
  FormControlLabel, IconButton, Tooltip, Typography } from '@material-ui/core'
import { SkipNext, SkipPrevious, Delete as DeleteIcon } from '@material-ui/icons';

import { TagNav } from './tagNav';
import { CabProps } from './cabProps';
export function TagProps(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 

    /**
   * render new cab tag exclusive at image level
   */
     const resetImageCabs = () => {
      try {
        const tags = common.axles.tags.filter((t:any) => t.ImageIndex === common.axles.imageIndex);
        tags?.forEach((t:any) => t.Cab = false);
          
      } catch (ex) {
        console.error('failed to reset image cabs:', ex);
      }  }

  const handleChanges = (key: string, value: any) => {
    try {
      switch(key) {

          case "AxleTagsChanged":
          case "AxlesSequenceLoaded":
            setValue(v => v + 1);
            break;

        case "wheelOrientation":
          case "wheelPosition":
          case "twin":
          case "partial":
            if (common.axles.tag) {
              common.axles.tag[key] = value;
              common.axles.imageDirty = true;
              setValue(v => v + 1);
              common.notify("AxleTagsChanged");
            }
            break;

            case "lifted":
              if (common.axles.tag) {
                common.axles.tag[key] = parseInt(value);
                common.axles.imageDirty = true;
                setValue(v => v + 1);
                common.notify("AxleTagsChanged");
              }
              break;


            case "NextTag":
              common.notify("AxlesNextTag");
              break;

            case "PreviousTag":
              common.notify("AxlesPreviousTag")
              break;

            case "Ok":
              common.notify("AxlesSaveImage");
              break;

            case "Cancel":
              common.notify("AxlesCancelImage");
              break;

            case "DeleteTag":
              common.notify('AxlesDeleteTag');
              setValue(v => v + 1);
              break;
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);

  const classes = useStyles();
  const sequence = common.axles.sequence;
  const codes = common.axles.codes;
  const tag = common.axles.tag;

  const totalTags = common.axles.tags.length;
  const tags = common.axles.tags;
  const tagIndex = tags ? tags.indexOf(common.axles.tag) : -1;


  return <Box display="flex" flexDirection="column" flex="1" width="300px" minWidth="300px">


<TagNav></TagNav>

{tag?.Cab && <CabProps></CabProps>}


{tag && !tag?.Cab &&
<Box display="flex" flexDirection="column" padding={"2px"} paddingLeft={"6px"}>




<Box display="flex" flexDirection="row" alignItems="center">  

      <Box display="flex" flexDirection="row">

      <Box display="flex" flexDirection="column">
        <Typography>Orientation:</Typography>
      <RadioGroup aria-label="occlusion" name="orientation"
        value={tag?.wheelOrientation || ''}  onChange={e =>handleChanges("wheelOrientation", e.target.value)}  >
          {codes.orientation.map(pt =><FormControlLabel value={pt[0]} control={<Radio style={{height:'16px'}} size="small" />} label={pt[1]} /> )}
      </RadioGroup>
      </Box>

      <Box display="flex" width="4px"/>

      <Box display="flex" flexDirection="column">
        <Typography>Position:</Typography>
      <RadioGroup aria-label="position" name="position"
        value={tag?.wheelPosition || ''} onChange={e =>handleChanges("wheelPosition", e.target.value)}>
          {codes.position.map(pt =><FormControlLabel value={pt[0]} control={<Radio style={{height:'16px'}} size="small" />} label={pt[1]} /> )}
      </RadioGroup>
      </Box>

      <Box display="flex" width="4px"/>

      <Box display="flex" flexDirection="column">
        <Typography>Lifted:</Typography>
      <RadioGroup aria-label="lifted" name="lifted"
         value={tag?.lifted || 0}  onChange={e =>handleChanges("lifted", e.target.value)} >
          {codes.lifted.map(pt =><FormControlLabel value={pt[0]} control={<Radio style={{height:'16px'}} size="small" />} label={pt[1]} /> )}
      </RadioGroup>
      </Box>

      <Box display="flex" width="2px"/>

      </Box>
      {/* <Select value={tag?.wheelOrientation || ''} className={classes.combo}
        onChange={e =>handleChanges("wheelOrientation", e.target.value)}>
        {codes.orientation.map(pt => ( <MenuItem value={pt[0]}>{pt[1]}</MenuItem>))}
      </Select> */}
    </Box>

    {/* <Box display="flex" flexDirection="row" alignItems="center">
      <Box display="inline" style={{width:80}}>Position: </Box>
      <Select value={tag?.wheelPosition || ''} className={classes.combo}
    onChange={e =>handleChanges("wheelPosition", e.target.value)}>
    {codes.position.map(pt => ( <MenuItem value={pt[0]}>{pt[1]}</MenuItem>))}
  </Select>
    </Box> */}

    {/* <Box display="flex" flexDirection="row" alignItems="center">
      <Box display="inline" style={{width:80}}>Lifted: </Box>
      <Select value={tag?.lifted || 0} className={classes.combo}
    onChange={e =>handleChanges("lifted", e.target.value)}>
    {codes.lifted.map(pt => ( <MenuItem value={pt[0]}>{pt[1]}</MenuItem>))}
  </Select>
    </Box> */}

<Box display="flex" height="4px"/>
 

<Box display="flex" flexDirection="row" alignItems="center">

<FormControlLabel style={{height:25}}
        control={<Checkbox size="small" checked={tag?.twin} 
        onChange={(e) => handleChanges("twin", e.target.checked)} />}
        label="Twin"/>


<FormControlLabel style={{height:25}}
        control={<Checkbox size="small" checked={tag?.partial} 
        onChange={(e) => handleChanges("partial", e.target.checked)} />}
        label="Partial"/>



        </Box>

  </Box>
}
  <Box display="flex" flex="1"></Box>



  <Box display="flex" flexDirection="row"  alignItems="center">

  <Tooltip title='Commit and move to next image' placement='bottom'>
            <span>
              <Button className={classes.button} style={{marginTop: 5}} 
                disabled={!common.axles.imageDirty}
                onClick={() => handleChanges('Ok', null)} variant="contained">OK
              </Button>
            </span>
          </Tooltip>

       <Tooltip title='Reload image, cancel changes' placement='bottom'>
         <span>
          <Button className={classes.button} style={{marginTop: 5}}
          disabled={!common.axles.imageDirty} 
          onClick={() => handleChanges('Cancel', null)} variant="contained">Cancel</Button>
          </span>
          </Tooltip>


<Tooltip title="Delete annotation">
          <IconButton  edge='start'
          onClick = {() =>  handleChanges("DeleteTag", null)}
          color="primary" >
          <DeleteIcon/>
          </IconButton>
          </Tooltip>
    </Box>






</Box>



}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 9,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  combo: {
    width: 80,
  },
}));
