
import { ScanPolicy } from "./scanPolicy";
export class AxleCodes {
  weather: string[][];
  status: string[][];
  vehicleClass: string[][];
  filterVehicleClass: string[][];
  orientation: string[][];
  position: string[][];
  occlusion: string[][];
  lifted: any[][];
  axles: number[];
  trailerAxles: number[];
  direction: string[][];

  constructor() {
    this.direction = [["RightToLeft", "<<<"],["LeftToRight", ">>>"]];
    this.weather = [["clear", "Clear"], ["fog", "Fog"], ["rain", "Rain"], ["snow", "Snow"],["undefined", "Undef"]];
    this.status = [["U", ""], ["V1", "V1"], ["V2", "V2"]];
    this.vehicleClass = [
      ["CAR", "CAR", "CAR"], 
      ["MOTORBIKE", "MOTORBIKE", "MTB"], 
      ["BUS", "BUS", "BUS"], 
    ["HEAVY TRUCK", "HEAVY TRUCK", "HVT"], 
    ["LIGHT TRUCK", "LIGHT TRUCK", "LGT"], 
    ["VAN", "VAN", "VAN"], 
    ["NA", "NA", "NA"]];

    this.orientation = [["upright", "Upright"],["horizontal", "Horz"],["across", "Across"], ["undefined", ""]];
    this.position = [["front", "Front"], ["rear", "Rear"], ["other", "Other"], ["undefined", ""]];
    this.occlusion = [ ["no", "None"], ["full", "Full"], ["partial", "Partial"],["undefined", "Undef"]];
    this.lifted = [[0, "Normal"], [1, "Lifted"], [2, ""]];
    this.axles = [];
    this.trailerAxles = [];
    for (let i = 0; i < 10; i++) {
      this.axles.push(i);
      this.trailerAxles.push(i);
    }

    this.filterVehicleClass = [...this.vehicleClass];
    this.filterVehicleClass.splice(0,0, ["ALL", "ALL"]);
  }
}

export class AxlesStateData {
  datasetId: string = '';
  // last record saved
  savedRecordId: string = '';
  bookmarks: string[] = [];
}

export class StitcherData {
  pixelsPerMs: number = 0;
  stitchOffset: number = 0;
}

export class SequenceData {
  multipleVehicles: boolean = false;
  vehicleClass: string = '';
  vehicleCat: string = '';
  trailer: boolean = false;
  axleCount: number = 0;
  twinCount: number = 0;
  raisedCount: number = 0;
  trailerAxleCount: number = 0;
  weather: string = 'clear';
  vehicleOcclusion: string = 'undefined';
  transitDirection: string = '';
  anomaly: boolean = false;
  invalid: boolean = false;
  stitcher: StitcherData = new StitcherData();
}

export class AxlesFilter {
  canFilter: boolean = false;
  problematic: boolean | null = null;
  marked: boolean | null = null;
  tagged: boolean | null = null;
  trailer: boolean | null = null;
  vehicleClass: string = 'ALL';
  minAxles: number = 0;
  maxAxles: number = 10;
  seqId: string = '';
  imageId: string = '';

  reset = () => {
    this.canFilter = false;
    this.problematic = null;
    this.tagged = null;
    this.trailer = null;
    this.marked = null;
    this.vehicleClass = 'ALL';
    this.minAxles = 0;
    this.maxAxles = 10;
    this.seqId = '';
    this.imageId = '';
    
  }
}



export class AxlesData {
  loadingDataset: boolean;
  loadingRecord: boolean;
  loadingImage: boolean;
  records: any[];
  unfiltered: any[] = [];
  thumbs: any[];
  selectedRow: any;
  record: any;
  datasetPath: string | null;
  sequence: SequenceData | null = null;
  // WTT-363 - save original sequence to detect modifications
  originalSequence: string = '';
  tag: any = null;
  cab: any = null;
  codes: AxleCodes;
  imageIndex: number;
  imageId: string = '';
  vehicleClasses: any[] = [];
  showClasses: boolean;
  classIndex: number;
  overlapMode: boolean;
  stitchPixelsPerMs: number;
  stitchOffset: number;
  imageSize: number[];
  debugInfo: string;
  showDebug: boolean;
  totalRecords: number;
  taggedRecords: number;
  axlesSubscription: any = null;
  gridApi: any;
  datasetsGridApi: any = null;
  dirty: boolean = false;
  datasets: any[] = [];
  filter: AxlesFilter = new AxlesFilter();
  itemStatesPercentage: number = 0;
  currentScanningPosition: number = 0;
  savingSequence: boolean = false;

  dataset: any = null;
  panelWidth: number = 0;
  panelHeight: number = 0;
  selectedTab: number = 0;
  detailsWidth: number = 0;
  editorsHeight: number = 0;
  detailsHeight: number = 0;

  nativeRecord: any = null;
  scanPolicy: ScanPolicy = new ScanPolicy();
  showStatusProgress: boolean = true;
  ironIds:string[] = [];
  datasetHash: number = 0;

  // a category has been selected, update vehicle class
  pendingCategorySelection: boolean = false;
  showVehiclesPanel: boolean = false;
  imageDirty: boolean = false;
  imageTags: any[] = [];
  newTagType: string = '';
  stitchTrim: number = 0;
  state: AxlesStateData = new AxlesStateData();
  tags: any[] = [];

  thumbPage: number = 0;
  thumbTotalPages: number = 0;
  thumbsPageSize: number = 30;

    // contains a validation error
    sequenceValidation: string = '';

  defaultWarpingConfig: any = null;
  warpingConfig: any = null;
  // WTT-260 stitched image id
  stitchedId: string = '';
  stitchedUrl: string = '';
  auditStatus: string = '';
  // enable or disable overlap mode change
  overlapAllowChange: boolean = false;

  // WTT-363 
  previousSelectedId: string = '';
  skipChangesCheck: boolean = false;
  reverting: boolean = false;
  // WTT-427
  emptyImage: boolean = false;

  constructor() {
    this.loadingDataset = false;
    this.loadingRecord = false;
    this.loadingImage = false;
    this.records = [];
    this.selectedRow = null;
    this.datasetPath = null;
    this.thumbs = [];
    this.codes = new  AxleCodes();
    this.record = null;
    this.imageIndex = -1;
    this.showClasses = false;
    this.classIndex = 0;
    this.overlapMode = true;
    this.stitchPixelsPerMs = 50;
    this.stitchOffset = 0;
    this.imageSize = [0,0];
    this.debugInfo = '';
    this.showDebug = false;
    this.totalRecords = 0;
    this.taggedRecords = 0;
    this.gridApi = null;
  }
}