import React, {useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { TriSwitch } from './triSwitch';

// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, IconButton,  Checkbox as Checkbox2, Tooltip, Select, MenuItem, Button, TextField } from "@material-ui/core";
import { Search, FilterList, FileCopy, Refresh } from '@material-ui/icons';
import common from '../../services/commonService';



const updateInput = (ref:any, checked:any) => {
  const input = ref.current;
  if (input) {
      input.checked = checked;
      input.indeterminate = checked == null;
  }
};

// eslint-disable-next-line
const ThreeStateCheckbox = ({name, checked, onChange}:{name:any, checked:any, onChange:any}) => {
  const inputRef = React.useRef(null);
  const checkedRef = React.useRef(checked);
  React.useEffect(() => {
      checkedRef.current = checked;
      updateInput(inputRef, checked);
  }, [checked]);
  const handleClick = () => {
      switch (checkedRef.current) {
          case true:
              checkedRef.current = false;
              break;
          case false:
              checkedRef.current = null;
              break;
          default: // null
              checkedRef.current = true;
              break;
      }
      updateInput(inputRef, checkedRef.current);
      if (onChange) {
          onChange(checkedRef.current);
      }
  };
  return (
    <input ref={inputRef} type="checkbox" name={name} onClick={handleClick} 
    style={{transform: 'scale(1.5)', margin:"2px"}}  />
  );
};


/**
 * filter control for plates
 * @param props 
 * @returns 
 */
export function AxlesFilter(props: any) {
  
  const [value, setValue] = useState(0); // integer state
  const [showFilter, setShowFilter] = useState(false);

  const classes = useStyles();

  /**
   * callbacks
   * @param arg 
   * @param value 
   */
  const handleCallback = (arg: string, value: any) => {
    try {
      switch (arg) {
        case "ToggleFilter":
          setShowFilter(!showFilter);
          break;

        case 'AxleFilterEnabled':
          setValue(v => v + 1);
          break;

        case 'vehicleClass':
          filter.vehicleClass = value as string;
          setValue(v => v + 1);
          common.notify('AxlesFilterChanged');
          break;

        case 'minAxles':
        case 'maxAxles':
          filter[arg] = parseInt(value);
          setValue(v => v + 1);
          common.notify('AxlesFilterChanged');
          break;

        case 'RefreshList':
          common.notify('AxlesFilterChanged');
          break;

        case 'SequenceId':
          filter.seqId = value;
          setValue(v => v + 1);
          common.notify('AxlesFilterChanged');
          break;

        case 'ImageId':
          filter.imageId = value;
          setValue(v => v + 1);
          common.notify('AxlesFilterImageIdChanged');
          break;

        case 'trailer':
        case 'tagged':
        case 'problematic':
        case 'marked':
          filter[arg] = value;
          common.notify('AxlesFilterChanged');
          break;
     
      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  /**
   * register for callbacks
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallback(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);


  const comboOptions:string[] = ["Tagged", "Not tagged", "Visited", "Not visited", 
  "Validation OK", "Validation error"];

const filter = common.axles.filter;
const codes = common.axles.codes;

  // rendering
  return (
    <Box display="flex" flexDirection="column"  >

    <Box display="flex" flexDirection="row" alignItems="center">
      <Tooltip title= 'Plates filter' placement="left">
         <IconButton  style={{height:20}}
        onClick = {(e) =>  handleCallback("ToggleFilter", e) }
          color="primary" >
        <FilterList/>
      </IconButton>
      </Tooltip>
      <b>Filter and search</b>

      <Box display="flex" flex="1"></Box>

      { showFilter && filter.canFilter  && 
      <Tooltip title="Refresh list">
        <IconButton style={{height:20}}
        onClick={(e) => handleCallback("RefreshList", e)}>
          <Refresh></Refresh>
        </IconButton>
        </Tooltip>
      }


      </Box>


      { showFilter && filter.canFilter &&
        <Box display="flex" flexDirection="column">

          <Box display="flex" flexDirection="row" alignItems="center" height="25px">
          <div style={{width:'14px', height:'14px', background:'red', margin:'2px'}}/>
            <Box display="inline" width="80px" margin="6px">Problematic:</Box>
            <TriSwitch value={filter.problematic} onChange={(e:any) => handleCallback('problematic', e) }/>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'skyBlue', margin:'2px'}}/>
        <Box display="inline" width="80px" margin="6px">marked:</Box>
        <TriSwitch value={filter.marked} onChange={(e:any) => handleCallback('marked', e) }/>
        </Box>

          <Box display="flex" flexDirection="row" alignItems="center"  height="25px">
            <Box display="inline" width="96px" margin="6px">Tagged:</Box>
            <TriSwitch value={filter.tagged} onChange={(e:any) => handleCallback('tagged', e) }/>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center"  height="25px">
            <Box display="inline" width="96px" margin="6px">Trailer:</Box>
            <TriSwitch value={filter.trailer} onChange={(e:any) => handleCallback('trailer', e) }/>
          </Box>

      <Box display="flex" flexDirection="row" alignItems="center" margin="6px">
      <Box display="inline" style={{width:80}}>Class: </Box>
      <Select value={filter.vehicleClass || ''} className={classes.combo}
          onChange={e =>handleCallback("vehicleClass", e.target.value)}>
          {codes.filterVehicleClass.map(pt => ( <MenuItem value={pt[0] || ''}>{pt[1] || ''}</MenuItem>))}
      </Select>
    </Box> 

    <Box display="flex" flexDirection="row" alignItems="center" margin="6px" height="25px">
    <Box display="inline" style={{width:80}}>Axles min: </Box>
    <TextField type="number" className={classes.number} value={filter.minAxles} onChange={(e) => handleCallback("minAxles", e.target.value)} ></TextField>
    <Box display="inline">&nbsp;max:&nbsp;</Box>
    <TextField type="number" className={classes.number} value={filter.maxAxles} onChange={(e) => handleCallback("maxAxles", e.target.value)}  ></TextField>
    </Box>

    <Box display="flex" flexDirection="row" alignItems="flex-end" margin="6px" height="25px">
      <Box display="inline" width="70px">Seq Id:</Box>
      <TextField InputProps={{style: {fontSize:'10px', width:'150px'}}}
      type="search" label="" value={filter.seqId} onChange={(e) => handleCallback("SequenceId", e.target.value) }></TextField>
    </Box>
    
    <Box display="flex" flexDirection="row" alignItems="flex-end" margin="6px" height="25px">
      <Box display="inline" width="70px">Image Id:</Box>
      <TextField InputProps={{style: {fontSize:'10px', width:'150px'}}}
      type="search" label="" value={filter.imageId} onChange={(e) => handleCallback("ImageId", e.target.value) }></TextField>
    </Box>


  </Box> 
  
  }
  </Box>

  

);


}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 9,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  combo: {
    width:150,
  },
  number: {
    width:50,
  }
}));