import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box'
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import { CanvasWrapper } from './canvasWrapper';
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';
import { PlateList }  from './plateList';
import { PlateDetails} from './plateDetails';
import { PlatesEditor } from './platesEditor';
import { BccmViewer } from './bccmViewer';
import { AuditPanel } from './auditPanel';

export function PlatesSplitter(props: any) {

  const [value, setValue] = useState(0); // integer state

/**
 * general callback
 * @param arg 
 * @param value 
 */
const handleCallback = (arg: string, value: any) => {
  try {
    switch (arg) {
      case 'PlateSettingsChanged':
        setValue(v => v + 1);
        break;
    }
  } catch (ex) {
    console.error('failed to handle callback: ', ex);
  }
}

/**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);

const record = common.plates.selectedRecord;

// rendering
return ( 
  <Box display="flex" flex="1" style={{background:'whitesmoke', height:'100%'}}>
      <ResizeObserver onResize={(rect) => 
        {common.plates.panelWidth = rect.width; common.plates.panelHeight = rect.height}}></ResizeObserver>
<SplitterLayout vertical={true} secondaryMinSize={100} primaryMinSize={100} >
  <SplitterLayout vertical={false} secondaryMinSize={400} primaryMinSize={200} >
    <CanvasWrapper></CanvasWrapper> 
    { !common.plates.settings.auditMode &&   <PlateDetails></PlateDetails> }
  </SplitterLayout>
  { !common.plates.settings.auditMode &&  <PlatesEditor ></PlatesEditor>}
  { common.plates.settings.auditMode &&  <AuditPanel ></AuditPanel>}
  {/* { common.plates.bccm && <BccmViewer></BccmViewer>} */}
      </SplitterLayout>

  </Box>
)

}