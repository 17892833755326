import React, {useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/styles";
import Box from '@material-ui/core/Box'
import insideService from '../../services/insideService';
import { InsideList } from './insideList';
import common from '../../services/commonService';
import { Annotator } from './annotator';
import tags from '../../services/tags';
import { VehiclePanel2 } from './vehiclePanel2';


interface Point{
  x: number;
  y: number;
}

export function InsideInspection(props: any) {

  const [value, setValue] = useState(0); 
  const classes = useStyles();

  /**
   * 
   * @param id 
   */
  const loadRecord = (id: string) => {
    try {
      common.inside.loadingRecord = true;
      common.notify('InsideLoadingRecord');
      insideService.getRecord(common.inside.datasetPath as string, id)
      .then( response => response.json())
      .then(response => {
        common.inside.loadingRecord = false;
        common.notify('InsdeLoadingRecord');
        if (!response.data) {
          console.error('failed to get record:', response.message);
          return;
        }
        // compatible with plates
        tags.setImage(response.data.base64);
        tags.setVehicles(response.data.Annotations);
        
      
      })
      .catch( reason => {
        common.inside.loadingRecord = false;
        common.notify('InsideLoadingRecord');
        console.error('failed to get record', reason);
      });

    } catch(ex) {
      console.error('failed to load record');
    }
  }

  /**
   * user clicked the save button
   */
  const handleSave = () => {
    try {
      // const path = common.selectedPath as string;
      const datasetPath = "dummy";
      const packed = tags.getVehicles();
      const record = common.inside.selectedRow;
      const id = record.id;
      const TagCount = record.TagCount;
      const Status = record.Status;
      const props = {opaque: tags.opaque, night: tags.night};
      const rec = {id, Annotations: packed, props, TagCount, Status };
      insideService.saveRecord(datasetPath, rec as any);

    } catch (ex) {
      console.error('failed to save sequence:', ex);
    }
  }

  const updateSelectedRecord = () => {
    try {
      const rec = common.axles.selectedRow;
      const seq = common.axles.sequence;
    

    } catch (ex) {
      console.error('failed to update selected record');
    }
  }

  

  /**
   * events handler
   * @param arg 
   * @param value 
   */
  const handleCallback = (arg: string, value: any) => {
    try {
      switch (arg) {

        case 'InsideLoadRecord':
          loadRecord(value);
          break;

        case 'InsideSaveRecord':
          handleSave();
          break;

      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  /**load dataset with the specified path
   * @param datasetPath 
   */
  const loadDataset = (datasetPath: string) => {
    try {
    common.inside.loadingDataset = true;
    common.notify('InsideLoadingDataset');
    insideService.getDataset(datasetPath)
    ?.then(response => response.json())
    .then((response) => {
      const data = response.data;
      common.inside.records = data.records;
      common.inside.datasetPath = datasetPath;
      common.inside.totalRecords = data.records?.length;
      common.inside.taggedRecords = data.records.filter((r:any) => r.Status !== 0).length;
   
      common.inside.loadingDataset = false;
      common.notify('InsideLoadingDataset');
      common.notify('InsideDatasetLoaded');
      })
      .catch((reason) => {
        common.inside.loadingDataset = false;
        common.notify('InsideLoadingDataset');
        console.error('failed to get dataset from dir:', reason);
      });
  
    } catch (ex) {
      common.inside.loadingDataset = false;
      common.notify('LoadingDataset', false as any);
      console.error('failed to load selected dataset:', ex);
    }
  }


  /**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });

    // bypass dataset selection
  loadDataset("dummy");
  return (() => {
    subscription.unsubscribe()});
}, []);

  const axles = common.axles;
  return (
    <div className={classes.paper}>
      <div className={classes.main} >
        <Box display="flex" flex="1" flexDirection="column" width="100%" height="100%">
          <Box display="flex" flex="1" flexDirection="row" height="100%">
            <Box display="flex" width="180px" height="100%" flexDirection="column" 
            justifyContent="center" 
            alignItems="center" >
                <InsideList></InsideList>
            </Box>
            
            <Box display="flex" flex="1" flexDirection="column">
            <Box display="flex" flex="1" flexDirection="column">
             <Annotator></Annotator>
             </Box>

             <Box display="flex"  flexDirection="column">
             <VehiclePanel2></VehiclePanel2>
             </Box>

            </Box>
          </Box>
        </Box>
   
    </div>
    </div>
  );
}

const useStyles = makeStyles({
	paper: {
		width: "100%",
		height: "100%",
    display: "flex",
    flexDirection: "column",
    
  },

  main: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },

  canvasContainer: {
    display: "flex",
    backgroundColor: "gainsboro",
    padding: 10,
    overflow: "hidden",
  },

  bottomPanel: {
    display: "flex",
    height: 160,
    minHeight: 160,
    maxHeight: 160,
  },

  buffer: {
    display: "flex",
    flex: 1,
  },

  header: {
    height: 40,
    background: 'gainsboro',
  },

  canvas: {
    // position: 'relative',
    width: 1920,
    height: 1200,
    background: "gray",
  },

  combo: {
    margin: 10,
  },

  tagger: {
    display: "flex",
    flex: 1,
  },

  horizontal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },

  column: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: 2,
    fontSize: 10,
    // backgroundColor: '#29367d',
    color: 'white',
    backgroundColor: 'black',
  },

  activeButton: {
    margin: 2,
    fontSize: 10,
    // backgroundColor: '#29367d',
    color: 'white',
    backgroundColor: 'dodgerBlue',
  },
  badge: {
    fontSize: 5,
  },


  

});