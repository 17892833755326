import { ScriptRow } from '../data/scriptsData';
import common from './commonService';
import platesService from './platesService';
import { ScriptLogRow, ScriptFiles  } from '../data/scriptsData';
import { truncate, truncateSync } from 'fs';
import { AndroidTwoTone, TramRounded } from '@material-ui/icons';
import { async } from 'rxjs';
const axios = require ('axios');

export class ScriptingService {

  

  constructor() {

    common.notifier$.subscribe(msg => {
      switch (msg.name) {

        case "StartGrinding":
          this.startGrinding();
        break;

        case "StopGrinding":
          common.app.grinder.active = false;
          break;
        
      }});
      
    }
    
  initialize() {
  }


  delay = (n: number) => {
    return new Promise(function(resolve){
        setTimeout(resolve,n);
    });
  }

  getPlateRect = (header: any) => {
    try {
      if (!header) throw(new Error('no metadata header'));
      const keys = ['I_PLATE_MIN_X','I_PLATE_MIN_Y','I_PLATE_MAX_X','I_PLATE_MAX_Y'];
      return keys.map(k => parseInt(header[k]));
    } catch (ex) {
      console.error('failed to get plateRect:',ex);
      return null;
    }
  }

  startGrinding = async () => {
    try {
      const grinder = common.app.grinder;
      grinder.active = true;
      const d = new Date();
      grinder.startTime = d.toLocaleTimeString();
      this.grinder5();
    } catch (ex) {
      console.error('failed on start grinding:', ex);
    }
  }

  dummy = () => {
    console.log('dummy');
  }

  // demonstrate promises accumulation
  grinder3 = async () => {
    try {
      while(true) {
        await new Promise(r => setTimeout(r, 1000));
        this.dummy();
      }
    } catch (ex) {
      console.error('failed on grinder 3');
    }
  }



  // grinder4 = async () => {
  //   try {
  //     while(true) {
  //       const url = "https://pandora.tattile.com/api/items/60b8a03d7b555f7149e9cd59";
  //       const details = await axios.get(url);
  //     }
  //   } catch (ex) {
  //     console.error('failed on grinder 3');
  //   }
  // }

  grinder5 = () => {
    try {
      const grinder = common.app.grinder;
      setInterval(() => {
        if (grinder.active && !grinder.busy) {
          switch(grinder.script) {
            case "annotate and next":
              this.annotatePlus(true, false);
              break;

            case "annotate current":
              this.annotatePlus(false, false);
              break;

            case "annotate and plate":
              this.annotatePlus(true, true);
              break;

            case "annotate plate number":
              this.setPlateNumber();
              break;
          }
        }
      }, 100);
    } catch (ex) {
      console.error('failed on grinder 4');
    }
  }


/**
 * simulate a single annotation
 * @param saveAndNext 
 * @returns 
 */
  annotatePlus = async (saveAndNext: boolean, plateNumber: boolean) => {
    try {
      common.app.grinder.busy = true;
      const plate = common.plates.selectedRecord;
      const native = common.plates.nativeRecord;
      if (!plate || !native)
        return;


        common.app.grindCounter++;
        common.notify('DebugStatusUpdate');

      const r = this.getPlateRect(native.image_library.header) || [100, 100, 200, 200];
      const center = [(r[0] + r[2])/2, (r[1] + r[3])/2];

      const plateCenter = platesService.canvas.getWindowPoint(center[0],center[1]);
      const ws = platesService.canvas.windowSize;
      const windowCenter = [ws[0]/2, ws[1]/2];

      common.notify("PlatesUnzoom");

      common.notify("ClearAnnotations");
      await this.delay(100);

      common.notify("SimulateMouse", {action: 'mouseDown', x:plateCenter.x, y:plateCenter.y, noTrans:true} as any);
      for (let i = 0; i < 30; i++) {
        const x = plateCenter.x + i *(windowCenter[0] - plateCenter.x) / 30;
        const y = plateCenter.y + i *(windowCenter[1] - plateCenter.y) / 30;
        common.notify("SimulateMouse", {action: 'mouseMove', x, y, noTrans:true} as any);  
        await this.delay(10);
      }
      common.notify("SimulateMouse", {action: 'mouseUp', x:0, y:0, noTrans:true} as any);  

      common.notify("SimulateMouse", {action: 'mouseMove', x:center[0], y:center[1]} as any);
      await this.delay(50);
      for (let i = 0; i < 5; i++) {
        common.notify("SimulateMouse", {action: 'mouseWheel', wheel: 1} as any);
        await this.delay(50);
      }

      common.notify("SimulateMouse", {action: 'mouseClick', x:r[0], y:r[1]} as any);
      common.notify("SimulateMouse", {action: 'doubleClick', x:r[0], y:r[1]} as any);
      await this.delay(10);
      for (let i = 0; i < 30; i++) {
        const x = r[0] + i *(r[2] - r[0]) / 30;
        const y = r[1];
        common.notify("SimulateMouse", {action: 'mouseMove', x, y} as any);  
        await this.delay(10);
      }
      common.notify("SimulateMouse", {action: 'mouseUp', x:r[2], y:r[1]} as any);
      await this.delay(10);

      common.notify("SimulateMouse", {action: 'mouseDown', x:r[2], y:r[1]} as any);
      await this.delay(10);
      for (let i = 0; i < 30; i++) {
        const x = r[2]
        const y = r[1] + i * (r[3] - r[1]) / 30;
        common.notify("SimulateMouse", {action: 'mouseMove', x, y} as any);  
        await this.delay(10);
      }
      common.notify("SimulateMouse", {action: 'mouseUp', x:r[2], y:r[3]} as any);
      await this.delay(10);


      common.notify("SimulateMouse", {action: 'mouseDown', x:r[2], y:r[3]} as any);
      await this.delay(10);
      for (let i = 0; i < 30; i++) {
        const x = r[2] + i *(r[0] - r[2]) / 30;
        const y = r[3];
        common.notify("SimulateMouse", {action: 'mouseMove', x, y} as any);  
        await this.delay(10);
      }
      common.notify("SimulateMouse", {action: 'mouseUp', x:r[0], y:r[3]} as any);
      await this.delay(200);
      common.notify("SimulateMouse", {action: 'mouseDown', x:r[0], y:r[3]} as any);
      await this.delay(10);
      common.notify("SimulateMouse", {action: 'mouseUp', x:r[0], y:r[3]} as any);
      await this.delay(10);



      // resize
      const w = r[2] - r[0];
      const h = r[3] - r[1];
      const resized = [r[0] - w/20, r[1] - h/20, r[2] + w/20, r[3] + h/20];
      await this.drag(r[0],r[1], resized[0], resized[1], 10);
      await this.delay(100);
      await this.drag(r[2],r[1], resized[2], resized[1], 10);
      await this.delay(100);
      await this.drag(r[2],r[3], resized[2], resized[3], 10);
      await this.delay(100);
      await this.drag(r[0],r[3], resized[0], resized[3], 10);
      await this.delay(100);

      const midX = (resized[0] + resized[2]) / 2;
      const midY = (resized[1] + resized[3]) / 2;
      const left = [resized[0], midY];
      const top = [midX, resized[1]];
      const right = [resized[2], midY];
      const bottom = [midX, resized[3]];

      const left2 = [left[0] + w/20, left[1]];
      const top2 = [top[0], top[1] + h/20];
      const right2 = [right[0] - w/20, right[1]];
      const bottom2 = [bottom[0], bottom[1] - h/20];


      await this.drag(left[0], left[1], left2[0], left2[1], 10);
      await this.delay(100);
      await this.drag(top[0], top[1], top2[0], top2[1], 10);
      await this.delay(100);
      await this.drag(right[0], right[1], right2[0], right2[1], 10);
      await this.delay(100);
      await this.drag(bottom[0], bottom[1], bottom2[0], bottom2[1], 10);
      await this.delay(100);

      const measure = [[midX - w/20, resized[1] + h/10], [midX + w/20, resized[3] - h/10]];

      common.notify("SimulateMouse", {action: 'mouseClick', x:measure[0][0], y:measure[0][1]} as any);
      common.notify("SimulateMouse", {action: 'doubleClick', x:measure[0][0], y:measure[0][1]} as any);

      for (let i = 0; i < 30; i++) {
        const x = measure[0][0] + i *(measure[1][0] - measure[0][0]) / 30;
        const y = measure[0][1] + i *(measure[1][1] - measure[0][1]) / 30;
        common.notify("SimulateMouse", {action: 'mouseMove', x, y} as any);  
        await this.delay(10);
      }

      common.notify("SimulateMouse", {action: 'mouseDown', x:measure[1][0], y:measure[1][1]} as any);
      await this.delay(10);
      common.notify("SimulateMouse", {action: 'mouseUp', x:measure[1][0], y:measure[1][1]} as any);
      await this.delay(10);

      if (plateNumber) {
        this.setPlateType();
        await this.setPlateNumber();
      }

      // save and goto next record
      if (saveAndNext) {
        common.notify('PlatesSaveRecord');
        await this.delay(1000);
  
        const lastRecord = common.plates.records[common.plates.records.length -1];
        if (common.plates.selectedRow === lastRecord)
          common.notify("SelectPlateByIndex", 1 as any);    
      }
  
      } catch (ex) {
      console.error('failed on grinder51:', ex);
    } finally {
      common.app.grinder.busy = false;
    }
  }

  /***
   * simulate dragging
   */
  drag = async (x0: number, y0: number, x1: number, y1: number, steps: number) => {
    try {
      common.notify("SimulateMouse", {action: 'mouseMove', x:x0, y:y0} as any);
      await this.delay(10);
      common.notify("SimulateMouse", {action: 'mouseDown', x:x0, y:y0} as any);
      for(let i = 0; i < steps; i++) {
        const x = x0 + i * (x1 - x0) / steps;
        const y = y0 + i * (y1 - y0) / steps;
        common.notify("SimulateMouse", {action: 'mouseMove', x, y} as any);  
        await this.delay(10);
      }
      common.notify("SimulateMouse", {action: 'mouseUp', x1, y1} as any);  
    } catch (ex) {
      console.error('failed to drag:', ex);
    }
  }

  setPlateNumber = async () => {
    try {
      const ant = common.plates.selectedAnnotation;
      if (!ant?.PlateNumber)
        return;

      const plate = common.plates.nativeRecord?.image_library?.header?.S_PLATE;
      if (!plate)
        return;
      

      let typed = '';
      for(let i = 0; i < plate.length; i++) {
        typed += plate.charAt(i)
        common.notify("SimulatePlateNumber", typed as any);
        await this.delay(5);
      }
      await this.delay(100);
      
    } catch (ex) {
      console.error('failed to set plate number:', ex);
    }
  }

 randomInt = (max:number) => {
  return (Math.floor(Math.random()*max));
 }

  setPlateType =  () => {
    try {
      const plates = common.plates;
      const ant = plates.selectedAnnotation;
      if (!ant)
        return;

      let count = plates.plateTypes.length;
      const pt = this.randomInt(count).toString();
      common.notify('SimulatePlateType', pt as any);
      count = plates.vehicleTypes.length;
      const vt = this.randomInt(count).toString();
      common.notify("SimulateVehicleType", vt as any);


    } catch (ex) {
      console.error('failed on setPlateType:', ex);
    }
  }
}



const scriptingService: ScriptingService = new ScriptingService();
export default scriptingService;