import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import Box from '@material-ui/core/Box'
import { Video } from '../components/video/video';



export function VideoPage() {
  const classes = useStyles();


  React.useEffect(() => {
    try {
    } catch (ex) {
      console.error('failed to initialize inside page:', ex);
    }  }, []);



  const settingRef = (r: any) => {
    console.log('setting ref');
  }

  const handleCallback = (arg: string, value: any) => {
    try {
      switch (arg) {
        case 'NextRecord':
          break;
      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }




	return (
		<div className={classes.root}>
        <Box display="flex" flex="1" flexDirection="column">
        <Video></Video>
        </Box>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
  },
  tableContainer: {
    display: "flex",
    marginLeft: 10,
  },

  panelContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    background: "gainsboro",
    marginLeft: 10,
  },
  
  table: {
    display: "flex",
  },

  panel: {
    display: "flex",
    flexDirection: "column",
    flex:1,
    backgroundColor: "magenta",
  },
  editor: {
    display: "flex",
    height: "auto",
    backgroundColor:"lavender",
  },

	buttonContainer: {
    display: "flex",
    width: 200,
    flexDirection: "column",
    marginLeft: 10,
	},

	button: {
    marginBottom: 2,
    fontSize: 10,
    backgroundColor: '#29367d',
	},
}));
