
import tags from "../../../services/tags";
import common from "../../../services/commonService";

class Belts {
  constructor() {

  }

  setBelt(belt:any) {
    try {
      tags.vehicle.belts[tags.objectIndex] = belt;
      tags.updateObject();
    } catch (ex) {
      console.error('failed to setBelt:', ex);
    }
  }

  clear = () => {
    try {
      this.setBelt([]);
    } catch (ex) {
      console.error('failed to clear:', ex);
    }
  };


  click = (evt: any) => {
    try {
      const pt = tags.getPoint(evt);
      const belt = tags.vehicle.belts[tags.objectIndex];
      if (!belt)
        return;

      if (belt.length >= 4)
        return;

      belt.push(pt);
      tags.updateObject();
      tags.undoAdd();
      common.notify('InsideTagsAdded');

    } catch(ex) {
      console.error('failed on click: ', ex);
    }
  };

  mouseDown = (evt: any) => {
    try {
      tags.dragAnchor = tags.hover;
    } catch(ex) {
      console.error('failed on mouseDown: ', ex);
    }
  };

  mouseMove = (evt: any) => {
    try {

      if (tags.dragAnchor) {

        tags.vehicle.belts.forEach(belt => {
          for(let i = 0; i < belt.length; i++) {
            if (belt[i] === tags.dragAnchor) {
              const pt = tags.getPoint(evt);
              belt[i] = pt;
              tags.dragAnchor = pt;
            }
          }
        });
        return;
      }

      const ptMouse = tags.getPoint(evt);
      tags.hover = null;
      tags.vehicle.belts.forEach(belt => {
        belt.forEach(pt => {
          if (tags.getDistance(ptMouse, pt) < 5) {
            tags.hover = pt;
            return;
          }
        });
      });

    } catch(ex) {
      console.error('failed on mouseMove: ', ex);
    }
  };

  mouseUp = (evt: any) => {
    try {
      if (tags.dragAnchor)
        tags.undoAdd();
        
      tags.dragAnchor = null;
    } catch(ex) {
      console.error('failed on mouseUp: ', ex);
    }
  };
}

const belts: Belts = new Belts();

export default belts;