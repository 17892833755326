import React, {useState, useEffect} from 'react';
import { Box } from "@material-ui/core";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import common from '../../services/commonService';
import { AxlesFilter } from './axlesFilter';

/**
 * List of records
 * @param props 
 * @returns 
 */
export function RecordList(props: any) {

  // const [gridApi, setGridApi] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState(0);

  const [value, setValue] = useState(0); // integer state

  const matchFormatter = (params:any) => {
    const auditStatus = params?.data?.auditStatus || '';
    switch (auditStatus) {
      case 'passed': return '✓';
      case 'failed': return 'x';
    }
    return '';
  }

  const columnDefs = [
    {headerName: '', 
    field: 'state',
    width: 20, 
    headerComponentParams: {
      template: '<div style="display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px;border-left:6px solid gray;border-right:6px solid gray;border-bottom:6px solid transparent" ></div>'
    },
    equals: (s1:any, s2:any) => { 
      return false;},
    cellRenderer: (data:any) => {
      let color = data?.value?.bookmark ? 'skyBlue' : 'transparent';
      if (data?.value?.lastSaved)
      color = 'mediumBlue';
      if (data?.value?.problematic)
      color = 'red';
      
      // override (in test)
      // if (color === 'skyBlue')
      //   return ;


      return '<div style="background-color:' + color + ';display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px" ></div>';
  }},

    {headerName:'#', field: 'index', width: 60, resizable: true},
    
    {headerName: 'Status', 
    field: 'status',
    width: 60, 
    cellRenderer: (data:any) => {
      let color = 'gray';
      switch(data?.value) {
        case "unvisited":
          color = 'transparent';
          break;

        case "error":
          color = 'red';
          break;
        case "pre-tagged":
          color = 'orange';
          break;

        case 'not-tagged':
          color = 'gray';
          break;

        case 'tagged':
          color = 'lawngreen';
          break;
      }
      return '<div style="background-color:' + color + ';display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px" ></div>';
  }},

  { headerName:'✓',  field: 'auditStatus', valueFormatter: matchFormatter, width: 60
},

  // {headerName: 'Audit',
  // width: 60,
  // field: 'auditStatus',
  // cellRenderer: (data:any) => {
  //   let color = 'gray';
  //   switch(data?.value) {
  //     case 'passed': 
  //       color = 'lawngreen';
  //       break;
        
  //     case 'failed':
  //       color = 'red';
  //       break;
  //   }
  //   return '<div style="background-color:' + color + ';display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px" ></div>';
  //  }},

    {headerName: 'Vehicle',
      width: 100,
      field: 'vehicleIcon',
      cellRenderer: (data:any) => {
        return `<img src="${getImageUrl(data.value)}" height="32px"></img>`;
      }},


  ];

  const getImageUrl = (cat: string) => {
    try {
      if (!cat)
        return '';

      return `data/vehicles/${cat}.png`;
    } catch (ex) {
      console.error('failed to get imageUrl:', ex);
      return null;
    }
  }

  const handleSelection = (e: any) => {
    try {
      // disable selection while loading plate
      if (common.axles.loadingRecord)
        return;

      const selectedRows = e.api.getSelectedRows();
      if (selectedRows.length === 0)
        return;
        
      const selectedRow = selectedRows[0];
      // WTT-363
      common.axles.previousSelectedId = common.axles.selectedRow?.id;
      common.axles.selectedRow = selectedRow;
      common.axles.showVehiclesPanel = false;
      common.notify("AxlesRecordSelected", selectedRow.id);
    } catch (ex) {
      console.error('failed on handle selection');
    }
  }

  /**
   * Select the first record
   */
  const selectFirstRecord = () => {
    try {
      setTimeout(() => {
        const api = common.axles.gridApi;
        if (api) 
          api.forEachNode((node:any)=> node.rowIndex == 0  ? node.setSelected(true) : node.setSelected(false))
      }, 100);
    } catch (ex) {
      console.error('failed to select first row:', ex);
    }
  }


  /**
   * update cells to reflect state or tag count changes
   */
  const updateCells = () => {
    try {
      const gridApi = common.axles.gridApi;
      (gridApi as any).refreshCells({force: true});
    } catch (ex) {
      console.error('failed to update validation state:', ex);
    }
  }

  const gotoNextRecord = () => {
    try {
      if (common.axles.dirty || common.axles.imageDirty)
        return;

      const api = common.axles.gridApi;
      if (api == null) return;

      const selectedRow = common.axles.selectedRow;
      let currentNode:any = null;
      api.forEachNode((node:any) => {if (node.data === selectedRow) currentNode = node });
      if (!currentNode) throw(new Error('failed to find current node'));
      api.forEachNode((node:any)=> node.rowIndex === currentNode.rowIndex + 1 ? node.setSelected(true) : node.setSelected(false));

      // const currentIndex = common.axles?.selectedRow?.index;
      // // hack - rowIndex and index
      // // rowIndex - 0,1,2,3,4 
      // // index  - 1, 2, 3, 4 (programatic)
      // api.forEachNode((node:any)=> node.rowIndex == currentIndex  ? node.setSelected(true) : node.setSelected(false))
      
    } catch (ex) {
      console.error('failed to gotoNext:', ex);
    }
  }

  const gotoPreviousRecord = () => {
    try {

      if (common.axles.dirty || common.axles.imageDirty)
        return;
  
        const api = common.axles.gridApi;
      if (api == null) return;

      const selectedRow = common.axles.selectedRow;
      let currentNode:any = null;
      api.forEachNode((node:any) => {if (node.data === selectedRow) currentNode = node });
      if (!currentNode) throw(new Error('failed to find current node'));
      api.forEachNode((node:any)=> node.rowIndex === currentNode.rowIndex - 1 ? node.setSelected(true) : node.setSelected(false));


      // const currentIndex = common.axles?.selectedRow?.index;
      // // hack - rowIndex and index
      // api.forEachNode((node:any)=> node.rowIndex == currentIndex - 2  ? node.setSelected(true) : node.setSelected(false))
      
    } catch (ex) {
      console.error('failed to gotoNext:', ex);
    }
  }

  function onGridReady(params:any) {
    // setGridApi(params.api);
    common.axles.gridApi = params.api;
    const api = common.axles.gridApi;
}

  /**
   * double click cell to toggle bookmark
   * @param e 
   */
   const handleCellDoubleClicked = (e: any) => {
    try {
      const api = common.axles.gridApi;
      const recs = common.axles.records;
      e.data.state.bookmark = !e.data.state.bookmark;
      api.refreshCells();
      common.axles.state.bookmarks = recs.filter(r => r.state.bookmark).map(r => r.id);
      common.notify("AxlesSaveState");
    } catch (ex) {
      console.error('failed to handle cell focus:', ex);
    }
  }

  const updateAxlesList = () => {
    try {
      setValue(v => v + 1);
    } catch (ex) {
      console.error('failed to update plate list:', ex);
    }
  }

    /**
   * select an entry bi id
   * @param id 
   */
     const SelectSequenceById = (id: any) => {
      try {
        const api = common.axles.gridApi;
        if (!api || !id)
          return;

        api.forEachNode((node:any)=> node.data.id === id ? node.setSelected(true) : node.setSelected(false));
        api.forEachNode((node:any)=> {
          if (node.data.id === id) 
            api.ensureNodeVisible(node, 'middle')
        });
      } catch (ex) {
        console.error('failed to handle plate selection')
      }
    }

  const handleCallbacks = (key: string, value: any) => {
    try {
      switch(key) {
        case 'AxlesOverlapModeChanged':
        case "AxlesDatasetLoaded":
          setValue(value => value + 1);
          if (common.app.search.open)
            selectFirstRecord();
          break;

        case "AxleStateUpdated":
        case "AxlesListChanged":
          common.axles.gridApi.refreshCells();
          break;

        case "AxlesListChanged2":
          updateAxlesList();
          break;

        case "AxlesGotoNextRecord":
          gotoNextRecord();
          break;

        case "AxlesGotoPreviousRecord":
          gotoPreviousRecord();
          break;

        case "SelectSequenceById":
          SelectSequenceById(value);
          break;

        case "AxlesRevertSelection":
          break;

  
      }

    } catch (ex) {
      console.error('failed to handle callbacks:', ex);
    }

  }


  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallbacks(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
   
  });


const records = common.axles.records || [];

  return (
    <Box display="flex" flex="1" flexDirection="column" >
              <AxlesFilter/>
              <div
                className="ag-theme-balham"
                style={{ height: '100%', width: '330px' }}
            >
                <AgGridReact
                    rowHeight={32}
                    rowSelection={'single'}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    onSelectionChanged={ (e:any) => handleSelection(e)}
                    onCellDoubleClicked = {(e:any) => handleCellDoubleClicked(e)}
                    rowData={records}>
              </AgGridReact>

            </div>
      </Box>
  );

}