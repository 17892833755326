import { ReflectiveData } from '../data/platesData';
import common from './commonService';

interface Point {
  x: number;
  y: number;
}

export class ReflectiveService {


    /**
     * standard constructor
     */
    constructor() {
        common.notifier$.subscribe(msg => {
            switch(msg.name) {
              case 'PlateDeleteReflective':
                this.deleteReflective(msg.data);
                break;

            }
        })
    }

    /**
     * standard initializer
     */
    async initialize() {
        try {

        } catch (ex) {
            console.error('failed to initialize diamonds:', ex);
        }
    }

    /**
     * get app data from native data
     * @param n 
     * @returns 
     */
    getReflectiveFromNative(n:any) {
      try {
        const h = new ReflectiveData();
        Object.assign(h, n);
        h.rect = [...n.rect];
        return h;
      } catch (ex) {
        console.error('failed to get hazrd from native:', ex);
      }
    }

    /**
     * get app data from native data
     * @param nativeRecord 
     * @returns 
     */
    getReflectivesFromNative(nativeRecord:any) {
        try {
          let natives = nativeRecord?.image_library?.human?.reflective_bar?.Annotations || [];
          const reflectives = natives.map((n:any) => this.getReflectiveFromNative(n));
          return reflectives;
        } catch (ex) {
          console.error('failed to get lights from native:', ex);
          return [];
        }
      }

      /**
       * native hazard item
       * @param r 
       * @returns 
       */
      getNativeReflective(r:ReflectiveData) {
        try {
          return {rect: r.rect, type: r.type};
        } catch (ex) {
          console.error('failed to get native hazard:', ex);
        }
      }

      /**
       *  native hazard data
       * @param reflectives 
       * @returns 
       */
      getNativeReflectives(reflectives: ReflectiveData[]) {
        try {
          return reflectives.map(h => this.getNativeReflective(h));
        } catch (ex) {
          console.error('failed to get native hazards:', ex);
        }
      }

      /**
       * data for tagging diff
       * @param original 
       * @returns 
       */
      getOriginalAndCurrent(original:any) {
        try {
          const previous = original?.image_library?.human?.reflective_bar?.Annotations || [];
          const current = this.getNativeReflectives(common.plates.reflectives);
          return {previous, current};
        } catch (ex) {
          console.error('failed to get original and current');
          return {previous:null, current:null};
        }
      }

      /***
       * get a new reflective data based on specified rect
       */
      getHazardFromRect(rect: number[]) {
        try {
            const h = new ReflectiveData();
            h.rect = [...rect];
            return h;
        } catch (ex) {
            console.error('failed to get light from rect:', ex);
        }
      }

      isHovering = (): boolean => {
        try {
          
          const reflectives = common.plates.reflectives;
          for (let l of reflectives) {
            if (l.hoverIndex > -1) return true;
        }
        return false;
        } catch (ex) {
          console.error('failed on vehicle hovering:', ex);
          return false;
        }
      }

      resetHoverIndex() {
        try {
          
          const reflectives = common.plates.reflectives || [];
          reflectives.forEach(h => h.hoverIndex = -1);
        } catch (ex) {
          console.error('failed to reset hover index:', ex);
        }
      }

      getPointsEx(rect:number[] | null) {
        try {
          if (!rect || rect.length !== 4)
            return [];
    
            const l = rect[0];
            const t = rect[1];
            const w = rect[2];
            const h = rect[3];
            const top = {x:l+w/2, y:t};
            const right = {x:l+w, y:t+h/2 };
            const bottom = {x:l+w/2,y:t+h};
            const left = {x:l,y:t+h/2};
            const pts =  [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h},top, right, bottom, left];
            return pts;
        } catch (ex) {
          console.error("failed to get points: " + ex);
          return [];
        }
      }

      getDistance = (pt0:Point, pt1: Point) => {
        try {
          const dx = pt0.x - pt1.x;
          const dy = pt0.y - pt1.y;
          return Math.sqrt(dx*dx + dy*dy);
        } catch (ex) {
          console.error('failed to get point: ', ex);
          return 0;
        }
      };


      updateHoverIndex(ptMouse:any, radius: number) {
        try {
          this.resetHoverIndex();
          const tagging = common.plates.newTagType === 'Reflective';
          if (!tagging)
            return false;
      
          // only selected light can be resized
          const selectedReflective = common.plates.selectedReflective;
          if (!selectedReflective)
            return false;

            const pts2 = this.getPointsEx(selectedReflective.rect);
            selectedReflective.hoverIndex = pts2?.findIndex(pt => this.getDistance(pt, ptMouse) < radius);
            if (selectedReflective.hoverIndex > -1)
              return true;
        } catch (ex) {
          console.error('failed to update hover index:', ex);
          return false;
        }
      }

      resizeRect(r:number[], hoverIndex:number, pt:any) {
        try {
          if (hoverIndex < 0) 
            return;

          const right = r[0] + r[2];
          const bottom = r[1] + r[3];
          let rect:number[] = [0,0,0,0];
          switch(hoverIndex) {
            case 0:
              rect = [pt.x, pt.y, right - pt.x, bottom - pt.y];
              break;
    
            case 1:
              rect = [r[0], pt.y, pt.x - r[0], bottom - pt.y];
              break;
    
            case 2:
              rect = [r[0], r[1], pt.x - r[0], pt.y - r[1]];
              break;
    
            case 3:
              rect = [pt.x, r[1], right - pt.x, pt.y - r[1]];
              break;
    
              // mid top
            case 4:
              rect = [r[0], pt.y, r[2], bottom - pt.y];
              break;
    
              // mid right
            case 5:
              rect = [r[0], r[1], pt.x - r[0], r[3]];
              break;
    
              // mid bottom
            case 6:
              rect = [r[0], r[1], r[2], pt.y - r[1]];
              break;
    
            case 7:
              rect = [pt.x, r[1], right - pt.x, r[3]];
              break;
          }
          for (let i = 0; i < 4; i++)
            r[i] = rect[i];

            return true;
        } catch (ex) {
          console.error('failed to resize rect:', ex);
        }
      }

      handleResize = (pt: any) => {
        try {
          const reflectives = common.plates.reflectives || [];

          for (let l of reflectives) {
            if (l.hoverIndex > -1) {
              this.resizeRect(l.rect, l.hoverIndex, pt);
            }
          }
        } catch (ex) {
          console.error('failed to handle resize:', ex);
        }
      }

      deleteReflective = (reflective:any) => {
        try {
          const reflectives = common.plates?.reflectives || [];
          const index = reflectives.indexOf(reflective);
          if (index < 0)
            return;
    
          reflectives.splice(index,1);
          common.notify('ReflectiveDeleted');
          common.plates.selectedReflective = undefined;
          common.notify('AnnotationSelected');
    
        } catch (ex) {
          console.error('failed to delete vehicle:', ex);
        }
      }



}
const reflectiveService: ReflectiveService = new ReflectiveService();
export default reflectiveService;