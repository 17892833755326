import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import {Box,Button, Accordion,  AccordionSummary} from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import  ResizeObserver  from 'react-resize-observer';


export function Item() {
  return (
    <Box margin="10px" style={{backgroundColor:'teal'}}
    width="200px" minWidth="200px" height="200px" minHeight="200px">Item</Box>
  )
}

 function FieldLine(props: any) {
  const {field, value} = props;
  const bool = (typeof value === "boolean");
  const val = bool ? value ? "TRUE" : "FALSE" : value;
    return (
      <Box display="flex" flexDirection="row">
        <Box display="inline">{field}:&nbsp;</Box>
        <Box display="inline" ><b>{val}</b></Box>
      </Box>
    )
}

 function TitleLine(props: any) {
  const {title} = props;
  return <Box display="flex" flexDirection="row"><b>{title}:</b></Box>
}

export function WarpPanel(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 
  const [height, setHeight] = useState(0);
  const [show, setShow] = useState(false);

  const handleChanges = (key: string, value: any) => {
    try {
      switch(key) {
        case "ToggleContent":
          setShow(!show);
          break;

      
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);

  const classes = useStyles();
const w:number = props.width;
const h:number = props.height;



  const config = common.axles.warpingConfig;
  const geometry = config?.AxleConfiguration?.Geometry;

  



  return   (
  <Box display="flex" flex="1" flexDirection="column"  height={h} style={{backgroundColor:'transparent'}}>
  <Button className={classes.button} onClick={() => handleChanges("ToggleContent", null)}>Unwarping config</Button>

{ show && 

  <Box overflow="auto" display="flex" flexDirection="column" flex="1" border="solid black 1px" style={{height:'0px'}} >

<Box display="flex" flexDirection="column" marginLeft="10px" >
      <TitleLine title="CAMERA"/>
      <TitleLine title="Intrinsics"/>
      <FieldLine field="Focal" value={config.Camera.Intrinsics.Focal}/>
      <FieldLine field="PixelSize" value={config.Camera.Intrinsics.PixelSize}/>
    <TitleLine title="AXLE CONFIGURATION"/>
    <FieldLine field="ZoomFactor" value={config.AxleConfiguration.ZoomFactor}/>
    <FieldLine field="IsWrapped" value={config.AxleConfiguration.IsWarped}/>
      <TitleLine title="geomentry"/>
      <Box display="flex" flexDirection="column" marginLeft="20px">
      <TitleLine title="Lane center"/>
      <FieldLine field="Height" value={geometry.LaneCenter.Height}/>
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="20px">
      <TitleLine title="Lane description"/>
      <FieldLine field="Width" value={geometry.LaneDescription.Width}/>
      <FieldLine field="OffsetFromCenter" value={geometry.LaneDescription.OffsetFromCenter}/>
      </Box>
    <TitleLine title="Angles"/>
    <FieldLine field="Tilt" value={geometry.Angles.Tilt}/>
    <FieldLine field="Rotation" value={geometry.Angles.Rotation}/>
    <FieldLine field="Pan" value={geometry.Angles.Pan}/>
    <TitleLine title="Side view"/>
    <FieldLine field="DistanceFromBottomLane" value={config.AxleConfiguration.SideView.DistanceFromBottomLane}/>
  <FieldLine field="Height" value={config.AxleConfiguration.SideView.Height}/>


</Box>


    </Box>
}
    </Box>
    )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "row",
  },
  button: {
    height: 24,
    margin: 2,
    fontSize: 12,
    width: 200,
    color: 'black',
    backgroundColor: 'gainsboro',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
}));
