import axios from 'axios';
import common from './commonService';
import {StatisticsInfo, StatisticsEntry} from '../data/platesData';
import { MenuItem } from '@material-ui/core';
import { isArray, isNumber } from '@material-ui/data-grid';
import axlesService from './axlesServices';


/**
 * cycle through recordset images, and collect info per image
 */
export class AxlesCycler {

  /**
   * launch manager on creation (from plates service)
   */
  constructor() {
    this.extraInfoManager();
  }

  /**
   * scan when appropriate
   */
  extraInfoManager = () => {
    try {
      setInterval(() => {
        const sp = common.axles.scanPolicy;
        if (!sp.busy && !sp.loadingDataset && !sp.abortRequest) {
          this.extraInfoCycle();
        }
      },100);
  
    } catch (ex) {
      console.error('failed on extraInfoManager:', ex);
    }
  }

  delay = (n: number) => {
    return new Promise(function(resolve){
        setTimeout(resolve,n);
    });
  }

  isSequenceValid = (seq: any): boolean => {
    try {
      if (!seq)
        return false;

      const val = '';
      const errors: string[] = [];
      if (seq.vehicleClass === '') 
        return false;
     

      if (!seq.vehicleCat) 
        return false;

      if (seq.weather === '')
        return false;

      if (seq.axleCount < 2)
        return false;

      if (seq.trailer && seq.trailerAxleCount < 1)
        return false;

      if (seq.axleCount - seq.trailerAxleCount < 2)
        return false;

      if (!seq.vehicleOcclusion || seq.vehicleOcclusion === 'undefined')
        return false;

      return true;

    } catch (ex) {
      console.error('failed to validate current sequence:', ex);
      return false;
    }
  }

    /**
   * verify that dataset hash has not changed
   * if changed - sends an error notification
   * @returns 
   */
     checkDatasetHash = async () => {
      try {
        const id = common.axles?.dataset?._id;
        if (!id)
          return;
  
        const serverUrl = process.env.REACT_APP_SERVER_URL;
        const url = `${serverUrl}/dataset/${id}`;
        const reply = await axios.get(url, {headers: {'Authorization': common.loginToken}});
        const ids = reply?.data?.sequences?.join();
        const hash = common.getHash(ids);
        if (hash !== common.axles.datasetHash)
          console.error(`axles dataset hash error - original hash: ${common.axles.datasetHash} new hash: ${hash}` as any);
  
      } catch (ex) {
        console.error('failed to check dataset hash:', ex);
      }
    }

  /**
   * a single cycle (mini or full)
   * @returns 
   */
  extraInfoCycle = async () => {
    try {
      if (common.mode !== 'AXLES')
        return;

      const sp = common.axles.scanPolicy;
      sp.busy = true;
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const recs = common.axles.unfiltered;
      // nothing to scan or not ready
      if (!recs || recs.length === 0 || !common.axles.gridApi?.rowRenderer)
        return;

      if (common.plates.datasetsDialogOpen)
        return;
  
      common.axles.totalRecords = recs.length;
      sp.setFirstRow(common.axles.gridApi.rowRenderer.firstRenderedRow);
      sp.setLastRow(common.axles.gridApi.rowRenderer.lastRenderedRow);
      sp.updateMoveStatus();
  
      let full = true;
      let firstRow = 0;
      let lastRow = recs.length - 1;
  
      switch(sp.scanStatus) {
        case "moving":
        case "unmoving":
        case "none":
          // not in condition to scan
            return;
  
        case "mini":
        case "moved":
          firstRow = sp.firstRow;
          lastRow = sp.lastRow;
          full = false;
          break;
  
        case "initialQuery":
        case "full":
          break;
      }
  
      // show progress only for first full scan
      if (sp.scanStatus === "initialQuery") {
        common.axles.showStatusProgress =  true;
        common.notify('PlateStateUpdated');
      }

      if (sp.scanStatus === 'full')
        await this.checkDatasetHash();
  
      sp.startScan();
      // scanning starts here
      let taggedRecords = 0;
    
    for(let i = firstRow; i <= lastRow; i++) {
      
      common.notify('PlateStateUpdated');
      await this.delay(20);
  
      // abort when changing dataset while fetching
      if (sp.abortRequest) {
        sp.endScan(false);
        common.notify('PlateStateUpdated');
        return;
      }
  
      const rec = recs[i];
      await this.updateRecord(rec);
      common.axles.itemStatesPercentage = Math.round(100 * (i+1) / recs.length);
      common.axles.currentScanningPosition = i + 1;
  
      // periodically refresh the list
      if (i % 5 === 0)
        common.notify('AxleStateUpdated');
    }

    if (sp.scanStatus === 'initialQuery') {
      common.axles.filter.canFilter = true;
      common.notify('AxleFilterEnabled');
    }
  
    sp.endScan(full);
    common.axles.showStatusProgress = false;
    common.axles.currentScanningPosition = lastRow + 1;
    common.notify('AxleStateUpdated');
    return;
  
    } catch (ex) {
      console.error('failed on extra info cycle:', ex);
    } finally {
      common.axles.scanPolicy.busy = false;
    }



    
  }

    /**
   * returns sequence status, based on tagging
   * @param sequence 
   * @returns 
   */
     getSequenceStatus = (sequence: any) : string => {
      try {
        if (!sequence)
          return 'error';
  
        if (sequence.invalid || sequence.anomaly)
          return 'error';
  
        if (!this.isSequenceValid(sequence))
          return 'not-tagged';
  
        return 'tagged';
        
      } catch (ex) {
        console.error('failed to get sequence status: ', ex);
        return 'error';
      }
    }

    /**
   * info to display on record list
   * @param native 
   * @returns 
   */
     getSequenceInfo = (native: any) => {
      try {
        const info = { vehicleCat: '', 
        ImageCount: 0, 
        Status: 'unvisited', 
        axleCount: 0, 
        problematic: false,
        tagged: false,
        vehicleClass: '', 
        trailer: false,
        auditStatus: '' }

        let vc = native?.sequence?.human?.axle?.vehicleCat;
        if (vc?.endsWith('.png'))
          vc = vc.replace('.png', '');
  
        if (vc?.startsWith('data/vehicles/'))
          vc = vc.replace('data/vehicles', '');
  
        info.vehicleCat = vc ?? '';
        info.ImageCount = native?.sequence?.images.length;
        const axle =  native?.sequence?.human?.axle;
        info.Status = axle ?  this.getSequenceStatus(axle) : 'not-tagged';
        // WTT-170 - general fix
        info.problematic = axle?.invalid || axle?.anomaly || false;
        info.tagged = this.isSequenceValid(axle);
        info.axleCount = axle?.axleCount;
        info.vehicleClass = axle?.vehicleClass;
        info.trailer = axle?.trailer || false;
        info.auditStatus = native?.sequence?.human?.auditStatus || '';
        return info;
      } catch (ex) {
        console.error('failed to get sequenceInfo:', ex);
      }
    }

  updateRecord = async (rec:any) => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      if (!rec) throw(new Error('No record to update'));

      // WTT-170
      // fake sequence that hosts a search item
      if (rec.id === '0')
        return;

      const id = rec.id;
      const url = `${serverUrl}/sequence/${id}`;
      const reply = await axios.get(url, {headers: {'Authorization': common.loginToken}});

      const nativeRecord = reply.data;
      const info = this.getSequenceInfo(reply.data);
      rec.vehicleIcon = info?.vehicleCat;
      rec.axleCount = info?.axleCount;
      rec.vehicleClass = info?.vehicleClass;
      rec.status = info?.Status;
      rec.problematic = info?.problematic;
      rec.tagged = info?.tagged;
      rec.imageCount = info?.ImageCount;
      rec.trailer = info?.trailer;
      rec.auditStatus = info?.auditStatus || '';
      if (info && info.Status === 'tagged')
        common.axles.taggedRecords++;


    } catch (ex) {

      console.error('failed to update record:', ex);
    }
  }

















}
