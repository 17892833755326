import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import {Box, Switch, Slider, Select, MenuItem, Checkbox, FormControlLabel, Tooltip, Button, FormControl, FormLabel,RadioGroup, Radio } from '@material-ui/core'

export function ToolsPanel(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 

  const handleChanges = (key: string, value: any) => {
    try {
      switch(key) {

        case 'AxlesTagType':
          switch(value as number) {
            case 0:
              if ( common.plates.settings.tagAxle)
                common.axles.newTagType = 'Axle';
              break;

            case 1:
              if ( common.plates.settings.tagCab)
              common.axles.newTagType = 'Cab';
              break;
          }
          setValue(v => v + 1);
          break;

        case 'TagType':
        common.axles.newTagType = value;
        setValue(v => v + 1);
        break;

        case 'AxlesTagTypeChanged':
          setValue(v => v + 1);
          break;
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);

  const classes = useStyles();
const w:number = props.width;
const h:number = props.height;

  const isDisabled = (key:string) => {
    try {
      // todo - move to common.
      const settings = common.plates.settings;
      switch(key) {
        case "Axle":
          return !settings.tagAxle;

        case "Cab":
          return !settings.tagCab;
          
      }
      return false;

    } catch (ex) {
      console.error('failed on isDsiabled:', ex);
      return false;
    }
  }


  return <Box display="flex" height={h} flexDirection="row"  style={{background:'silver'}} padding={"4px"} >
    <b>&nbsp;New tag:&nbsp;</b>
    <FormControl component="fieldset">
      <RadioGroup row aria-label="gender" name="tagType" value={common.axles.newTagType} onChange={(evt:any) => handleChanges("TagType", evt.target.value)}>
        <FormControlLabel disabled={isDisabled("Axle")} value="Axle" control={<Radio />} label="Axle" style={{height:'22px'}} />
        <FormControlLabel disabled={isDisabled("Cab")} value="Cab" control={<Radio />} label="Vehicle" style={{height:'22px'}} />
       </RadioGroup>
    </FormControl>
  </Box>
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 9,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
}));
