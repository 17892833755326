import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import { SkipNext, SkipPrevious } from "@material-ui/icons"
import {Box, Checkbox, FormControlLabel, IconButton, Typography} from '@material-ui/core'

export function TagNav(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 

  const handleChanges = (key: string, value: any) => {
    try {
      switch(key) {

        case 'AxlesThumbChanged':
        case 'AxlesThumbsLoaded':
        case 'AxlesEmptyChanged':
          setValue(v => v + 1);
          break;

          case "NextImage":
            common.notify('AxlesNextImage');
            break;

          case "PreviousImage":
            common.notify('AxlesPreviousImage');
            break;

          case "TagClicked":
            common.notify('AxlesTagSelected', value);
            break;

          case 'EmptyImage':
            common.notify('AxlesToggleEmpty');
            break;




      
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);

  const classes = useStyles();
  const sequence = common.axles.sequence;
  const codes = common.axles.codes;
  const tag = common.axles.tag;
  const thumbs = common.axles.thumbs;
  const empty = common.axles.emptyImage;

  const noTags = common.axles.imageTags?.length === 0;


  
const w:number = props.width;
const h:number = props.height;

const getBorder = (tag:any) => {
  try {
    const selected = common.axles.tag === tag;
    return selected ?  'solid lawngreen 3px' : 'solid gainsboro 3px';

  } catch (ex) {
    console.error('failed to get border:', ex);
  }
}

const getBackground = (tag: any) => {
  try {

    return tag.Cab ? '#8B008B' :  'black';

  } catch (ex) {
    console.error('failed to get border:', ex);
  }
}


  return <Box display="flex" width={w} flexDirection="row" alignItems="center" flex="1" height="35px" maxHeight="35px"  padding={"4px"} >



    {/* <Box className={classes.circle} marginRight='4px' >{common.axles.imageIndex+1}</Box> */}
<b>Tags:&nbsp;</b>

{common.axles.imageTags?.map((t: any, index: number) => 
     (<Box display="flex" onClick={(e) => handleChanges("TagClicked", t)}     alignItems="center" fontSize="8"
     justifyContent="center" width="26px" marginLeft="1px"
     style={{color:'white',  background:getBackground(t), cursor:'pointer',border:getBorder(t) }}>{index + 1}</Box>))}
  
  {/* <IconButton color="primary"  style={{height:'20px', width:'20px'}}  onClick={(e) => handleChanges('NextImage', null)} ><SkipNext/></IconButton> */}
  <Box flex="1"/>


  <FormControlLabel className={classes.formControl}
  control={<Checkbox checked={ empty}  className={classes.checkbox} 
  onChange={ e => handleChanges("EmptyImage", e.target.checked)}/>} label="No object" />



  </Box>

}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 9,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
    },
  },
  circle: {
      width: '22px',
      color:'white',
      background:'#383838',
      lineHeight: '22px',
      borderRadius: '50%',
      textAlign: 'center',
      fontSize: '9px',
  },
  formControl: {
    margin: 0,
    padding: 0,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
}));
