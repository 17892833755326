import { AxlesCanvas } from './axlesCanvas';
import common from './commonService';
import moment from 'moment';
import axlesStitcher from './axlesStitcher';
import { AxlesCycler } from './axlesCycler';
import { createAbstractBuilder } from 'typescript';
import { async } from 'rxjs';
import { CloseOutlined, CollectionsOutlined, DirectionsRunSharp, LaptopWindows } from '@material-ui/icons';
import { SequenceProps } from '../components/axles/sequenceProps';
import { start } from 'repl';
const axios = require ('axios');

/**
 * local container
 */
export class AxlesServiceData {
  imageSize: number[];

  constructor() {
    this.imageSize = [0, 0];
  }
}

/**
 * axles provider
 */
export class AxlesService {

  callbacks: any = {};
  data: AxlesServiceData;
  canvas: AxlesCanvas;
  cycler: AxlesCycler = new AxlesCycler();

  constructor() {
    this.callbacks = {};
    this.data = new AxlesServiceData();
    this.canvas = new AxlesCanvas();

    window.onresize = ((evt:any) => {
      var dimensions = {
        height: (evt.srcElement || evt.currentTarget).innerHeight,
        width: (evt.srcElement || evt.currentTarget).innerWidth
    };
      console.log('resized');
    });

    document.onkeydown = (e) => {
      const arrows = ['ArrowUp', 'ArrowDown','ArrowLeft','ArrowRight'];
      if (common.mode === 'AXLES' && arrows.includes(e.key))
        this.handleArrowKeys(e.key);
    };

    common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case "GenerateAxlesFile":
          // this.generateAxlesfile(msg.data);
          break;

        case "AxlesAddTag":
          this.addTag(msg.data as number[]);
          break;

        case "AxlesCabCanvased":
        case "SequenceLoaded":
        case "AxleTagsChanged":
          this.canvas.paint("service");
          break;

        case "AxlesRecordSelected":
          this.getSequence(msg.data);
          break;

        case "AxlesTagSelected":
          common.axles.tag = msg.data;
          common.notify("AxleTagsChanged");
          break;

        case "AxlesUnzoom":
          this.canvas.unzoom();
          break;

        case "AxlesClearTags":
          this.clearTags();
          break;
    
        // case 'AxlesLoadDataset':
        //   this.loadDataset(msg.data);
        //   break;

        case "AxlesStitchSelectIndex":
        case "AxleThumbClicked":
          this.selectSequenceImage(msg.data);
          break;

        case "AxlesSaveSequence":
          this.saveSequence();
          break;

        case 'AxlesSaveSequenceAndNext':
          this.saveSequenceAndNext();
          break;

        case "AxlesCancelSequence":
          this.reloadSequence();
          break;

        case "AxlesSaveImage":
          this.saveImage();
          break;

        case "AxlesCancelImage":
          this.reloadImage();
          break;

        case "AxlesDeleteTag":
          this.deleteSelectedTag();
          break;

        case "AxlesPreviousImage":
          this.navigateToNextImage(false);
          break;

        case "AxlesNextImage":
          this.navigateToNextImage(true);
          break;

        case "AxlesNextImagesPage":
          this.navigateToNextImagesPage(true);
          break;

          case "AxlesPreviousImagesPage":
          this.navigateToNextImagesPage(false);
          break;

        case "AxlesPreviousTag":
          this.navigateToNextTag(false);
          break;

        case "AxlesNextTag":
          this.navigateToNextTag(true);
          break;

        case "AxlesOrderVehicles":
          this.orderVehicleClasses();
          break;

        case 'AxlesSettingsChanged':
          this.updateTagTypes();
          break;

        case "AxlesSaveState":
          this.saveState();
          break;

        case 'ClearAndNext':
          this.clearAndNext();
          break;

        case "AxlesFilterChanged":
          this.updateAxlesFilter();
        break;

        case 'AxlesFilterImageIdChanged':
          this.searchSelectedImageId();
          break;

        case 'DownloadRawAxleImage':
          this.downloadRawAxleImage();
          break;

        case 'AxlesToggleEmpty':
          this.toggleEmpty();
          break;
  
      }


    });
  }

  /**
   * global key events
   * @param key 
   */
  handleArrowKeys = (key: string) => {
    try {
      switch(key) {
        case 'ArrowLeft':
          this.navigateToNextImage(false);
          break;

        case 'ArrowRight':
          this.navigateToNextImage(true);
          break;

        case 'ArrowDown':
          common.notify('AxlesGotoNextRecord');
          break;

        case 'ArrowUp':
          common.notify('AxlesGotoPreviousRecord');
          break;
      }

    } catch (ex) {
      console.error('failed to handle arrow keys:')
    }
  }
  
  clearTags = () => {
    try {
        common.axles.tags = [];
        common.axles.tag = null;
        common.axles.dirty = true;
        common.notify("AxleTagsChanged");
        this.updateRowFromSequence();
    } catch (ex) {
      console.error('failed to clear tags:', ex);
    }
  }

  /**
   * add a new tag at the specified location
   * @param rect 
   */
  addTag = (rect:number[]) => {
    try {
      rect = rect.map(x => Math.round(x));
      const cab = common.axles.newTagType === 'Cab';
      var tag = { 
        Cab: cab,
        rect: rect, 
        wheelPosition: "other",
        twin: false,
        wheelOrientation: "upright",
        lifted: 0,
        partial: false,
        ImageIndex: common.axles.imageIndex
      };

      if (cab) {
        // WTT-262
        (tag as any).zone = 'front';
      }
      
      // only one cab per image, so if already exists, recycle
      const tags = common.axles.tags;
      // if (cab) {
      //   const existing = tags.find((t:any) => t.ImageIndex === common.axles.imageIndex && t.Cab);
      //   if (existing) {
      //     tag = existing;
      //     tag.rect = rect;
      //     common.axles.tag = tag;
      //     this.updateImageTags();
      //     common.notify("AxleTagsChanged");
      //     return;
      //   }
          
      // }

      const record = common.axles.record;
      if (!tags) throw(new Error('no tags'));
      tags.push(tag);
      common.axles.tag = tag;
      common.axles.selectedRow.TagCount++;
      common.axles.imageDirty = true;
      this.updateImageTags();
      common.notify("AxleTagsChanged");
  
    } catch (ex) {
      console.error('failed to add tag:', ex);
    }
  }

    /**
   * update plates filter
   */
     updateAxlesFilter = () => {
      try {
        const axles = common.axles;
        axles.records = axles?.unfiltered?.filter(p => this.passFilter(p));
        common.notify('AxlesListChanged2');
  
      } catch (ex) {
        console.error('failed to update plates filter: ', ex);
      }
    }

    /**
     * search for the specified imageId
     */
    searchSelectedImageId = () => {
      try {
        const thumbs = common.axles.thumbs;
        if(!thumbs) return;

        const imageId = common.axles.filter.imageId;
        if (!imageId) return;

        const index = thumbs.findIndex(thumb => thumb.id.includes(imageId));
        this.selectSequenceImage(index);
      } catch (ex) {
        console.error('failed to search selected image id:', ex);
      }
    }

  /**
   * pass records filter
   * @param record 
   * @returns 
   */
  passFilter = (record: any) :boolean => {
    try {
      const filter = common.axles.filter;
      if (filter.problematic === true && !record.problematic)
        return false;

      if (filter.problematic === false && record.problematic)
        return false;

      if (filter.marked === true && !record.state.bookmark)
        return false;

      if (filter.marked === false && record.state.bookmark)
        return false;

      if (filter.tagged === true && !record.tagged)
        return false;

      if (filter.tagged === false && record.tagged)
        return false;

      if (filter.trailer === true && !record.trailer)
        return false;

      if (filter.trailer === false && record.trailer)
        return false;

      if (filter.minAxles > record.axleCount)
        return false;
      
      if (filter.maxAxles < record.axleCount)
        return false;

      if (filter.vehicleClass !== 'ALL' && filter.vehicleClass !== record.vehicleClass)
        return false;

      if (filter.seqId) {
        if (!record.id.includes(filter.seqId))
          return false;
      }

      return true;
    } catch (ex) {
      console.error('failed on axles pass filter: ', ex);
      return false;
    }
  }

  /**
   * 
   * @param data 
   * @param offsetX 
   * @returns 
   */
  getImageIndex = (data: any, offsetX: number): number => {
    try {
      var pixelsPerMs = data.PixelsPerMs;
      var stitchOffset = data.StitchOffset;
      var imageSize = data.stitchedImageSize;
      var nw = imageSize[0];
      var aw = imageSize[2];

      var factor = nw / aw;
      offsetX *= factor;

      // stitched image reduction factor
      offsetX *= 4;

      var images = data.Images;
      var t0 = images[0].Timestamp;
      var offsetsMs = images.map((ii:any) => (ii.Timestamp - t0));
      var offsetPixels = offsetsMs.map((iii: any) => iii * pixelsPerMs / 10) ;

      // for every image, calc distance from center to click position
      // then pick the image with with click position closest to the center
      const deltas: number[] = [];
      const centerX = this.canvas.imageSize[0] / 2;
      for (let i = 0; i < images.length; i++) {
        const delta = Math.abs(centerX - (offsetX - offsetPixels[i] + stitchOffset));
        deltas.push(delta);
      }

      var min = Math.min(...deltas);
      var minIndex = deltas.indexOf(min);
      return minIndex;
    } catch (ex) {
      console.error('failed to get image index: ', ex);
      return -1;
    }
  }

  /**
   * debug - load the db dataset with the give id
   */
  loadDataset = async (id: string, selectedEntry: string) => {
    try {

      await common.assureLogin();

      common.mode = 'AXLES';
      common.notify('ApplicationModeChanged');
      common.app.context.json = '';

      common.axles.loadingDataset = true;
      common.notify('AxlesLoadStateChanged');
      common.axles.scanPolicy.loadingDataset = true;
      await common.axles.scanPolicy.asyncQuit();

      common.axles.filter.reset();
      common.axles.filter.canFilter = false;
      common.notify('AxlesFilterEnabled');

      const serverUrl =  process.env.REACT_APP_SERVER_URL;
      const seqUrl = `${serverUrl}/dataset/${id}`;
      const reply = await axios.get(seqUrl, {headers: {'Authorization': common.loginToken}});
      const dataset = reply.data;
      const alias = dataset?.dataset?.alias;
      const settings = common.plates.settings;
      const aliasChanged = alias !== settings.datasetName;
      common.plates.settings.datasetName = alias;
      if (aliasChanged) {
        settings.tagAxle = false;
        settings.tagCab = false;
        settings.tagOcr = false;
        settings.tagVehicle = false;
        common.axles.newTagType = '';
        common.plates.newTagType = '';
      }
      this.updateTagTypes();
      common.notify('SaveSettings');
      const ids = dataset.sequences;
      common.axles.ironIds = [...ids];     
      const jointIds = ids.join();
      common.axles.datasetHash = common.getHash(jointIds);
      common.addWarning(`axles dataset: ${id}, hash: ${common.plates.datasetHash}`);

      const valid = common.validateImageIds(ids);

      const records = ids.map((id:string, index: number) => ({ 
        id, 
        index: index + 1, 
        status: 'not-tagged', 
        TagCount: 0,
        state: {
          lastSaved: false, 
          problematic: false,
          bookmark: false},
        vehicleClass: 'data/vehicles/000_UNK_0_100.png'}));
      common.axles.dataset = dataset;
      common.axles.unfiltered = records;
      common.axles.records = records;
      common.axles.totalRecords = records.length;
      this.restoreBookmarks();
      common.notify('AxlesDatasetLoaded');
      common.plates.settings.datasetId = `${common.mode} ${id} ${alias}`;
      common.notify('SaveSettings');
      common.axles.scanPolicy.reset(); 
      common.axles.scanPolicy.loadingDataset = false;
      const entry = selectedEntry || ids[0] || null;
      await common.delay(500);
      common.notify('SelectSequenceById', entry as any);

    } catch (ex) {
      common.relogIfTokenExpired(ex);
      console.log('failed to load dataset:', ex);
    }
    finally {
      common.axles.loadingDataset = false;
      common.notify('AxlesLoadStateChanged');
    }
  }




  /**
   * sanitize the specified record, add missing pieces
   * @param sequence 
   */
  sanitizeSequence = (sequence: any) => {
    try {
      sequence.images = sequence.images ?? [];
      sequence.human = sequence.human ?? {};
      sequence.human.axle = sequence.human.axle ?? {};
      sequence.human.axle.stitcher = sequence.human.axle.stitcher ?? {};

      const axle = sequence.human.axle;

      axle.multipleVehicles = axle.multipleVehicles ?? false;
      axle.anomaly = axle.anomaly ?? false;
      axle.invalid = axle.invalid ?? false;
      axle.weather = axle.weather || 'clear';
      axle.vehicleOcclusion = axle.vehicleOcclusion || 'no';
      axle.trailer = axle.trailer ?? false;
      axle.axleCount = axle.axleCount ?? 0;
      axle.twinCount = axle.twinCount ?? 0;
      axle.raisedCount = axle.raisedCount ?? 0;
      axle.trailerAxleCount = axle.trailerAxleCount ?? 0;
      axle.vehicleClass = axle.vehicleClass || '';
      axle.vehicleCat = axle.vehicleCat || '';
      axle.transitDirection = axle.transitDirection || 'RightToLeft';
      const stitcher = axle.stitcher;
      stitcher.pixelsPerMs = stitcher.pixelsPerMs || common.axles.stitchPixelsPerMs;
      stitcher.stitchOffset = stitcher.stitchOffset || common.axles.stitchOffset;

      if (axle.vehicleCat.endsWith('.png'))
        axle.vehicleCat = axle.vehicleCat.replace('.png','');

      if (axle.vehicleCat.startsWith('data/vehicles/'))
        axle.vehicleCat = axle.vehicleCat.replace('data/vehicles/', '');
 
      // remove if exists previous stuff
      delete sequence.human.axles;
      delete axle.Tags;
      delete axle.Trailer;
      delete axle.PixelsPerMs;
      delete axle.pixelsPerMs;
      delete axle.stitchOffset;

    } catch (ex) {

    }
  }

  getEpoch = (item:any, index = 0): number => {
    try {
      // WTT-371 - simulate guatemala
      // fallback if no header
      if (!item.header)
        return index * 20;

      const date = item.header['S_DATE'];
      const time = item.header['S_TIME'];

      // WTT-371 
      if (!date || !time)
        return index * 20;

      const d = date.split('-');
      const t = time.split('-');
      const s = `${d[0]}-${d[1]}-${d[2]} ${t[0]}:${t[1]}:${t[2]}.${t[3]}`;
      const m = moment.utc(s);
      return m.valueOf();
    } catch (ex) {
      console.error('failed to get epoch:', ex);
      // fallback
      return index * 20;
    }
  }

  /**
 * helper for compare values with diff
 * @param x 
 * @param y 
 * @param diff 
 * @param key 
 * @returns 
 */
compareDiff(x:any, y:any, diff:any[], key:any):boolean {
  if (x !== y) {
    diff.push( `${key} ${y} => ${x}`);
  }
  return (x === y);
}

/**
 * helper for compare keys with diff
 * @param kx 
 * @param ky 
 * @param diff 
 * @returns 
 */
compareKeys(kx:string[], ky: string[], diff: any[]): boolean {
  if (kx.length !== ky.length) {
    let difference = kx
                 .filter(x => !ky.includes(x))
                 .concat(ky.filter(x => !kx.includes(x)));
    diff.push(difference);
  }
  return kx.length === ky.length;
}


/**
 * true if equal, diff contains a list of changes (short circuit logic, probably 1 change)
 * @param x 
 * @param y 
 * @param diff 
 * @param k 
 * @returns 
 */
deepDiff(x:any, y:any, diff:any[], k:any = ''):boolean {
  const ok = Object.keys, tx = typeof x, ty = typeof y;
  return x && y && tx === 'object' && tx === ty ? (
    this.compareKeys(ok(x), ok(y), diff) &&
      ok(x).every(key => this.deepDiff(x[key], y[key], diff, k + '.' + key))
  ) : this.compareDiff(x, y, diff, k);
}

  /**
   * detect changes in sequence
   * WTT-363
   * @returns 
   */
  pendingSequenceChanges = () => {
    try {
      const axles = common.axles;
      if (!axles.sequence || !axles.originalSequence)
        return;

      const current = JSON.parse(JSON.stringify(axles.sequence));
      const original = JSON.parse (common.axles.originalSequence);

      // skip changes in stitcher - 
      // not a first class citizen
      delete current.stitcher;
      delete original.stitcher;

      const diff:any[] = [];
      const modified = !this.deepDiff(current, original, diff);
      return modified ? diff.toString() : '';
    } catch(ex) {
      console.error("Failed on pendingSequenceChanges:", ex);
      return false;
    }
    
  }

  /**
   * user pressed cancel sequence - reload data
   * @returns 
   */
  reloadSequence = async() => {
    try {
      // WTT-363 - warn if changes were made
      const changes = this.pendingSequenceChanges();
      if (changes) {
        const confirmed = await common.confirm("Cancel sequence changes", 
        `Sequence was modified, discard changes ?`);
        if (confirmed !== true)
          return;
      }
      common.axles.loadingRecord = true;
      common.notify('AxlesLoadingRecord');
      const serverUrl =  process.env.REACT_APP_SERVER_URL;
      const id = common.axles.selectedRow.id;
      const seqUrl = `${serverUrl}/sequence/${id}`;
      const reply = await axios.get(seqUrl, {headers: {'Authorization': common.loginToken}});
      const seq = reply.data.sequence.human.axle;
      
      const sequence = reply.data.sequence;
      this.sanitizeSequence(sequence);
      common.axles.sequence = sequence.human.axle;
      // WTT-363 - detect changes
      common.axles.originalSequence = JSON.stringify(common.axles.sequence);

      common.axles.dirty = false;
      common.notify('AxlesDirtyChanged');
    } catch (ex) {
      common.relogIfTokenExpired(ex);
      console.error('failed to reload sequence');
    }
    finally {
      common.axles.loadingRecord = false;
      common.notify('AxlesLoadingRecord');
    }
  }

  /**
   * sanitize specified tag
   * @param tag 
   * @returns 
   */
  sanitizeTag = (tag: any) => {
  try {
    if (!tag)
      return;

    if (tag.Cab) {
      tag.zone = tag.zone ?? '';
      return;
    }

    tag.lifted = tag.lifted ?? 2;
    tag.partial = tag.partial ?? false;
    tag.twin = tag.twin ?? false;
    tag.wheelOrientation = tag.wheelOrientation || 'undefined';
    tag.wheelPosition = tag.wheelPosition || 'undefined';
    tag.rect = tag.rect || [0,0,0,0];

  } catch (ex) {
    console.error('failed to sanitize tag:', ex);
  }
}

  /**
   * sanitize an array f tags
   * @param tags 
   * @returns 
   */
  sanitizeTags = (tags:any[]) => {
    try {
      if(!tags)
        return;

      tags.forEach((t:any) => this.sanitizeTag(t));
    } catch (ex) {
      console.error('failed to sanitize tags: ', ex);
    }
  }

  getSearchSequence = () => {
    try {
      const sequence = { images: [common.app.search.searchText]};
      return { data: {sequence}};

    } catch (ex) {
      console.error('failed to get search sequence:', ex)
    }
  }

  /**
   * check for pending changes - and if so, confirm with user wether to discard or revert
   * true means there are pending changes - in this case do not fetch new data
   * @returns 
   */
  checkPendingChanges = async() => {
    try {

      if (common.axles.reverting)
        return true;

      const sequenceChanges = this.pendingSequenceChanges();

      const imageChanges = await this.pendingImageChanges();
      if (sequenceChanges || imageChanges) {
        const changeType = sequenceChanges && imageChanges ? "sequence and images" : sequenceChanges ? "sequence" : "images"; 
        const confirmed = await common.confirm('Cancel  changes', `${changeType} tagging was changed, discard changes ?`);
        // user opted to discard changes, proceed 
        if (confirmed === true) 
          return false;

        const prevId = common.axles.previousSelectedId;
        this.skipChangesCheck(true);
        common.notify('SelectSequenceById', prevId as any);
        return true;
      }
      return false;


    } catch (ex) {
      console.error('failed on check pending changes:');
      return false;
    }
  }

  skipChangesCheck = (reverting: boolean = false) => {
    try {
      common.axles.skipChangesCheck = true;
      common.axles.reverting = reverting;
      setTimeout(() => {
        common.axles.skipChangesCheck = false;
        common.axles.reverting = false;  
      }
      , 200);
    } catch (ex) {
      console.error('failed on skip changes check:', ex);
    }
  }

  /**
   * return a sequence with the specified id
   * @param id 
   */
  getSequence = async (id:string) => {
    try {
      if (await this.checkPendingChanges()) {
        return;
      }

      const start = (new Date()).valueOf();
      await common.assureLogin();
      const context:any = { sequence: '', items: [] };
      const axles = common.axles;
      axles.loadingRecord = true;
      common.notify('AxlesLoadingRecord');
      const serverUrl =  process.env.REACT_APP_SERVER_URL;
      const seqUrl = `${serverUrl}/sequence/${id}`;
      const reply = id === '0' ? this.getSearchSequence() : await axios.get(seqUrl, {headers: {'Authorization': common.loginToken}});
      // store before modification
      context.sequence = JSON.stringify(reply.data, null, 2);
      axles.stitchedUrl = '';
      const stitchedId = reply.data?.sequence?.general?.stitched || '';
      axles.stitchedId = reply.data?.sequence?.general?.stitched || '';
      axles.auditStatus = reply.data?.sequence?.human?.auditStatus || '';
      // ofer 20/05/2022 - WTT-290
      axles.stitchedUrl = stitchedId ?  `${serverUrl}/sequence_audits/${axles.stitchedId}?${common.imageToken}&raw` : '';
      common.notify('StitchedUrlChanged');
      // priority to stitch mode
      axles.overlapMode = stitchedId ? false : true;
      // allow user to switch from stitched to overlap
      axles.overlapAllowChange = !!stitchedId && reply?.data?.sequence?.images?.length > 0;
      common.notify('AxlesOverlapModeChanged');
      

      axles.nativeRecord = reply.data;
      const sequence = reply.data.sequence;
      this.sanitizeSequence(sequence);
      axles.sequence = sequence.human.axle;
      // WTT-363 - detect changes
      axles.originalSequence = JSON.stringify(axles.sequence);

      if (!common.app.search.open) {
        common.plates.settings.datasetSelectedItemId = id;
        common.notify('SaveSettings');
      }


      const nativeItems = [];
      const items = [];
      const ids = sequence.images;
      const tags = [];
      const cabs = [];

      // WTT-323 get items in parallel, with retries
      const items2 = await this.getItemsFromIds(ids);
      if (!items2) {
        console.error(`No sequence image`);
        await common.alert('Axles load error', `failed to load sequence image`);
        return;
      }
      

      const promises = ids.map((id:string) => axios.get(`${serverUrl}/sequence_items/${id}`, {headers: {'Authorization': common.loginToken}}));
      const replies = await Promise.allSettled(promises);

      // default to this
      common.axles.warpingConfig = common.axles.defaultWarpingConfig;
      for(let i = 0; i < ids.length; i++) {
        const itemUrl = `${serverUrl}/sequence_items/${ids[i]}`;
        // const imageReply = await axios.get(itemUrl, {headers: {'Authorization': common.loginToken}});
        // WTT-204 stringify originals before any modification
        const rep = items2[i] as any;
        const imageReply = items2[ids[i]];
        common.assert(imageReply, 'Failed to get sequence image');
        // const imageReply = rep.value;
        
        if (!imageReply?.data) {
          console.error(`No sequence image ${ids[i]}`);
          await common.alert('Axles load error', `failed to load sequence image ${ids[i]}`);
          return;
        }

        context.items.push(JSON.stringify(imageReply?.data || {}, null, 2));

        let item = imageReply.data.sequence;
        // take info from first image
        if (i === 0) 
          common.axles.warpingConfig = this.getWarpingFromNative(imageReply.data);

        const imageTags = item?.human?.wheels?.Annotations;
        if (imageTags) {
          this.sanitizeTags(imageTags);
          // verify imageIndex
          imageTags.forEach((t:any) => t.ImageIndex = i);
          tags.push(...imageTags);
        }
        // retro compatibilità
        const cabs = item?.human?.front_vehicle?.Annotations;
        cabs?.forEach((cab:any) => {
          if (cab) {
            cab.Cab = true;
            cab.ImageIndex = i;
            cab.coverage = 'front'
            tags.push(cab);
          }
        });

        // new format
        const cabs2 = item?.human?.vehicle?.Annotations;
        cabs2?.forEach((cab:any) => {
          if (cab && cab.rect) {
            cab.Cab = true;
            cab.ImageIndex = i;
            tags.push(cab);
          }
        });
        // used for json dialog
        nativeItems.push(item);

        const epoch = this.getEpoch(item, i);
        item = {
          imageUrl: `${serverUrl}/sequence_items/${ids[i]}?${common.imageToken}&raw`, 
          // thumbUrl: `${serverUrl}/sequence_items/${ids[i]}?raw`, 
          thumbUrl: `${serverUrl}/sequence_items/${ids[i]}?${common.imageToken}&thumbnail&width=320&height=200`,
          id: ids[i],
          data: item, 
          epoch, 
          index: i};
        items.push(item);
      }

      const context2:any = {};
      let json = context.sequence;
      context.items.forEach((i:string) => json += i)
      common.app.context.json = json;
      common.app.context.id = id;

      common.axles.tags = tags;
      common.notify("AxlesSequenceLoaded");
      common.axles.thumbs = items;
      common.axles.thumbTotalPages = items.length > 0 ? Math.ceil(items.length / common.axles.thumbsPageSize) : 0;
      common.notify("AxlesThumbsLoaded");
      common.axles.dirty = false;
      common.axles.imageDirty = false;
      axles.thumbPage = 0;
      
      axlesStitcher.initialize();
      this.selectImageAfterLoad();
      const finish = (new Date()).valueOf() - start;
      console.log(`Load sequence: ${finish/1000}`);
    } catch (ex) {
      common.relogIfTokenExpired(ex);
      console.error('failed to get sequence:', ex);
      common.alert('Axles', 'Failed to get sequence');
    } finally {
      common.axles.loadingRecord = false;
      common.notify('AxlesLoadingRecord');
    }
  }

  getWarpingFromNative = (native: any) => {
    try {
      console.assert(native, 'No native');
      return native.sequence?.warper_config || common.axles.defaultWarpingConfig;
    } catch (ex) {
      console.error('failed to get warper config:', ex);
      return common.axles.defaultWarpingConfig;
    }
  }

  /**
   * get items with retries
   * @param ids 
   * @returns 
   */
  async getItemsFromIds(ids:string[]) {
    try {
      const serverUrl =  process.env.REACT_APP_SERVER_URL;
      const header =  {headers: {'Authorization': common.loginToken}};
      const items:any = {};
      ids.forEach(id => items[id] = null);

      for (let i = 0; i < 10; i++) {
          const failed:string[] = [];
          const promises = ids.map((id:string) => axios.get(`${serverUrl}/sequence_items/${id}`,header));
          const replies = await Promise.allSettled(promises);
          replies.forEach((rep,index) => {
            if (rep.status === 'fulfilled') {
              items[ids[index]] = rep.value;
            } else {
              failed.push(ids[index]);
            }
          });
          if (failed.length === 0) {
            return items;
          } else {
            ids = failed;
          }
          console.error(`WARNING: failed load axles on ${i} iteration`);
      }
      console.error('failed to get items from ids');
      return null;
    } catch (ex) {
      console.error('failed to get items from ids:', ex);
      return Promise.reject('failed to get items from ids');
    }
  }



  /**
   * select an image after sequence selection
   */
  selectImageAfterLoad = () => {
    try {
      if (common.axles.thumbs.length === 0) {
        this.selectSequenceImage(-1);
        return;
      }
      for (let i = 0; i < common.axles.thumbs?.length; i++) {
        if (common.axles.tags.find((t:any) => t.ImageIndex === i)) {
          this.selectSequenceImage(i);
          return;
        } 
      }

      // a simpplified version
      // should select first tagged image, if exists
      this.selectSequenceImage(0);
    } catch (ex) {
      console.error('failed to select image after load:', ex);
    }
  }

  /**
   * returns sequence status, based on tagging
   * @param sequence 
   * @returns 
   */
  getSequenceStatus = (sequence: any) : string => {
    try {
      if (!sequence)
        return 'error';

      if (sequence.invalid || sequence.anomaly)
        return 'error';

      if (!this.isSequenceValid(sequence))
        return 'not-tagged';

      return 'tagged';

    } catch (ex) {
      console.error('failed to get sequence status: ', ex);
      return 'error';
    }
  }

  /**
   * update list entry from sequence
   * @returns 
   */
  updateRowFromSequence = () => {
    try {
      const row = common.axles.selectedRow;
      const sequence = common.axles.sequence;
      if (!row || !sequence)
        return;

      row.vehicleIcon = sequence.vehicleCat;
      row.trailer = sequence.trailer;
      row.axleCount = sequence.axleCount;
      row.problematic = sequence.anomaly || sequence.invalid;
      row.status = this.getSequenceStatus(sequence);
      // current row receives current audit status
      row.auditStatus = common.axles.auditStatus;
      common.notify('AxlesListChanged');

    } catch (ex) {
      console.error('failed to updateRow: ', ex);
    }
  }

validateCurrentSequence = () => {
  try {
    return this.isSequenceValid(common.axles.sequence);
  } catch (ex) {
    console.error('failed to validate current sequence:', ex);
    return false
  }
}

  /**
   * validate current sequence prior to saving
   */
  isSequenceValid = (seq: any): boolean => {
    try {
      if (!seq)
        return false;

      // WTT-196 - if judged invalid - tagging is valid
      if (seq.invalid)
        return true;

      // audit - if there are no images, sequence is valid
      const imageCount = common.axles.thumbs?.length || 0;
      if (imageCount === 0)
        return true;

      const val = '';
      const errors: string[] = [];
      if (seq.vehicleClass === '') 
        errors.push('Vehicle class not specified');
     

      if (!seq.vehicleCat) 
        errors.push('Vehicle category icon not selected');

      if (seq.weather === '' || seq.weather === 'undefined')
        errors.push('Weather was not specified');

      if (seq.axleCount < 2)
        errors.push('Axle count was not set');

      if (seq.twinCount > seq.axleCount)
        errors.push('Twin count invalid');

      if (seq.raisedCount > seq.axleCount)
        errors.push('Raised count invalid');

      if (seq.trailer && seq.trailerAxleCount < 1)
        errors.push('Trailer axle count was not set');

      if (seq.axleCount - seq.trailerAxleCount < 2)
        errors.push('Tractor is expected to have at least 2 axles');

      if (!seq.vehicleOcclusion || seq.vehicleOcclusion === 'undefined')
        errors.push('Vehicle occlusion not set');

      const codes = common.axles.codes;
      const directions = codes.direction.map(x => x[0]);

      if (!directions.includes(seq.transitDirection))
        errors.push('Transit direction not set');


      common.axles.sequenceValidation = errors.length === 0 ? '' : errors[0];
      return errors.length === 0;
    } catch (ex) {
      console.error('failed to validate current sequence:', ex);
      return false;
    }
  }

  saveAuditStatus = (native:any) => {
    try {
      const axles = common.axles;
      // audit status can only be set
      if (axles.auditStatus) {
        native.sequence.human = native.sequence.human || {};
        native.sequence.human.auditStatus = axles.auditStatus;
      }
      return true;
    } catch (ex) {
      console.error('failed to save audit status:', ex);
      return false;
    }
  }

  /**
   * save sync and goto next record
   */
  saveSequenceAndNext = async () => {
    try {
      await this.saveSequence();
      common.notify('AxlesGotoNextRecord');
    } catch (ex) {
      console.error('failed to save and next:', ex);
    }
  }

  /**
   * save sequence and move to next one
   * @returns 
   */
  saveSequence = async () => {
    try {

      const start = (new Date()).valueOf();

      common.axles.savingSequence = true;
      common.notify('AxlesSavingSequenceChanged');

      if (!this.validateCurrentSequence()) {
        await common.alert('Sequence validation',common.axles.sequenceValidation);
        return;
      }

      await common.assureLogin();
      this.updateRowFromSequence();
      const nativeRecord = common.axles.nativeRecord;
      if (!nativeRecord) 
        return;

      this.saveAuditStatus(nativeRecord);

     

      const id = nativeRecord._id;
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const url = `${serverUrl}/sequence/${id}`;
      const reply = await axios.put(url, nativeRecord, {headers: {'Authorization': common.loginToken}});

      common.axles.originalSequence = JSON.stringify(nativeRecord.sequence.human.axle);

      const ids = common.axles?.nativeRecord?.sequence?.images || [];
      // WTT-323
      // WTT-377
      // const propgated = await this.massPropogateDirection(ids);
      // common.assert(propgated, 'failed to propgate direction');
      
      common.axles.dirty = false;
      common.axles.state.savedRecordId = id;
      common.axles.state.datasetId = common.axles.dataset?._id;
      common.notify('AxlesDirtyChanged');
      this.saveState();
      this.restoreBookmarks();
      const finish = (new Date()).valueOf() - start;
      console.log(`save seconds : ${finish/1000}`);

    } catch (ex) {
      console.error('failed to save axles', ex);
      const relogged = common.relogIfTokenExpired(ex);
      if (!relogged) {
        common.alert('Axles', 'Failed to save axles record');
      }

    } finally {
      common.axles.savingSequence = false;
      common.notify('AxlesSavingSequenceChanged');
    }
  }

  /**
   * propogate direction with retries
   * @param ids 
   * @returns 
   */
  // massPropogateDirection = async (ids:string[]) => {
  //   try {
  //     const items:any = {};
  //     ids.forEach(id => items[id] = null);

  //     for (let i = 0; i < 10; i++) {
  //         const failed:string[] = [];
  //         const promises = ids.map((id:string) => this.propogateDirection(id));
  //         const replies = await Promise.allSettled(promises);
  //         replies.forEach((rep,index) => {
  //           if (rep.status !== 'fulfilled') 
  //             failed.push(ids[index]);
  //         });
  //         if (failed.length === 0) {
  //           return true;
  //         } else {
  //           ids = failed;
  //         }
  //         console.error(`WARNING: failed propogate axles on ${i} iteration`);
  //         return false;
  //     }
  //     console.error('failed to get items from ids');
  //     return false;

  //   } catch (ex) {
  //     console.error('failed to propogate direction:', ex);
  //     return Promise.reject('failed to propogate direction');
  //   }
  // }

  /**
   * pass transit direction to images
   * @param id 
   */
  // propogateDirection = async (id:string) => {
  //   try {
  //     const direction = common.axles.sequence?.transitDirection;
  //     const serverUrl = process.env.REACT_APP_SERVER_URL;
  //     const itemUrl = `${serverUrl}/sequence_items/${id}`;
  //     const imageReply = await axios.get(itemUrl, {headers: {'Authorization': common.loginToken}});
  //     console.assert(imageReply?.data?.sequence);
  //     const sequence = imageReply.data.sequence;
  //     if (!sequence.human)
  //       sequence.human = {};
      
  //     sequence.human.transitDirection = direction;
  //     await axios.put(itemUrl, imageReply.data, {headers: {'Authorization': common.loginToken}});

      

  //   } catch (ex) {
  //     console.error('failed to propogateDirection:', ex);
  //   }
  // }



  /**
   * select the specified image
   */
  selectSequenceImage = (index:number) => {
    try {

      // unselect
      if (index < 0) {
        common.axles.imageIndex = -1;
        common.axles.imageId = '';
        common.axles.tag = null;
        common.notify('AxleTagsChanged');
        this.updateImageTags();
        this.canvas.setImage('');
        common.notify('AxlesThumbChanged');
        return;

      }


      const thumbs = common.axles?.thumbs;
      if (!thumbs || thumbs.length <= index)
        return;

      if (common.axles.imageDirty)
        return;

      const thumb = thumbs[index];
      if (!thumb)
        return;

      common.axles.imageIndex = index;
      this.updateImageTags();
      common.axles.imageId = thumb?.id;
      this.canvas.setImage(thumb.imageUrl);
      common.notify('AxlesThumbChanged');
      let tags = common.axles.tags;

      // wtt-99 - select tag in that image;
      tags = tags?.filter((t:any) => t.ImageIndex === index);
      common.axles.tag = tags?.length > 0 ? tags[0] : null;

      // WTT-427 - initialize empty image
      const annotated = thumb.data?.annotated || false;
      common.axles.emptyImage = annotated && tags.length === 0;
      common.notify("AxleTagsChanged");
    } catch (ex) {
      console.error('failed to select sequence image:', ex);
    }
  }

  navigateToNextTag = (next:boolean) => {
    try {
      const tags = common.axles.tags;
      const tag = common.axles.tag;
      if (!tags) return;
      const index = tags.indexOf(tag);
      
      if (next && index < tags.length - 1) {
        const tag2 = tags[index+1];
        common.notify('AxlesStitchSelectIndex', tag2.ImageIndex);
        common.notify("AxlesTagSelected", tag2);
      }

      if (!next && index > 0) {
        const tag2 = tags[index-1];
        common.notify('AxlesStitchSelectIndex', tag2.ImageIndex);
        common.notify("AxlesTagSelected", tag2);
      }

    } catch (ex) {
      console.error('failed to navigate to next tag:', ex);
    }
  }

  navigateToNextImage = (next: boolean) => {
    try {
    
    const axles = common.axles;
      // disable navigation in case of dirty image
      if (axles.imageDirty)
        return;

      const low = axles.thumbsPageSize * axles.thumbPage;
      const high = low + axles.thumbsPageSize;

      const index = common.axles.imageIndex;
      const totalImages = common.axles.thumbs?.length;
      if (next) {
        if (index < high - 1)
          this.selectSequenceImage(index+1);
        else
          this.navigateToNextImagesPage(true);
      }

      if (!next) {
        if (index > low)
          this.selectSequenceImage(index-1);
        else
          this.navigateToNextImagesPage(false);
      }

    } catch (ex) {
      console.error('failed to navigate to next image:', ex);
    }
  }
  navigateToNextImagesPage = (next: boolean) => {
    try {
      const axles = common.axles;
      // disable navigation in case of dirty image
      if (axles.imageDirty)
        return;

      const page = axles.thumbPage;
      const totalPages = axles.thumbTotalPages;
      if (next && page < totalPages - 1) {
        axles.thumbPage++;
        // select first image
        this.selectSequenceImage(axles.thumbsPageSize * axles.thumbPage);

      }

      if (!next && page > 0) {
        axles.thumbPage--;
        this.selectSequenceImage(axles.thumbsPageSize * (axles.thumbPage + 1) - 1);
      }
    } catch (ex) {
      console.error('failed to navigate to next image:', ex);
    }
  }

  

  /**
   * vehicle class fitness
   * @param vc 
   * @param selected 
   * @param axles 
   * @returns 
   */
  getClassFitness = (vc:any, selected: any, axles: number) => {
    try {

      if(!vc)
        return 'unfit';

      if (vc === selected)
        return 'selected';

      if (vc.minAxles <= axles && vc.maxAxles >= axles)
        return 'fit';

      return 'unfit';

    } catch (ex) {
      console.error('failed to get class fitness:', ex);
      return 'unfit';
    }
  }

  /**
   * order vehicle classes with ranged vehicles first
   * @returns 
   */
  orderVehicleClasses = () => {
    try {
      const classes = common.axles.vehicleClasses;
      const axles = common.axles.sequence?.axleCount || 0;

      const vc = common.axles.sequence?.vehicleCat;
      const vc2 = classes.find((cls:any) => cls.Filename === vc);
     

      classes.sort((a:any,b:any) : number => {
        const aFit = this.getClassFitness(a,vc2,axles);
        const bFit = this.getClassFitness(b,vc2, axles);

        // first selected
        if (aFit === 'selected')
          return -1;

        if (bFit === 'selected')
          return 1;

        // second fit, ordered by
        if (aFit === 'fit' && bFit === 'fit') {
          if (a.minAxles < b.minAxles)
            return -1;

          if (a.minAxles > b.minAxles)
            return 1;
        }

        if (aFit === 'fit' && bFit === 'unfit')
          return -1;

        if (aFit === 'unfit' && bFit === 'fit')
          return 1;

        
          // second fit, ordered by
          if (aFit === 'unfit' && bFit === 'unfit') {
            if (a.minAxles < b.minAxles)
              return -1;

            if (a.minAxles > b.minAxles)
              return 1;
          }

        if (a.minAxles < b.minAxles)
          return -1;

        if (a.minAxles > b.minAxles)
          return 1;

        if (a.index < b.index)
          return -1;

        if (a.index > b.index)
          return 1;

        

        return 0;
      });



    } catch (ex) {
      console.error('failed to update vehicle classes:', ex);
    }
  }

  /**
   * vehicle tags
   * @param tags 
   * @returns 
   */
  getVehicleTags = (tags: any[]) => {
    try {
      const cabs = tags?.filter((t:any) => t.Cab);
      // WTT-363 - use empty arrays
      if (!cabs || cabs.length === 0)
        return [];

        // WTT-371 - coverage in case of no zone (retro)
      return cabs.map(c => ({ rect: c.rect, zone: c.zone || c.coverage}) );

      const c = cabs[0];
      return { rect: c.rect };
    } catch (ex) {
      console.error('failed to get cab tag:', ex);
    }
  }

  getCleanTags = (): any[] => {
    try {
      const imageIndex = common.axles.imageIndex;
      const sequenceTags = common.axles.tags.filter((t:any) => t.ImageIndex === imageIndex);
      const tags = sequenceTags.filter((t:any) => t.ImageIndex === imageIndex && !t.Cab);
      const clean:any[] = [];
      tags.forEach(t => {
        const cloned = Object.assign({}, t);
        delete cloned.ImageIndex;
        delete cloned.Cab;
        clean.push(cloned);
      });
      return clean;
    } catch (ex) {
      console.error('failed to get clean tags:', ex);
      return [];
    }
  }

  /**
   * image tags validation
   * @returns 
   */
  validateImageTagging = () => {
    try {
      const sequence = common.axles.sequence;
      const imageIndex = common.axles.imageIndex;
      const sequenceTags = common.axles.tags.filter((t:any) => t.ImageIndex === imageIndex);
      const vehicles = sequenceTags.filter((t:any) => t.Cab === true);
      for (let i = 0; i < vehicles.length; i++)  {
        if (!vehicles[i].zone) {
          window.alert('Vehicle zone was not specified');
          return false;
        }
      }

      if (sequenceTags?.length > 0 && common.axles.emptyImage) {
        window.alert('No object is checked and tagging exists');
        return false;
      }


      return true;
    } catch (ex) {
      console.error('failed to validate image tagging:', ex);
      return false;
    }

  }

  /**
   * save current image
   */
  saveImage = async () => {
    try {

      const valid = this.validateImageTagging();
      if (!valid)
        return;

      const native = common.axles.nativeRecord;
      const sequence = common.axles.sequence;
      const ids = native.sequence.images;
      const imageIndex = common.axles.imageIndex;
      if (!ids || !sequence || imageIndex === undefined) return;
      const sequenceTags = common.axles.tags.filter((t:any) => t.ImageIndex === imageIndex);
      const imageId = ids[imageIndex];
      const tags = sequenceTags.filter((t:any) => t.ImageIndex === imageIndex);

      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const itemUrl = `${serverUrl}/sequence_items/${imageId}`;
      const reply = await axios.get(itemUrl, {headers: {'Authorization': common.loginToken}});
      let item = reply.data.sequence;
      // WTT-427 - tagged if marked empty or annotated
      item.annotated = tags.length > 0 || common.axles.emptyImage;
      // this is cached - update
      common.axles.thumbs[imageIndex].data.annotated = item.annotated;
      // add path struct if missing
      if (!item.human) item.human = {};
      item.human.wheels = { Annotations: this.getCleanTags() };
      // clean
      delete item.human.axles;
     
      // during read - front_vehicle was imported
      // and now saved in vehicles.
      if (item.human.front_vehicle)
        delete(item.human.front_vehicle);

      if (!item.human.vehicle)
        item.human.vehicle = { Annotations: []};
      item.human.vehicle.Annotations = this.getVehicleTags(tags);


      if (common.axles.emptyImage)
        item.human = {};

       await axios.put(itemUrl, reply.data, {headers: {'Authorization': common.loginToken}});
      common.axles.imageDirty = false;
      common.notify('AxleTagsChanged');
      this.navigateToNextImage(true);
    } catch (ex) {
      common.relogIfTokenExpired(ex);
      console.error('failed to save current image:', ex);
    }
  }

  deepEqual(x:any, y:any):boolean {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
      ok(x).length === ok(y).length &&
        ok(x).every(key => this.deepEqual(x[key], y[key]))
    ) : (x === y);
  }
  

  /**
   * avoid caching all wheels, at the cost of making cancel slower
   * detect current wheel changes
   * @returns 
   */
  pendingImageChanges = async () => {
    try {
      const current = { Annotations: this.getCleanTags() }
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const native = common.axles.nativeRecord;
      if (!native?.sequence)
        return false;

      const ids = native.sequence.images;
      const imageIndex = common.axles.imageIndex;
      // handle case that there are no images
      if (imageIndex < 0 || imageIndex >= ids.length)
        return false;

      // WTT-427 - empty image
      // const annotated = common.axles.thumbs[imageIndex].data.annotated;
      // // checkbox status
      // const empty = common.axles.emptyImage; 
      // const tagged = common.axles.imageTags.length > 0;
      // const expectedEmpty = annotated && !tagged;
      // if (empty !== expectedEmpty)
      //   return true;


      const imageId = ids[imageIndex];
      const itemUrl = `${serverUrl}/sequence_items/${imageId}`;
      const reply = await axios.get(itemUrl, {headers: {'Authorization': common.loginToken}});
      let wheel = reply.data?.sequence?.human?.wheels;
      if (!wheel)
        wheel = {};
      
      wheel.Annotations = wheel.Annotations || [];
      this.sanitizeTags(wheel.Annotations);
    
      const tags = common.axles.tags.filter((t:any) => t.ImageIndex === imageIndex);
      const currentVehicles = {Annotations: this.getVehicleTags(tags) };


      const vehicles = this.extractVehiclesFromNative(reply);

      const wheelsChanged = !this.deepEqual(current, wheel);
      const vehiclesChanged = !this.deepEqual(currentVehicles, vehicles);

      // WTT-427 verify pending "no object" checkbox
      const annotated = reply.data?.sequence?.annotated || false;
      const empty = annotated && tags?.length === 0;
      const taggedEmpty = common.axles.emptyImage || false;
      if (empty !== taggedEmpty)
        return true;

      return (wheelsChanged || vehiclesChanged);
    } catch(ex) {
      console.error('failed on pending image changes:', ex);
      return false;
    }
  }

  extractVehiclesFromNative = (reply: any) => {
    try {
      const human = reply.data.sequence.human;
      if (!human) return [];

      const vehicleAnnotations = human.vehicle?.Annotations || [];
      const frontVehicleAnnotations = human.front_vehicle?.Annotations || [];
      frontVehicleAnnotations.forEach((v:any) => v.zone = 'front');
      vehicleAnnotations.push(...frontVehicleAnnotations);
      return {Annotations: vehicleAnnotations};
    } catch (ex) {
      console.error('failed to extractct vehicles from natives: ', ex);
    }
  }

  /**
   * use pressed cancel after changing image tags - reload
   * @returns 
   */
  reloadImage = async () => {
    try {
      const pendingChanges = await this.pendingImageChanges();
      if (pendingChanges) {
        const confirmed = await common.confirm('Cancel image changes', 'Image tagging was changed, discard changes ?');
        if (confirmed !== true)
          return;
      }

      const native = common.axles.nativeRecord;
      const sequence = common.axles.sequence;
      const ids = native.sequence.images;
      const imageIndex = common.axles.imageIndex;
      if (!ids || !sequence || imageIndex === undefined) return;
      const imageId = ids[imageIndex];
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const itemUrl = `${serverUrl}/sequence_items/${imageId}`;
      const reply = await axios.get(itemUrl, {headers: {'Authorization': common.loginToken}});
      let item = reply.data.sequence;

      const tags = common.axles.tags.filter((t:any) => t.ImageIndex !== imageIndex);
      const imageTags = item?.human?.wheels?.Annotations;

      if (imageTags) {
        imageTags.forEach((t:any) => t.ImageIndex = imageIndex);
        this.sanitizeTags(imageTags);
        tags.push(...imageTags);
      }

      const cabs = item?.human?.front_vehicle?.Annotations;
      if (cabs) {
        cabs.forEach((cab:any) => {
          if (cab) {
            cab.Cab = true;
            cab.ImageIndex = imageIndex;
            tags.push(cab);
          }
        });        
      }

      // WTT-363 - modified
      const cabs2 = item?.human?.vehicle?.Annotations;
      cabs2?.forEach((cab:any) => {
        if (cab && cab.rect) {
          cab.Cab = true;
          cab.ImageIndex = imageIndex;
          tags.push(cab);
        }
      });

      common.axles.tags = tags;
      common.axles.tag = tags.find((t:any) => t.ImageIndex === imageIndex);


      // WTT-427 - restore empty checkbox
      const annotated = item.annotated || false;
      common.axles.emptyImage = annotated && tags.length === 0;
      common.notify('AxlesEmptyChanged');


      this.updateImageTags();
      common.axles.imageDirty = false;
      common.notify('AxleTagsChanged');

    } catch (ex) {
      common.relogIfTokenExpired(ex);
      console.error('failed on reload image:', ex);
    }
  }

  /**
   * imageTags - tags for current image
   */
  updateImageTags = () => {
    try {
      const tags = common.axles?.tags;
      common.axles.imageTags = tags?.filter((t:any) => t.ImageIndex === common.axles.imageIndex) || [];
      common.notify('AxlesImageTagsChanged');
    } catch (ex) {
      console.error('failed to update imageTag:', ex);
    }
  }

  /**
   * delete selected tag
   * @returns 
   */
  deleteSelectedTag = () => {
    try {
      const tag = common.axles.tag;
      const tags = common.axles.tags;
      if (!tag || !tags)
        return;

      const index = tags.indexOf(tag);
      if (index < 0)
        return;

      common.axles.tags.splice(index, 1);
      // select another tag in the image, if exists
      const imageTag = common.axles.tags.find((t:any) => t.ImageIndex === common.axles.imageIndex);
      common.axles.tag = imageTag;
      common.axles.imageDirty = true;
      this.updateImageTags();
      common.notify("AxleTagsChanged");
    } catch (ex) {
      console.error('failed to delete selected tag:', ex);
    }
  }

  /**
   * update tag types
   */
  updateTagTypes = () => {
    try {
      const settings = common.plates.settings;
      switch (common.axles.newTagType) {
        case "Axle":
          if (!settings.tagAxle) {
            common.axles.newTagType = settings.tagCab ? "Cab" : '';
          }
          break;

        case "Cab":
          if (!settings.tagCab) {
            common.axles.newTagType = settings.tagAxle ? "Axle" : '';
          }
          break;

        case "":
          if (settings.tagAxle)
            common.axles.newTagType = "Axle";
          else if (settings.tagCab)
            common.axles.newTagType = "Cab";
          else
            common.axles.newTagType = '';

          break;
      }

      common.notify('AxlesTagTypeChanged');

    } catch (ex) {
      console.error('failed to update tag type:');
    }
  }

    /**
   * load cached state from local storage
   */
     loadState = () => {
      try {
        const item = localStorage.getItem('axles.state');
        if (item) {
          const state = JSON.parse(item);
          common.axles.state = state;
        }
  
      } catch (ex) {
        console.error('failed to load state:', ex);
      }
    }

      /**
   * cache state in local storage
   */
  saveState = () => {
    try {
      const state = common.axles.state;
      const item = JSON.stringify(state);
      localStorage.setItem('axles.state', item);
    } catch (ex) {
      console.error('failed to save state:', ex);
    }
  }

  updateBookmarks = () => {
    try {

    } catch (ex) {

    }
  }
    /**
   * select last saved record
   */
  restoreBookmarks = () => {
    try {
      // load cached info
      this.loadState();
      const state = common.axles.state;
      if (state.datasetId !== common.axles?.dataset?._id) 
        return;

      const recs = common.axles.records;
      recs.forEach(r => {
        r.state.bookmark = state.bookmarks.includes(r.id);
        r.state.lastSaved = r.id === state.savedRecordId});
      common.notify('AxleStateUpdated');
    } catch (ex) {
      console.error('failed on goto last saved');
    }
  }

  clearAndNext = async () => {
    try {
      const row = common.axles.selectedRow;
      row.vehicleIcon = '';
      row.status = "not-tagged";
      common.notify('AxlesListChanged');
      const nativeRecord = common.axles.nativeRecord;
      const id = nativeRecord._id;
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const url = `${serverUrl}/sequence/${id}`;
      nativeRecord.sequence.human.axle = null;
      const reply = await axios.put(url, nativeRecord, {headers: {'Authorization': common.loginToken}});
      common.axles.dirty = false;
      common.notify('AxlesDirtyChanged');
      this.saveState();

    } catch (ex) {
      common.relogIfTokenExpired(ex);
      console.error('failed to clear:', ex);
    }
  }

    /**
   * scarica l'immagine attuale
   */
     async downloadRawAxleImage() {
      try {
        const FileSaver = require('file-saver');
        const serverUrl =  process.env.REACT_APP_SERVER_URL;
        const native = common.axles.nativeRecord;
        const ids = native.sequence.images;
        const imageIndex = common.axles.imageIndex;
        const id = ids[imageIndex];
        const imageUrl =`${serverUrl}/sequence_items/${id}?${common.imageToken}&raw`
        FileSaver.saveAs(imageUrl,`${id}.jpg`);
      } catch (ex) {
        console.error('failed to download raw image:', ex);
      }
    }

    /**
     * toggle empty 
     * @returns 
     */
    async toggleEmpty() {
      try {
        const axles = common.axles;
        const empty = axles.emptyImage;
        // mark as not empty, no consequences
        if (empty) {
          axles.emptyImage = false;
          common.notify('AxlesEmptyChanged');
          common.axles.imageDirty = true;
          common.notify("AxleTagsChanged");
          return;
        }

        // from here - mark as empty
        // remove existing tags, if any
        const tags = axles.imageTags;
        if (tags.length > 0) {
          const confirmed = await common.confirm('Empty object', 'Remove all tags');
          if (!confirmed) return;

          axles.tags = axles.tags.filter(t => !tags.includes(t));
          axles.tag = undefined;
          this.updateImageTags();
        } 
        
        common.axles.emptyImage = true;
        common.axles.imageDirty = true;
        common.notify("AxleTagsChanged");
        common.notify('AxlesEmptyChanged');
      } catch(ex) {
        console.error('failed to handle empty:', ex);
      }
    }

}

const axlesService: AxlesService = new AxlesService();
export default axlesService;