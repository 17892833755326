import React, {useState, useRef, useEffect} from 'react';
import {Box, IconButton, MenuItem,Tooltip, Typography, Button, Select, Switch } from '@material-ui/core'
import {Check as CheckIcon, Cancel as CancelIcon, Delete as DeleteIcon, DragHandleRounded, Edit as EditIcon } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import platesService from '../../services/platesService';
import './table.css';
import { LightsBounding } from '../../data/platesData';


/**
 * editor for plate annotations - contains tag editors
 * handles most editing operations
 * @param props 
 * @returns 
 */
export function LightEditor(props: any) {

  const [value, setValue] = useState(0); // integer state
  const [passengerOpen, setPassengerOpen] = useState(false);
  const canvasRef = useRef(null);

  const ant = props.ant as LightsBounding;



  /**
   * common change handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      switch (key) {
    
        case 'AnnotationSelected':
          setValue(v => v + 1);
          break;

        case 'ImageClicked':
          common.unselectAnnotations();
          common.plates.selectedLights = ant;
          common.notify("AnnotationSelected", ant as any);
          break;

        case 'DeleteLight':
            common.notify('PlatesDeleteLight', value)
            break;

        case 'Type':
            value.light.type = value.value;
            setValue(v => v + 1);
            common.notify('LightChanged');
            break;

        case 'Status':
            value.light.status = value.value ? 'on' : 'off';
            setValue(v => v + 1);
            common.notify('LightChanged');
            break;
    
      }
    } catch (ex) {
      console.error('failed to handle lightEditor changes: ', ex);
    }
  }








  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    platesService.canvas.setLightThumbnail(props.index, canvas);
    return () => {
      platesService.canvas.setLightThumbnail(props.index, null);
    };
  });

  const getBorder = () => {
    try {
  
      if (selected)
        return "cyan solid 2px";

      return "gray solid 1px";
    } catch (ex) {
      console.error('failed to get border:', ex);
      return "red solid 5px";
    }
  }

  const classes = useStyles();
  const tooltips: boolean = true;
  const selected = ant === common.plates.selectedLights;

  const showDetails = common.plates.newTagType === 'Lights';

  const lights = ant.lights || [];

  const types = ['red','yellow','green','none'];


  return ( 
    <Box display="flex" flex="1" flexDirection="column"  maxWidth="410px" border={getBorder()} marginRight="4px"  >

        <Box display="flex" flexDirection="row">

        <Box display="flex" flexDirection="column">
      <canvas  ref={canvasRef} 
        onMouseDown={e => handleChanges('ImageClicked', null) }
        width={150} height={75}   style={{backgroundColor: 'teal', cursor: 'pointer', height:'75px', width:'150px', margin:'4px'}}>
        </canvas>
        <Typography style={{margin:'4px', fontWeight: selected ? 'bold' : 'normal'}}>Traffic light</Typography>
        <Box height="40px"/>
        <Box display="flex" flexDirection="row" marginLeft="10px" >
            <Tooltip title={tooltips ? "Delete annotation" : ''}>
            <IconButton  edge="start" 
            onClick = {() =>  handleChanges('DeleteLight', ant as any)}
              color="primary" >
            <DeleteIcon/>
            </IconButton>
            </Tooltip>
            </Box>
            </Box>

           

            {showDetails && <Box display="flex" flexDirection="column">
            {lights.map((l,index) => <Box display="flex" flexDirection="row">
                    <Select style={{width: '70px'}} value={l.type} onChange={e => handleChanges('Type', {light:l, value: e.target.value})}>
                        {types.map(t => <MenuItem value={t}>{t}</MenuItem>)}
                    </Select>
                    <Switch checked={l.status === 'on'} size="small" onChange={e => handleChanges('Status', {light:l, value: e.target.checked})}></Switch>
                </Box>)}
            </Box>}

        </Box>
 

    </Box>
  )

}

const useStyles = makeStyles({

  radio: {
    margin: 0,
    padding: 0,
    fontSize: 40,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
    fontSize:40,
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

  combo: {
    width: 90,
  },

});