import React, {useState, useRef, useEffect} from 'react';
import {Box, IconButton, Checkbox, FormControlLabel, Tooltip, Typography, Button } from '@material-ui/core'
import {Check as CheckIcon, Cancel as CancelIcon, Delete as DeleteIcon, DragHandleRounded, Edit as EditIcon } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import platesService from '../../services/platesService';
import './table.css';
import { InsideEntry, InsideItem } from '../../data/platesData';


/**
 * editor for plate annotations - contains tag editors
 * handles most editing operations
 * @param props 
 * @returns 
 */
export function InsideEditor(props: any) {

  const [value, setValue] = useState(0); // integer state
  const [passengerOpen, setPassengerOpen] = useState(false);
  const canvasRef = useRef(null);

  const ant = props.ant as InsideEntry;



  /**
   * common change handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      switch (key) {

        case 'OccupiedChecked':
            const item = value.item as InsideItem;
            item.occupied.value = value.value;
            setValue(v => v + 1);
            common.notify('InsideOccupiedChanged');
            break;

        // case 'InsideLaunchPassengersDialog':
        //     const insides = common.plates.insides;
        //     const index = insides.indexOf(ant);
        //     if (ant === common.plates.selectedInside && !passengerOpen) {
        //       setPassengerOpen(true);
        //       // wait for the canvas to be created
        //       setTimeout(() => common.notify('InsidePassengerOpened'), 100);
        //     }
        //   break;

        case 'EditClicked':
             common.notify('InsideEditPassengers');
            break;

        case 'DeleteInside':
          common.notify('PlatesDeleteInside', value)
          break;

        case 'AnnotationSelected':
          setValue(v => v + 1);
          break;

        case 'ImageClicked':
          common.unselectAnnotations();
          common.plates.selectedInside = ant;
          common.notify("AnnotationSelected", ant as any);
          break;

    

     

  
    
    
      }
    } catch (ex) {
      console.error('failed to handle plateEditor changes: ', ex);
    }
  }








  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    platesService.canvas.setInsideThumbnail(props.index, canvas);
    return () => {
      platesService.canvas.setInsideThumbnail(props.index, null);
    };
  });

  const getBorder = () => {
    try {
  
      if (selected)
        return "cyan solid 2px";

      return "gray solid 1px";
    } catch (ex) {
      console.error('failed to get border:', ex);
      return "red solid 5px";
    }
  }





  const getBeltIcon = (item?:InsideItem) => {
    try {
      if (!item)
        return '';
    
      const visible = item?.beltVisible?.value === true;
      const fastened = item?.beltFastened?.value === true;
      if (visible && fastened)
        return 'icons/greenBelt.png';
      else if (visible)
        return 'icons/redBelt.png';
      else if (fastened)
        return 'icons/belt.png';
      
      return '';

    } catch(ex) {
      console.error('failed to get belt icon')
    }
  }

  const getPhoneIcon = (item?:InsideItem) => {
    try {
      if (!item)
        return '';
    
      const visible = item?.phoneVisible?.value === true;
      const used = item?.phoneUsed?.value === true;
      if (visible && used)
        return 'icons/greenCell.png';
      else if (visible)
        return 'icons/redCell.png';
      else if (used)
        return 'icons/cell.png';
      
      return '';

    } catch(ex) {
      console.error('failed to get belt icon')
    }
  }

  const passengerCell = (item: InsideItem) => {


    return (<td style={{border:'solid 1px black'}}>
      <Box display="flex" flexDirection="column">


          <Box display="flex" flexDirection="row"  alignItems="center">
              <Checkbox size="small" 
              disabled={true}
              checked={item?.occupied?.value} 
              onChange={(e) => handleChanges('OccupiedChecked', {item, value:e.target.checked } ) }/>
              {/* <IconButton size="small" disabled={!(item?.occupied?.value === true)} onClick={() => handleChanges('EditClicked', item)}><EditIcon/></IconButton> */}
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" margin="2px">
              {item.driver.value && <img style={{width:'16px', height:'16px'}} src='icons/wheel3.png'/>}
              {getBeltIcon(item) && <img  style={{width:'16px', height:'16px'}} src={getBeltIcon(item)}></img>}
              {getPhoneIcon(item) && <img  style={{width:'16px', height:'16px'}} src={getPhoneIcon(item)}></img>}
 
              {item.faceVisible.value && <img style={{width:'16px', height:'16px'}} src='icons/person.png'/>}
              
          </Box>

  </Box>
    </td>)
  }


  const classes = useStyles();
  const tooltips: boolean = true;
  const selected = ant === common.plates.selectedInside;
  const showDetails = common.plates.newTagType === 'Inside';
  // const left = ant.left;
  // const right = ant.right;
  // const middle = ant.middle;

  return ( 
    <Box display="flex" flex="1" flexDirection="column"  maxWidth="410px" border={getBorder()} marginRight="4px"  >
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column">
      <canvas  ref={canvasRef} 
        onMouseDown={e => handleChanges('ImageClicked', null) }
        width={150} height={75}   style={{backgroundColor: 'teal', cursor: 'pointer', height:'75px', width:'150px', margin:'4px'}}>
        </canvas>
        <Typography style={{margin:'4px', fontWeight: selected ? 'bold' : 'normal'}}>Inside tagging</Typography>
        <Box height="40px"/>
        <Box display="flex" flexDirection="row" marginLeft="10px" >
            <Tooltip title={tooltips ? "Delete annotation" : ''}>
            <IconButton  edge="start" 
            onClick = {() =>  handleChanges('DeleteInside', ant as any)}
              color="primary" >
            <DeleteIcon/>
            </IconButton>
            </Tooltip>
            </Box>
            </Box>
        {showDetails && 
        
         <Box display="flex" flexDirection="column">

    <table style={{border:'solid 1px black'}}>
            <tr  style={{border:'solid 1px black'}}>
                <td rowSpan={2} >
                  <Tooltip title="Edit">
                  <img style={{cursor:'pointer'}} height="70px" 
                  onClick={() => handleChanges('EditClicked', null)}
                  src="icons/carTop.png"></img>
                  </Tooltip>
                  </td>
                
                {passengerCell(ant.passengers[0][0])}
                {passengerCell(ant.passengers[0][1])}
                {passengerCell(ant.passengers[0][2])}
            </tr>

            <tr style={{border:'solid 1px black'}}>
                {passengerCell(ant.passengers[1][0])}
                {passengerCell(ant.passengers[1][1])}
                {passengerCell(ant.passengers[1][2])}
            </tr>
        </table>

       
            </Box>
        }
      </Box>
    </Box>
  )

}

const useStyles = makeStyles({

  radio: {
    margin: 0,
    padding: 0,
    fontSize: 40,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
    fontSize:40,
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

  combo: {
    width: 90,
  },

});