import platesService from '../../services/platesService';
import common from '../../services/commonService';
import { InsideEntry, LightsBounding, VehicleBounding } from '../../data/platesData';
import windshield from '../inside/editors/windshield';
import lightsService from '../../services/lightsService';
import hazardService from '../../services/hazardService';
import charsService from '../../services/charsService';
import reflectiveService, { ReflectiveService } from '../../services/reflectiveService';


interface Point {
  x: number;
  y: number;
}
/**
 * pan, zoom canvas mouse handler
 */
class Pan {

  ptDrag: any = null;
  ptMouse: any = null;


  /**
   * constructor
   */
  constructor() {
    this.ptDrag = null;
  }

  clear = () => {
    try {
    } catch (ex) {
      console.error('failed to clear:', ex);
    }
  };


  click = (evt: any) => {
    try {
    } catch(ex) {
      console.error('failed on click: ', ex);
    }
  };

  /**
   * handle double click on canvas
   * @param evt 
   * @returns 
   */
  doubleClick = (evt: any) => {
    try {


      // if ctrl is pressed - start vehicle annotation
      const cnvs = platesService.canvas;
      const pt = cnvs.getPoint(evt);

      if (!evt.ctrlKey && this.doubleClickSelection(pt)) {
        console.log('double click selection:');
        return;
      }
    
      // double click inside tag - execute char measuring
      const container = cnvs.getContainingTag(pt);
      if (common.plates.newTagType === 'Ocr' && container !== null) {
        cnvs.measureCharHeight("Start", pt);
        return;
      }

      if (common.plates.newTagType === 'Vehicle') {
        cnvs.boundVehicle("Start", pt);
        return;
      }

      if (common.plates.newTagType === 'Lights') {
        cnvs.boundLights("Start", pt);
        return;
      }

      if (common.plates.newTagType === 'Hazard') {
        cnvs.boundHazard("Start", pt);
        return;
      }

      if (common.plates.newTagType === 'Reflective') {
        cnvs.boundReflect("Start", pt);
        return;
      }
      
      if (common.plates.newTagType === 'Inside')
        cnvs.setNewPoly( "New", pt);
        
      // if non of the above, start a new polygon
      if (common.plates.newTagType === 'Ocr')
        cnvs.setNewPoly( "New", pt);

        // if (common.plates.newTagType === 'Chars')
        //   common.notify("CharsDoubleClicked", pt);
    
    } catch (ex) {
      console.error('failed on double click');
    }
  }

  /**
   * double click selection of annotation
   * WTT-436
   * @param pt 
   * @returns 
   */
  doubleClickSelection(pt: any) {
    try {
      switch(common.plates.newTagType) {
        case 'Vehicle':
          const vehicles = common.plates.vehicles || [];
          const vehicle = vehicles.find(v => this.rectContains(v.rect.value, pt));
          if (vehicle) {
            common.unselectAnnotations();
            common.plates.selectedVehicle = vehicle;
            common.notify('VehicleBoudingChanged');
            common.notify('PromoteSelectedAnnotation');
            return true;
          }
          break;

        case 'Hazard':
          const hazards = common.plates.hazards || [];
          const hazard = hazards.find(v => this.rectContains(v.rect,pt));
          if (hazard) {
            common.unselectAnnotations();
            common.plates.selectedHazard = hazard;
            common.notify("AnnotationSelected", hazard as any);
            common.notify('PromoteSelectedAnnotation');
            return true;
          }
          break;

        case 'Reflective':
          const reflectives = common.plates.reflectives || [];
          const reflective = reflectives.find(r => this.rectContains(r.rect, pt));
          if (reflective) {
            common.unselectAnnotations();
            common.plates.selectedReflective = reflective;
            common.notify("AnnotationSelected", reflective as any);
            common.notify('PromoteSelectedAnnotation');
            return true;
          }
          break;

        case 'Ocr':
          var ants = common.plates.selectedRecord.Annotations;
          const ant = ants.find((a:any) => this.polyContains(a, pt));
          if (ant && ant !== common.plates.selectedAnnotation) {
            common.unselectAnnotations();
            common.plates.selectedAnnotation = ant;
            common.notify("AnnotationSelected", ant);
            common.notify('PromoteSelectedAnnotation');
            return true;
          }
          break;
      }
      return false;

    } catch (ex) {
      console.error('failed on ')
    }
  }

  /**
   * rect contains point
   * @param rect 
   * @param pt 
   * @returns 
   */
  rectContains(rect:number[], pt:any) {
    try {
      return rect[0] < pt.x && rect[1] < pt.y && rect[0] + rect[2] > pt.x && rect[1] + rect[3] > pt.y;
    } catch (ex) {
      console.error('failed on pt inside:', ex);
      return false;
    }
  }

  /**
   * poly contains point
   * @param ant 
   * @param pt 
   * @returns 
   */
  polyContains(ant:any, pt:any) {
    try {
      const cnvs = platesService.canvas;
      const pts = cnvs.polyToPoints(ant.Poly?.value);
      if (!pts) return false;
      const inside = cnvs.inside( pt, pts);
      return inside;
    } catch(ex) {
      console.error('failed on poly contains:', ex);
    }
  }





  getContainedInside = (pt:any) => {
    try {
      // const insides = common.plates.insides || [];
      // for (let i = 0; i < insides.length; i++) {
      //   const r = insides[i].rect.value;
      //   const contained = pt.x > r[0] && pt.y > r[1] && pt.x < r[0] + r[2] && pt.y < r[1] + r[3];
      //   if (contained)
      //     return insides[i];
      // }
      return null;
    } catch (ex) {
      console.error('failed to get contained inside:', ex);
      return null;
    }
  }

  /**
   * delete the specified vehicle annotation
   * @param v 
   */
  deleteVehicle = (v: VehicleBounding) => {
    try {
      const index = common.plates.vehicles.indexOf(v);
      common.plates.vehicles.splice(index, 1);
      common.notify('VehicleBoudingDeleted');
    } catch (ex) {
      console.error('failed to deleteVehicle:', ex);
    }
  }

  /**
   * hovering over a vertex ?
   * @returns 
   */
  hovering = ():boolean => {
    try {
      const cnvs = platesService.canvas;
      const index = cnvs.tags?.findIndex((t:any) => t.hoverIndex > -1);
      return (index > -1);
    } catch (ex) {
      console.error('failed on hovering:', ex);
      return false;
    }
  }

  /**
   * hovering over a plate segment
   * @returns 
   */
  segmentHovering = ():boolean => {
    try {
      const cnvs = platesService.canvas;
      const index = cnvs.tags?.findIndex((t:any) => t.hoverSegIndex > -1);
      return (index > -1);
    } catch (ex) {
      console.error('failed on hovering:', ex);
      return false;
    }
  }

  /**
   * hovering over a vehicle vertex
   * @returns 
   */
  vehicleHovering = (): boolean => {
    try {
      // WTT-434 - resize only on selected vehicle
      const selectedVehicle = common.plates.selectedVehicle;
      if (!selectedVehicle) return false;
      return selectedVehicle.hoverIndex > -1;

      // const vehicles = common.plates.vehicles;
      // const cnvs = platesService.canvas;
      // const index = vehicles?.findIndex((t:any) => t.hoverIndex > -1);
      // return (index > -1);

    } catch (ex) {
      console.error('failed on vehicle hovering:', ex);
      return false;
    }
  }

  insideHovering = (): boolean => {
    try {
      const insides = common.plates.insides;
      const cnvs = platesService.canvas;
      const index = insides?.findIndex((t:any) => t.hoverIndex > -1);
      return (index > -1);

    } catch (ex) {
      console.error('failed on vehicle hovering:', ex);
      return false;
    }
  }

  lightHovering = (): boolean => {
    try {
      const lights = common.plates.lights;
      const cnvs = platesService.canvas;
      const index = lights?.findIndex((t:any) => t.hoverIndex > -1);
      return (index > -1);

    } catch (ex) {
      console.error('failed on vehicle hovering:', ex);
      return false;
    }
  }

  /**
   * order poly vertices in clockwise direction, starting from top left
   * @param poly 
   * @returns 
   */
  orderPoly = (poly: any[]) => {
    try {
      if (!poly || poly.length !== 4)
        return poly;

      poly.sort((pt0, pt1) =>  pt0.x - pt1.x );
      if (poly[0].y > poly[1].y) {
        const tmp = poly[0];
        poly[0] = poly[1];
        poly[1] = tmp;
      };

      if (poly[2].y > poly[3].y) {
        const tmp = poly[3];
        poly[3] = poly[2];
        poly[2] = tmp;
      }

      poly = [poly[0], poly[2], poly[3], poly[1]];
      return poly;
    } catch (ex) {
      console.log('failed to order poly:', ex);
      return poly;
    }

  }

  /**
   * generate a new annotation based on 4 points
   * @param p 
   * @returns 
   */
  getNewAnnotation = (p: any) => {

    p = this.orderPoly(p);

    const settings = common.plates.settings;
    var poly = [p[0].x,p[0].y,p[1].x,p[1].y,p[2].x,p[2].y,p[3].x,p[3].y]; 
    // convert to integers
    poly = poly.map(p => parseInt(p));
    var ant = {
      PlateNumber: { value: "", status: 'tagged' },
      WhiteChars: { value: false, status: 'tagged' },
      Bilingual: { value: false, status: 'tagged' },
      VehicleType: { value: 1, status: 'tagged' },
      ExtraType: { value: 0, status: 'tagged'},
      Category: {value: 0, status: 'tagged'},
      CharHeight: { value: 0, status: 'tagged'},
      Poly: { value: (poly.map(pp => `${pp}`)).join(','), status: 'tagged'},
      Rect: { value: '', status: 'tagged'},
      InternalPoly: { value: [0,1,1,0], status: 'tagged'},
      PlateType: { value: 1, status: 'tagged' },
      // take default values
      Nation: { value: settings.defaultNation, status: 'tagged' },
      Region: { value: settings.region, status: 'tagged' }
      }
    return ant;

  }

  getNewInside = (p: any) => {

    const inside = new InsideEntry();
    p = this.orderPoly(p);

    const settings = common.plates.settings;
    var poly = [p[0].x,p[0].y,p[1].x,p[1].y,p[2].x,p[2].y,p[3].x,p[3].y]; 
    // convert to integers
    poly = poly.map(p => parseInt(p));
    inside.windshield =  { value: (poly.map(pp => `${pp}`)).join(','), status: 'tagged'};
    return inside;

  }

  

  /**
   * use is painting a new poly 
   * @param pt 
   * @returns 
   */
  handlePaintNewPoly = (pt: any) => {
    try {
      const cnvs = platesService.canvas;
      const p = cnvs.hotPoly;
      if (!p) return;
        cnvs.setNewPoly( "AddPoint", this.ptDrag);

      // if 4 vertices were give, create a new annotation
      if (p.length === 4 && common.plates.newTagType === 'Ocr')
      {
        var ant = this.getNewAnnotation(p);
        cnvs.hotPoly = null;
        common.notify("NewAnnotation", ant as any);
      }

      if (p.length === 4 && common.plates.newTagType === 'Inside')
      {
        var ant2 = this.getNewInside(p);
        cnvs.hotPoly = null;
        common.notify("AddInside", ant2 as any);
      }
      
    } catch (ex) {
      console.error('failed to paint new poly');
    }
  }

  canEditPlates = () => {
    try {
      const settings = common.plates.settings;
      return settings.tagOcr || settings.tagVehicle || 
      settings.tagInside || settings.tagLights || settings.tagHazard || settings.tagChars || settings.tagReflective;
    } catch (ex) {
      console.error('failed on canEditPlates:', ex);
    }
  }

  /**
   * handle mouse down event on canvas
   * @param evt 
   * @returns 
   */
  mouseDown = (evt: any) => {
    try {
      const cnvs = platesService.canvas;
      const pt = cnvs.getPoint(evt);

      if (cnvs.bounding)
        return cnvs.boundVehicle("MouseDown", pt);
      if (cnvs.measuring)
        return cnvs.measureCharHeight("MouseDown", pt);
      if (cnvs.insiding)
        return cnvs.boundInside("MouseDown", pt);
      if (cnvs.lighting)
        return cnvs.boundLights("MouseDown", pt);
      if (cnvs.hazarding)
        return cnvs.boundHazard("MouseDown", pt);
      if (cnvs.reflecting)
        return cnvs.boundReflect("MouseDown", pt);
      
      this.ptDrag = pt;

      if (cnvs.adding)
        return this.handlePaintNewPoly(this.ptDrag);

      // generate flags
      cnvs.resizing = this.hovering();
      cnvs.segResizing = this.segmentHovering();
      cnvs.vehicleResizing = this.vehicleHovering();
      cnvs.insideResizing = this.insideHovering();
      cnvs.lightResizing = lightsService.isHovering();
      cnvs.hazardResizing = hazardService.isHovering();
      cnvs.reflectResizing = reflectiveService.isHovering();
      cnvs.charsResizing = charsService.isHovering();
      
    } catch(ex) {
      console.error('failed on mouseDown: ', ex);
    }
  };

  /**
   * for the given point - test wether it hovers over a vertex or segment
   */
  getHoverPoint(ptMouse: any) {
    try {
      const cnvs = platesService.canvas;
      const tags = cnvs.tags;
      const radius = cnvs.getAnchorSize();
      // WTT-471 - limit to Ocr tagging
      const tagOcr = common.plates.newTagType === 'Ocr';
      if (tags && tagOcr) {
        for (let tag of tags) {
          const segs = tag.InternalSegments;
          if (!segs)
            continue;
          var pts = cnvs.polyToPoints(tag.Poly?.value);
          if (!pts)
            continue;
          tag.hoverSegIndex = -1;
          if (tag === common.plates.selectedAnnotation) {
            tag.hoverIndex = pts?.findIndex(pt => cnvs.getDistance(pt, ptMouse) < radius);
            if (tag.hoverIndex < 0)
              tag.hoverSegIndex = segs?.findIndex( (sg:any) => cnvs.getSegmentDistance(ptMouse, sg[0], sg[1]) < radius);
          }
        
        }
      }

      const vehicles = common.plates.vehicles;
      if (vehicles) {
        const vehicleTagging = common.plates.newTagType === 'Vehicle';
        for (let v of vehicles) {
          // WTT-434
          v.hoverIndex = -1;
          // ofer 31/01/2022 WTT-208
          if (vehicleTagging) {
            const pts2 = cnvs.getPointsEx(v.rect.value);
            v.hoverIndex = pts2?.findIndex(pt => cnvs.getDistance(pt, ptMouse) < radius);
          }
        }
      }

      const insides = common.plates.insides;
      if (insides) {
        for (let v of insides) {
          var pts = cnvs.polyToPoints(v.windshield?.value);
          if (!pts)
            continue;
            v.hoverIndex = pts?.findIndex(pt => cnvs.getDistance(pt, ptMouse) < radius);
        }
      }
      

      lightsService.updateHoverIndex(ptMouse, radius);
      hazardService.updateHoverIndex(ptMouse, radius);
      reflectiveService.updateHoverIndex(ptMouse, radius);
      charsService.updateHoverIndex(ptMouse, radius);
      

    } catch (ex) {
      console.error('failed to get hover point');
    }
  }

  /**
   * tag was manual changed - check pre-tagging
   */
  checkPreTagging = (tag: any) => {
    try {
      var fields = ['Poly','InternalPoly'];
      var preTagged = fields.find(f => tag[f].status === 'pre-tagged');
      if (!preTagged)
        return;

      fields.forEach(f => tag[f].status = 'tagged');
      common.notify('TaggingStatusChanged');
    } catch (ex) {
      console.error('failed to check preTagging: ', ex);
    }
  }

  /**
   * user is resizing a poly vertex
   * @param pt 
   */
  handleResize = (pt: any) => {
    try {
      const cnvs = platesService.canvas;
      const tags = cnvs.tags;
      const hoverTag = tags.find( (t:any) => t.hoverIndex > -1);
      if (!hoverTag) throw new Error('failed to find hoverTag');
      // WTT-265 - user repositioned box - no longer place holder
      if (hoverTag.polyPlaceholder) {
        hoverTag.polyPlaceholder = false;
      }
      const pts = cnvs.polyToPoints(hoverTag.Poly.value);
      if (!pts) throw new Error('failed on polyToPoints');
      pts[hoverTag.hoverIndex] = pt;
      hoverTag.Poly.value = cnvs.pointsToPoly(pts);
      if (hoverTag.Poly.status !== 'tagged') {
        hoverTag.Poly.status = "tagged";
        common.notify('TaggingStatusChanged');
      }
      cnvs.paint('pan-resize');
    } catch (ex) {
      console.error('failed to handle resize:', ex);
    }
  }

  /**
   * resize vehicle annotation, dragging the vertices
   * @param pt 
   */
  handleVehicleResize = (pt: any) => {
    try {
      const cnvs = platesService.canvas;
      const vehicles = common.plates.vehicles as VehicleBounding[];
      const vehicle = vehicles?.find( (t:any) => t.hoverIndex > -1);
      if (!vehicle) throw new Error('failed to find hoverVehicle');
      // ofer 31/01/2022 - WTT-208
      const r = vehicle.rect.value as number[];
      const right = r[0] + r[2];
      const bottom = r[1] + r[3];
      switch(vehicle.hoverIndex) {
        case 0:
          vehicle.rect.value = [pt.x, pt.y, right - pt.x, bottom - pt.y];
          break;

        case 1:
          vehicle.rect.value = [r[0], pt.y, pt.x - r[0], bottom - pt.y];
          break;

        case 2:
          vehicle.rect.value = [r[0], r[1], pt.x - r[0], pt.y - r[1]];
          break;

        case 3:
          vehicle.rect.value = [pt.x, r[1], right - pt.x, pt.y - r[1]];
          break;

          // mid top
        case 4:
          vehicle.rect.value = [r[0], pt.y, r[2], bottom - pt.y];
          break;

          // mid right
        case 5:
          vehicle.rect.value = [r[0], r[1], pt.x - r[0], r[3]];
          break;

          // mid bottom
        case 6:
          vehicle.rect.value = [r[0], r[1], r[2], pt.y - r[1]];
          break;

        case 7:
          vehicle.rect.value = [pt.x, r[1], right - pt.x, r[3]];
          break;
      }
      vehicle.rect.status = 'tagged';
      cnvs.paint('pan-vehicle-resize');
    } catch (ex) {
      console.error('failed to handle resize:', ex);
    }
  }

  
  handleInsideResize = (pt: any) => {
    try {
      const cnvs = platesService.canvas;
      const insides = common.plates.insides as InsideEntry[];
      const inside = insides?.find( (t:any) => t.hoverIndex > -1);
      if (!inside) throw new Error('failed to find hoverVehicle');

      const pts = cnvs.polyToPoints(inside.windshield.value);
      if (!pts) throw new Error('failed on polyToPoints');
      pts[inside.hoverIndex] = pt;
      inside.windshield.value = cnvs.pointsToPoly(pts) as string;
      if (inside.windshield.status !== 'tagged') {
        inside.windshield.status = "tagged";
        common.notify('TaggingStatusChanged');
      }
      cnvs.paint('pan-resize');

     
    } catch (ex) {
      console.error('failed to handle resize:', ex);
    }
  }


  // handleLightResize = (pt: any) => {
  //   try {
  //     const cnvs = platesService.canvas;
  //     const lights = common.plates.lights;
  //     const light = lights?.find( (t:any) => t.hoverIndex > -1);
  //     if (!light) throw new Error('failed to find hoverVehicle');
  //     // ofer 31/01/2022 - WTT-208
  //     const r = light.rect as number[];
  //     const right = r[0] + r[2];
  //     const bottom = r[1] + r[3];
  //     switch(light.hoverIndex) {
  //       case 0:
  //         light.rect = [pt.x, pt.y, right - pt.x, bottom - pt.y];
  //         break;

  //       case 1:
  //         light.rect = [r[0], pt.y, pt.x - r[0], bottom - pt.y];
  //         break;

  //       case 2:
  //         light.rect = [r[0], r[1], pt.x - r[0], pt.y - r[1]];
  //         break;

  //       case 3:
  //         light.rect = [pt.x, r[1], right - pt.x, pt.y - r[1]];
  //         break;

  //         // mid top
  //       case 4:
  //         light.rect = [r[0], pt.y, r[2], bottom - pt.y];
  //         break;

  //         // mid right
  //       case 5:
  //         light.rect = [r[0], r[1], pt.x - r[0], r[3]];
  //         break;

  //         // mid bottom
  //       case 6:
  //         light.rect = [r[0], r[1], r[2], pt.y - r[1]];
  //         break;

  //       case 7:
  //         light.rect = [pt.x, r[1], right - pt.x, r[3]];
  //         break;
  //     }
  //     cnvs.paint('');
     
  //   } catch (ex) {
  //     console.error('failed to handle resize:', ex);
  //   }
  // }

  /**
   * drag a segment
   * @param pt 
   * 
   */
  handleSegmentResize = (pt: any) => {
    try {
      const cnvs = platesService.canvas;
      const tags = cnvs.tags;
      const hoverTag = tags.find( (t:any) => t.hoverSegIndex > -1);
      if (!hoverTag) throw new Error('failed to find hoverTag');
      const segs = hoverTag.InternalSegments;
      if (!segs) throw new Error('no internal segments');
      const pts = cnvs.polyToPoints(hoverTag.Poly.value);
      if (!pts) throw new Error('failed on polyToPoints');

      const indexToVertices = [[0,3,1,2], [3,2,0,1],[0,3,1,2], [3,2,0,1]]
      const verticeIndices = indexToVertices[hoverTag.hoverSegIndex];
      const vertices = verticeIndices.map(i => pts[i]);

      // iterative procedure for generating new segment pos
      let low = 0;
      let high = 1;
      for (let i = 0; i < 20; i++) {
        const f = (low + high) / 2;
        const seg = cnvs.getMidSegment(vertices, f);
        if (!seg || !seg[0] || !seg[1]) throw new Error('failed to get seg');
        const d = cnvs.getSignedDistance(pt, seg[0], seg[1]);
        if (Math.abs(d) < 1)
          break;

        if (d > 0) 
          high = f;
        else
          low = f;
      }
      // calculated value
      hoverTag.InternalPoly.value[hoverTag.hoverSegIndex] = (low + high)/2;
      if (hoverTag.InternalPoly.status !== "tagged") {
        hoverTag.InternalPoly.status = "tagged";
        common.notify('TaggingStatusChanged');
      }
      common.notify('InternalPolyChanged');
      // cnvs.paint('pan-segment-resize');
    } catch (ex) {
      console.error('failed to handle resize:', ex);
    }
  }

  getBoundedPoint = (pt: any): any => {
    try {
      const size = platesService.canvas.imageSize;
      const x = Math.min(Math.max(pt.x, 0), size[0] - 1);
      const y = Math.min( Math.max(pt.y, 0), size[1] - 1);
      return {x, y};
    } catch (ex) {
      console.error('failed to get bounded point:', ex);
      return pt;
    }
  }

  /**
   * handle mouse movement on canvas
   * @param evt 
   * @returns 
   */
  mouseMove = (evt: any) => {
    try {

     
      const cnvs = platesService.canvas;
      const ptUnbounded = cnvs.getPoint(evt);
      const pt = this.getBoundedPoint(ptUnbounded);

      // whether point is out of image bounds
      const outOfBounds = pt.x !== ptUnbounded.x || pt.y !== ptUnbounded.y;

      // the various cases
      if (cnvs.bounding)
        return cnvs.boundVehicle("MouseMove", pt);
      
      if (cnvs.insiding)
        return cnvs.boundInside("MouseMove", pt);

      if (cnvs.lighting)
        return cnvs.boundLights("MouseMove", pt);

      if (cnvs.hazarding)
        return cnvs.boundHazard("MouseMove", pt);

      if (cnvs.reflecting)
        return cnvs.boundReflect("MouseMove", pt);

      if (cnvs.measuring)
        return cnvs.measureCharHeight("MouseMove", pt);

      if (cnvs.adding)
        return  cnvs.setNewPoly( "MouseMove", pt);

      if (cnvs.resizing)
        return this.handleResize(pt);

      if (cnvs.segResizing)
        return this.handleSegmentResize(pt);

      if (cnvs.vehicleResizing)
        return this.handleVehicleResize(pt);

      if (cnvs.insideResizing)
        return this.handleInsideResize(pt);

      if (cnvs.lightResizing) {
        lightsService.handleResize(pt);
        cnvs.paint('');
        return;
      }

      if (cnvs.hazardResizing) {
        hazardService.handleResize(pt);
        cnvs.paint('');
        return;
      }

      if (cnvs.reflectResizing) {
        reflectiveService.handleResize(pt);
        cnvs.paint('');
        return;
      }

      if (cnvs.charsResizing) {
        charsService.handleResize(pt);
        cnvs.paint('');
        return;
      }
      


      cnvs.mousePos = pt;
      if (this.ptDrag && !outOfBounds) {
        cnvs.ctx.translate((pt.x - this.ptDrag.x), (pt.y - this.ptDrag.y));
        cnvs.saveMatrix();
        return;
    }

    if (this.canEditPlates())
      this.getHoverPoint(ptUnbounded);



    } catch (ex) {
      console.error('failed on mouseMove');
    }

  };

  /**
   * handle mouse up event on canvas
   * @param evt 
   * @returns 
   */
  mouseUp = (evt: any) => {
    try {
      const cnvs = platesService.canvas;

      // trap for vehicle and plate poly movements
      if (cnvs.resizing || cnvs.vehicleResizing)
        common.notify('VehicleResized');

      const pt = cnvs.getPoint(evt);

      if (cnvs.bounding && common.plates.newTagType == 'Vehicle') {
        const rect = cnvs.getHotBounding();
        if (!rect) return;
        const v = new VehicleBounding();
        // ofer 31/01/2022
        v.rect.value = rect;
        v.pose.value = '';
        cnvs.boundVehicle("MouseUp", pt);
        common.notify('AddVehicleBounding', v as any);
        return;
      }

      if (cnvs.lighting && common.plates.newTagType == 'Lights') {
        const r = cnvs.getHotLight();
        if (r) {
          const l = lightsService.getLightFromRect(r);
          cnvs.boundLights("MouseUp", pt);
          common.notify('AddLightsBounding', l as any);
        }
        return;
      }

      // WTT-414 a new hazard is born
      if (cnvs.hazarding && common.plates.newTagType == 'Hazard') {
        const r = cnvs.getHotHazard();
        if (r) {
          const h = hazardService.getHazardFromRect(r);
          cnvs.boundHazard("MouseUp", pt);
          common.notify('AddHazard', h as any);
        }
        return;
      }

      if (cnvs.reflecting && common.plates.newTagType == 'Reflective') {
        const r = cnvs.getHotReflect();
        if (r) {
          const rf = reflectiveService.getHazardFromRect(r);
          cnvs.boundReflect("MouseUp", pt);
          common.notify('AddReflect',rf as any);
        }
        return;
      }



      if (cnvs.measuring)
        return cnvs.measureCharHeight("MouseUp", pt);

      this.ptDrag = null;
      cnvs.resizing = false;
      cnvs.segResizing = false;
      cnvs.vehicleResizing = false;
      cnvs.insideResizing = false;
      cnvs.insiding = false;
      cnvs.lighting = false;
      cnvs.lightResizing = false;
      cnvs.hazarding = false;
      cnvs.hazardResizing = false;
      cnvs.reflectResizing = false;
      cnvs.charsResizing = false;
   

    } catch(ex) {
      console.error('failed on mouseUp: ', ex);
    }
  };

  /**
   * mouse left the canvas - stop designing
   * @param evt 
   */
  mouseLeave = (evt: any) => {
    try {
      this.ptDrag = null;
      const cnvs = platesService.canvas;
      cnvs.resizing = false;
      cnvs.insideResizing = false;
      cnvs.lightResizing = false;
      cnvs.segResizing = false;
      cnvs.vehicleResizing = false;
      cnvs.hazardResizing = false;
      cnvs.reflectResizing = false;
      cnvs.charsResizing = false;
      
    } catch (ex) {
      console.error('failed to handle mouse leave');
    }
  }

  /**
   * mouse wheel to zoom
   * @param clicks 
   * @returns 
   */
  zoom = (clicks:number) => {
    try {
        const tags = platesService.canvas;
        const ctx = tags.ctx;
        const scaleFactor = 1.1;
        const pt = tags.mousePos;
        if (!pt)
          return;
          
        ctx.translate(pt.x, pt.y);
        const factor = Math.pow(scaleFactor, clicks);
        ctx.scale(factor, factor);
        ctx.translate(-pt.x, -pt.y);
        tags.saveMatrix();
      } catch (ex) {
        console.error('failed to zoom:', ex);
      }
    };

  /**
   * call zoom function
   * @param evt 
   */
  mouseWheel = (evt: any) => {
    try {
      const delta = evt.nativeEvent.wheelDelta ? evt.nativeEvent.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
      this.zoom(delta);
    } catch (ex) {
      console.error('failed to handle canvasMouseDown:', ex);
    }
  };


}

const pan: Pan = new Pan();

export default pan;