
import { InsideRecord, Vehicle } from '../services/tags';

const axios = require ('axios');




export class VideoService {

  videoCallback: any;
  transitsCallback: any;
  detailsCallback: any;
  imagesCallback: any;

  constructor() {
    this.videoCallback = null;
    this.transitsCallback = null;
    this.detailsCallback = null;
    this.imagesCallback = null;
  }

  notify = (name: string, data: any) => {
    try {
      if (this.videoCallback)
        this.videoCallback(name, data);
      if (this.transitsCallback)
        this.transitsCallback(name, data);
      if (this.detailsCallback)
        this.detailsCallback(name, data);
      if (this.imagesCallback)
        this.imagesCallback(name, data);
 
    } catch (ex) {
      console.error('failed to notify:', ex);
    }
  }

 
  getDatasets = () => {
    try {
      return fetch(`http://localhost:3001/video`);
    } catch (e) {
      console.error('failed to get datasets: ')
    }
  };

  getDataset = (path: string) => {
    try {
      return fetch(`http://localhost:3001/video/${path}`);
    } catch (e) {
      console.error('failed to get datasets: ')
      return Promise.reject("failed to get dataset");
    }
  };

  getRecord = (dataset:string, recordId: string) => {
    try {
      dataset = encodeURIComponent(dataset);
      return fetch(`http://localhost:3001/video/${dataset}/${recordId}`);
    } catch (e) {
      console.error('failed to get datasets: ')
      return Promise.reject("failed to get dataset record");
    }
  };

  saveRecord(insideRecord: InsideRecord):Promise<any> {
    try {
         const index = insideRecord.id;
          return axios.post(`http://localhost:3001/video/0/${index}`, insideRecord)
          .then( (res:any) => {
            console.log(`statusCode: ${res.statusCode}`)
            console.log(res)
          })
          .catch( (error:any) => {
            console.error(error)
          }) 

         
    } catch (exception) {
      console.error('failed to save current record: ', exception);
      return Promise.reject("failed to get dataset record");
    }
  }

}

const videoService: VideoService = new VideoService();
export default videoService;