import { makeStyles, Theme } from "@material-ui/core";
import * as React from "react";
import { LaptopWindows, Person as PersonIcon, RoundedCorner, Warning as WarningIcon } from '@material-ui/icons';
import { Button, Dialog, DialogActions, DialogTitle, TextField, Box } from "@material-ui/core";
import  common  from '../services/commonService';
import { useHotkeys } from 'react-hotkeys-hook';


interface Props {
	open: boolean;
	onClose: () => void;
}

export function LoginDialog(props: any) {
  const { open, onClose } = props;
	const { size, ...other } = props;
  const classes = useStyles(props);

  const [username, setUsername] = React.useState('');
  const [password, setPassword ] = React.useState('');
  const [value, setValue] = React.useState(0); // integer state

  const usernameRef = React.createRef();
  const passwordRef = React.createRef();

  // const loginService = new LoginService();
  // const storeData = useSelector((state: RootState) => state.storeData);

  // ofer 25/03/2022 - hotkeys called when dialog is closed.
  // useHotkeys('enter', () => {
  //   if (open)
  //     handleCallbacks('EnterLogin', null)
  // }
  // );

  const handleClose = (e: any, reason: string) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown')
      return;

		onClose();
  };
  
  const handleCallbacks = (key: string, data: any) => {
    try {
      switch(key) {
        case 'PasswordKeyDown':
          if (username && password && data === 13) {
            common.notify('Authenticate', {username, password, origin} as any)
          }
          break;

          case 'EnterLogin':
            const u = (document.getElementById("userInput") as HTMLInputElement).value;
            const p = (document.getElementById("passwordInput") as HTMLInputElement).value;
            common.notify('Authenticate', {username:u, password:p, origin} as any)            
            break;

          case "Login":
            common.notify('Authenticate', {username, password, origin} as any)
            break;
  
          case 'LoginFailedChanged':
            setValue(v => v + 1);
            break;

          case 'ReloggedIn':
          case 'LoggedIn':
            onClose();
          break;

          
          case 'Automated':
            common.automated = true;
            common.notify('AutomationChanged');
            break;
      }

      

    } catch (ex) {
      console.error('failed to handle logger changes:', ex);
    }
  }

  React.useEffect(() => {
 
    const subscription = common.notifier$.subscribe(msg => {
      handleCallbacks(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  const TEST_ENVIRONMENT = !process.env.REACT_APP_SERVER_URL?.includes('pandora.tattile.com');

	return (
		<Dialog 
    PaperProps={{ style:{boxShadow:'0 2px 3px rgba(0,0,0,0.16), 0 2px 3px rgba(0,0,0,0.23)'}}}
    BackdropProps={{
      style: {
        backgroundColor: 'white',
        boxShadow: 'none',
      },
    }}
    open={open} onClose={handleClose}>
      <Box display="flex" flex="1" flexDirection="column" style={{background:'white'}}>
        <Box display="flex" flexDirection="column" 
        style={{background: '#0847A6', color:'white'}} 
        height="40px" alignItems="center" 
        justifyContent="center" alignContent="center" >
        <b>JAKARTA</b>
        </Box>


    <Box display="flex" flexDirection="row">

      <Box display="flex" flexDirection="column" marginLeft="25px" marginRight="25px">

        <Box display="flex" height="40px"/>

      <img src="logoTattile3.png" height="75px"   />
      </Box>

      <Box display="flex" flexDirection="column" width="320px" marginTop="15px" marginLeft="10px" >
        &nbsp;&nbsp;User:

      <input type='text' id="userInput"  value={username} onChange={(e) => setUsername(e.target.value)}  
      style={{background:'#F1F1F1', width:'290px', borderRadius:'4px',
      height:'25px', border:'gainsboro solid 1px', margin: '4px', paddingLeft: '6px' }}></input>
      <Box display="flex" height="12px"></Box>
      &nbsp;&nbsp;Password:
      <input type='password' id="passwordInput" value={password} 
      onKeyDown={(e) => handleCallbacks("PasswordKeyDown", e.keyCode)}
      onChange={(e) => setPassword(e.target.value)}  
      style={{background:'#F1F1F1', width:'290px', borderRadius:'4px',
      height:'25px', border:'gainsboro solid 1px', margin: '4px', paddingLeft: '6px'  }}></input>
        </Box>
    </Box>


    { common.LoginFailed && 
        <Box display="flex" flexDirection="row" marginTop="10px" alignItems="center" justifyContent="center">
          <WarningIcon></WarningIcon>
          &nbsp;&nbsp;
          Login failed, please verify credentials and try again
        </Box>
      }

      </Box>
			<DialogActions>
      { TEST_ENVIRONMENT && 
        <Button  id="automationButton" style={{background:'transparent', color:'transparent'}}
         className={classes.button} onClick={() => handleCallbacks('Automated', null)}>
          Auto
        </Button>
        }
          <Box display="flex" flex="1"/>
        <Box display="inline" marginRight="20px">
        <Button id="loginButton" className={classes.button} onClick={() => handleCallbacks('Login', null)}>
          Login
        </Button>
        </Box>
			</DialogActions>
		</Dialog>
	);
}

const styledBy = (property: string, props: any, mapping: any): string =>
  mapping[props[property]];
  
const useStyles = makeStyles((theme: Theme) => ({
	
 box: {

 },
	text: {
		color: "white",
  },

  button: {
    margin: "2px",
    marginTop: '10px',
    color: '#0847A6',
    height:'25px',
    width: '50px',
    fontSize: '11px',
    backgroundColor: '#E9EDFF',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  


  textField: {
		width: "80%",
		margin: 20,
	},
}


));
