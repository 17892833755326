
import { PlateMatch } from '../data/platesData';
import common from './commonService';


class PlateAuditService {

    constructor() {
        common.notifier$.subscribe(msg => {
            switch(msg.name) {

                case 'PlateAuditYes':
                    this.handleAudit('true');
                    break;
                
                case 'PlateAuditNo':
                    this.handleAudit('false');
                    break;

                case 'PlateAuditNoPlate':
                    this.handleAudit('noplate');
                    break;

                case 'PlateAuditOccluded':
                    this.handleAudit('occluded');
                    break;

                case 'PlateAuditSave':
                    this.handleSave();
                    break;

                case 'PlateAuditLoaded':
                    this.handleLoad(msg.data);
                    break;

            }
        })

    }

    handleAudit(passed:string) {
        try {
            const rec = common.plates.selectedRow;
            const plate = common.plates.headerPlateNumber || '';
            rec.audit = {passed, plate};
            common.notify('PlatesUpdateCurrentRow');
            common.notify('PlateListChanged');
            common.notify('GotoNextRecord');


        } catch (ex) {
            console.error('failed to handle audit', ex);
        }
    }

    /**
     * 
     */
    getAuditStatus(r:any) {
        try {
              // WTT-342 - give precedence to plate  number
              const tagged = !!r.plateNumber;
              if (tagged) {
                return r.match === PlateMatch.Match ? 'true' : 'false';
              } 

              if (r.audit === undefined)
                return '';

              return r.audit.passed;
        } catch (ex) {
            console.error('failed to get audio status:', ex);
            return '';
        }
    }
    getAuditCsv() {
        try {
            const recs = common.plates.records;
            const csvs = recs.map(r => {
                // WTT-342 - give precedence to plate  number
                const tagged = !!r.plateNumber;
                const passed = this.getAuditStatus(r);
                const plate = tagged ? r.plateNumber : ( r.audit === undefined ? '' : r.audit.plate);
                const id = r._id;
                return `${id};${plate};${passed}`;
            });
            const text = csvs.join('\r\n');
            return text;
        } catch (ex) {
            console.error('failed to get audit csv:', ex);
            return '';
        }
    }

    handleSave() {
        try {
            common.plates.auditSaving = true;
            common.notify('AuditSavingChanged');

            const FileSaver = require('file-saver');
            const csv = this.getAuditCsv() as string;
            var blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
            const ds = common.plates.datasetAlias;
            const dt = (new Date()).toLocaleString();
            // note - " AUDIT " serve come un sentinel
            const filename = `${ds} AUDIT ${dt}.csv`;
            FileSaver.saveAs(blob, filename);
        } catch (ex) {
            console.error('failed to handle audit save', ex);
        } finally {
            common.plates.auditSaving = false;
            common.notify('AuditSavingChanged');
        }
    }

    getDatasetFromAuditFile(auditFile:string) {
        try {
            common.assert(auditFile, 'No audit file');
            const pos = auditFile.indexOf(" AUDIT ");
            common.assert(pos > -1, 'failed to locate audit sentinel');
            return auditFile.substring(0, pos);
        } catch (ex) {
            console.error('failed to get dataset from audit file:', ex);
        }
    }

    async handleLoad(csv: string) {
        try {
            const recs = common.plates.records;
            if (!recs || recs.length === 0) {
            common.alert('Plate audit', 'No records loaded' );
                return;
            }

            common.plates.auditLoading = true;
            common.notify('AuditLoadingChanged');

            const auditFile = common.plates.auditFile || '';
            const auditDataset = this.getDatasetFromAuditFile(auditFile);
            if (!auditDataset) {
                await common.alert('Audit', 'Selected file does not correspond to audit format, please verify file');
                return;
            }

            const alias = common.plates.datasetAlias;
            if (auditDataset !== alias) {
                await common.alert('Audit', `Current dataset is ${alias}. `  + 
                 `Audit file dataset is ${auditDataset}, Please select a matching file`);
                return;
            }

            let lastTagged:number = -1;

            const auditValues = ['true', 'false', 'noplate', 'occluded'];

            const lines = csv.match(/[^\r\n]+/g) || [];
            for (let i = 0; i < lines.length; i++) {
                const tokens = lines[i].split(';');
                const id = tokens[0];
                const plate = tokens[1];
                // const passed = tokens[2] === 'true' ? 'true' : tokens[2] === 'false' ? false : undefined;
                // only allowed values
                const passed = auditValues.includes(tokens[2]) ? tokens[2] : undefined;
                const rec = recs.find(r => r._id === id);
                if (rec) {
                    rec.audit = passed === undefined ? undefined :  {plate, passed};
                    if (passed !== undefined)
                        lastTagged = i;
                }
            }
            common.notify('PlateStateUpdated');
            const nextTag = Math.min(lastTagged + 1, lines.length - 1);
            const nextLine = lines[nextTag];
            const nextId = nextLine.split(';')[0] as string;
            common.notify('SelectPlateById', nextId as any);

        } catch (ex) {
            console.error('failed to handle audit load', ex);
        } finally {
            common.plates.auditLoading = false;
            common.notify('AuditLoadingChanged');

        }
    }

    /**
     * initializer
     */
    initialize() {

    }

}
const plateAuditService:PlateAuditService = new PlateAuditService();
export default plateAuditService;