import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import {SkipNext, SkipPrevious, FirstPage, LastPage } from "@material-ui/icons"
import {Box, IconButton} from '@material-ui/core'

export function ImageNav(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 

  const handleChanges = (key: string, value: any) => {
    try {
      switch(key) {

        case 'AxlesThumbChanged':
        case 'AxlesThumbsLoaded':
          setValue(v => v + 1);
          break;

          case "NextImage":
            common.notify('AxlesNextImage');
            break;

          case "PreviousImage":
            common.notify('AxlesPreviousImage');
            break;

          case "NextPage":
            common.notify('AxlesNextImagesPage');
            break;
          
          case "PreviousPage":
            common.notify('AxlesPreviousImagesPage');
            break;

          case "ImageClicked":
            common.notify('AxleThumbClicked', value);
            break;

      
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);

  const classes = useStyles();
  const sequence = common.axles.sequence;
  const codes = common.axles.codes;
  const tag = common.axles.tag;
  const thumbs = common.axles.thumbs;


  
const w:number = props.width;
const h:number = props.height;

const getBorder = (index: number) => {
  try {
    if (index === common.axles.imageIndex)
      return 'solid lawngreen 3px';

    return 'solid gainsboro 3px';

  } catch (ex) {
    console.error('failed to get border:', ex);
  }
}

const getBackground = (index: number) => {
  try {

    const annotated = common.axles.thumbs[index].data.annotated || false;

    const tags = common.axles?.tags;
    if (!tags) 
      return annotated ? 'black' : 'gray';

    const tagged = tags.find((t:any) => t.ImageIndex === index);
    const empty = common.axles.emptyImage || false;

    if (!annotated)
      return 'gray';

    return tagged ? 'black' : 'darkBlue';

  } catch (ex) {
    console.error('failed to get background:', ex);
  }
}

const showThumb = (thumb: any) => {
  try {
    const low = common.axles.thumbsPageSize * common.axles.thumbPage;
    const high = low + common.axles.thumbsPageSize;
    const index:number = thumb.index;
    return index >= low && index < high;
  } catch (ex) {
    console.error('failed to get background:', ex);
  }
}


  return <Box display="flex" width={w} flexDirection="row" flex="1" style={{background:'lightGray'}} padding={"4px"} >

    <Box display="flex" flex="1" flexDirection="row" style={{overflowX:'scroll'}}>

    { common.axles.thumbTotalPages > 1 && 
    <IconButton color="primary" style={{height:'20px', width:'20px'}}  onClick={(e) => handleChanges('PreviousPage', null)} ><FirstPage/></IconButton>
    }

    <IconButton color="primary" style={{height:'20px', width:'20px'}}  onClick={(e) => handleChanges('PreviousImage', null)} ><SkipPrevious/></IconButton>


{common.axles.thumbs?.filter((thumb:any, index:number) => showThumb(thumb)).map((thumb: any, index: number) =>  
     (<Box display="flex" onClick={(e) => handleChanges("ImageClicked", thumb.index)}     alignItems="center" fontSize="8"
     justifyContent="center" width="26px" 
     style={{color:'white',  background:getBackground(thumb.index), cursor:'pointer',border:getBorder(thumb.index) }}>{thumb.index + 1}</Box>))}

    <IconButton color="primary"  style={{height:'20px', width:'20px'}}  onClick={(e) => handleChanges('NextImage', null)} ><SkipNext/></IconButton>
    { common.axles.thumbTotalPages > 1 && 
      <Box display="inline">
      <IconButton color="primary" style={{height:'20px', width:'20px'}}  onClick={(e) => handleChanges('NextPage', null)} ><LastPage/></IconButton>
        Page: {common.axles.thumbPage+1}/{common.axles.thumbTotalPages}
      </Box>
    }
</Box>

  </Box>
 

}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 9,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
}));
