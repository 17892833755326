import React, {useState, useEffect} from 'react';
import { Box } from "@material-ui/core";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import common from '../../services/commonService';
import {PlatesFilter} from './platesFilter';
import {PlateSearch} from './plateSearch';
import { Height } from '@material-ui/icons';
import { TriSwitch} from '../axles/triSwitch';
import { AnyAaaaRecord } from 'dns';

/**
 * List of records
 * @param props 
 * @returns 
 */
export function PlateList(props: any) {

  const auditMode = common.plates.settings.auditMode;

  const matchFormatter = (params:any) => {
   
    return params.data.match === 4 ? '✓' : 'x';
  }

  const auditFormatter = (params:any) => {
      const audit = params.data.audit;
      if (audit === undefined)
        return '';
      const passed = audit.passed;
      switch(passed) {
        case 'true': return '✓';
        case 'false': return 'x';
        case 'noplate': return 'n';
        case 'occluded': return 'o';
      }
      return '?';
    
  }

  const [value, setValue] = useState(0); // integer state

  const columnDefs = [
    {headerName: '', 
    field: 'state',
    width: 20, 
    headerComponentParams: {
      template: '<div style="display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px;border-left:6px solid gray;border-right:6px solid gray;border-bottom:6px solid transparent" ></div>'
    },
    equals: (s1:any, s2:any) => { 
      return false;},
    cellRenderer: (data:any) => {
      let color = data?.value?.bookmark ? 'skyBlue' : 'transparent';
      if (data?.value?.lastSaved)
      color = 'mediumBlue';
      if (data?.value?.problematic)
      color = 'red';
      
      // override (in test)
      // if (color === 'skyBlue')
      //   return ;


      return '<div style="background-color:' + color + ';display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px" ></div>';
  }},

    {headerName:'#', field: 'index', width: 45, resizable: true},
    
    {headerName: 'Status', 
    field: 'status',
    width: 115, 
    cellRenderer: (data:any) => {
      let color0 = 'transparent';
      let color1 = 'transparent';
      let color2 = 'transparent';
      let color3 = 'transparent';
      let color4 = 'transparent';
      let color5 = 'transparent';
      let color6 = 'transparent';
      let color7 = 'transparent';

      const stat = data?.value || '';
      const tokens = stat.split('+');

      // OCR
      const token0 = tokens[0];
      // bccm
      const token1 = tokens[1] || '';
      // vehicle
      const token2 = tokens[2] || '';
      // inside
      const token3 = tokens[3] || '';
      // lights
      const token4 = tokens[4] || '';
      // hazard
      const token5 = tokens[5] || '';
      // chars
      const token6 = tokens[6] || '';
      // reflectives
      const token7 = tokens[7] || '';

      switch(token0) {
          case "unvisited":
            color0 = 'transparent';
            break;
  
          case "pre-tagged":
            color0 = 'orange';
            break;
  
          case 'not-tagged':
            color0 = 'gray';
            break;
  
          case 'tagged':
            color0 = 'lawngreen';
            break;
      }
      switch(token1) {
        case "bccm":
          color1 = 'teal';
          break;  
        case "bccm-preTagged":
          color1 = 'orange';
        break;
      }
      switch(token2) {
        case "vehicles":
          color2 = 'blue';
          break;
        case "vehicles-preTagged":
          color2 ='orange';
          break;
      }

      switch(token3) {
        case "insides":
          color3 = 'cyan';
          break;
      }

      switch(token4){
        case 'lights':
          color4 = 'brown';
          break;
      }

      switch(token5) {
        case 'hazards':
          color5 = 'green';
      }
      switch(token6) {
        case 'chars':
          color6 = 'orange';
      }

      switch(token7) {
        case 'reflectives':
          color7 = 'black';
      }


      return getStatusBoxes(color0, color1, color2,color3, color4, color5, color6, color7);
  }},

//   { headerName:'✓', width: 40, field: 'match',
//   cellRenderer: (data:any) => {
//     const match = data?.value || 0;
//     const colors = ["black","black","black","red","paleGreen","black","black",];
//     const color = colors[match];
//     return '<div style="background-color:' + color + 
//     ';display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px" ></div>';
// }
//   },

  { headerName:'✓', hide: auditMode,  field: 'match', valueFormatter: matchFormatter, width: 40},
  { headerName:'✓', hide: !auditMode, field: 'audit', valueFormatter: auditFormatter, width: 40},
  
    {headerName: 'Plate',
      width: 80,
      field: 'plateNumber'},
  ];


  const getBox = (clr:string) => {
    return `<div style="background-color:${clr};display: table-cell;vertical-align:middle; width:8px; min-width:8px; max-width:8px; height:12px;margin:0px" ></div>`;
  }

  const getStatusBoxes = (clr0: string, clr1: string,  clr2:string, clr3:string, clr4:string, clr5:string, clr6:string, clr7:string): string => {
    try {
      const gap = '<Box display="flex" width="0px"></Box>';
      const left = getBox(clr0);
      const center = getBox(clr1);
      const right = getBox(clr2);
      const inside = getBox(clr3);
      const lights = getBox(clr4);
      const hazards = getBox(clr5);
      const chars = getBox(clr6);
      const reflectives = getBox(clr7);
      const combined =  `<Box display="table-cell"> ${left} ${gap} ${center} ${gap} ${right} ${gap} ${inside} ${gap} ${lights} ${gap} ${hazards} ${gap} ${chars} ${gap} ${reflectives}</Box>`;
      return combined;

    } catch (ex) {
      console.error('failed to get status boxes:', ex);
      return '';
    }
  }

  /**
   * double click cell to toggle bookmark
   * @param e 
   */
  const handleCellDoubleClicked = (e: any) => {
    try {
      const api = common.plates.gridApi;
      const state = common.plates.state;
      e.data.state.bookmark = !e.data.state.bookmark;
      common.plates.gridApi.refreshCells();
      state.bookmarks = common.plates.records.filter(r => r.state.bookmark).map(r => r._id);
      common.notify("PlatesSaveState");
    } catch (ex) {
      console.error('failed to handle cell focus:', ex);
    }
  }

  /**
   * sync row selection with focus selection (which obeys arrows)
   */
  const handleCellFocused = (e: any) => {
    try {
      const api = common.plates.gridApi;
      api.forEachNode((node:any)=> node.setSelected(node.rowIndex === e.rowIndex));
    } catch (ex) {
      console.error('failed to handle cell focus:', ex);
    }
  }
  
  /**
   * handle row selection
   * @param e 
   * @returns 
   */
  const handleSelection = (e: any) => {
    try {
      // disable selection while loading plate
      if (common.plates.loadingRecord)
        return;
        
      const selectedRows = e.api.getSelectedRows();
      if (selectedRows.length === 0)
        return;

      const selectedRow = selectedRows[0];

      // WTT 363 - should not exit here - 
      if (selectedRow === common.plates.selectedRow)
        return;

      // WTT-363 - 
      common.plates.previousSelectionId = common.plates.selectedRow?._id || '';
      common.plates.selectedRow = selectedRow;

      common.notify("PlatesSelectionChanged", selectedRow._id);
    } catch (ex) {
      console.error('failed on handle selection:', ex);
    }
  }

  /**
   * select an entry bi id
   * @param id 
   */
  const selectPlateById = (id: any) => {
    try {
      const api = common.plates.gridApi;
      api.forEachNode((node:any)=> node.data._id === id ? node.setSelected(true) : node.setSelected(false));
      api.forEachNode((node:any)=> {if (node.data._id === id) api.ensureNodeVisible(node, 'middle')});
      
      common.notify("LoadPlate", id);
    } catch (ex) {
      console.error('failed to handle plate selection')
    }
  }

  /**
   * select an entry by index
   * @param index 
   */
  const selectPlateByIndex = (index: any) => {
    try {
      const api = common.plates.gridApi;
      api.forEachNode((node:any)=> node.data.index === index ? node.setSelected(true) : node.setSelected(false));
      const selectedRows = api.getSelectedRows();
      const selectedRow = selectedRows[0];
      common.notify("LoadPlate", selectedRow.id);
    } catch (ex) {
      console.log('failed to handle plate selection')
    }
  }

  /**
   * refresh cells
   * @param state 
   */
  const updateValidationState = (state: number) => {
    try {
      common.plates.gridApi.refreshCells();
    } catch (ex) {
      console.error('failed to update validation state:', ex);
    }
  }

  /**
   * selects the next record 
   * @returns 
   */
  const gotoNextRecord = () => {
    try {
      console.log('navigating to next record ...');
      const api = common.plates.gridApi
      if (api == null) return;
      const selectedRows = api.getSelectedRows();
      if (selectedRows.length === 0) return;
      const selectedRow = selectedRows[0];
      let currentNode:any = null;
      api.forEachNode((node:any) => {if (node.data === selectedRow) currentNode = node });
      if (!currentNode) throw(new Error('failed to find current node'));
      
      const count = common.plates.records?.length || 0;
      if (currentNode.rowIndex < count - 1)
        api.forEachNode((node:any)=> node.rowIndex === currentNode.rowIndex + 1 ? node.setSelected(true) : node.setSelected(false))
      else
        common.notify("PlatesGetRecord2", currentNode.data._id);

    } catch (ex) {
      console.error('failed to gotoNext:', ex);
    }
  }

  /**
   * select previous record
   * @returns 
   */
  const gotoPreviousRecord = () => {
    try {
      console.log('navigating to next record ...');
      const api = common.plates.gridApi
      if (api == null) return;
      const selectedRows = api.getSelectedRows();
      if (selectedRows.length === 0) return;
      const selectedRow = selectedRows[0];
      let currentNode:any = null;
      api.forEachNode((node:any) => {if (node.data === selectedRow) currentNode = node });
      if (!currentNode) throw(new Error('failed to find current node'));
      api.forEachNode((node:any)=> node.rowIndex === currentNode.rowIndex - 1 ? node.setSelected(true) : node.setSelected(false))
   
      // let currentNode:any = null;
      // api.forEachNode((node:any) => {if (node.data._id === selectedRow._id) currentNode = node });
      // if (!currentNode) return;
      // const currentIndex = currentNode.rowIndex;
      // if (currentIndex === 0) return;
      // api.forEachNode((node:any)=> node.rowIndex === currentIndex - 1 ? node.setSelected(true) : node.setSelected(false))

    } catch (ex) {
      console.error('failed on gotoPreviousRecord:', ex);
    }
  }

  /**
   * obtain a pointer to the grid api
   * @param params 
   */
  function onGridReady(params:any) {
    common.plates.gridApi = params.api;
    common.plates.gridApi.getRowNodeId = (d:any) => {
      return d.id; // return the property you want set as the id.
   }
}

  /**
   * Select the first record
   */
   const selectFirstRecord = () => {
    try {
      setTimeout(() => {
        const api = common.axles.gridApi;
        if (api) 
          api.forEachNode((node:any)=> node.rowIndex === 0  ? node.setSelected(true) : node.setSelected(false))
      }, 100);
    } catch (ex) {
      console.error('failed to select first row:', ex);
    }
  }

  /**
   * reload
   */
  const updatePlateList = () => {
    try {
      setValue(v => v + 1);

    } catch (ex) {
      console.error('failed to update plate list:', ex);
    }
  }

  /**
   * WTT-363
   * @param id 
   */
  const revertSelection = (id:string) => {
    try {
      const api = common.plates.gridApi;
      api.forEachNode((node:any)=> node.data._id === id ? node.setSelected(true) : node.setSelected(false));
      api.forEachNode((node:any)=> {if (node.data._id === id) api.ensureNodeVisible(node, 'middle')});
    } catch (ex) {
      console.error('failed to revert selection:', ex);
    }
  }


  /**
   * callback handler
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case "UpdateRecord":
          break;

        case "GotoNextRecord":
          gotoNextRecord();
          break;

        case "UpdateValidationState":
          updateValidationState(msg.data as number);
          break;

        case "SelectPlateById":
          selectPlateById(msg.data);
          break;

        case "SelectPlateByIndex":
          selectPlateByIndex(msg.data);
          break;

        case "PlateListChanged":
          updatePlateList();
          break;

        case "PlatesDatasetLoaded":
          setValue(v => v + 1);
          if (common.app.search.open)
            selectFirstRecord();
          break;

        case "PlateStateUpdated":
          common.plates.gridApi.refreshCells();
          break;

        case "GotoPreviousRecord":
          gotoPreviousRecord();
          break;

        case 'PlateSettingsChanged':
          setValue(v => v + 1);
          break;

        case 'PlatesRevertSelection':
          revertSelection(msg.data);
          break;
      }
    });

    return (() => subscription.unsubscribe())
  }, []);
  


  return (
    <Box display="flex" flex="1" flexDirection="column" >
              <PlatesFilter></PlatesFilter>

              <div className="ag-theme-balham" style={{ height: '100%', width: '290px'}}>
               
                <AgGridReact 
                    rowSelection={'single'}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    // suppressCellSelection={true}
                    onSelectionChanged={ (e:any) => handleSelection(e)}
                    onCellFocused = {(e:any) => handleCellFocused(e)}
                    onCellDoubleClicked = {(e:any) => handleCellDoubleClicked(e)}
                    overlayNoRowsTemplate='<b>No plate images</b>'
                    rowData={common.plates.records || []}>
              </AgGridReact>
            </div>
      </Box>
  );

}