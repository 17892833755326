import React, {useState, useEffect} from 'react';
import  { FormControlLabel, Button, Typography, Tooltip, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { PlateEditor } from './plateEditor';
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';
import { Check as CheckIcon, ErrorOutline as ErrorIcon, Save as SaveIcon, FolderOpen as LoadIcon } from '@material-ui/icons';





/**
 * editor for plate annotations - contains tag editors
 * handles most editing operations
 * @param props 
 * @returns 
 */
export function AuditPanel(props: any) {

  const [value, setValue] = useState(0); // integer state


  /**
   * common change handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any = null) => {
    try {
      switch (key) {

        case 'AuditSavingChanged':
        case 'AuditLoadingChanged':
        case 'PlatesRecordLoaded':
          setValue(v => v + 1);
          break;

        case 'Yes':
            common.notify('PlateAuditYes');
            break;

        case 'No':
            common.notify('PlateAuditNo');
            break;

        case 'Noplate':
          common.notify('PlateAuditNoPlate');
          break;

        case 'Occluded':
          common.notify('PlateAuditOccluded');
          break;

        case 'Save':
            common.notify('PlateAuditSave');
            break;

     
    
      }
    } catch (ex) {
      console.error('failed to handle plateEditor changes: ', ex);
    }
  }


  /**
   * re
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  const dispFile = (contents:any) => {
    common.notify('PlateAuditLoaded', contents as any)
    const elm = document.getElementById('contents');
    if (elm) 
      elm.innerHTML=contents
  }

  const clickElem = (elem:any) => {
    var eventMouse = document.createEvent("MouseEvents")
    eventMouse.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
    elem.dispatchEvent(eventMouse)
  }

  let fileInput: any = null;
  const  openFile = (func:any) => {
    const readFile = function(e:any) {
      var file = e.target.files[0];
      if (!file) {
        return;
      }
      common.plates.auditFile = file.name;
      var reader = new FileReader();
      reader.onload = function(e) {
        var contents = e?.target?.result;
        fileInput.func(contents)
        document.body.removeChild(fileInput)
      }
      reader.readAsText(file)
    }
    fileInput = document.createElement("input")
    fileInput.type='file'
    fileInput.style.display='none'
    fileInput.onchange=readFile
    fileInput.func=func
    document.body.appendChild(fileInput)
    clickElem(fileInput)
  }

  const isTagged = () => {
    try {
      const annotations = common.plates.selectedRecord?.Annotations || [];
      return annotations.length > 0;
    } catch (ex) {

    }
  }

  const loading = common.plates.auditLoading;
  const saving = common.plates.auditSaving;

  const w = '110px';

  return ( 
    <Box display="flex" flex="1" flexDirection="column"  margin="4px" >
          <Box display="flex" flexDirection="row">
        <Box width="10px"/>
        <Button disabled={isTagged()} onClick={() => handleChanges('Yes')} style={{color: isTagged() ?  'Gray': 'green', width: w}} startIcon={<CheckIcon/>} variant="contained">Yes</Button>
        <Box width="10px"/>
        <Button disabled={isTagged()} onClick={() => handleChanges('No')} style={{color: isTagged() ?  'Gray':'red', width: w}} startIcon={<ErrorIcon/>} variant="contained">No</Button>
        <Box width="50px"/>
        <Button onClick={() => handleChanges('Save')} style={{color:'black'}} startIcon={<SaveIcon/>} variant="contained">Save</Button>
        <Box width="10px"/>
        <Button onClick={ () => openFile(dispFile)} style={{color:'black'}} startIcon={<LoadIcon/>} variant="contained">Load</Button>

        { loading && <Box display="flex" flexDirection="row" alignItems="center">
            <CircularProgress style={{height:'20px', width: '20px'}}></CircularProgress>
            <Typography>Loading audit data ...</Typography>
          </Box>}

          { saving && <Box display="flex" flexDirection="row" alignItems="center">
            <CircularProgress style={{height:'20px', width: '20px'}}></CircularProgress>
            <Typography>Saving audit data ...</Typography>
          </Box>}
    </Box> 

    <Box height="20px"/>

    <Box display="flex" flexDirection="row">
    <Box width="10px"/>
        <Button disabled={isTagged()} onClick={() => handleChanges('Noplate')} style={{color: isTagged() ?  'Gray': 'black', width: w}}  
        variant="contained">No plate</Button>
        <Box width="10px"/>
        <Button disabled={isTagged()} onClick={() => handleChanges('Occluded')} style={{color: isTagged() ?  'Gray': 'black', width: w}}  
        variant="contained">Occluded</Button>
   
    </Box>

    

    {isTagged() && <Box display="flex" flexDirection="row" margin="4px">
    <Typography>Record is already tagged, audit is disabled</Typography>
    </Box>}

      
    </Box>

 
 
  )

}

const useStyles = makeStyles({

  radio: {
    margin: 0,
    padding: 0,
    fontSize: 40,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
    fontSize:40,
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

});