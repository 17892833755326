
import { CompoundBool, CompoundRect, InsideEntry, InsideItem } from '../data/platesData';
import common from './commonService';
import platesService from './platesService';

/**
 * duplicate point definition
 */
 interface Point {
    x: number;
    y: number;
  }

/**
 * manage inside inspection
 */
export class InsideCanvas {

    insiding: boolean = false;
    insideStart: Point | null = null;
    insideEnd: Point | null = null;

  canvas:any;
  ctx: any;
  matrix: number[] = [1,0,0,1,0,0];
  mousePos: Point | null = null;
  resizing: boolean = false;

    constructor() {
        common.notifier$.subscribe(msg => {
            switch(msg.name) {
                case 'PlatesDeleteInside':
                    this.deleteInside(msg.data);
                    break;

                case 'InsideGainChanged':
                  this.paint();
                  break;
            }
        });
    }

    /**
     * creates the singleton
     */
    initialize() {
        


    }

      /**
   * retrieve point of a mouse event
   * @param e mouse event
   */
  getPoint = (e: any): any => {
    try {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      if (!this.ctx)
        return {x:0, y:0};
        
      return (this.ctx as any).transformedPoint(x, y);
    } catch (ex) {
      console.error('failed to getPoint', ex);
      return {x: 0, y: 0};
    }
  }
      /**
   * pass a ref to ui canvas 
   * @param canvas 
   */
  setCanvas = (canvas: HTMLCanvasElement) => {
    try {
      if (canvas === null) 
        return;

      this.canvas = canvas;
      this.ctx = canvas.getContext('2d') as any;
      this.trackTransforms(this.ctx);
    } catch (ex) {
      console.error('failed to set canvas: ', ex);
    }
  }

  paint = () => {
    try {
      const canvas = this.canvas;
      if (!canvas) {
        // console.error('failed to get inside canvas');
        return;
      }

      const ctx = this.ctx;
      ctx.save();
      ctx.setTransform(1,0,0,1,0,0);
      ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
      ctx.restore();
      const img = platesService.canvas.img;
      if (img?.src?.endsWith('null'))
        return;
      
      if (!img?.src)
        return;
      
      try {
        ctx.drawImage(img, 0, 0);
      } catch (ex) {
        console.error('failed on drawImage:', ex);
      }

      this.paintHotInside();
      this.paintFaces();
      this.applyGain(ctx);
    } catch (ex) {
      console.error(`failed to paint inside:`, ex);
    } finally {
      common.plates.painting = false;
    }
  }

  applyGain = (ctx: any) =>  {
    try {
      const gain = common.plates.insideGain;
      if (gain === 0)
        return;

      
      const imgData = ctx.getImageData(0,0,ctx.canvas.width,ctx.canvas.height);
      const data = imgData.data;
      const factor = (1 + gain / 25);
      for (let i = 0; i  < data.length; i++) {
        data[i] = Math.min(data[i] * factor, 255);
      }
      ctx.putImageData(imgData, 0, 0);
      return true;
    } catch (ex) {
      console.error('failed to apply gain:', ex);
    }
  }

    /**
   * draw an anchor around a vertex
   * @param ctx 
   * @param pt 
   */
     drawAnchor = (ctx:any, pt:Point) => {
      try {
        const trans = ctx.getTransform();
        let r = this.getAnchorSize();
        const color = "red";
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.arc(pt.x, pt.y, r, 0, 2 * Math.PI);
        ctx.stroke();
      } catch (ex) {
        console.error('failed to draw anchor:', ex);
      }
    }

  boundInside(op: string, pt: Point) : boolean {
    try {
      switch (op) {
        case "Start":
          this.insideStart = pt;
          this.insideEnd = pt;
          this.insiding = true;
          break;
  
        case "MouseMove":
          this.insideEnd = pt;
          this.paint();
          break;
  
        case "MouseUp":
          this.updateFaceRect();
          this.insideStart = null;
          this.insideEnd = null;
          this.insiding = false;
          this.paint();
          break;
      }
      return true;
  
    } catch (ex) {
      console.error('failed to measure char height:', ex);
      return false;
    }
  }

  updateFaceRect = () => {
    try {
        const inside = common.plates.selectedInside;
        const passenger = inside?.selectedPassenger;
        const hotInside = this.getHotInside();
        if (!passenger || !hotInside)
            return;

        passenger.faceRect = new CompoundRect();
        passenger.faceRect.value = hotInside;

        passenger.faceVisible.value = true;
        common.notify('InsidePassengerChanged');

    } catch (ex) {
        console.error('failed to update face rect:', ex);
    }
  }

  getHotInside = () => {
    try {
      if (!this.insideStart || !this.insideEnd)
        return null;
      
      const x = Math.min(this.insideStart.x, this.insideEnd.x);
      const y = Math.min(this.insideStart.y, this.insideEnd.y);
      const w = Math.abs(this.insideEnd.x - this.insideStart.x);
      const h = Math.abs(this.insideEnd.y - this.insideStart.y);
      return [Math.round(x),Math.round(y),Math.round(w),Math.round(h)];
    } catch (ex) {
      console.error('failed to get hot bounding:', ex);
    }
  }

  paintHotInside = () => {
    try {
      const pt0 = this.insideStart;
      const pt1 = this.insideEnd;
      if (pt0 && pt1) {
        const x = Math.min(pt0.x, pt1.x);
        const y = Math.min(pt0.y, pt1.y);
        const w = Math.max(pt0.x, pt1.x) - x;
        const h = Math.max(pt0.y, pt1.y) - y;
        const vertices = this.getPoints([x,y,w,h]);
        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = "cyan";
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        ctx.lineTo(vertices[1].x, vertices[1].y);
        ctx.lineTo(vertices[2].x, vertices[2].y);
        ctx.lineTo(vertices[3].x, vertices[3].y);
        ctx.lineTo(vertices[0].x, vertices[0].y);
        ctx.stroke();        
      }
    } catch (ex) {
      console.error('failed to measure rect:', ex);
    }
  }

  paintFaces = () => {
    try {
        const inside = common.plates.selectedInside;
        if (!inside)
            return;

        const passengers = inside.passengers;
        passengers.forEach(r => {
            r.forEach(p => {
                this.paintFace(p);
            });
        });

    } catch (ex) {
        console.error('failed to paint faces:', ex);
    } 
  }

  paintFace = (item:InsideItem) => {
    try {
        const selectedPassenger = common.plates.selectedInside?.selectedPassenger
        if (!selectedPassenger)
          return;
        const selected = item === selectedPassenger;
        const rect = item.faceRect.value;
        if (rect?.length !== 4)
            return;
        const vertices = this.getPoints(rect);
        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = selected ? "cyan" : "gray";
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        ctx.lineTo(vertices[1].x, vertices[1].y);
        ctx.lineTo(vertices[2].x, vertices[2].y);
        ctx.lineTo(vertices[3].x, vertices[3].y);
        ctx.lineTo(vertices[0].x, vertices[0].y);
        ctx.stroke();        

        if (selected && selectedPassenger) {
            const pts = this.getPointsEx(item.faceRect.value);
            this.paintAnchorPositions(pts, "cyan");
            const hoverIndex = selectedPassenger.hoverIndex;
            if (hoverIndex > -1)
              this.drawAnchor(ctx, pts[hoverIndex]);
        }
    } catch (ex) {
        console.error('failed to paint face:', ex);
    }
  }

    /**
   * vertices and midpoints
   * @param rect 
   * @returns 
   */
     getPointsEx(rect:number[] | null) {
        try {
          if (!rect || rect.length !== 4)
            return [];
    
            const l = rect[0];
            const t = rect[1];
            const w = rect[2];
            const h = rect[3];
            const top = {x:l+w/2, y:t};
            const right = {x:l+w, y:t+h/2 };
            const bottom = {x:l+w/2,y:t+h};
            const left = {x:l,y:t+h/2};
            const pts =  [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h},top, right, bottom, left];
            return pts;
        } catch (ex) {
          console.error("failed to get points: " + ex);
          return [];
        }
      }

        /**
   * distance between two points
   * @param pt0 
   * @param pt1 
   */
  getDistance = (pt0:Point, pt1: Point) => {
    try {
      const dx = pt0.x - pt1.x;
      const dy = pt0.y - pt1.y;
      return Math.sqrt(dx*dx + dy*dy);
    } catch (ex) {
      console.error('failed to get point: ', ex);
      return 0;
    }
  };

  paintAnchorPositions = (pts:any[], color: string) => {
    try {
      const ctx = this.ctx;
      ctx.fillStyle = color;
      ctx.globalAlpha = 0.6;
      let r = this.getAnchorSize();
      r = r / 2;
      pts.forEach(pt => {
        ctx.fillRect(pt.x - r, pt.y - r, r * 2, r * 2);
      });
      ctx.globalAlpha = 1.0;

    } catch (ex) {
      console.error('failed to paint anchor positions: ', ex);
    }
  }

  getAnchorSize = (): number => {
    try {
      const trans = this.ctx.getTransform();
      let anchorSize = 10 / trans.a;
      anchorSize = Math.max(anchorSize,5);
      anchorSize = Math.min(anchorSize, 50);
      return anchorSize;
    } catch (ex) {
      return 1;
    }
  }

    /**
   * get a list of point vertices of specified rect
   * @param rect 
   */
     getPoints(rect:number[] | null) {
        try {
          if (!rect || rect.length !== 4)
            return [];
    
            const l = rect[0];
            const t = rect[1];
            const w = rect[2];
            const h = rect[3];
            return [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h}];
        } catch (ex) {
          console.error("failed to get points: " + ex);
          return [];
        }
      }

        /**
   * gets the linewidth, factored by zoom
   */
  getLineWidth = (): number => {
    try {
      const trans = this.ctx.getTransform();
      let lineWidth = 3 / trans.a;
      lineWidth = Math.max(lineWidth,1);
      lineWidth = Math.min(lineWidth, 10);
      return lineWidth;
    } catch (ex) {
      return 1;
    }
  }

    /**
   * save ctx matrix
   */
     saveMatrix = () => {
        try {
          const m = this.ctx.getTransform();
          this.matrix = [m.a, m.b, m.c, m.d, m.e, m.f];
        } catch (ex) {
          console.error('failed to save matrix:', ex);
        }
      };

        /**
   * restore trans matrix
   */
  restoreMatrix = () => {
    try {
      const m = this.matrix;
      this.ctx.setTransform(...m);
    }  catch (ex) {
      console.error('failed to restoreMatrix:', ex);
    }
  }

    /**
   * extend ctx for pan and zoom
   * @param ctx 
   */
     trackTransforms = (ctx:any) => {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      let xform = svg.createSVGMatrix();
      ctx.getTransform = function() { return xform; };
    
      const savedTransforms:any = [];
      const save = ctx.save;
      ctx.save = function() {
        savedTransforms.push(xform.translate(0, 0));
        return save.call(ctx);
      };
      const restore = ctx.restore;
      ctx.restore = function() {
        xform = savedTransforms.pop();
        return restore.call(ctx);
      };
    
      const scale = ctx.scale;
      ctx.scale = function(sx:number, sy:number) {
        xform = (xform as any).scaleNonUniform(sx, sy);
        return scale.call(ctx, sx, sy);
      };
      const rotate = ctx.rotate;
      ctx.rotate = function(radians:number) {
        xform = xform.rotate(radians * 180 / Math.PI);
        return rotate.call(ctx, radians);
      };
      const translate = ctx.translate;
      ctx.translate = function(dx:number, dy:number) {
        xform = xform.translate(dx, dy);
        return translate.call(ctx, dx, dy);
      };
      const transform = ctx.transform;
      ctx.transform = function(a:number, b:number, c:number, d:number, e:number, f:number) {
        const m2 = svg.createSVGMatrix();
        m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
        xform = xform.multiply(m2);
        return transform.call(ctx, a, b, c, d, e, f);
      };
      const setTransform = ctx.setTransform;
      ctx.setTransform = function(a:number, b:number, c:number, d:number, e:number, f:number) {
        xform.a = a;
        xform.b = b;
        xform.c = c;
        xform.d = d;
        xform.e = e;
        xform.f = f;
        return setTransform.call(ctx, a, b, c, d, e, f);
      };
      const pt  = svg.createSVGPoint();
      ctx.transformedPoint = function(x:number , y:number) {
        pt.x = x; pt.y = y;
        return pt.matrixTransform(xform.inverse());
      };
      ctx.untransformedPoint = function(x:number , y:number) {
        pt.x = x; pt.y = y;
        return pt.matrixTransform(xform);
      };
    }

    /**
     * delete the specified tagging
     * @param inside 
     * @returns 
     */
    deleteInside = (inside:any) => {
        try {
          const insides = common.plates?.insides;
          const index = insides?.indexOf(inside);
          if (index < 0)
            return;
    
          insides.splice(index,1);
          common.notify('InsideDeleted');
    
        } catch (ex) {
          console.error('failed to delete vehicle:', ex);
        }
      }

      /**
       * get the native version of an inside item
       * @param e 
       */
      getNativeInsideItem(e: InsideItem) {
        try {



        } catch (ex) {
          console.error('failed to get native inside item:', ex);
        }
      }

      /**
       * get the native collection of inside items
       * @returns 
       */
      getNativeInsides() {
        try {
          const insides = common.plates.insides;
          const natives = insides.map(inside => inside.getNative());
          return natives;
        } catch (ex) {
          console.error('failed to get native insides:', ex);
        }
      }

      /**
       * helper - returns a boolean entry from an native entry
       * @param native 
       * @returns 
       */
      getBoolFromNative(native: any): CompoundBool {
        try {
          const cb = new CompoundBool();
          cb.value = native?.value === '1';
          cb.status = native?.status || 'tagged';
          return cb;
        } catch (ex) {
          console.error('failed to get compound bool:', ex);
          return new CompoundBool();
        }
      }

      /**
       * returns a single tagging from native data
       * @param native 
       * @returns 
       */
      getInsideItemFromNative(native: any): InsideItem {
        try {
          const occupied = native.occupied;
          const driver = native.driver;
          const belt = native.belt;
          const phone = native.phone;
          const item = new InsideItem();
          item.occupied = this.getBoolFromNative(occupied);
          item.driver = this.getBoolFromNative(driver);
          item.beltVisible = this.getBoolFromNative(belt);
          item.phoneVisible = this.getBoolFromNative(phone);
          return item;
        } catch (ex) {
          console.error('failed to get inside item from native:', ex);
          return new InsideItem();
        }
      }

      /**
       * retuns inside collection from native record
       * @param nativeRecord 
       * @returns 
       */
      getInsidesFromNative(nativeRecord:any) {
        try {
          let natives = nativeRecord?.image_library?.header?.insides;
          if (!natives)
            return [];

          // remove null entries
          natives = natives.filter((n:any) => !!n);


          const insides = natives.map((native:any) => {
            const inside = new InsideEntry();
              return inside;
          });
          return insides;
        } catch (ex) {
          console.error('failed to get insides from native:', ex);
        }
      }
}

