
import common from './commonService';

class Point {
  x: number;
  y: number;
  constructor() {
    this.x = 0;
    this.y = 0;
  }
}

export class AxlesStitcher {

  canvas:any;
  ctx: any;
  images: any[];
  thumbWidth: number;
  thumbHeight: number;
  loaded: boolean = false;

  constructor() {
    this.canvas = null;
    this.ctx = null;
    this.images = [];
    this.thumbWidth = 0;
    this.thumbHeight = 0;
    

    common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'AxleThumbsLoaded':
          this.initialize();
          break;

        case 'AxleTagsChanged':
        case 'AxlesClearTags':
        case 'AxlesStitchChanged':
          this.paint();
          break;

        case 'AxlesStitchClicked':
          this.handleImageClick(msg.data);
          break;

        case "AxlesStitchMode":
        case 'AxlesStitchedSizeChanged':
          this.paint();
          break;

      }
    });
  }

  setCanvas = (canvas: HTMLCanvasElement) => {
    try {
      this.canvas = canvas;
      this.ctx = canvas.getContext('2d') as any;
      this.paint();
    } catch (ex) {
      console.error('failed to set canvas: ', ex);
    }
  }

  /**
   * initialize images (when thumbs are loaded)
   */
  initialize() {
    try {
      this.clear();
      const thumbs = common.axles.thumbs;
      let loaded = 0;
      this.images = thumbs.map(thumb => {
        const img = new Image();
        img.onload = () => {
          this.thumbWidth = img.naturalWidth;
          this.thumbHeight = img.naturalHeight;
          loaded += 1;
          if(loaded === this.images.length) {
            this.loaded = true;
            this.paint();
          }
        };
        img.setAttribute('src', thumb.thumbUrl);
        return {img, epoch: thumb.epoch, thumb };
      })
    } catch (ex) {
      console.error('failed to initialize');
    }
  }

  /**
   * clear the canvas
   */
  clear() {
    try {
      this.ctx?.clearRect(0, 0, 2000, 200);
    } catch (ex) {
      console.error('failed to clear stitched:', ex);
    }
  }

  /**
   * render stitched image
   */
  paint() {
    try {
      if (!this.ctx || !common.axles.overlapMode || !this.loaded)
        return;

      if (common.axles.loadingRecord)
        return;
        
      this.ctx.clearRect(0, 0, 2000, 200);
      const pixelsPerMs = common.axles.sequence?.stitcher.pixelsPerMs || 0;
      const offset = common.axles.sequence?.stitcher.stitchOffset || 0;
      const f = 200 / this.thumbHeight;
      const sw = this.thumbWidth - offset;
      const sh = this.thumbHeight;

      const w = this.thumbWidth * f - offset * f;
      const h = this.thumbHeight * f;
      const t0 = this.images[0].epoch;
   
      const lastImage = this.images[this.images.length - 1];
      let dxLast = (lastImage.epoch - t0);
      dxLast = (dxLast * pixelsPerMs) / 10;

      this.ctx.font = '14px Arial';
      this.ctx.fillStyle = 'red';

      for (let i = 0; i < this.images.length; i++) {
        const img = this.images[i];
        let dx = img.epoch - t0;
        dx = (dx * pixelsPerMs) / 10;

        if (common.axles.sequence?.transitDirection === 'LeftToRight')
          dx = dxLast - dx;

        img.offset = dx;
        img.width = w;
        img.height = h;
        // ratio between displayed and full size image
        img.factor = 200 / common.axles.imageSize[1];
        // ratio between thumbnail and displayed image
        img.factor2 = w / sw;
        img.dx = dx;
    
        try {
          this.ctx.drawImage(img.img, offset, 0, sw, sh, dx, 0, w, h);
        } catch (ex) {
          console.error(`failed on draw stitch: ${i}`);
        }
     
        
      }



      const tags = this.getTags();
      this.paintTags(tags);
      this.paintSelectedImageLocation();
   
    } catch (ex) {
      console.error('failed to paint stitched:', ex);
    }
  }

  /**
   * paints a green bar to depict selected image location
   * @returns 
   */
  paintSelectedImageLocation() {
    try {
      if (common.axles.imageIndex < 0) return;
      const ctx = this.ctx;
      const img = this.images[common.axles.imageIndex];
      const w = 2;
      ctx.fillStyle="lawngreen";
      ctx.fillRect(img.dx, 0, img.width, w)
    } catch (ex) {
      console.error('failed to paint selected image location:', ex);
    }
  }

  /**
   * convert point to stitch coords
   * @param pt 
   * @param imageIndex 
   * @returns 
   */
  toStitchCoords(pt: Point, imageIndex: number): Point | null {
    try {
      const image = this.images[imageIndex];
      if(!image) {
        console.error('failed toStitchCoords');
        return {x:0,y:0};
      }

      const offset = common.axles.sequence?.stitcher.stitchOffset || 0;
      let x = pt.x *  image.factor;
      const y = pt.y * image.factor;
      const imageOffset = image.offset;
      x += imageOffset - offset * image.factor2; //  * image.factor;
      return {x, y};
    } catch (ex) {
      console.error('failed to stitchCoords:', ex);
      return null;
    }
  }

  getTags(): any[] {
    try {
      const seq = common.axles.sequence;
      return common.axles.tags || [];
    } catch (ex) {
      console.error('failed to get tags:', ex);
      return [];
    }
  }

  isDistinctTag(tag: any, tags: any[]): boolean {
    try  {
      const rect = this.rectToStitch(tag.rect, tag.ImageIndex);
      const cx = rect[0] + rect[2]/2;
      const cy = rect[1] + rect[3]/2;
      for(let i = 0; i < tags.length; i++) {
        // non dovrebbe
        if (!tags[i])
          continue;

        const r = this.rectToStitch(tags[i].rect, tags[i].ImageIndex);
        const inside = r[0] <= cx && r[0] + r[2] > cx && r[1] <= cy && r[1] + r[3] > cy;
        if (inside === true) {
          // tags[i].overlays++;
          return false;
        }
      }
      return true;
    } catch (ex) {
      console.error('failed on isDistinctTag:', ex);
      return false;
    }
  }

  getOverlaps(tag: any, tags: any[]): any[] {
    try  {
      const rect = this.rectToStitch(tag.rect, tag.ImageIndex);
      const cx = rect[0] + rect[2]/2;
      const cy = rect[1] + rect[3]/2;
      const overlaps = []; 
      for(let i = 0; i < tags.length; i++) {
        const r = this.rectToStitch(tags[i].rect, tags[i].ImageIndex);
        const inside = r[0] <= cx && r[0] + r[2] > cx && r[1] <= cy && r[1] + r[3] > cy;
        if (inside === true) {
          overlaps.push(tags[i]);
        }
      }
      return overlaps;
    } catch (ex) {
      console.error('failed on isDistinctTag:', ex);
      return [];
    }
  }

  getDistinctTags(): any[] {
    try {
      let tags = common.axles.tags;
      if (!tags)
        return [];

        const distinct:any[] = [];
      const selected = common.axles.tag;
  
      if (selected && !selected.Cab)
        distinct.push(selected);

      // prevent mixing cabs and wheels
      tags = tags.filter((t:any) => !t.Cab);
      tags.forEach((t:any) => {
        if (this.isDistinctTag(t, distinct)) 
          distinct.push(t);
      });

      distinct.reverse();
      const cabs = common.axles.tags.filter((t:any) => t.Cab);
      if (cabs)
        distinct.push(cabs[0]);

      return distinct;
    } catch (ex) {
      console.error('failed to get distinct tags: ',ex);
      return [];
    }
  }



  getPoints(rect:number[] | null) {
    try {
      if (!rect || rect.length !== 4)
        return [];

        const l = rect[0];
        const t = rect[1];
        const w = rect[2];
        const h = rect[3];
        return [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h}];
    } catch (ex) {
      console.error("failed to get points: " + ex);
      return [];
    }
  }

  rectToStitch(rect: number[], index: number): number[] {
    try {
      const pts = this.getPoints(rect);
      const pts2 = pts.map(pt => this.toStitchCoords(pt, index));
      const x = pts2[0]?.x || 0;
      const y = pts2[0]?.y || 0;
      const w = (pts2[1]?.x || 0) - x;
      const h = (pts2[3]?.y || 0) - y;
      return [x,y,w,h];
    } catch (ex) {
      console.error('failed on rectToStitch:', ex);
      return [0,0,0,0];
    }
  }

  /**
   * paint all tags
   * @param tags 
   */
  paintTags(tags:any[]) {
    try {
      const tags = this.getDistinctTags();
      const selectedTag = common.axles.tag;
      tags.forEach(t => {
        const selected = t === selectedTag;
        const r = this.rectToStitch(t.rect, t.ImageIndex);
        this.ctx.fillStyle = 'red';
        this.ctx.strokeStyle= selected ? 'lawngreen' : 'green';
        if (t.Cab)
          this.ctx.strokeStyle = 'magenta';
        this.ctx.beginPath();
        this.ctx.lineWidth = selected ? 4 : 2;
        this.ctx.strokeRect(...r);
        const decPos = {x: r[0], y: r[1] + r[3]}
        this.paintTagDecorations(t, decPos);
      
      });

    } catch (ex) {
      console.log('')
    }
  }

  /**
   * whether a point is within a rect
   * @param rect 
   * @param pt 
   * @returns 
   */
  contains(rect: number[], pt: any) {
    try {
      const contains =  rect[0] < pt.x && rect[0] + rect[2] > pt.x &&
        rect[1] < pt.y && rect[1] + rect[3] > pt.y;
        return contains;
    } catch (ex) {
      console.error('failed on contains: ', ex);
      return false;
    }
  }

  /**
   * tag was clicked - select it
   * @param tag 
   */
  handleTagClicked(tag: any) {
    try {
      if (common.axles.imageDirty)
        return;
        
      common.notify('AxlesStitchSelectIndex', tag.ImageIndex);
      common.notify("AxlesTagSelected", tag);
    } catch (ex) {
      console.error('failed to handle tag clicked:', ex);
    }
  }

  getNextTag(t: any, tags: any[]) {
    try {
      if (!tags)
        return null;

      if (!t)
        return tags[0];

      const index = tags.indexOf(t);
      if (index < 0)
        return tags[0];

      return index === tags.length -1 ? tags[0] : tags[index+1];
    } catch (ex) {
      console.error('failed to get next tag:', ex);
    }
  }

  /**
   * user clicked stitched image
   * @param evt 
   */
  handleImageClick(evt: any) {
    try {
      if (!common.axles.sequence)
        return;

      const pt = this.getPoint(evt);

      // 1. check for tag click
      const tags = this.getTags();

      const contained = tags.filter((t:any) => this.contains(this.rectToStitch(t.rect, t.ImageIndex), pt));
      if (contained ) {
        const nextTag = this.getNextTag(common.axles.tag, contained);
        if (nextTag)
          return this.handleTagClicked(nextTag);
      }


      let containedTag = null;
      tags.forEach(t => {
        const r = this.rectToStitch(t.rect, t.ImageIndex);
        if (this.contains(r, pt)) 
          containedTag = t;
      });
      if (containedTag)
        return this.handleTagClicked(containedTag);

      for (let i = 0; i < this.images.length-1; i++) {
        if (this.images[i].dx < pt.x && this.images[i+1].dx > pt.x) {
          common.notify('AxlesStitchSelectIndex', i as any);
        }
      }
    } catch (ex) {
      console.error('failed on handleImageClick:', ex);
    }
  }

  /**
   * point from mouse event
   * @param e 
   * @returns 
   */
  getPoint = (e: any): any => {
    try {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      return { x, y};
    } catch (ex) {
      console.error('failed to getPoint');
      return {x: 0, y: 0};
    }
  }

  /**
   * get decorations for the give tag
   * @param tag 
   * @returns 
   */
  getDecorations = (tag:any): string[] => {
    try {
      const decorations:string[]  = [];

      if (tag.WheelPosition === "F")
          decorations.push("Front");

      if (tag.Twin === "1")
          decorations.push("Twin");

      if (tag.Lifted === "1")
          decorations.push("Lifted");

      if (tag.WheelOrientation !== "U" || tag.WheelPosition === "UP" || tag.Lifted === "2" || tag.Partial === "1")
          decorations.push("Warning");

      if (tag.WheelPosition === "R")
          decorations.push("Rear");

      return decorations;
    } catch (ex) {
      console.error('failed to get decorations:', ex);
      return [];
    }
  }

  paintTagDecorations = (tag: any, pt: Point) => {
    try {

      let x = pt.x;
      const w = 5;
      const y = pt.y + w * 1.5;
      const ctx = this.ctx;
   
      const decorations = this.getDecorations(tag);
      decorations.forEach(dec => {
        switch(dec) {
          case "Front":
            {
              ctx.fillStyle="lawngreen";
              ctx.beginPath();
              ctx.moveTo(x, y);
              ctx.lineTo(x + w, y - w);
              ctx.lineTo(x + w, y + w );
              ctx.fill();
              x += w * 1.5;
            }
            break;

          case "Twin":
            {
              const r = w / 2;
              ctx.strokeStyle="lawnGreen";
              ctx.beginPath();
              ctx.arc(x + r, y, r, 0, 2 * Math.PI);
              ctx.stroke();
              x += r;
              ctx.beginPath();
              ctx.arc(x + r, y, r, 0, 2 * Math.PI);
              ctx.stroke();
              x += r * 3;
            }
            break;

          case "Lifted":
            {
              ctx.fillStyle="lawngreen";
              ctx.beginPath();
              ctx.moveTo(x, y + w);
              ctx.lineTo(x + w / 2, y - w);
              ctx.lineTo(x + w, y + w );
              ctx.fill();
              x += w * 1.5;
            }
            break;

          case "Warning":
            {
              const h = 2 * w / 3;
              ctx.fillStyle="orangered";
              ctx.beginPath();
              ctx.moveTo(x, y + h);
              ctx.lineTo(x + w, y - h);
              ctx.lineTo(x + 2 * w, y + h );
              ctx.fill();
              x += w * 2.5;
            }
            break;

          case "Rear":
            {
              ctx.fillStyle="lawngreen";
              ctx.beginPath();
              ctx.moveTo(x, y + w);
              ctx.lineTo(x, y - w);
              ctx.lineTo(x + w, y);
              ctx.fill();
              x += w * 1.5;
            }
            break;
        }
      });

    } catch (ex) {
      console.error('failed to paint tag decorations:', ex);
    }
  }



}

const axlesStitcher:AxlesStitcher = new AxlesStitcher();
export default axlesStitcher;