import React, {useState, useEffect} from 'react';
import { Box} from "@material-ui/core";
import common from '../../services/commonService';

export function Images(props: any) {


    // eslint-disable-next-line
  const [value, setValue] = useState(0); 

  const handleChanges = (arg: string, value: any) => {
    try {
      switch (arg) {
        case 'VideoRecordLoaded':
          setValue(v => v + 1);
          break;
      
        
      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
  
    return (() => {
      subscription.unsubscribe()});
  }, []);


  return <Box display="flex" flex="1" flexDirection="row"  >
    <Box display="flex">
    <img src={common.video?.record?.base64Frame} width={500} height={400} alt=""></img>
    </Box>
    <Box display="flex">
    <img src={common.video?.record?.base64Ocr} width={500} height={400} alt="" ></img>
    </Box>
  </Box>

}