import React, {useState, useEffect, useRef} from "react";
import { Box,  IconButton, Tooltip } from "@material-ui/core";
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';
import platesService from '../../services/platesService';
import { AspectRatio, Reorder as ReorderIcon, ArrowDownward as DownloadIcon, FileCopy as CopyIcon, AddCircle as PasteIcon } from "@material-ui/icons";
import pan from './pan';

export function IdWrapper(props:any) {

  const[value, setValue] = useState(0);
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case "PlatesTagTypeChanged":
        case "PlatesTagTypeChanged2":
        case "PlatesRecordLoaded":
          setValue(v => v + 1);
          break;

        
      }
    });
    return (() => subscription.unsubscribe())
  }, []);

  const id = common.plates?.selectedRow?._id;
  return ( 
  <Tooltip title="Image ID"> 
  <span> { id &&
    <span style={{padding:4, color:'white', background: 'rgba(0,0,0,0.2)'}}>{id}</span>
    }
  </span>
  </Tooltip> )
}

export function ControlsWrapper(props:any) {

  const[value, setValue] = useState(0);
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'AxlesThumbChanged':
        case "PlatesRecordLoaded":
          setValue(v => v + 1);
          break;
      }
    });
    return (() => subscription.unsubscribe())
  }, []);

  const id = common.axles.imageId;
  const seqId = common.axles?.selectedRow?.id;
  const lightTagging = common.plates.newTagType === 'Lights';
  const hazardTagging = common.plates.newTagType === 'Hazards';

  return ( 

  <Box display="flex" flexDirection="row">
        
        {lightTagging && <Tooltip title="Copy traffic lights geometry">
        <div>
        <IconButton   style={{color: 'white'}}
          onClick={() => common.notify('CopyLightsGeometry')}
          size="medium"
          color="primary" >
          <CopyIcon />
        </IconButton>
        </div>
      </Tooltip> }

      {lightTagging && <Tooltip title="Paste traffic lights geometry">
        <div>
        <IconButton   style={{color: 'white'}}
          onClick={() => common.notify('PasteLightsGeometry')}
          size="medium"
          color="primary" >
          <PasteIcon />
        </IconButton>
        </div>
      </Tooltip>}
      <Tooltip title="Download raw image">
        <div>
        <IconButton   style={{color: 'white'}}
          onClick={() => common.notify('DownloadRawImage')}
          size="medium"
          color="primary" >
          <DownloadIcon />
        </IconButton>
        </div>
      </Tooltip>
     <Tooltip title="Unzoom">
        <IconButton  style={{color: 'white'}}
          onClick={() => common.notify('PlatesUnzoom')}
          size="medium"
          color="primary" >
          <AspectRatio />
        </IconButton>
      </Tooltip>
      <Tooltip title="Current record in JSON format">
        <div>
        <IconButton  disabled={common.app.context.json === ''}  style={{color: 'white'}}
          onClick={() => common.notify('ShowJsonRequest')}
          size="medium"
          color="primary" >
          <ReorderIcon />
        </IconButton>
        </div>
      </Tooltip>
  </Box>
  
  )
 
}


export function HeaderPlate(props:any) {

  const[value, setValue] = useState(0);
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case "PlateSettingsChanged":
        case "PlatesRecordLoaded":
          setValue(v => v + 1);
          break;
      }
    });
    return (() => subscription.unsubscribe())
  }, []);

  
  return ( 
  <Tooltip title="Header plate number"> 
  <Box display="inline" style = {{background: 'white', fontSize: "16px"}} >
   { common.plates.headerPlateNumber }
   </Box>
 
  </Tooltip> )
}

/**
 * Host the canvas with resizing
 * @param props 
 * @returns 
 */
export function CanvasWrapper(props: any) {


  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const canvasRef = useRef(null);

  const[value, setValue] = useState(0);

  /**
   * simulate mouse operations - needed by the scripts - analog th handleMouse below
   * @param data 
   */
  const simulateMouse = (data:any) => {
    try {
      const pt = data.noTrans ? {x: data.x, y: data.y} : platesService.canvas.getWindowPoint(data.x, data.y);
      const evt = {nativeEvent: {offsetX: pt.x, offsetY: pt.y, wheelDelta: 120 * data.wheel}};
      switch(data.action) {
        case "doubleClick":
          pan.doubleClick(evt);
          break;

        case "mouseDown":
          pan.mouseDown(evt);
          platesService.canvas.paint('simulate-down');
          break;

        case "mouseMove":
          pan.mouseMove(evt);
          platesService.canvas.paint('simulate-move');
          break;

        case "mouseUp":
          pan.mouseUp(evt);
          break;

        case "mouseWheel":
          pan.mouseWheel(evt);
          platesService.canvas.paint('simulate-wheel');
          break;

      }
    } catch (ex) {
      console.error('failed to simulate mouse:', ex);
    }
  }

  /**
   * handle mouseMove
   * @param evt 
   */
  const handleMouseMove = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = platesService.canvas;
    platesService.canvas.mousePos = platesService.canvas.getPoint(evt);
    const editor = getEditor();
    if (evt.ctrlKey)
      pan.mouseMove(evt);
    else
      editor.mouseMove(evt);
      platesService.canvas.paint('wrap-move');
  }

  /**
   * Handle mouse down
   * @param evt 
   */
  const handleMouseDown = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = platesService.canvas;
 
    const editor = getEditor();
    if (evt.ctrlKey) 
      pan.mouseDown(evt);
    else if (editor)
      editor.mouseDown(evt);
    else
       pan.mouseDown(evt);
  }

  /**
   * handle mouse wheel - zoom
   * @param evt 
   */
  const handleMouseWheel = (evt: any) => {
    // evt.preventDefault();
    evt.stopPropagation();
    pan.mouseWheel(evt);
    platesService.canvas.paint('wrap-wheel');
  }

  /**
   * handle mouse up
   * @param evt 
   */
  const handleMouseUp = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = platesService.canvas;
    const editor = getEditor();
    if (evt.ctrlKey)
      pan.mouseUp(evt);
    else
      editor.mouseUp(evt);
   
  }

  /**
   * handle mouse leave the canvas
   * @param evt 
   */
  const handleMouseLeave = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    pan.mouseLeave(evt);
  }

  /**
   * handle mouse click
   * @param evt 
   */
  const handleMouseClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = platesService.canvas;
    const editor = getEditor();
    if (editor)
      editor.click(evt);
      platesService.canvas.paint('wrap-click');
  }

  /**
   * handle double click (start annotation)
   * @param evt 
   * @returns 
   */
  const handleDoubleClick = (evt: any) => {
    try {
      if (common.plates.settings.mode !== 'Annotation')
        return;

      evt.preventDefault();
      evt.stopPropagation();
      pan.doubleClick(evt);
      console.log('double clicked');
    } catch (ex) {
      console.error('failed on double click', ex);
    }
  }

  /**
   * callback handler
   * @param msg 
   * @param value 
   */
  const handleCallbacks = (msg: string, value: any) => {
    try {
      switch(msg) {
        case "SimulateMouse":
          simulateMouse(value);
          break;

        case 'PlatesTagTypeChanged2':
        case 'PlatesTagTypeChanged':
        case "PlateSettingsChanged":
          setValue(v => v +1);
          break;
     
      }
    } catch (ex) {

    }
  }

  /**
   * single editor for now
   * @returns 
   */
  const getEditor = (): any => {
    return pan;
  }

  /**
   * on redraw
   */
  useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    platesService.canvas.setCanvas(canvas);
    platesService.canvas.pendingRestore = true;
  });

  /**
   * on creation
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallbacks(msg.name as string, msg.data);
    });
    return (() => subscription.unsubscribe())
  }, []);

  /**
   * WTT-342 - removing right panel changes width calc
   * @returns 
   */
  const getCanvasWidth = () => {
    try {
      
      const auditMode = common.plates.settings.auditMode;
      // record list + navigator width
      return auditMode ? window.innerWidth - 290 - 60 : canvasWidth;
    } catch (ex) {
      console.error('failed to get Id pos:', ex);
      return 0;
    }
  }

  const lightsTagging = common.plates.newTagType === 'Lights';
  const hazardTagging = common.plates.newTagType === 'Hazards';
  const controlsWidth = lightsTagging ? 240 : 160;

  return <Box display="flex" flexDirection="column" flex={1} style={{height:'100%'}}>
    <ResizeObserver onResize={(rect) => 
      {setCanvasHeight(rect.height); 
      setCanvasWidth(rect.width);
      common.plates.detailsWidth = common.plates.panelWidth - rect.width;
      common.plates.editorsHeight = common.plates.panelHeight - rect.height;
      platesService.canvas.windowSize = [rect.width, rect.height];}}></ResizeObserver>
    <div style={{width:canvasWidth, height:canvasHeight, overflow:'hidden'}}>
    <canvas ref={canvasRef} 
          onMouseDown={handleMouseDown}
          onWheel={handleMouseWheel}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onClick={handleMouseClick}
          onDoubleClick={handleDoubleClick}
          width={1920}  
          height={1200} 
          style={{background:'lightGray'}}/>

    <Box display="flex" flexDirection="row" position="absolute" left={getCanvasWidth() - controlsWidth} top={canvasHeight - 60}>
     <ControlsWrapper/>
    </Box>

    <Box position="absolute" left={getCanvasWidth() - 200} top={10}>
      <IdWrapper></IdWrapper>
    </Box>

{ common.plates.settings.showPlate && 
 <Box position="absolute" left={10} top={10}>
 <HeaderPlate/>
</Box>
}
   



    </div>
  </Box>


}