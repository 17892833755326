import React, {useState, useRef, useEffect} from 'react';
import ResizeObserver from 'react-resize-observer';
import { Box, Slider, Switch, Button, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core'
import axlesStitcher from '../../services/axlesStitcher';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import { ToolsPanel } from './toolsPanel';

/**
 * display stitched image
 * @param props 
 * @returns 
 */
export function Stitched(props: any) {

  const canvasRef = useRef(null);
  // refresher
  const [value, setValue] = useState(0); 

  const [canvasWidth, setCanvasWidth] = useState(500);

  /**
   * generic change handler
   * @param arg 
   * @param value 
   */
  const handleChanges = (arg: string, value: any) => {
    try {
      switch (arg) {
        case "PixelsPerMs":
          if (common.axles.sequence)
            common.axles.sequence.stitcher.pixelsPerMs = value;
          common.axles.stitchPixelsPerMs = value;
          common.notify('AxlesStitchChanged');
          common.axles.dirty = true;
          common.notify('AxlesDirtyChanged');          
          setValue (v => v + 1);
          break;

        case "Offset":
          if (common.axles.sequence)
            common.axles.sequence.stitcher.stitchOffset = value;
             // cache for next image
          common.axles.stitchOffset = value;
          common.notify('AxlesStitchChanged');
          common.axles.dirty = true;
          common.notify('AxlesDirtyChanged');
          setValue (v => v + 1);
          break;

         case "Trim":
           common.axles.stitchTrim = value;
           common.plates.settings.axlesStitchTrim = value;
           common.notify('SaveSettings');
           setValue(v => v + 1);
           break;

        case 'ImageClicked':
          common.notify('AxlesStitchClicked', value);
          break;

        case 'AxlesThumbsLoaded':
          setValue(v => v + 1);
          break;

      
      }
    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  const handleStitchChanged = (checked: boolean) => {
    try {
      // only if both stitched and single images exist
      if (!common.axles.overlapAllowChange)
        return;
      common.axles.overlapMode = !checked;
      setValue(v => v + 1);
      common.notify('AxlesOverlapModeChanged');
    } catch (ex) {
      console.error('failed to handle stitch changed:', ex);
    }
  }

  /**
   * ref to canvas
   */
useEffect(() => {
  const canvas = (canvasRef.current as any) as HTMLCanvasElement;
  axlesStitcher.setCanvas(canvas);
});

/**
 * subscribe to global notifications
 */
 useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleChanges(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);

const classes = useStyles();

const pixelsPerMs = common.axles.sequence?.stitcher.pixelsPerMs || 0;
const stitchOffset = common.axles.sequence?.stitcher.stitchOffset || 0;
const stitchTrim = common.axles.stitchTrim || 0;
const leftToRight = (common.axles.sequence?.transitDirection === 'LeftToRight')|| false;
const w:number = props.width;
const h:number = props.height;
const stitched = !common.axles.overlapMode;
common.notify('AxlesStitchedSizeChanged');
  return (
        <Box display="flex" flex="1" height={h} width={w} flexDirection="row" style={{}} >

          <Box display="flex" flex="1"  overflow="hidden auto" >
              <canvas ref={canvasRef} 
              onMouseDown={(evt) => handleChanges("ImageClicked",evt)}
              width={w + 1000 } height={200} style={{background:'gainsboro', marginLeft:-stitchTrim, width:w + 1000, height:200}} ></canvas>
          </Box>




          <Box display="flex" flexDirection="column" style={{background:'gainsboro', padding:'4px'}} width={120}>

          <FormControlLabel 
              control={<Checkbox size="small" checked={stitched} 
              onChange={ e => handleStitchChanged( e.target.checked)}/>} label="Stitched" />
              
            <b>Stitcher:</b>

            <Tooltip title='Vehicle speed estimation'>
            <Slider value={pixelsPerMs} max={30} 
            onChange={(e, value) => handleChanges("PixelsPerMs", value)}
            style={{marginLeft:10, marginRight:10, width:80}}></Slider>
            </Tooltip>

            <Tooltip title='image offset'>
            <Slider value={stitchOffset} max={1000}
            onChange={(e, value) => handleChanges("Offset", value)}
            style={{marginLeft:10, marginRight:10, width:80}}></Slider>
            </Tooltip>
            <Tooltip title='Left edge offset'>
            <Slider value={stitchTrim} max={1000}
            onChange={(e, value) => handleChanges("Trim", value)}
            style={{marginLeft:10, marginRight:10, width:80}}></Slider>
            </Tooltip>

           



  
            
      
        </Box>


        


        </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
}));

