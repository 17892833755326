import React, {useState, useRef, useEffect} from 'react';
import { Box } from "@material-ui/core";
import common from '../../services/commonService';
import ResizeObserver from 'react-resize-observer';
import { makeStyles } from "@material-ui/styles";
import seats from './editors/seats';
import phone from './editors/phone';
import faces from './editors/faces';
import windshield from './editors/windshield';
import pan from './pan';
import belts from './editors/belts';
import plate from './editors/plate';
import outline from './editors/outline';
import tags from '../../services/tags';



export function Annotator(props: any) {

  /* eslint-disable no-unused-vars */
  const [value, setValue] = useState(0);
  /* eslint-disable no-unused-vars */
  const [canvasHeight, setCanvasHeight] = useState(500);
  const classes = useStyles();
  const canvasRef = useRef(null);



  /**
   * change handler
   * @param arg 
   * @param value 
   */
  const handleChanges = (arg: string, value: any) => {
    try {
      switch (arg) {
        case 'InsideDatasetLoaded':
          setValue(value => value + 1);
          break;

        case 'InsidePropertyChanged':
          setValue(value => value + 1);
          break;

          case 'InsideDeleteSelectedTag':
            handleClear();
            break;
            
          case 'InsideClearSelectedVehicle':
          tags.clearVehicle();
          common.notify("InsidePropertyChanged");
          setValue(v => v + 1);
          break;
      }
    } catch (ex) {
      console.error('failed to handle callback:', ex);
    }
  }

  /**
   * mode specific editor
   */
  const getEditor = (): any => {
    switch (common.inside.mode) {
      case 0: return pan;
      case 1: return windshield;  
      case 2: case 3: case 4: return seats;
      case 5: case 6: case 7: return faces;
      case 8: case 9: case 10: return belts;
      case 11: return phone;
      case 12: return plate;
      case 13: return outline;
    }
  }

  const handleClear = () => {
    const editor = getEditor();
    if (editor)
      editor.clear();

    common.notify("InsidePropertyChanged");
  };

  /**
   * handle mouse down
   * @param evt 
   */
  const handleMouseDown = (evt: any) => {
    const editor = getEditor();
    if (evt.ctrlKey) 
      pan.mouseDown(evt);
    else if (editor)
      editor.mouseDown(evt);
    else
       pan.mouseDown(evt);

       common.notify('InsideMouseDown');
  }

  const handleMouseWheel = (evt: any) => {
    pan.mouseWheel(evt);
    common.notify('InsideMouseWheel');
  }

  const handleMouseMove = (evt: any) => {
    tags.mousePos = tags.getPoint(evt);
    const editor = getEditor();
    if (evt.ctrlKey)
      pan.mouseMove(evt);
    else
      editor.mouseMove(evt);
    common.notify('InsideMouseMove');
  }

  const handleMouseUp = (evt: any) => {
    const editor = getEditor();
    if (evt.ctrlKey)
      pan.mouseUp(evt);
    else
      editor.mouseUp(evt);
    common.notify('InsideMouseUp');
  }

  const handleMouseClick = (evt: any) => {
    const editor = getEditor();
    if (editor)
      editor.click(evt);
    common.notify('InsideMouseClick');
  }

  useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    tags.setCanvas(canvas);
    tags.restoreMatrix();
  });



  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
    handleChanges(msg.name as string, msg.data);
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []);




  return (
    <Box display="flex" flex="1" >
          
          <ResizeObserver onResize={(rect) => {setCanvasHeight(rect.height - 20);}} />

<div className={classes.canvasContainer} style={{height: canvasHeight}}>
  <canvas  
    onMouseDown={handleMouseDown}
    onWheel={handleMouseWheel}
    onMouseMove={handleMouseMove}
    onMouseUp={handleMouseUp}
    onClick={handleMouseClick}
    style={{cursor:common.inside.mode === 0 ? 'default' : 'crosshair'}}
    ref={canvasRef} width={1920} height={1200} className={classes.canvas}>
  </canvas>
</div>

      </Box>
  );

}

const useStyles = makeStyles({
	paper: {
		width: "100%",
		height: "100%",
    display: "flex",
    flexDirection: "column",
    
  },

  main: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },

  canvasContainer: {
    display: "flex",
    backgroundColor: "gainsboro",
    padding: 10,
    overflow: "hidden",
  },

  bottomPanel: {
    display: "flex",
    height: 160,
    minHeight: 160,
    maxHeight: 160,
  },

  buffer: {
    display: "flex",
    flex: 1,
  },

  header: {
    height: 40,
    background: 'gainsboro',
  },

  canvas: {
    // position: 'relative',
    width: 1920,
    height: 1200,
    background: "gray"
  },

  combo: {
    margin: 10,
  },

  horizontal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },

  column: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: 2,
    fontSize: 10,
    // backgroundColor: '#29367d',
    color: 'white',
    backgroundColor: 'black',
  },

  activeButton: {
    margin: 2,
    fontSize: 10,
    // backgroundColor: '#29367d',
    color: 'white',
    backgroundColor: 'dodgerBlue',
  },
  badge: {
    fontSize: 5,
  },


  

});