import * as React from "react";
import * as ReactDOM from "react-dom";
import { createContext } from "react";
import App from "./App";


const globals = {
}

export const GlobalContext = createContext(globals);

const rootEl = document.getElementById("root");
ReactDOM.render(
  <GlobalContext.Provider value={globals} >

<App />
{/* <ReduxRoot /> */}

  </GlobalContext.Provider>
, rootEl);

// comment in for PWA with service worker in production mode
// registerServiceWorker();
