

export class SlotData {

  session: any = null;
  user: any = null;
  dataset: any = null;
  datasetShortName: string = '';
}
export class BinData {
  week: number = 0;
  year: number = 0;
  slots: SlotData[] = [];
}
export class AdminData {

  items: any[] = [];
  users: any[] = [];
  datasets: any[] = [];
  sessions: any[] = [];

  weeklyBins: BinData[] = [new BinData(), new BinData(), new BinData(), new BinData()];


  modes: string[] = ['users','datasets','activities','sessions'];
  mode: string = 'activities';
  gridApi: any = null;

  week: number = 0;
  year: number = 0;
  dt: Date = new Date();


}