import React, {useState, useEffect, useRef} from "react";
import { Box,  IconButton, Tooltip,  FormControlLabel, Checkbox  } from "@material-ui/core";
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';
import { AspectRatio, Reorder as ReorderIcon, ViewModule as StitchedIcon, ArrowDownward as DownloadIcon} from "@material-ui/icons";
import pan from './pan';
import axlesService from "../../services/axlesServices";

export function IdWrapper(props:any) {

  const[value, setValue] = useState(0);
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'AxlesThumbChanged':
        case "PlatesRecordLoaded":
          setValue(v => v + 1);
          break;
      }
    });
    return (() => subscription.unsubscribe())
  }, []);

  const id = common.axles.imageId;
  const seqId = common.axles?.selectedRow?.id;

  return ( 

  <Box display="flex" flexDirection="column">
    { seqId && 
    <Tooltip title="Sequence ID">
      <span style={{padding:2, color:'white', background: 'rgba(0,0,0,0.2)'}}>{seqId}</span>
    </Tooltip>
     }
  <Box display="flex" height="2px"/>
  { id && 
  <Tooltip title="Image ID">
  <span style={{padding:2, color:'white', background: 'rgba(0,0,0,0.2)'}}>{id}</span>
  </Tooltip>
  
  }
  </Box>
  
  
  )
 
}

export function StitchWrapper(props:any) {

  const[value, setValue] = useState(0);

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'AxlesOverlapModeChanged':
        case 'AxlesThumbChanged':
        case "PlatesRecordLoaded":
          setValue(v => v + 1);
          break;
      }
    });
    return (() => subscription.unsubscribe())
  }, []);

  const id = common.axles.imageId;
  const seqId = common.axles?.selectedRow?.id;

  const handleStitchChanged = (checked: boolean) => {
    try {
      // only if both stitched and single images exist
      if (!common.axles.overlapAllowChange)
        return;
      common.axles.overlapMode = !checked;
      setValue(v => v + 1);
      common.notify('AxlesOverlapModeChanged');
    } catch (ex) {
      console.error('failed to handle stitch changed:', ex);
    }
  }

  const stitched = !common.axles.overlapMode;
  return ( 

  <Box display="flex" flexDirection="row" style={{background:'rgba(255,255,255,0.4)'}}>
         <FormControlLabel style={{marginLeft:'2px'}}
              control={<Checkbox size="small" checked={stitched} 
              onChange={ e => handleStitchChanged( e.target.checked)}/>} label="Stitch view" />
  </Box>
  
  
  )
 
}

export function ControlsWrapper(props:any) {

  const[value, setValue] = useState(0);
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'AxlesThumbChanged':
        case "PlatesRecordLoaded":
          setValue(v => v + 1);
          break;
      }
    });
    return (() => subscription.unsubscribe())
  }, []);

  const id = common.axles.imageId;
  const seqId = common.axles?.selectedRow?.id;

  return ( 

  <Box display="flex" flexDirection="row">
       <Tooltip title="Download raw image">
        <div>
        <IconButton   style={{color: 'white'}}
          onClick={() => common.notify('DownloadRawAxleImage')}
          size="medium"
          color="primary" >
          <DownloadIcon />
        </IconButton>
        </div>
      </Tooltip>
     <Tooltip title="Unzoom">
        <IconButton  style={{color: 'white'}}
          onClick={() => common.notify('AxlesUnzoom')}
          size="medium"
          color="primary" >
          <AspectRatio />
        </IconButton>
      </Tooltip>
      <Tooltip title="Current record in JSON format">
        <div>
        <IconButton  disabled={common.app.context.json === ''}  style={{color: 'white'}}
          onClick={() => common.notify('ShowJsonRequest')}
          size="medium"
          color="primary" >
          <ReorderIcon />
        </IconButton>
        </div>
      </Tooltip>

  </Box>
  
  
  )
 
}


/**
 * Host the canvas with resizing
 * @param props 
 * @returns 
 */
export function CanvasWrapper(props: any) {


  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const canvasRef = useRef(null);

  const[value, setValue] = useState(0);

  /**
   * simulate mouse operations - needed by the scripts - analog th handleMouse below
   * @param data 
   */
  // const simulateMouse = (data:any) => {
  //   try {
  //     const pt = data.noTrans ? {x: data.x, y: data.y} : axlesService.canvas.getWindowPoint(data.x, data.y);
  //     const evt = {nativeEvent: {offsetX: pt.x, offsetY: pt.y, wheelDelta: 120 * data.wheel}};
  //     switch(data.action) {
  //       case "doubleClick":
  //         pan.doubleClick(evt);
  //         break;

  //       case "mouseDown":
  //         pan.mouseDown(evt);
  //         axlesService.canvas.paint('simulate-down');
  //         break;

  //       case "mouseMove":
  //         pan.mouseMove(evt);
  //         axlesService.canvas.paint('simulate-move');
  //         break;

  //       case "mouseUp":
  //         pan.mouseUp(evt);
  //         break;

  //       case "mouseWheel":
  //         pan.mouseWheel(evt);
  //         axlesService.canvas.paint('simulate-wheel');
  //         break;

  //     }
  //   } catch (ex) {
  //     console.error('failed to simulate mouse:', ex);
  //   }
  // }

  /**
   * handle mouseMove
   * @param evt 
   */
  const handleMouseMove = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = axlesService.canvas;
    axlesService.canvas.mousePos = axlesService.canvas.getPoint(evt);
    const editor = getEditor();
    if (evt.ctrlKey)
      pan.mouseMove(evt);
    else
      editor.mouseMove(evt);
      axlesService.canvas.paint('wrap-move');
  }

  /**
   * Handle mouse down
   * @param evt 
   */
  const handleMouseDown = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = axlesService.canvas;
 
    const editor = getEditor();
    if (evt.ctrlKey) 
      pan.mouseDown(evt);
    else if (editor)
      editor.mouseDown(evt);
    else
       pan.mouseDown(evt);
  }

  /**
   * handle mouse wheel - zoom
   * @param evt 
   */
  const handleMouseWheel = (evt: any) => {
    // evt.preventDefault();
    evt.stopPropagation();
    pan.mouseWheel(evt);
    axlesService.canvas.paint('wrap-wheel');
  }

  /**
   * handle mouse up
   * @param evt 
   */
  const handleMouseUp = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = axlesService.canvas;
    const editor = getEditor();
    if (evt.ctrlKey)
      pan.mouseUp(evt);
    else
      editor.mouseUp(evt);
   
  }

  /**
   * handle mouse leave the canvas
   * @param evt 
   */
  const handleMouseLeave = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    pan.mouseLeave(evt);
  }

  /**
   * handle mouse click
   * @param evt 
   */
  const handleMouseClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    const cnvs = axlesService.canvas;
    const editor = getEditor();
    if (editor)
      editor.click(evt);
      axlesService.canvas.paint('wrap-click');
  }

  /**
   * handle double click (start annotation)
   * @param evt 
   * @returns 
   */
  const handleDoubleClick = (evt: any) => {
    try {
      if (common.plates.settings.mode !== 'Annotation')
        return;

      evt.preventDefault();
      evt.stopPropagation();
      pan.doubleClick(evt);
      console.log('double clicked');
    } catch (ex) {
      console.error('failed on double click', ex);
    }
  }

  /**
   * callback handler
   * @param msg 
   * @param value 
   */
  const handleCallbacks = (msg: string, value: any) => {
    try {
      switch(msg) {
        case "SimulateMouse":
          // simulateMouse(value);
          break;
     
      }
    } catch (ex) {

    }
  }

  /**
   * single editor for now
   * @returns 
   */
  const getEditor = (): any => {
    return pan;
  }

  /**
   * on redraw
   */
  useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    axlesService.canvas.setCanvas(canvas);
    axlesService.canvas.restoreMatrix();
  });

  /**
   * on creation
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallbacks(msg.name as string, msg.data);
    });
    return (() => subscription.unsubscribe())
  }, []);

  const w:number=props.width;
  const h:number=props.height;
  const zoomLeft:number = w - 20;

  axlesService.canvas.windowSize = [w, h];
  // common.notify('AxlesCanvasResized');

  return <Box display="flex" flexDirection="column" flex={1} width={w} height={h}>
    {/* <ResizeObserver onResize={(rect) => 
      {setCanvasHeight(rect.height); 
      setCanvasWidth(rect.width);
      common.axles.detailsWidth = common.axles.panelWidth - rect.width;
      common.axles.editorsHeight = common.axles.panelHeight - rect.height;
      common.axles.detailsHeight = rect.height;
      common.notify('AxlesCanvasResized')
      axlesService.canvas.windowSize = [rect.width, rect.height];}}></ResizeObserver> */}
    <div style={{width:w, height:h, overflow:'hidden', position:'relative'}}>
    <canvas ref={canvasRef} 
          onMouseDown={handleMouseDown}
          onWheel={handleMouseWheel}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          // onMouseLeave={handleMouseLeave}
          onClick={handleMouseClick}
          onDoubleClick={handleDoubleClick}
          width={1920}  
          height={1200} 
          style={{background:'lightGray'}}/>

    <Box display="flex" flexDirection="row" position="absolute" left={w - 160} top={h - 60}>
      <ControlsWrapper/>
    </Box>

    {/* <Box display="flex" flexDirection="row" position="absolute" left={0} top={h - 36}>
      <StitchWrapper/>
    </Box> */}


    <Box position="absolute" left={w - 200} top={10}>
      <IdWrapper></IdWrapper>
    </Box>



    </div>
  </Box>


}