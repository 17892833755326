import React, {useState, useEffect } from 'react';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, IconButton,  Checkbox, Tooltip, Select, MenuItem, Button } from "@material-ui/core";
import { Search, FilterList, FileCopy, Refresh, SwapCalls } from '@material-ui/icons';
import { PlateSearch } from './plateSearch';
import common from '../../services/commonService';
import { TriSwitch } from '../axles/triSwitch';
import { IdSearch } from './idSearch';

/**
 * filter control for plates
 * @param props 
 * @returns 
 */
export function PlatesFilter(props: any) {
  
  const [value, setValue] = useState(0); // integer state
  const [showFilter, setShowFilter] = useState(false);

  /**
   * callbacks
   * @param arg 
   * @param value 
   */
  const handleCallback = (arg: string, value: any) => {
    try {
      switch (arg) {
        case "ToggleFilter":
          setShowFilter(!showFilter);
          break;

        case "PlatesFilterEnabled":
          if (!filter.canFilter)
            setShowFilter(false);
          setValue(v => v + 1);
          break;

        case 'reflectives':
        case "match":
        case "problematic":
        case "marked":
        case "tagged":
        case "pretagged":
        case "bccm":
        case "vehicles":
        case "insides":
        case "occluded":
        case "lights":
        case 'hazards':
        case 'chars':
          filter[arg] = value;
          setValue(v => v + 1);
          common.notify('PlatesFilterChanged');
          break;

        case "RefreshList":
          common.notify('PlatesFilterChanged');
          break;

        // show or hide bccm options
        case "PlatesFilterShowBccm":
          setValue(v => v + 1);
          break;

        case "VehicleType":
          filter.vehicleType = value;
          setValue(v => v + 1);
          common.notify('PlatesFilterChanged');
          break;

        case "ExtraType":
          filter.extraType = value;
          setValue(v => v + 1);
          common.notify('PlatesFilterChanged');
          break;

        case "Copy":
          common.notify('PlatesCopyListToClipboard');
          break;

        case "Scan":
          common.notify("PlatesForceScan");
          break;
      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  /**
   * register for callbacks
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallback(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);


  const comboOptions:string[] = ["Tagged", "Not tagged", "Visited", "Not visited", 
  "Validation OK", "Validation error"];

const filter = common.plates.filter;

  // rendering
  return (
    <Box display="flex" flexDirection="column"  >
    <Box display="flex" flexDirection="column"  >


      <Box display="flex" flexDirection="row" alignItems="center">
      <Tooltip title= 'Plates filter' placement="left">
         <IconButton  style={{height:20}}
        onClick = {(e) =>  handleCallback("ToggleFilter", e) }
          color="primary" >
        <FilterList/>
      </IconButton>
      </Tooltip>
      <b>Filter and search</b>

      <Box display="flex" flex="1"></Box>

      { showFilter && filter.canFilter  && 
      <Box display="flex" flexDirection="row">

      <Tooltip title="Refresh list">
        <IconButton style={{height:20}}
        onClick={(e) => handleCallback("RefreshList", e)}>
          <Refresh></Refresh>
        </IconButton>
        </Tooltip>
          </Box>
      }

      </Box>

      { showFilter && filter.canFilter && 
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" marginLeft="8px" height="200px" maxHeight="200px" overflow="auto">
     
        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'red', margin:'2px'}}/>
        <Box display="inline" width="80px" margin="6px">problem:</Box>
        <TriSwitch value={filter.problematic} onChange={(e:any) => handleCallback('problematic', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'skyBlue', margin:'2px'}}/>
        <Box display="inline" width="80px" margin="6px">marked:</Box>
        <TriSwitch value={filter.marked} onChange={(e:any) => handleCallback('marked', e) }/>
        </Box>

       
        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'lawnGreen', margin:'2px'}}/>
        <Box display="inline" width="80px" margin="6px">tagged:</Box>
        <TriSwitch value={filter.tagged} onChange={(e:any) => handleCallback('tagged', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'orange', margin:'2px'}}/>
         <Box display="inline" width="80px" margin="6px">pretagged:</Box>
        <TriSwitch value={filter.pretagged} onChange={(e:any) => handleCallback('pretagged', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'teal', margin:'2px'}}/>
         <Box display="inline" width="80px" margin="6px">bccm:</Box>
        <TriSwitch value={filter.bccm} onChange={(e:any) => handleCallback('bccm', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'blue', margin:'2px'}}/>
       <Box display="inline" width="80px" margin="6px">vehicles:</Box>
        <TriSwitch value={filter.vehicles} onChange={(e:any) => handleCallback('vehicles', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'paleGreen', margin:'2px'}}/>
       <Box display="inline" width="80px" margin="6px">match:</Box>
        <TriSwitch value={filter.match} onChange={(e:any) => handleCallback('match', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'cyan', margin:'2px'}}/>
       <Box display="inline" width="80px" margin="6px">inside:</Box>
        <TriSwitch value={filter.insides} onChange={(e:any) => handleCallback('insides', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'brown', margin:'2px'}}/>
       <Box display="inline" width="80px" margin="6px">lights:</Box>
        <TriSwitch value={filter.lights} onChange={(e:any) => handleCallback('lights', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'green', margin:'2px'}}/>
       <Box display="inline" width="80px" margin="6px">hazards:</Box>
        <TriSwitch value={filter.hazards} onChange={(e:any) => handleCallback('hazards', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'black', margin:'2px'}}/>
       <Box display="inline" width="80px" margin="6px">Reflectives:</Box>
        <TriSwitch value={filter.reflectives} onChange={(e:any) => handleCallback('reflectives', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'powderBlue', margin:'2px'}}/>
       <Box display="inline" width="80px" margin="6px">occluded:</Box>
        <TriSwitch value={filter.occluded} onChange={(e:any) => handleCallback('occluded', e) }/>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" style={{height:25}}>
        <div style={{width:'14px', height:'14px', background:'orange', margin:'2px'}}/>
       <Box display="inline" width="80px" margin="6px">chars:</Box>
        <TriSwitch value={filter.chars} onChange={(e:any) => handleCallback('chars', e) }/>
        </Box>


        </Box>
       
  </Box>


  <Box display="flex" flexDirection="row" alignItems="center" style={{marginLeft:10, height:30}}>
        Vehicle types:&nbsp;
  <Select 
    value={filter.vehicleType} 
    onChange={e =>handleCallback("VehicleType", e.target.value)}>
        {common.plates.filter.vehicleTypes?.map((vt:any) => ( <MenuItem value={vt.value}>{vt.name}</MenuItem>))}
  </Select>
  <Box display="flex" flex="1"/>
        <Tooltip title='Copy list to clipboard'>
    <IconButton  onClick={() => handleCallback('Copy', null) } >
          <FileCopy style={{color: '#29367d'}} />
        </IconButton>
        </Tooltip>
      </Box>

    


    <PlateSearch></PlateSearch>


  </Box>
}
    


 

      </Box>
      </Box>
  );

}