import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import {Box, Button, Select, MenuItem, FormControlLabel, LinearProgress, RadioGroup, Radio, TextField,
  Checkbox, IconButton, Tooltip, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import { SkipNext, SkipPrevious, Settings as SettingsIcon } from '@material-ui/icons';
import { SequenceData } from '../../data/axlesData';
import { ConfigDialog } from './configDialog';
import { WarpPanel } from './warpPanel';

export function SequenceProps(props: any) {

  // eslint-disable-next-line
  const [value, setValue] = useState(0);
  const [configOpen, setConfigOpen] = useState(false);

  const classes = useStyles();

  const tag = common.axles.tag;

  const handleCloseConfig = () => {
    setConfigOpen(false);
  }

  const updateClassFromCategory = () => {
    try {
      const cat = sequence?.vehicleCat;
      if (!cat)
        return;

      const cls = cat.split('_')[1];
      const vehicleCodes = common.axles.codes.vehicleClass;
      const code = vehicleCodes.find(code => code[2] === cls);
      if (code && sequence)
        sequence.vehicleClass = code[0];
      setValue(v => v + 1);
    } catch (ex) {
      console.error('failed to get class from category:', ex);
    }
  }

  const handleChanges = (key: string, value: any) => {
    try {
      const sequence = common.axles.sequence;
      switch(key) {

        case 'AxlesVehicleClassChanged':
          updateClassFromCategory();
          break;

        case 'AxlesSavingSequenceChanged':
        case 'AxlesDirtyChanged':
        case "AxleTagsChanged":
        case "AxlesSequenceLoaded":
          setValue(v => v + 1);
          break;

        case "multipleVehicles":
        case "vehicleClass":
        case "weather":
        case "vehicleOcclusion":
        case "trailer":
        case "TrafficStatus":
        case "anomaly":
        case "invalid":
        case "transitDirection":
          if (sequence !== null) {
            (sequence as any)[key] = value;
            common.axles.dirty = true;
            setValue(v => v + 1);
            common.notify("AxleTagsChanged");
          }
          break;

       
            case 'twinCount':
            case 'raisedCount':
            case "axleCount":
            case "trailerAxleCount":
              if (sequence !== null) {
                (sequence as any)[key] = value ? parseInt(value): 0;
                common.axles.dirty = true;
                setValue(v => v + 1);
                common.notify("AxleTagsChanged");
              }
              break;          



            case "DeleteTag":
              common.notify('AxlesDeleteTag');
              setValue(v => v + 1);
              break;

            case "VehicleClassClick":
              common.notify('AxlesOrderVehicles');
              common.axles.showVehiclesPanel = true;
              common.notify('AxlesPanelChanged');
              // common.notify('AxlesSelectTab', 1 as any);
              break;

            case "SaveSequence":
              common.notify("AxlesSaveSequence");
              break;

              case "Next":
                common.notify("AxlesGotoNextRecord");
                break;

              case "Cancel":
                common.notify("AxlesCancelSequence");
                break;

              case 'Clear':
                common.notify('ClearAndNext');
                break;

              case 'ShowConfiguration':
                setConfigOpen(true);
                break;
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  /**
   * render new cab tag exclusive at image level
   */
  const resetImageCabs = () => {
    try {
      const tags = common.axles.tags.filter((t:any) => t.ImageIndex === common.axles.imageIndex);
      tags?.forEach((t:any) => t.Cab = false);
        
    } catch (ex) {
      console.error('failed to reset image cabs:', ex);
    }  }

  const getVehicleUrl = () => {
    try {
      const vehicle = common.axles.sequence?.vehicleCat;
      if (!vehicle)
        return "data/vehicles/blank.png";

      return `data/vehicles/${vehicle}.png`;
    } catch (ex) {
      console.error('failed to get vehicle url');
    }
  }

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });

    if (common.axles.pendingCategorySelection) {
      common.axles.pendingCategorySelection = false;
      updateClassFromCategory();
    }     

    return (() => {
      subscription.unsubscribe();
    });

  

  }, []);

  const sequence = common.axles.sequence;
  const codes = common.axles.codes;

  const vehicleClasses = common.axles.vehicleClasses;

  const totalTags = common.axles.tags?.length;
  const tags = common.axles.tags;
  const tagIndex = tags ? tags.indexOf(common.axles.tag) : -1;
  const seqId = common.axles?.selectedRow?.id;

  const totalImages = common.axles.thumbs?.length;
  const imageIndex = common.axles.imageIndex;

  const stitchedUrl = common.axles.stitchedUrl || '';
 // WTT-170 - hide sequence properties when show searched sequence item
  return <Box display="flex" flex="1" flexDirection="column" margin={2}   >

    <b>Sequence properties:</b>
    {common.axles.sequence && seqId !== '0' && 
    <Box display="flex" flexDirection="column" flex="1" >

    <Box display="flex" flexDirection="row">

    <Box display="flex" flexDirection="column">
      <Typography>Weather:</Typography>
      <RadioGroup  value={sequence?.weather || ''} onChange={e =>handleChanges("weather", e.target.value)}>
        {codes.weather.map(pt => <FormControlLabel value={pt[0]} control={<Radio size="small" style={{height:"20px"}} />} label={pt[1]} />)}
      </RadioGroup>
    </Box>
    <Box display="flex" width="6px"/>
    <Box display="flex" flexDirection="column">
      <Typography>Occlusion:</Typography>
      <RadioGroup  value={sequence?.vehicleOcclusion || ''} onChange={e =>handleChanges("vehicleOcclusion", e.target.value)}>
        {codes.occlusion.map(pt => <FormControlLabel value={pt[0]} control={<Radio size="small" style={{height:"20px"}} />} label={pt[1]} />)}
      </RadioGroup>
    </Box>
    <Box display="flex" width="6px"/>
    <Box display="flex" flexDirection="column">
    <Typography>Direction:</Typography>
      <RadioGroup  value={sequence?.transitDirection || ''} onChange={e =>handleChanges("transitDirection", e.target.value)}>
        {codes.direction.map(pt => <FormControlLabel value={pt[0]} control={<Radio size="small" style={{height:"20px"}} />} label={pt[1]} />)}
      </RadioGroup>
    </Box>

    </Box>

    <Box display="flex" height="10px"/>

    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography>Axles:</Typography>
      &nbsp;
      <TextField size="small" InputProps={{inputProps:{min:0,max:12}}} variant='outlined' type="number" style={{width:'200'}} className={classes.number} value={sequence?.axleCount} onChange={(e) => handleChanges("axleCount", e.target.value)} ></TextField>
      <Box display="flex" width="20px"/>
      <FormControlLabel
      control={<Checkbox size="small" style={{width:'16px'}} checked={sequence?.trailer || false} 
      onChange={(e) => handleChanges("trailer", e.target.checked)} />}
      label="Trailer, axles:"/>
     <TextField size="small" InputProps={{inputProps:{min:0,max:12}}} variant='outlined' type="number" style={{width:'200'}} className={classes.number} value={sequence?.trailerAxleCount} onChange={(e) => handleChanges("trailerAxleCount", e.target.value)} ></TextField>
    </Box>

    <Box display="flex" height="10px"/>

    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography>Twins:</Typography>
      &nbsp;
      <TextField size="small" InputProps={{inputProps:{min:0,max:12}}} variant='outlined' type="number" style={{width:'200'}} className={classes.number} value={sequence?.twinCount} onChange={(e) => handleChanges("twinCount", e.target.value)} ></TextField>
      <Box display="flex" width="20px"/>
      <Typography>Raised:</Typography>
      &nbsp;
      <TextField size="small" InputProps={{inputProps:{min:0,max:12}}} variant='outlined' type="number" style={{width:'200'}} className={classes.number} value={sequence?.raisedCount} onChange={(e) => handleChanges("raisedCount", e.target.value)} ></TextField>
       </Box>

    <Box display="flex" height="14px"/>

    <Box display="flex" flexDirection="row" alignItems="center">
    <img alt="" src={getVehicleUrl()} width={100}
      style={{cursor:'pointer', border:'solid black 2px'}}
      onClick={() => handleChanges("VehicleClassClick", null)}></img> 
      <Box display="flex" width="10px"/>
      <Select value={sequence?.vehicleClass || ''} className={classes.combo}
          onChange={e =>handleChanges("vehicleClass", e.target.value)}>
          {codes.vehicleClass.map(pt => ( <MenuItem value={pt[0] || ''}>{pt[1] || ''}</MenuItem>))}
      </Select>
    </Box>

    <Box display="flex" flexDirection="row" alignItems="center">

    <FormControlLabel style={{height:20}}
      control={<Checkbox size="small" checked={sequence?.anomaly || false} 
      onChange={(e) => handleChanges("anomaly", e.target.checked)} />}
      label="Anomaly"/>
         <FormControlLabel
      control={<Checkbox size="small" checked={sequence?.invalid || false} 
      onChange={(e) => handleChanges("invalid", e.target.checked)} />}
      label="Invalid"/>
        <FormControlLabel
      control={<Checkbox size="small" checked={sequence?.multipleVehicles || false} 
      onChange={(e) => handleChanges("multipleVehicles", e.target.checked)} />}
      label="Multiple vehicles"/>


    </Box>


      
    <Box display="flex" flex="1">
      <WarpPanel height={props.height - 420}/>
    </Box>

    <Box display="flex" flexDirection="row" alignItems="center" >

    <Tooltip title='Commit' placement='bottom'>
            <span>
              <Button className={classes.button} style={{marginTop: 5}} 
                disabled={!common.axles.dirty}
                onClick={() => handleChanges('SaveSequence', null)} variant="contained">OK
              </Button>
            </span>
          </Tooltip>

       <Tooltip title='Cancel changes to sequence properties' placement='bottom'>
         <span>
          <Button className={classes.button} style={{marginTop: 5}} 
          disabled={!common.axles.dirty}
          onClick={() => handleChanges('Cancel', null)} variant="contained">Cancel</Button>
          </span>
          </Tooltip>

          <Tooltip title='Go to next sequence' placement='bottom'>
         <span>
          <Button className={classes.button} style={{marginTop: 5}} 
          disabled={common.axles.dirty}
          onClick={() => handleChanges('Next', null)} variant="contained">Next</Button>
          </span>
          </Tooltip>

          {common.axles.savingSequence && 
            <LinearProgress  style={{width:50, height:12, margin: 2}} />
          }

          <Box display="flex" flex="1"/>
      

          </Box>



  </Box>
  
}
    <ConfigDialog open={configOpen} onClose={handleCloseConfig}></ConfigDialog>
    </Box>

}

const useStyles = makeStyles((theme: Theme) => ({
  combo: {
    width: 150,
  },
  button: {
    margin: 2,
    fontSize: 9,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  number: {
    width:70,
  }
}));
