import platesService from '../../services/platesService';
import common from '../../services/commonService';
import { InsideEntry, VehicleBounding } from '../../data/platesData';
import windshield from '../inside/editors/windshield';
import insideInspection from '../../services/insideInspection';

/**
 * pan, zoom canvas mouse handler
 */
class PanInside {

  ptDrag: any = null;
  ptMouse: any = null;


  /**
   * constructor
   */
  constructor() {
    this.ptDrag = null;
  }

  clear = () => {
    try {
    } catch (ex) {
      console.error('failed to clear:', ex);
    }
  };


  click = (evt: any) => {
    try {
    } catch(ex) {
      console.error('failed on click: ', ex);
    }
  };

  passengerOccupied = () => {
    try {
      const inside = common.plates.selectedInside;
      const passenger = inside?.selectedPassenger;
      return passenger?.occupied?.value;
    } catch (ex) {
      console.error('failed on selectDriverMode');
    }

  }

  /**
   * handle double click on canvas
   * @param evt 
   * @returns 
   */
  doubleClick = (evt: any) => {
    try {

      if (!this.passengerOccupied())
        return;
   
    
      // if ctrl is pressed - start vehicle annotation
      const cnvs = insideInspection.insideCanvas;
      const pt = cnvs.getPoint(evt);

      cnvs.boundInside("Start", pt);
    
        
    } catch (ex) {
      console.error('failed on double click');
    }
  }

  getContainedInside = (pt:any) => {
    try {
      // const insides = common.plates.insides || [];
      // for (let i = 0; i < insides.length; i++) {
      //   const r = insides[i].rect.value;
      //   const contained = pt.x > r[0] && pt.y > r[1] && pt.x < r[0] + r[2] && pt.y < r[1] + r[3];
      //   if (contained)
      //     return insides[i];
      // }
      return null;
    } catch (ex) {
      console.error('failed to get contained inside:', ex);
      return null;
    }
  }



  /**
   * hovering over a vertex ?
   * @returns 
   */
  hovering = ():boolean => {
    try {
      const passenger = common.plates.selectedInside?.selectedPassenger;
      if (!passenger)
        return false;

      return passenger.hoverIndex > -1;
    } catch (ex) {
      console.error('failed on hovering:', ex);
      return false;
    }
  }





  insideHovering = (): boolean => {
    try {
      const insides = common.plates.insides;
      const cnvs = platesService.canvas;
      const index = insides?.findIndex((t:any) => t.hoverIndex > -1);
      return (index > -1);

    } catch (ex) {
      console.error('failed on vehicle hovering:', ex);
      return false;
    }
  }









  /**
   * handle mouse down event on canvas
   * @param evt 
   * @returns 
   */
  mouseDown = (evt: any) => {
    try {
      const cnvs = insideInspection.insideCanvas;
      const pt = cnvs.getPoint(evt);

      this.ptDrag = pt;

      cnvs.resizing = this.hovering();
      return cnvs.boundInside("MouseDown", pt);
 
      
    } catch(ex) {
      console.error('failed on mouseDown: ', ex);
    }
  };

  /**
   * for the given point - test wether it hovers over a vertex or segment
   */
  getHoverPoint(ptMouse: any) {
    try {
      const cnvs = insideInspection.insideCanvas;
      const passenger = common.plates.selectedInside?.selectedPassenger;
      if (!passenger)
        return;

      const radius = cnvs.getAnchorSize();
      var pts = cnvs.getPointsEx(passenger?.faceRect.value as number[]);
      passenger.hoverIndex = pts?.findIndex(pt => cnvs.getDistance(pt, ptMouse) < radius);
      if (passenger.hoverIndex > -1) {
        console.log('hovering !!');
      }
    } catch (ex) {
      console.error('failed to get hover point');
    }
  }



  /**
   * user is resizing a poly vertex
   * @param pt 
   */
  handleResize = (pt: any) => {
    try {
      const cnvs = insideInspection.insideCanvas;
      const passenger = common.plates.selectedInside?.selectedPassenger;
      if (!passenger)
        return;

      const hoverIndex = passenger.hoverIndex;
      if (hoverIndex < 0)
        return;

      const rect = passenger.faceRect;
        const r = rect.value as number[];
        const right = r[0] + r[2];
        const bottom = r[1] + r[3];
        switch(hoverIndex) {
          case 0:
            rect.value = [pt.x, pt.y, right - pt.x, bottom - pt.y];
            break;
  
          case 1:
            rect.value = [r[0], pt.y, pt.x - r[0], bottom - pt.y];
            break;
  
          case 2:
            rect.value = [r[0], r[1], pt.x - r[0], pt.y - r[1]];
            break;
  
          case 3:
            rect.value = [pt.x, r[1], right - pt.x, pt.y - r[1]];
            break;
  
            // mid top
          case 4:
            rect.value = [r[0], pt.y, r[2], bottom - pt.y];
            break;
  
            // mid right
          case 5:
            rect.value = [r[0], r[1], pt.x - r[0], r[3]];
            break;
  
            // mid bottom
          case 6:
            rect.value = [r[0], r[1], r[2], pt.y - r[1]];
            break;
  
          case 7:
            rect.value = [pt.x, r[1], right - pt.x, r[3]];
            break;
        }
     
      cnvs.paint();
    } catch (ex) {
      console.error('failed to handle resize:', ex);
    }
  }

  /**
   * resize vehicle annotation, dragging the vertices
   * @param pt 
   */
  handleVehicleResize = (pt: any) => {
    try {
      const cnvs = platesService.canvas;
      const vehicles = common.plates.vehicles as VehicleBounding[];
      const vehicle = vehicles?.find( (t:any) => t.hoverIndex > -1);
      if (!vehicle) throw new Error('failed to find hoverVehicle');
      // ofer 31/01/2022 - WTT-208
      const r = vehicle.rect.value as number[];
      const right = r[0] + r[2];
      const bottom = r[1] + r[3];
      switch(vehicle.hoverIndex) {
        case 0:
          vehicle.rect.value = [pt.x, pt.y, right - pt.x, bottom - pt.y];
          break;

        case 1:
          vehicle.rect.value = [r[0], pt.y, pt.x - r[0], bottom - pt.y];
          break;

        case 2:
          vehicle.rect.value = [r[0], r[1], pt.x - r[0], pt.y - r[1]];
          break;

        case 3:
          vehicle.rect.value = [pt.x, r[1], right - pt.x, pt.y - r[1]];
          break;

          // mid top
        case 4:
          vehicle.rect.value = [r[0], pt.y, r[2], bottom - pt.y];
          break;

          // mid right
        case 5:
          vehicle.rect.value = [r[0], r[1], pt.x - r[0], r[3]];
          break;

          // mid bottom
        case 6:
          vehicle.rect.value = [r[0], r[1], r[2], pt.y - r[1]];
          break;

        case 7:
          vehicle.rect.value = [pt.x, r[1], right - pt.x, r[3]];
          break;
      }
      vehicle.rect.status = 'tagged';
      cnvs.paint('pan-vehicle-resize');
    } catch (ex) {
      console.error('failed to handle resize:', ex);
    }
  }

  handleInsideResize = (pt: any) => {
    try {
      const cnvs = platesService.canvas;
      const insides = common.plates.insides as InsideEntry[];
      const inside = insides?.find( (t:any) => t.hoverIndex > -1);
      if (!inside) throw new Error('failed to find hoverVehicle');

      const pts = cnvs.polyToPoints(inside.windshield.value);
      if (!pts) throw new Error('failed on polyToPoints');
      pts[inside.hoverIndex] = pt;
      inside.windshield.value = cnvs.pointsToPoly(pts) as string;
      if (inside.windshield.status !== 'tagged') {
        inside.windshield.status = "tagged";
        common.notify('TaggingStatusChanged');
      }
      cnvs.paint('pan-resize');

      // ofer 31/01/2022 - WTT-208
      // const r = inside.rect.value as number[];
      // const right = r[0] + r[2];
      // const bottom = r[1] + r[3];
      // switch(inside.hoverIndex) {
      //   case 0:
      //     inside.rect.value = [pt.x, pt.y, right - pt.x, bottom - pt.y];
      //     break;

      //   case 1:
      //     inside.rect.value = [r[0], pt.y, pt.x - r[0], bottom - pt.y];
      //     break;

      //   case 2:
      //     inside.rect.value = [r[0], r[1], pt.x - r[0], pt.y - r[1]];
      //     break;

      //   case 3:
      //     inside.rect.value = [pt.x, r[1], right - pt.x, pt.y - r[1]];
      //     break;

      //     // mid top
      //   case 4:
      //     inside.rect.value = [r[0], pt.y, r[2], bottom - pt.y];
      //     break;

      //     // mid right
      //   case 5:
      //     inside.rect.value = [r[0], r[1], pt.x - r[0], r[3]];
      //     break;

      //     // mid bottom
      //   case 6:
      //     inside.rect.value = [r[0], r[1], r[2], pt.y - r[1]];
      //     break;

      //   case 7:
      //     inside.rect.value = [pt.x, r[1], right - pt.x, r[3]];
      //     break;
      // }
      // inside.rect.status = 'tagged';
      // cnvs.paint('pan-vehicle-resize');
    } catch (ex) {
      console.error('failed to handle resize:', ex);
    }
  }

  /**
   * drag a segment
   * @param pt 
   */
  handleSegmentResize = (pt: any) => {
    try {
      const cnvs = platesService.canvas;
      const tags = cnvs.tags;
      const hoverTag = tags.find( (t:any) => t.hoverSegIndex > -1);
      if (!hoverTag) throw new Error('failed to find hoverTag');
      const segs = hoverTag.InternalSegments;
      if (!segs) throw new Error('no internal segments');
      const pts = cnvs.polyToPoints(hoverTag.Poly.value);
      if (!pts) throw new Error('failed on polyToPoints');

      const indexToVertices = [[0,3,1,2], [3,2,0,1],[0,3,1,2], [3,2,0,1]]
      const verticeIndices = indexToVertices[hoverTag.hoverSegIndex];
      const vertices = verticeIndices.map(i => pts[i]);

      // iterative procedure for generating new segment pos
      let low = 0;
      let high = 1;
      for (let i = 0; i < 20; i++) {
        const f = (low + high) / 2;
        const seg = cnvs.getMidSegment(vertices, f);
        if (!seg || !seg[0] || !seg[1]) throw new Error('failed to get seg');
        const d = cnvs.getSignedDistance(pt, seg[0], seg[1]);
        if (Math.abs(d) < 1)
          break;

        if (d > 0) 
          high = f;
        else
          low = f;
      }
      // calculated value
      hoverTag.InternalPoly.value[hoverTag.hoverSegIndex] = (low + high)/2;
      if (hoverTag.InternalPoly.status !== "tagged") {
        hoverTag.InternalPoly.status = "tagged";
        common.notify('TaggingStatusChanged');
      }
      // cnvs.paint('pan-segment-resize');
    } catch (ex) {
      console.error('failed to handle resize:', ex);
    }
  }

  getBoundedPoint = (pt: any): any => {
    try {
      const size = platesService.canvas.imageSize;
      const x = Math.min(Math.max(pt.x, 0), size[0] - 1);
      const y = Math.min( Math.max(pt.y, 0), size[1] - 1);
      return {x, y};
    } catch (ex) {
      console.error('failed to get bounded point:', ex);
      return pt;
    }
  }

  /**
   * handle mouse movement on canvas
   * @param evt 
   * @returns 
   */
  mouseMove = (evt: any) => {
    try {


      const cnvs = insideInspection.insideCanvas;
      const ptUnbounded = cnvs.getPoint(evt);
      const pt = this.getBoundedPoint(ptUnbounded);

      // whether point is out of image bounds
      const outOfBounds = pt.x !== ptUnbounded.x || pt.y !== ptUnbounded.y;

      if (cnvs.resizing)
        return this.handleResize(pt);

      // the various cases
  

      cnvs.mousePos = pt;
      if (this.ptDrag && !outOfBounds) {
        cnvs.ctx.translate((pt.x - this.ptDrag.x), (pt.y - this.ptDrag.y));
        cnvs.saveMatrix();
        cnvs.paint();
        return;
    }

    if (cnvs.insiding)
        return cnvs.boundInside("MouseMove", pt);


    this.getHoverPoint(ptUnbounded);
    cnvs.paint();

    } catch (ex) {
      console.error('failed on mouseMove');
    }

  };

  /**
   * handle mouse up event on canvas
   * @param evt 
   * @returns 
   */
  mouseUp = (evt: any) => {
    try {
      const cnvs = insideInspection.insideCanvas;
      const pt = cnvs.getPoint(evt);
      this.ptDrag = null;
      cnvs.boundInside("MouseUp", pt);
      cnvs.resizing = false;
    } catch(ex) {
      console.error('failed on mouseUp: ', ex);
    }
  };

  /**
   * mouse left the canvas - stop designing
   * @param evt 
   */
  mouseLeave = (evt: any) => {
    try {
      this.ptDrag = null;
      const cnvs = platesService.canvas;
      cnvs.resizing = false;
      cnvs.insideResizing = false;
      cnvs.lightResizing = false;
      cnvs.segResizing = false;
      cnvs.vehicleResizing = false;
    } catch (ex) {
      console.error('failed to handle mouse leave');
    }
  }

  /**
   * mouse wheel to zoom
   * @param clicks 
   * @returns 
   */
  zoom = (clicks:number) => {
    try {
        const canvas = insideInspection.insideCanvas;
        const ctx = canvas.ctx;
        const scaleFactor = 1.1;
        const pt = canvas.mousePos;
        if (!pt)
          return;
          
        ctx.translate(pt.x, pt.y);
        const factor = Math.pow(scaleFactor, clicks);
        ctx.scale(factor, factor);
        ctx.translate(-pt.x, -pt.y);
        canvas.saveMatrix();
        canvas.paint();
      } catch (ex) {
        console.error('failed to zoom:', ex);
      }
    };

  /**
   * call zoom function
   * @param evt 
   */
  mouseWheel = (evt: any) => {
    try {
      const delta = evt.nativeEvent.wheelDelta ? evt.nativeEvent.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
      this.zoom(delta);
    } catch (ex) {
      console.error('failed to handle canvasMouseDown:', ex);
    }
  };


}

const panInside: PanInside = new PanInside();

export default panInside;