import React, {useEffect, useState } from 'react';
import {Typography, Box, Button, Dialog, DialogTitle, DialogContent, LinearProgress, 
  Tooltip, IconButton, TextField, Select, MenuItem } from '@material-ui/core'
import { Search,  PieChart as PieChartIcon, ExpandMore as ExpandMoreIcon, ChevronRight as ChevronRightIcon, Lens as CircleIcon,
} from '@material-ui/icons';
import common from '../../services/commonService';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import MuiTreeItem from "@material-ui/lab/TreeItem";
import { TreeView, TreeItem } from '@material-ui/lab';
import { TreeNode } from '../../data/platesData';
import { StatisticsPanel } from '../plate/statisticsPanel';

/**
 * select dataset, show dataset statistics
 * @param props 
 * @returns 
 */
export function DatasetsTree (props:any) {

  const[value, setValue] = useState(0);
  const[selectedId, setSelectedId] = useState('');
  const[searchMode, setSearchMode] = useState(false);
  const[clickTime, setClickTime] = useState(0);
  const [expanded, setExpanded] = React.useState<string[]>([]);
  const [selected, setSelected] = React.useState([]);

  // from props
  const { onClose, open } = props;

  const handleToggle = (event:any, nodeIds:any) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event:any, nodeIds:any) => {
    setSelected(nodeIds);
    if (nodeIds.startsWith('node'))
      common.notify('TreeNodeExpanded', nodeIds);
  };

/**
 * perform statistics on selected dataset
 * @returns 
 */
const handleStatistics = () => {
  try {

    if (common.plates.showStatisticsPanel) {
      common.plates.showStatisticsPanel = false;
      setValue(v => v + 1);
      common.notify('StopPlatesStatistics');
      return;
    }

    if (!selectedId || selectedId?.startsWith('node'))
      return;

    common.notify('StartPlateStatistics', selectedId as any);
    common.plates.showStatisticsPanel = true;
    setValue(v => v + 1);
  } catch (ex) {
    console.error('failed to handle select:', ex);
  }
}

const handleItemClicked = (dataset: any) => {
  try {

    if (dataset._id === selectedId)
      return;

    setSelectedId(dataset._id);
    if (common.plates.showStatisticsPanel)
      common.notify('StartPlateStatistics', dataset._id);


  } catch (ex) {
    console.error('failed to handle item click:', ex);
  }
}

/**
 * dispatcher
 */
const handleCallback = (arg: string, value: any) => {
  try {
    switch(arg) {

      case 'TreeNodePopulated':
        setValue(v => v + 1);
        break;
     
      case "Select":
        common.notify('PlatesLoadDataset',{id:selectedId} as any);
        handleClose();
        break;

        case 'ItemClicked':
          const now = Date.now();
          const delta = now - clickTime;
          if (delta > 1000) {
            setClickTime(now);
            handleItemClicked(value);
            return;
          }
        
          if (!selectedId)
            return;

          common.notify('PlatesLoadDataset', {id:selectedId} as any);
          handleClose();  
          break;

      case "Statistics":
        handleStatistics();
        break;

      case "PlateStatisticsChanged":
        refreshCells();
        break;

      case "StatisticsProgressChanged":
        setValue(v => v + 1);
        break;

      case "ToggleSearch":
        setSearchMode(!searchMode);
        break;

      case "CountryFilter":
        common.plates.datasetsFilter.country = value;
        common.notify('PlatesDatasetsFilterChanged');
        setValue(v => v + 1);
        break;

      case "NameFilter":
        common.plates.treeFilter = value;
        common.notify('TreeFilterChanged');
        setValue(v => v + 1);
        break;

      case "DatasetsTreeOpened":
        handleOpen();
        break;

        case "PlatesDatasetsArrived":
          handleDataset();
          break;

        case "GetDatasetsPercentageChanged":
        case "GettingDatasetsChanged":
          setValue(v => v + 1);
          break;

        case 'NodeSelected':
          // handleSelect(value);
          break;

        case 'DatasetsRootChanged':
          const root = 'root' as any;
          setExpanded([root]);
          break;

    

    }

  } catch (ex) {
    console.error('failed on handleCallback tree');
  }
}

const handleOpen = () => {
  try {
    setSearchMode(false);
    common.plates.datasetsDialogOpen = true;
    common.plates.treeFilter = '';
    common.notify('PlatesGetDatasets');
  } catch (ex) {
    console.log('failed to handle open:', ex);
  }
}

const handleDataset = () => {
  try {
    refreshCells();
  } catch (ex) {
    console.error('failed to handle datasets arrival:', ex);
  }
}

/**
 * refresh grid cells
 */
const refreshCells = () => {
  try {
    if (common.plates.datasetsGridApi)
      common.plates.datasetsGridApi.refreshCells();
  } catch (ex) {
    console.error('failed to update validation state:', ex);
  }
}

/**
 * close the dialog
 */
  const handleClose = () => {
    setSelectedId('');
    common.notify('StopPlatesStatistics');
    // in case closed while getting datasets
    common.plates.abortGettingDatasets = true;
    common.plates.showStatisticsPanel = false;
    common.plates.datasetsDialogOpen = false;
    onClose();
  };

  const handleListItemClick = (value:any) => {
    onClose(value);
  };

  const classes = useStyles();

  /**
   * subscribe, unsubscribe to events
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallback(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  const stats = common.plates.stats;

    const TreeItem = withStyles({
    root: {
      "&.Mui-selected > .MuiTreeItem-content": {
        background: "gray"
      }
    }
  })(MuiTreeItem);

  const getDatasetCount = (ds: any) => {
    try {
      const imageCount = ds.imageCount || -1;
      const sequenceCount = ds.sequenceCount || -1;
      if (imageCount > 0) return `(${imageCount})`;
      if (sequenceCount > 0) return `(${sequenceCount})`;
      return '-';

      Math.max(ds.imageCount || -1,ds.sequenceCount || -1)
    } catch (ex) {
      console.error('failed to get datset count:', ex);

    }
  }

  const renderTree = (node: TreeNode) => (
    <TreeItem  
     key={node.id} nodeId={node.id} label={node.name}>
      {node.nodes.length > 0 ? node.nodes.map((node) => renderTree(node)) : null}
      {node.datasets.map(ds => <TreeItem key={ds._id} nodeId={ds._id} 
      label={<Box display="flex" alignItems="center" flexDirection="row" onClick={() => handleCallback('ItemClicked', ds)}  >
        <CircleIcon style={{width:'10px', height:'10px'}}/>&nbsp;&nbsp;
        <Typography style={{fontSize: '12px', color:'black'}}>{ds.dataset.alias}&nbsp; <b>{getDatasetCount(ds)}</b></Typography>
        </Box>}></TreeItem>)}
 
    </TreeItem>
  );

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xl" disableBackdropClick={true} >
      <DialogTitle id="Plates datasets">
        <Box display="flex" flexDirection="row" alignItems="center"> 
        <Box display="inline">Datasets selector</Box> 
        <Box width={4}></Box>
        <Box display= {common.plates.gettingDatasets ? "flex" : "none" } flexDirection="row" alignItems="center">
        <LinearProgress variant="determinate"  value={common.plates.getDatasetsPercentage}  style={{width:100, height:12, margin: 2}} />  
        <Box width={4}></Box> 
        <Typography variant="caption">Loading ...</Typography>
        </Box>
 
        </Box>
      </DialogTitle>

      <DialogContent>
      { true &&
      <Box display="flex" flexDirection="row">
        <Box display="flex" flexDirection="column" overflow="hidden" >
          <Box display="flex" flexDirection="column"  style={{ height: '400px', width: '400px' }}>
       

          <TreeView className={classes.root} 
                // onNodeSelect={(event:any, nodeIds:any) => handleCallback('NodeSelected', {event, nodeIds})}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpanded={['root']}
                defaultExpandIcon={<ChevronRightIcon />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
          
          >{renderTree(common.plates.datasetsRoot)}</TreeView>



          </Box>
          <Box display="flex" flex="1" flexDirection="column">
          <Box display="flex" flex="1" flexDirection="row" alignItems="center">
          <Tooltip title= "Toggle dataset search" placement="bottom">
            <span>
              <Button disabled={common.plates.gettingDatasets} variant="contained" startIcon={<Search/>} onClick={() => handleCallback("ToggleSearch", null)}
              className={classes.button} style={{maxWidth: '40px', minWidth: '40px'}}>
              </Button>
              </span>
              </Tooltip>
              <Tooltip title= "Show selected dataset statistics" placement="bottom">
                <span>
              <Button startIcon={<PieChartIcon/>} style={{maxWidth: '40px', minWidth: '40px'}}
              disabled={!selectedId} className={classes.button} variant="contained" onClick={() => handleCallback("Statistics", null)}></Button>
              </span>
              </Tooltip>
              <Box display={common.plates.showStatisticsPanel ? "flex" : "none"} flexDirection="row">
              <LinearProgress variant="determinate"  value={common.plates.statisticsPercentage}  style={{width:100, height:12, margin: 2}} />   
              {common.plates.statisticsPercentage}%
          </Box>
          <Box display="flex" flex="1" flexDirection="row" justifyContent="flex-end" >
              <Button disabled={!selectedId} className={classes.button} variant="contained" onClick={() => handleCallback("Select", null)}>Select</Button>
              <Button className={classes.button} variant="contained" onClick={() => handleClose()}>Close</Button>
          </Box>
        </Box>
          <Box display={searchMode ? "flex":"none"} alignItems="flex-end">
            
          <TextField type="search" label="Search dataset by name" value={common.plates.treeFilter} onChange={(e) => handleCallback("NameFilter", e.target.value) }></TextField>
          </Box>
        </Box>
      </Box>
      <Box flexDirection="column" display={common.plates.showStatisticsPanel   ? "flex" : "none"}  >

      <Box display={common.plates.statisticsState === 'running'  ? "flex" : "none"} flex="1" justifyContent="center" alignItems="center"  minWidth={600}>
        <h3>Collecting statistics ...</h3>
      </Box>
        
      <Box  flexDirection="column" display={common.plates.statisticsState !== 'running'  ? "flex" : "none"}  >
          <StatisticsPanel/>
      </Box>
    
    
    </Box>
    </Box>
    }

    </DialogContent>
</Dialog>
  );
}

DatasetsTree.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme: Theme) => ({ 
  root: {
    height: 390,
    overflow: 'auto',
    border:'solid 1px gainsboro'
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  combo: {
    width: 150,
  },
  grid: {

  },
}));