import { TransferWithinAStationTwoTone } from "@material-ui/icons";
import common from "../services/commonService";


export class LogItem {
  time: number;
  severity: string;
  message: string;
  id: number;
  context: string = '';

  constructor() {
    this.time = 0;
    this.severity = 'Error';
    this.message = '';
    this.id = 0;
  }
}

export class LogsData {
  logs: LogItem[];
  filteredLogs: LogItem[] = [];
  errors: number;
  warnings: number;
  infos: number;
  showErrors: boolean = true;
  showWarnings: boolean = false;
  showInfos: boolean = false;
  gridApi: any;

  constructor() {
    this.logs = [];
    this.errors = 0;
    this.warnings = 0;
    this.infos = 0;
    this.gridApi = null;
  }

  addLog(args:any) {
    try {
      const newLog = new LogItem();
      newLog.message = args.toString();
      newLog.severity = 'Info';
      newLog.time = Date.now();
      newLog.id = this.logs.length;
      this.logs.push(newLog);
      this.infos++;
      if (this.showInfos)
        this.filteredLogs.push(newLog);
    } catch (ex) {
      // nothing here
    }

  }

  addWarning(args:any) {
    try {
      const newLog = new LogItem();
      newLog.message = args.toString();
      newLog.severity = 'Warning';
      newLog.time = Date.now();
      newLog.id = this.logs.length;
      this.logs.push(newLog);
      this.infos++;
      if (this.showInfos)
        this.filteredLogs.push(newLog);
    } catch (ex) {
      // nothing here
    }

  }

  public getContext = () => {
    try {
      if (common.mode === 'PLATES') {
        const plates = common.plates;
        const alias = plates.datasetAlias || 'No alias';
        const rec = plates.selectedRecord?.index || 'No record';
        return `${alias} ${rec}`;
      }
      if (common.mode === 'AXLES') {
        const axlesAlias = common.plates.settings.datasetName || 'No alias';
        const axlesIndex = common.axles.selectedRow?.index || 'No record';
        return `${axlesAlias} ${axlesIndex}`;
      }

      return 'No context';

    } catch (ex) {
      return 'failed to get context';
    }
  }

  addError(args:any) {
    try {
      const newLog = new LogItem();
      newLog.severity = 'Error';
      newLog.message = args.toString();
      if (newLog.message.toLowerCase().startsWith('warning'))
        newLog.severity = 'Warning';
        
        newLog.time = Date.now();
        newLog.context = this.getContext();
        newLog.id = this.logs.length;
        this.logs.push(newLog);
        if (newLog.severity === 'Error') {
          this.errors++;
          if (this.showErrors)
            this.filteredLogs.push(newLog);
        }
        if (newLog.severity === 'Warning') {
          this.warnings++;
          if (this.showWarnings)
            this.filteredLogs.push(newLog);
        }
    } catch (ex) {
      // nothing here
    }
    
  }


}

export class GrinderData {
  iterations: number = 0;
  active: boolean = false;
  busy: boolean = false;
  startTime: string = '';
  scripts: string[] = [
    "annotate and next",
    "annotate current",
    "annotate plate number",
    "annotate and plate",
  ];
  script: string = '';
}

export class SearchData {
  open: boolean = false;
  searching: boolean = false;
  searchText: string = '';
  cachedDatasetId: string = '';
  cachedMode: string = '';
  found: boolean = false;
}

export class AlertData {
  title: string = '';
  text: string = '';
}

export class ContextData {
  json: string = '';
  id: string = '';
}

export class AppData {
  logs: LogsData;
  datasetItemsFromImagelib: boolean = false;
  showRibbonDebugs: boolean = false;
  doGrind: boolean = false;
  grinder: GrinderData = new GrinderData();
  grindCounter: number = 0;
  changelog: any = null;
  search: SearchData = new SearchData();
  alert: AlertData = new AlertData();
  context: ContextData = new ContextData();
  // WTT-361 detect change in order
  orderChanged: boolean = false;
  // indicate wether order fields had effect
  orderValidField0 = false;
  orderVAlidField1 = false;

  // sanitiy check - verify order was not empty
  orderApply: boolean = false;

  constructor() {
    this.logs = new LogsData();
  }
}