import React, { useEffect} from 'react';
import { Box} from "@material-ui/core";
import common  from '../../services/commonService';
import  ResizeObserver  from 'react-resize-observer';

import {SequenceProps} from './sequenceProps';
import { TagProps } from './tagProps';
import { VehicleClasses} from './classes';

/**
 * details panel - hosts various tabs
 * @param props 
 * @returns 
 */
export function AxleDetails(props: any) {


  const [value, setValue] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const handleChange = (key: string, value: any) => {
    switch(key) {
      case "AxlesPanelChanged":
        setValue(v => v + 1);
        break;
    }


  };

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChange(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  const selectTab = common.plates.selectedTab;
  const w:number = props.width;
  const h:number = props.height;

  return <Box display="flex" flexDirection="column" flex="1" width={w} height={h} >
    {common.axles.showVehiclesPanel && <VehicleClasses></VehicleClasses>}
    {!common.axles.showVehiclesPanel && <SequenceProps height={h} width={w}></SequenceProps>}
    </Box>
}