// prettier-ignore
import {  Button, IconButton, Paper, Switch, Badge,
  FormGroup, FormControlLabel, Tooltip, ButtonGroup, Box } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from "@material-ui/styles";
import { useState, useEffect } from "react";
import * as React from "react";
import tags from '../../services/tags';
import common from '../../services/commonService';

import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForever from '@material-ui/icons/DeleteForever';

import {Save as SaveIcon, Cancel as CancelIcon } from '@material-ui/icons';




export function VehiclePanel2(props: any) {

  const [value, setValue] = useState(0); 
  const classes = useStyles();

    const handleChanges = (name: string, value: any) => {
      switch (name) {
        case "Opaque":
          tags.opaque = value;
          setValue(v => v + 1);
          break;

        case "Night":
          tags.night = value;
          setValue(v => v + 1);
          break;

        case "Vehicle":
          tags.vehicleIndex = value;
          tags.vehicle = tags.vehicles[value];
          setValue(v => v + 1);
          common.notify('InsidePropertyChanged');
          break;

        case "DeleteObject":
          common.notify('InsideDeleteSelectedTag');
          break;

        case "ClearVehicle":
          common.notify('InsideClearSelectedVehicle');
          setValue(v => v + 1);
          break;

        case "InsideTagsAdded":
          setValue(v => v + 1);
          break;

        case "Save":
          common.notify('InsideSaveRecord');
          break;

        case "Occupancy":
          handleOccupancyClick(value);
          break;
      }

      
    };

  const handleVehicleClass = (e: any) => {
    try {
      const vehicleClass = (e.target as any).value;  
      const inside = common.inside;
      tags.vehicles[tags.vehicleIndex].vehicleType = vehicleClass;
      common.notify('InsidePropertyChanged');
      setValue(v => v + 1);
     
    } catch (ex) {
      console.error('failed to hadle click:', ex);
    }
  };  
  
  const handleClick = (index:number) => {
    try {
      common.inside.mode = index;
      tags.setMode(index);
      console.log('set mode:', index);
      setValue(v => v + 1);
      common.notify('InsidePropertyChanged');
    } catch (ex) {
      console.error('failed to hadle click:', ex);
    }
  };

  const getNextOccupancy = (occupancy: string): string => {
    switch (occupancy) {
      case "-": return "+";
      case "+": return "?";
      case "?": return "-";
    }
    return "-";
  }

  const handleUndo = () => {
    try {
      tags.undoPop();
      handleChanges('Undo', null);

    } catch (ex) {
      console.error('failed to handle undo:');
    }
  };

  const handleOccupancyClick = (index: number) => {
    try {
      const inside = common.inside;
      const vehicle = tags.vehicles[tags.vehicleIndex];
      const occupancy = vehicle.occupancy[index];
      vehicle.occupancy[index] = getNextOccupancy(occupancy);
      common.notify('InsidePropertyChanged');
      setValue(v => v + 1);

    } catch (ex) {
      console.error('failed to handle occupancy');
    }
  };

  const getSeatOpacity = (position: number): any => {
    try {
      const vehicle = tags.vehicles[tags.vehicleIndex];
      return  vehicle.occupancy[position]  === "+" ? 1 : 0.4;
    } catch (ex) {
      console.error('failed to get seat visibility:', ex);
      return "hidden";
    }
  }

  const inside = common.inside;
  const badges = tags.getBadges();

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);
 

	return (
		<Paper className={classes.paper}>
      
      <form className={classes.root} noValidate autoComplete="off">
      <FormGroup>
        <div className={classes.row}>


      <Box display="flex" flexDirection="column">

      <b>Image:</b>

<FormControlLabel
    control={<Switch checked={tags.opaque} onChange={(e) => handleChanges( "Opaque", e.target.checked)} />}
    label="opaque"
  />

  <FormControlLabel
    control={<Switch checked={tags.night} onChange={(e) => handleChanges('Night', e.target.checked)} />}
    label="night"
  />


      </Box>

      <Box display="flex" flexDirection="column">

      <Badge color="primary" variant="dot"  badgeContent={badges[0]} >
      <Button variant="contained"
        onClick={() => handleChanges("Vehicle", 0) }
        className={tags.vehicleIndex  === 0 ? classes.activeButton : classes.button}>Car 1
      </Button>
      </Badge>
    
      <Badge color="primary" variant="dot" badgeContent={badges[1]} >
      <Button variant="contained"
        onClick={() => handleChanges("Vehicle", 1) }
        className={tags.vehicleIndex  === 1 ? classes.activeButton : classes.button}>Car 2
      </Button>
      </Badge>

      <Badge color="primary" variant="dot" badgeContent={badges[2]} >
      <Button variant="contained"
        onClick={() => handleChanges("Vehicle", 2) }
        className={tags.vehicleIndex  === 2 ? classes.activeButton : classes.button}>Car 3
      </Button>
      </Badge>


      </Box>

      <Box display="flex" width={20}></Box>

        <div className={classes.column}>

        Occupancy:
        <ButtonGroup color="primary" aria-label="outlined primary button group">
        <Button onClick={() => handleChanges("Occupancy", 0)}><b>{tags.vehicles[tags.vehicleIndex].occupancy[0]}</b></Button>
        <Button onClick={() => handleChanges("Occupancy", 1)}><b>{tags.vehicles[tags.vehicleIndex].occupancy[1]}</b></Button>
        <Button onClick={() => handleChanges("Occupancy", 2)}><b>{tags.vehicles[tags.vehicleIndex].occupancy[2]}</b></Button>
      </ButtonGroup>

      <Select className={classes.combo} 
      value={tags?.vehicles[tags.vehicleIndex]?.vehicleType} 
      onChange={e =>handleVehicleClass(e as any)}>
            <MenuItem value="Car">Car</MenuItem>
            <MenuItem value="Van">Van</MenuItem>
            <MenuItem value="LT">LT</MenuItem>
            <MenuItem value="HT">HT</MenuItem>
            <MenuItem value="Bus">Bus</MenuItem>
          </Select>

        </div>





        <div style={{width:30}}></div>

        <div className={classes.column} >

        <div className={classes.header}>
        <Badge color="primary"  badgeContent={badges[13]} >
        <Button
              className={inside.mode === 13 ? classes.activeButton : classes.button}
              variant="contained"
              onClick={() => handleClick(13)}>Car
          </Button>
          </Badge>
              </div>
          
              <Badge className={classes.badge} color="primary"  badgeContent={badges[1]} >
            <Button
              className={inside.mode === 1 ? classes.activeButton : classes.button}
              variant="contained"
              onClick={() => handleClick(1) }>Glass
          </Button>
          </Badge>
          <Badge color="primary"  badgeContent={badges[11]} >
            <Button
                className={inside.mode === 11 ? classes.activeButton : classes.button}
                variant="contained"
                onClick={() => handleClick(11)}>Phone
            </Button>
            </Badge>
            <Badge color="primary"  badgeContent={badges[12]} >
            <Button
                className={inside.mode === 12 ? classes.activeButton : classes.button}
                variant="contained"
                onClick={() => handleClick(12)}>Plate
            </Button>
            </Badge>

          </div>

          <div style={{width:9}}></div>

          <div className={classes.column} style={{ opacity: getSeatOpacity(0)}}>
            <div className={classes.header}>
            Left
            </div>
            <Badge color="primary"  badgeContent={badges[2]} >
            <Button
              className={inside.mode === 2 ? classes.activeButton : classes.button}
              variant="contained"
              onClick={() => handleClick(2)}>Seat
          </Button>
          </Badge>
          <Badge color="primary"  badgeContent={badges[5]} >
            <Button
                className={inside.mode === 5 ? classes.activeButton : classes.button}
                variant="contained"
                onClick={() => handleClick(5)}>Face
            </Button>
            </Badge>
            <Badge color="primary"  badgeContent={badges[8]} >
            <Button
                className={inside.mode === 8 ? classes.activeButton : classes.button}
                variant="contained"
                onClick={() => handleClick(8)}>Belt
            </Button>
            </Badge>
          </div>

          <div style={{width:9}} ></div>

          <div className={classes.column} style={{ opacity: getSeatOpacity(1)}}>
            <div className={classes.header}>
           Center
            </div>
            <Badge color="primary"  badgeContent={badges[3]} >
            <Button
              className={inside.mode === 3 ? classes.activeButton : classes.button}
              variant="contained"
              onClick={() => handleClick(3)}>Seat
          </Button>
          </Badge>
          <Badge color="primary"  badgeContent={badges[6]} >
            <Button
                className={inside.mode === 6 ? classes.activeButton : classes.button}
                variant="contained"
                onClick={() => handleClick(6)}>Face
            </Button>
           </Badge>
           <Badge color="primary"  badgeContent={badges[9]} >
            <Button
                className={inside.mode === 9 ? classes.activeButton : classes.button}
                variant="contained"
                onClick={() => handleClick(9)}>Belt
            </Button>
            </Badge>
          </div>
  
          <div style={{width:9}}></div>

          <div className={classes.column} style={{ opacity: getSeatOpacity(2)}}>
            <div className={classes.header}>
            Right
            </div>
            <Badge color="primary"  badgeContent={badges[4]} >
            <Button
              className={inside.mode === 4 ? classes.activeButton : classes.button}
              variant="contained"
              onClick={() => handleClick(4)}>Seat
          </Button>
          </Badge>
          <Badge color="primary"  badgeContent={badges[7]} >
            <Button
                className={inside.mode === 7 ? classes.activeButton : classes.button}
                variant="contained"
                onClick={() => handleClick(7)}>Face
            </Button>
            </Badge>
            <Badge color="primary"  badgeContent={badges[10]} >
            <Button
                className={inside.mode === 10 ? classes.activeButton : classes.button}
                variant="contained"
                onClick={() => handleClick(10)}>Belt
            </Button>
            </Badge>
          </div>
 
          <div style={{width:25}}></div>

          <div className={classes.column}>

            <div className={classes.header}></div>

            <Button
            className={inside.mode === 0 ? classes.activeButton : classes.button}
            variant="contained"
            onClick={() => handleClick(0)}>Pan
            </Button>

            <Button
            className={classes.button}
            variant="contained"
            onClick={() => handleUndo()}>Undo
            </Button>

            <div className={classes.row}>

            <Tooltip title="Delete selection">
            <IconButton  
            onClick = {() =>  handleChanges("DeleteObject", null)}
            className={classes.iconButton}  color="primary" >
            <DeleteIcon/>
            </IconButton>
            </Tooltip>


            <Tooltip title="Clear vehicle">
            <IconButton 
               onClick = {() =>  handleChanges("ClearVehicle", null)}
            className={classes.iconButton} color="primary" >
            <DeleteForever/>
            </IconButton>
            </Tooltip>

            </div>

          






</div>


        <Box display="flex" width={20}></Box>
        <Box display="flex" flexDirection="column">

        <Button variant="contained"
        onClick={() => handleChanges("Save", null)}
        startIcon={<SaveIcon />}
        className={classes.button}>Next
      </Button>

      <Button variant="contained"
        onClick={() => handleChanges("Cancel", null) }
        startIcon={<CancelIcon />}
        className={classes.button}>Cancel
      </Button>
        </Box>


        </div>
     
      </FormGroup>
</form>
		</Paper>
	);
}

const useStyles = makeStyles({
	paper: {
    display: "flex",
    backgroundColor: 'gainsboro',
    margin: 5,
    padding: 5,
	},
   root: {
    '& > *': {
      margin: 2,
      width: '100%',
    },
    
  },
  editors: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection:"row",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: 2,
    fontSize: 10,
    // backgroundColor: '#29367d',
    color: 'white',
    backgroundColor: 'black',
    hover: 'green',
  },
  iconButton: {
    color: 'black',
    width: 20,
    height: 20,
  },
  occupancyButton: {
    width:16,
    maxWidth:16,
    size: 'small',
    color: 'black',

  },
  combo: {
    margin: 10,
  },
  
  header: {
    height: 33,
  },
  switch: {
    fontSize: 10,
  },
  activeButton: {
    margin: 2,
    fontSize: 10,
    // backgroundColor: '#29367d',
    color: 'white',
    backgroundColor: 'dodgerBlue',
  },
  badge: {
    fontSize: 5,
    hover: 'orange'
  },
});
