
import tags from "../../../services/tags";
import common from '../../../services/commonService';

class Seats {
  constructor() {

  }

  setSeat(seat:any) {
    try {
      tags.vehicle.seats[tags.objectIndex] = seat;
      tags.updateObject();
    } catch (ex) {
      console.error('failed to setFace:', ex);
    }
  }

  clear = () => {
    try {
      this.setSeat([]);
      tags.undoAdd();
    } catch (ex) {
      console.error('failed to clear:', ex);
    }
  };


  click = (evt: any) => {
    try {
      const pt = tags.getPoint(evt);
      const seat = tags.vehicle.seats[tags.objectIndex];
      if (!seat)
        return;

      if (seat.length >= 4)
        return;

      seat.push(pt);
      tags.updateObject();
      tags.undoAdd();
      common.notify('InsideTagsAdded');

    } catch(ex) {
      console.error('failed on click: ', ex);
    }
  };

  mouseDown = (evt: any) => {
    try {
      tags.dragAnchor = tags.hover;
    } catch(ex) {
      console.error('failed on mouseDown: ', ex);
    }
  };

  mouseMove = (evt: any) => {
    try {

      if (tags.dragAnchor) {

        tags.vehicle.seats.forEach(seat => {
          for(let i = 0; i < seat.length; i++) {
            if (seat[i] === tags.dragAnchor) {
              const pt = tags.getPoint(evt);
              seat[i] = pt;
              tags.dragAnchor = pt;
            }
          }
        });
        return;
      }

      const ptMouse = tags.getPoint(evt);
      tags.hover = null;
      tags.vehicle.seats.forEach(seat => {
        seat.forEach(pt => {
          if (tags.getDistance(ptMouse, pt) < 5) {
            tags.hover = pt;
            return;
          }
        });
      });

    } catch(ex) {
      console.error('failed on mouseMove: ', ex);
    }
  };

  mouseUp = (evt: any) => {
    try {
      if (tags.dragAnchor)
        tags.undoAdd();
        
      tags.dragAnchor = null;
    } catch(ex) {
      console.error('failed on mouseUp: ', ex);
    }
  };
}

const seats: Seats = new Seats();

export default seats;