import React, {useState, useEffect } from 'react';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, IconButton, Tooltip, TextField } from "@material-ui/core";
import { Search } from '@material-ui/icons';
import common from '../../services/commonService';

/**
 * mini search control
 * @param props 
 * @returns 
 */
export function PlateSearch(props: any) {

  
  const [value, setValue] = useState(0); // integer state
  const [showFilter, setShowFilter] = useState(false);

  /**
   * callback handler
   * @param arg 
   * @param value 
   */
  const handleCallback = (arg: string, value: any) => {
    try {
      switch (arg) {

        case "Search":
          filter.search = value?.toUpperCase();
          setValue(v => v + 1);
          common.notify('PlatesFilterChanged');
          break;

          case "SearchId":
          filter.searchId = value;
          setValue(v => v + 1);
          common.notify('PlatesFilterChanged');
          break;
      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallback(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);


  const comboOptions:string[] = ["Tagged", "Not tagged", "Visited", "Not visited", 
  "Validation OK", "Validation error"];

const filter = common.plates.filter;

  return (
    <Box display="flex" flexDirection="column"  >
    <Box display="flex" flexDirection="column"  >


      <Box display="flex" flexDirection="row" alignItems="flex-end" height="25px">
      <Box display="inline" margin='4px' width="60px">Plate:</Box> &nbsp;&nbsp;
      <TextField type="search" label="" value={filter.search} onChange={(e) => handleCallback("Search", e.target.value) }></TextField>
      &nbsp;
      </Box>

      <Box display="flex" flexDirection="row" alignItems="flex-end" height="25px">
        <Box display="inline" margin='4px' width="60px">Image Id:</Box> &nbsp;&nbsp;
        <TextField type="search" label="" value={filter.searchId} onChange={(e) => handleCallback("SearchId", e.target.value) }></TextField>
        </Box>

      </Box>
      </Box>
  );

}