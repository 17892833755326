import axios from 'axios';
import common from './commonService';
import {StatisticsInfo, StatisticsEntry, PlateMatch} from '../data/platesData';
import { MenuItem } from '@material-ui/core';
import { isArray, isNumber } from '@material-ui/data-grid';
import platesService from './platesService';


/**
 * cycle through recordset images, and collect info per image
 */
export class PlatesCycler {

  /**
   * launch manager on creation (from plates service)
   */
  constructor() {
    this.extraInfoManager();
  }

  /**
   * scan when appropriate
   */
  extraInfoManager = () => {
    try {
      setInterval(() => {
        const sp = common.plates.scanPolicy;
        if (!sp.busy && !sp.loadingDataset && !sp.abortRequest) {
          this.extraInfoCycle();
        }
      },100);
  
    } catch (ex) {
      console.error('failed on extraInfoManager:', ex);
    }
  }

  delay = (n: number) => {
    return new Promise(function(resolve){
        setTimeout(resolve,n);
    });
  }

  isPreTagged = (annotations: any[]): boolean => {
    try {
      if (!annotations)
        return false;
        
      const fields = ["PlateNumber","WhiteChars","Bilingual","PlateType","Nation","VehicleType",
      "ExtraType","Category","CharHeight","Poly","Rect","InternalPoly", "PlateType"];
      let preTagged = false;
      annotations.forEach(ant => {
          fields.forEach(f => {
            if(ant[f]?.status === 'pre-tagged')
              preTagged = true;
          });
        });
      return preTagged;
    } catch (ex) {
      console.error('failed on isPreTagged (3):', ex);
      return false;
    }
  }

  /**
   * sanitize plate
   * @param plate 
   * @returns 
   */
  sanitizePlate = (plate:any) => {
    try {
      if (!plate)
        return;
  
      if (!plate.Annotations)
        plate.Annotations = [];
  
      if (!isArray(plate.Annotations))
        plate.Annotations = [plate.Annotations];
  
      plate.Annotations.forEach((a:any) => {
        if (!a.VehicleType)
          a.VehicleType = {status:'tagged', value: 0};
  
        if (!a.VehicleType.value)
          a.VehicleType.value = 0;
  
        if (!isNumber(a.VehicleType.value))
          a.VehicleType.value = parseInt(a.VehicleType.value);
  
        if (isNaN(a.VehicleType.value))
          a.VehicleType.value = 0;
      });
  
    } catch (ex) {
      console.error('failed to sanitize plate:', ex);
    }
  }

  /**
   * get a list of vehicle types
   * @param plate 
   * @returns 
   */
  getVehicleTypes = (plate:any) => {
    try {
      return plate.Annotations.map((a:any) => a.VehicleType.value) || [];
    } catch (ex) {
      console.error('failed to get vehicle types:', ex);
      return [];
    }
  }

  getExtraValues = (plate:any) => {
    try {
      return plate.Annotations.map((a:any) => a.ExtraType.value) || [];
    } catch (ex) {
      console.error('failed to get vehicle types:', ex);
      return [];
    }
  }
  
  /**
   * at least one annotation is marked occluded
   * @param plate 
   * @returns 
   */
  isOccluded = (plate:any) => {
    try {
      const occluded = plate.Annotations.find((a:any) => a?.ExtraType?.value !== 0);
      return !!occluded;
    } catch (ex) {
      console.error('failed on isOccluded:', ex);
      return false;
    }
  }

  /**
   *  at least one annotation has CharsPoly
   * @param plate 
   * @returns 
   */
  charsTagged = (plate:any) => {
    try {
      const chars = plate.Annotations.find((a:any) => a?.CharsPoly);
      return !!chars;
    } catch (ex) {
      console.error('failed on charsTagged:', ex);
      return false;
    }
  }



  /**
   * verify that dataset hash has not changed
   * if changed - sends an error notification
   * @returns 
   */
  checkDatasetHash = async () => {
    try {
      const id = common.plates.datasetId;
      if (!id)
        return;

      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const url = `${serverUrl}/dataset/${id}`;
      const reply = await axios.get(url, {headers: {'Authorization': common.loginToken}});
      const ids = reply?.data?.images.join();
      const hash = common.getHash(ids);
      if (hash !== common.plates.datasetHash)
        console.error(`dataset hash error - original hash: ${common.plates.datasetHash} new hash: ${hash}` as any);

    } catch (ex) {
      console.error('failed to check dataset hash:', ex);
    }
  }

  /**
   * a single cycle (mini or full)
   * @returns 
   */
  extraInfoCycle = async () => {
    try {

      if (common.mode !== 'PLATES')
        return;
      
      // temp - 
      let containsBccm = false;

      const sp = common.plates.scanPolicy;
      sp.setBusy(true);
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const recs = common.plates.unfiltered;
      // nothing to scan or not ready
      if (!recs || recs.length === 0 || !common.plates.gridApi?.rowRenderer)
        return;

      if (common.plates.datasetsDialogOpen)
        return;
  
      common.plates.totalRecords = recs.length;
      sp.setFirstRow(common.plates.gridApi.rowRenderer.firstRenderedRow);
      sp.setLastRow(common.plates.gridApi.rowRenderer.lastRenderedRow);
      sp.updateMoveStatus();
  
      let full = true;
      let firstRow = 0;
      let lastRow = recs.length - 1;
  
      switch(sp.scanStatus) {
        case "moving":
        case "unmoving":
        case "none":
          // not in condition to scan
            return;
  
        case "mini":
        case "moved":
          firstRow = sp.firstRow;
          lastRow = sp.lastRow;
          full = false;
          break;
  
        case "initialQuery":
        case "full":
          break;
      }
  
      // show progress only for first full scan
      if (sp.scanStatus === "initialQuery") {
        common.plates.filter.bccmFilter = false;
        common.plates.filter.includesVehicles = false;
        common.plates.showStatusProgress =  true;
        common.notify('PlateStateUpdated');
      }

      if (sp.scanStatus === 'full')
        await this.checkDatasetHash();
  
  
    sp.startScan();
    // scanning starts here
    let taggedRecords = 0;
  
    // perform statistics only on full scan
    if (full) {
      common.plates.filter.vehicleTypes.forEach(v => v.count = 0);
    }
    
    for(let i = firstRow; i <= lastRow; i++) {
      
      common.notify('PlateStateUpdated');
      await this.delay(20);
  
      // abort when changing dataset while fetching
      if (sp.abortRequest) {
        sp.endScan(false);
        common.notify('PlateStateUpdated');
        return;
      }
  
      const rec = recs[i];
      await this.updateRecord(rec);
      
      // rec.plate = details.data?.image_library?.header?.S_PLATE;
      common.plates.itemStatesPercentage = Math.round(100 * (i+1) / recs.length);
      if (rec.TagCount > 0)
        taggedRecords++;

      common.plates.currentScanningPosition = i;
  
      // periodically refresh the list
      if (i % 5 === 0)
        common.notify('PlateStateUpdated');
    }
  
    // conditions to notify end of scan - used for launching order confirmation
    const notifyEndOfScan = sp.scanStatus === 'initialQuery' || sp.forced;

    sp.endScan(full);
    common.notify('PlateStateUpdated');
  
    if (full) {
      common.plates.taggedRecords = taggedRecords;
      common.plates.filter.canFilter = true;

      const vehicleTypes = common.plates.filter.vehicleTypes;
      recs.forEach(r => {
        r.vehicleTypes.forEach((vt:number) => {
          // ofer 11/10/2021 - protect against straying vehicle types (floats)
          if (vehicleTypes[vt+1] !== undefined && vehicleTypes[0] !== undefined) {
              common.plates.filter.vehicleTypes[0].count++;
              common.plates.filter.vehicleTypes[vt+1].count++;
          }
        });
      });
      common.notify('PlatesFilterEnabled');
      // notify (ordering) full scan was completed

      // if dataset was 
      if (notifyEndOfScan)
        common.notify('PlateFullScanningCompleted');
    }
  
    // update the ribbon
    common.plates.showStatusProgress = false;
    common.notify('PlateStateUpdated');

    return;
  
    } catch (ex) {
      common.relogIfTokenExpired(ex);
      console.error('failed on extra info cycle:', ex);
    } finally {
      common.plates.scanPolicy.setBusy(false);
    }
  }

  /**
   * returns plates matching sequence
   * 
   * @param native - native record
   */
  getPlateMatchStatus = (native:any): PlateMatch => {
    try {
      const plates = native?.image_library?.human?.plates || { Annotations: []};
      const header = native?.image_library?.header;
      let plateNumber = header?.S_PLATE;
      // remove nation part (format is number-nation)
      if (plateNumber)
        plateNumber = plateNumber.split('-')[0];

      if (!plateNumber)
        return PlateMatch.NoPlate;

      const ants = plates?.Annotations;
      if (!ants)
        return PlateMatch.NoAnnotations;

      let matched = false;      
      for (let i = 0; i < ants.length; i++)  
        if (ants[i]?.PlateNumber?.value === plateNumber)
          return PlateMatch.Match;

      return PlateMatch.NoMatch;

    } catch (ex) {
      console.error('failed to gate plate status:', ex);
      return PlateMatch.None;
    }
  }



  preTaggedVehicles = (vehicles:any):boolean => {
    try {
      // WTT-251 - missing ?
      const ants = vehicles?.Annotations || [];
      ants.forEach((v:any) => {
        if (v?.rect?.status === 'pre-tagged')
        return true;

      if (v?.pose?.status === 'pre-tagged')
        return true;

      if (v?.plateIndices?.status === 'pre-tagged')
        return true;
      });
    
      return false;
    } catch (ex) {
      console.error('failed on preTaggedVehicles:', ex);
      return false;
    }
  }

  /**
   * update the specified record from info nel server
   * @param rec 
   */
  updateRecord = async (rec:any) => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_URL;
      const sp = common.plates.scanPolicy;
      const id = rec._id;
      const url = `${serverUrl}/items/${id}`;
      const details = await axios.get(url, {headers: {'Authorization': common.loginToken}});
      const plates = details.data?.image_library?.human?.plates || { Annotations: []};
      const bccm = details.data?.image_library?.human?.bccm;

      // traffic light annotations
      const lights = details.data?.image_library?.human?.trafficlight?.Annotations || [];
      // WTT-414
      const hazards = details.data?.image_library?.human?.hazardous?.Annotations || [];
      const reflectives = details.data?.image_library?.human?.reflective_bar?.Annotations || [];
      

      if (bccm && sp.scanStatus === "initialQuery")
        common.plates.filter.bccmFilter = true;

      const vehicles = details.data?.image_library?.human?.vehicle;
      if (vehicles && sp.scanStatus === "initialQuery")
        common.plates.filter.includesVehicles = true;
   
      // initialize record - plates might be missing.
      rec.vehicleTypes = [];
      rec.PreTagged = false;
      rec.vehiclePreTagged = this.preTaggedVehicles(vehicles);
      rec.status = "not-tagged";
      rec.state.problematic  = false;
      rec.TagCount =  0;
      this.sanitizePlate(plates);
      rec.PreTagged = false;
      rec.insides = details.data?.image_library?.human?.inside_inspection?.Annotations;
      rec.state.problematic = plates?.Problematic || false;
      rec.TagCount = plates?.Annotations?.length || 0;
      // WTT-392
      // rec.tagged = rec.TagCount > 0;
      rec.tagged = platesService.statistics.strictlyTagged(plates?.Annotations);
      rec.vehicleTypes = this.getVehicleTypes(plates);
      rec.occluded = this.isOccluded(plates);
      rec.chars = this.charsTagged(plates);
      if (rec.TagCount > 0) {
        rec.PreTagged = this.isPreTagged(plates.Annotations);
        // WTT-76 - display translated if exists, otherwise plate number
        var translated = plates.Annotations[0].PlateTextTranslated?.value;
        rec.plateNumber = translated || plates.Annotations[0].PlateNumber?.value;
      }
      rec.plateNumbers = plates.Annotations.map((a:any) => a.PlateNumber?.value);
      rec.plate = details.data?.image_library?.header?.S_PLATE;
      const vehiclesTags = vehicles || [];
      rec.bccm = !!bccm;
      rec.vehicles = vehiclesTags?.Annotations?.length > 0;
      rec.lights = lights;
      rec.hazards = hazards;
      rec.reflectives = reflectives;
      rec.status = this.getRowstatus(rec);
      rec.match = this.getPlateMatchStatus(details.data);
      this.updateRecOrder(rec, details.data);
    } catch (ex) {
      console.error('failed to update record:', ex);
    }
  }

  /**
   * 
   * @param rec 
   * @param details 
   * @returns 
   */
  updateRecOrder = (rec:any, details: any) => {
    try {
      const settings = common.plates.settings;
      if (!settings.orderBy) {
        rec.order = null;
        return;
      }

      rec.order = [undefined, undefined];
      rec.order[0] = this.getFieldValue(details, settings.orderField0);
      rec.order[1] = this.getFieldValue(details, settings.orderField1);
      

    } catch (ex) {
      console.error('failed to update rec order:', ex);
    }
  }

  getFieldValue = (data:any, field:string) => {
    try {
      if(!field) return undefined;
      const parts = field.split('.');
      var f = data;
      for (let i = 0; i < parts.length; i++) {
        f = f[parts[i]];
        if (f === undefined) return f;
        if (i == parts.length - 1)
          return f;
      }
    } catch (ex) {
      console.error('failed to get field value:', ex);
    }
  }

  /**
   * return a string which represents the row status based on the row properties
   * string is formatted tag+bccm+vehicles
   * @param rec 
   * @returns 
   */
  getRowstatus = (rec: any) : string => {
    try {
      if (!rec) throw(new Error("no record"));
      let status = 'not-tagged';
      if (rec.tagged)
        status = "tagged";
      if (rec.PreTagged)
        status="pre-tagged";
        
      status += rec.bccm ? "+bccm" : "+";

        // third token - vehicle tagging exist 
    const vehiclized = rec.vehicles;
    status += vehiclized ? '+vehicles' : '+';

    const insided = rec.insides?.length > 0;
    status += insided ? '+insides' : '+';

    const lighted = rec.lights?.length > 0;
    status += lighted ? '+lights': '+';

    const hazardous = rec.hazards?.length > 0;
    status += hazardous ? '+hazards' : '+';
   
    const chars = rec.chars;
    status += chars ? '+chars': '+';

    const reflective = rec.reflectives?.length > 0;
    status += reflective ? '+reflectives': '+';

      
      const vehicles = rec.vehiclePreTagged ? "+vehicles-preTagged" : rec.vehicles ? "+vehicles" : "+"; 
      status += vehicles;
      return status;
    } catch (ex) {
      console.error('failed to get row status:', ex);
      return '';
    }
  }















}
