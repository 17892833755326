import {  Subject } from 'rxjs';
import { AxlesData } from '../data/axlesData';
import { PlatesData } from '../data/platesData';
import { AppData, LogItem } from '../data/appData';
import { InsideData } from '../data/insideData';
import { DatasetsData,  } from '../data/datasetData';
import { VideoData } from '../data/videoData';
import { ScriptsData } from '../data/scriptsData';
import { RawData } from '../data/rawData';
import { AdminData } from '../data/adminData';
import { history } from "../configureStore";

const axios = require('axios');

export class MessageData {
  name: string | null;
  data: any;
  constructor() {
      this.name = null;
      this.data = null;
  }
}

class Subscriptions {
  plate: any;
  constructor() {
    this.plate = null;
  }
}

export class ValueEntry {
  name: string = '';
  value: string = '';
}

export class PropEntry {
  name: string = '';
}
export class QueryData {
  propNames: PropEntry[] = [];
  selectedPropName: PropEntry | null = null;
  values: ValueEntry[] = [];
  gridApi: any = null;
  gridApi2: any = null;
}

export class ConfirmData {
  title: string = '';
  text: string = '';
  exitNotification: string = '';
  reply: boolean | null = null;
}

/**
 * application wide services
 */
export class CommonService {
  public confirmData = new ConfirmData();
  public host: string;
  public rest: string;
  public pendingDataset: string | null;
  public selectedPath: string | null;
  public notifier$: Subject<MessageData> = new Subject();
  public selectedDirectory: string | null;
  public plates: PlatesData;
  public axles: AxlesData;
  public video: VideoData;
  public inside: InsideData;
  public datasets: DatasetsData;
  public changeLog: string | null;
  public subscriptions: Subscriptions;
  public app: AppData;
  public scripts: ScriptsData = new ScriptsData();  
  public raw: RawData = new RawData();
  public admin: AdminData = new AdminData();
  // main mode
  public mode: string = 'PLATES';
  pendingStack: any[] = [];

  // not active yet
  public loggedIn: boolean = false;
  public LoginFailed: boolean = false;
  public loginToken: string = '';
  public imageToken: string = '';
  public launchLogin: boolean = true;
  public automated: boolean = false;
  autoQuery: QueryData = new QueryData();


  // WTT-203
  // relogin context
  public relogin: boolean = false;
  // for testing !!
  public relogins: number = 0;
  public loginTime: number = 0;




  constructor() {
    this.host = '';
    this.rest = '';
    this.pendingDataset = null;
    this.selectedPath = null;
    this.selectedDirectory = null;
    this.plates = new PlatesData();
    this.axles = new AxlesData();
    this.video = new VideoData();
    this.inside = new InsideData();
    this.datasets = new DatasetsData();
    this.changeLog = null;
    this.subscriptions = new Subscriptions();
    this.app = new AppData();

    setInterval(() => {
      while(this.pendingStack.length > 0) {
        const pending = this.pendingStack.shift();
        this.notifier$.next(pending);
      }
    },10);
  }

  public notify2(name: string, data = null, pagename = null) {
    this.pendingStack.push({name, data});
  }  

  public notify(name: string, data = null, pagename = null) {
    switch (name) {
      case "LogFilterChanged":
        this.updateFilteredLogs();
        break;

      case 'Authenticate':
        this.login(data);
        break;

        case 'RelogIfExpired':
        this.relogIfTokenExpired(data);
        break;

      case 'LoadLastDataset':
        this.loadLastDataset();
        break;
      
      case 'CommonConfirmReply':
        this.handleConfirmReply(data as any);
        break;
  }
    this.notifier$.next({ data, name });
  }

  /**
   * Initialize
   */
  public initialize = () => {
    // this.getChangeLog()
    // .then(reply => reply.json())
    // .then( reply => {
    //   this.changeLog = JSON.stringify(reply, null, 2);
    //   this.notify("ChangeLogLoaded");
    // })
    // .catch (reason => {
    //   console.error('failed to get change log:', reason);
    // });
  }

  handleConfirmReply = async(reply:boolean) => {
    common.confirmData.reply = reply;
  }

  /**
   * add log to logger
   * @param args 
   */
  public addLog = (args: any) => {
    try {
      this.app.logs.addLog(args);
    } catch (ex) {
      // don't log
    }
  }

  /**
   * add error to logger
   * @param args 
   */
  public addError = (args: any) => {
    try {
      this.app.logs.addError(args);
      this.notify('ErrorCountChanged');
    } catch (ex) {
      // don't log
    }
  }

  /**
   * add warning to logger
   * @param args 
   */
  public addWarning = (args: any) => {
    try {
      this.app.logs.addWarning(args);
    } catch (ex) {
      // don't log
    }
  }

  /**
   * simple hash
   * @param s 
   * @returns 
   */
  public getHash = (s:string) => {
    try {
        var h = 0, l = s.length, i = 0;
        if ( l > 0 )
          while (i < l)
            h = (h << 5) - h + s.charCodeAt(i++) | 0;
        return h;
    } catch (ex) {
      console.error('failed to get hash');
      return 0;
    }
  }

    /**
   * get text doc
   * @returns 
   */
     getChangeLog = () => {
      try {
        return fetch(`changelog.json`);
      } catch (e) {
        console.error('failed to get changeLog:')
        return Promise.reject("failed to get plates progress");
      }
    };

    /**
     * determine wether a log item should be displayed
     * @param item 
     * @returns 
     */
    passLogFilter = (item: LogItem): boolean => {
      try {
        const logs = common.app.logs;
        switch (item.severity) {
          case 'Error': return logs.showErrors;
          case 'Warning': return logs.showWarnings;
          case 'Info': return logs.showInfos;
        }
        return false;
      } catch (ex) {
        return false;
      }
    }

    /**
     * login with specified credentials
     * @param credentials 
     */
    login = async (credentials: any) => {
      try {
        const authServer = process.env.REACT_APP_AUTH_SERVER;
        const reply = await axios.post(authServer, credentials);
        common.loggedIn = true;
        common.LoginFailed = false;
        common.loginToken = reply.data;
        // 04/02/2022 WTT-215
        common.imageToken = `user-authorization=${common.loginToken}`;
        if (common.relogin)
          common.notify('ReloggedIn');
        else {
          common.notify('LoggedIn');
          this.loadLastDataset();
        }
        // WTT-203 keep timestamp of last login
        common.loginTime = (new Date()).valueOf();
 

      } catch (ex) {
        // a message is displayed to the user, suppress error
        // console.error('failed on login:', ex);
        common.LoginFailed = true;
        common.loggedIn = false;
        common.notify('LoginFailedChanged');
      }
    }

    /**
     * checks if login token is still valid
     * @returns 
     */
    isLoggedIn = async() => {
      try {
        // WTT-203 - testing - expire after 10 seconds of inactivity
        // const dt = (new Date()).valueOf();
        // if(dt - common.loginTime > 10000)
        //   return false;

        let authServer = process.env.REACT_APP_AUTH_SERVER;
        authServer = authServer?.replace('/login','/token');
        const url = `${authServer}/${common.loginToken}`;
        const reply = await axios.get(url);
        return reply?.status === 200;
      } catch (ex) {
        return false;
      }
    }

    assureLogin = async () => {
      try {
        // avoid premature calls - this means user has logged in at least once
        if (!common.loggedIn) {
          console.error('oops');
          return true;
        }

        // debug - simulate login timeout
        
        var startTime = performance.now();
        const loggedIn = await this.isLoggedIn();
        if (loggedIn) {
          common.loginTime = (new Date()).valueOf();
          return true;
        }

        // otherwise - relogin !!

        // debug - timeout after 1 minute - replace code above
        // if ((new Date()).valueOf() - common.loginTime < 60000)
        //   return true;
        
        common.relogin = true;
        common.notify('LaunchLoginDialog');
        await this.waitForNotification('ReloggedIn');
        console.log('relogged in');
        

      } catch (ex) {
        console.error('failed to assure login:', ex);
      } finally {
        common.relogin = false;
      }
    }

    /**
     * update displayed logs after filter was modified
     */
    updateFilteredLogs = ()  => {
      common.app.logs.filteredLogs = common.app.logs.logs.filter(l => this.passLogFilter(l));
    }

    /**
     * at the moment, no distinct code for expired token
     * so anything is treated as expired
     * @param ex 
     * @returns 
     */
    relogIfTokenExpired = (ex:any) => {
      try {
        const code = ex?.response?.status || 0;
        if (code === 401)
        history.push('/login');
        return code === 401;
      } catch (ex) {
        console.error('failed to relog:', ex);
      }
  }

  delay = (n: number) => {
    return new Promise(function(resolve){
        setTimeout(resolve,n);
    });
  }

  loadLastDataset = async () => {
    try {
      const cachedDataset = common.plates.settings.datasetId;
      if (!cachedDataset)
        return;
        
        const itemId = common.plates.settings.datasetSelectedItemId;
        const typeId:string[] = cachedDataset.split(' ');
        const id = typeId[1];

      this.notify('PlatesLoadDataset', {id, entry: itemId} as any);
   
    } catch (ex) {
      console.error('failed to load last dataset:', ex);
    }
  }

  public confirm = async(title:string, text:string) => {
    try {
      common.confirmData.reply = null;
      common.notify('LaunchConfirmationDialog', {
        title: title,
        text: text,
        reply: null,
        exitNotification: 'CommonConfirmReply'
      } as any);

      return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (common.confirmData.reply !== null) {
          resolve(common.confirmData.reply);
          clearInterval(interval);
        }}, 100);
      });
    } catch (ex) {
      console.error('failed to confirm')
    }
  }

  alert = async (title: string, text: string) => {
    try {
      common.app.alert.title = title;
      common.app.alert.text = text;
      common.notify('LaunchAlert');
      return this.waitForNotification('AlertDismissed');
    } catch (ex) {
      console.error('failed on alert:', ex);
    }
  }

  assert = (value:any, message:string) => {
    if(!value)
      throw(new Error(message));
  }

  waitForNotification = async (trigger: string) => {
    try {
      return new Promise((resolve, reject) => {
        const subscription = this.notifier$.subscribe((msg) => {
          if (msg.name === trigger) {
            resolve(msg.data);
            subscription.unsubscribe();
          }
      });
      });

    } catch (ex) {
      console.error('failed on waitForNotification:', ex);
    }
  }

    /**
   * unselect all annotations
   */
  unselectAnnotations = () => {
      try {
        common.plates.selectedAnnotation = null;
        common.plates.selectedInside = undefined;
        common.plates.selectedLights = undefined;
        common.plates.selectedReflective = undefined;
        common.plates.selectedHazard = undefined;
        common.plates.selectedVehicle = undefined;
      } catch (ex) {
        console.error('failed to unselect:', ex);
      }
    }

  
/**
 * check for duplicate id's
 * @param ids 
 * @returns 
 */
validateImageIds = (ids: string[]) : boolean => {
  try {
    if (!ids)
      return false;

    const duplicates = [];
    const ids2:any = {};
    ids.forEach((id:string) => {
      if (ids2[id])  {
        duplicates.push(id);
      }
      else
        ids2[id] = id;
    });

    if (duplicates.length > 0) {
      common.addWarning(`dataset contains ${duplicates.length} duplicates`);
      return false;
    }
     
    return true;
  } catch (ex) {
    console.error('failed to validate image ids:', ex);
    return false;
  }
}


}

const common: CommonService = new CommonService();
export default common;