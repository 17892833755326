import React, {useState, useEffect} from 'react';
import {Box } from '@material-ui/core'

import 'react-reflex/styles.css';
import common from '../../services/commonService';
import { RecordList } from './recordList';
import { AxlesSplitter } from './axesSplitter';
import { Thumbnails } from './thumbnails';
import { AxlesDivider } from './axlesDivider';
import { useHotkeys } from 'react-hotkeys-hook';

/**
 * main plate annotation panel
 * @param props 
 * @returns 
 */
export function Axle(props: any) {

  useHotkeys('shift+1', () => common.notify('AxlesTagType', 0 as any));
  useHotkeys('shift+2', () =>  common.notify('AxlesTagType', 1 as any));

  const [value, setValue] = useState(0); // integer state
/**
 * general callback
 * @param arg 
 * @param value 
 */
const handleCallback = (arg: string, value: any) => {
  try {

    switch (arg) {
    }

  } catch (ex) {
    console.error('failed to handle callback: ', ex);
  }
}

/**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);


return <Box display="flex" flex="1" flexDirection="row"  >
  <Box display="flex" width={330}>
  <RecordList></RecordList>
  </Box>
  <Box display="flex" flex="1" flexDirection="column">
        <AxlesDivider></AxlesDivider>
  </Box>
</Box>
}