import React, { useEffect, useState} from 'react';
import { Box, Button, Tooltip, Tabs, Tab, Checkbox, FormControlLabel, IconButton } from "@material-ui/core";
import {SkipNext, SkipPrevious } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles";

// store hooks
import platesService from '../../services/platesService';
import common from '../../services/commonService';
import {SequenceProps} from './sequenceProps';
import { TagProps } from './tagProps';
import { VehicleClasses} from './classes';
import { AxleDetails } from './axleDetails';
import { maxHeaderSize } from 'http';


/**
 * Plate settings horizontal panel
 * @param props 
 * @returns 
 */
export function AxleTabs(props: any) {

  const [value, setValue] = useState(0); // integer state
  const [tabIndex, setTabIndex] = useState(0);

  /**
   * callbacks
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      const settings = common.plates.settings;
      switch (key) {

        case "AxlesSelectTab":
          // common.axles.selectedTab = value;
          // setValue(v => v + 1);
          // common.notify("AxlesSelectedTabChanged");          
          break;

        case "SelectedTab":
          // common.axles.selectedTab = value;
          // setValue(v => v + 1);
          // common.notify("AxlesSelectedTabChanged");
          break;

        case "AxlesThumbChanged":
        case "AxlesCanvasResized":
          setValue(v => v + 1);
          break;

        case "Next":
          common.notify('AxlesSaveNext');
          break;

        case "Cancel":
          common.notify('AxlesCancel');
          break;

        case "Clear":
          common.notify('AxlesClearTags');
          break;

        case "StitchMode":
          common.axles.overlapMode = value;
          setValue(v => v + 1);
          common.notify('AxlesStitchMode');
          break;

          case "NextImage":
            common.notify('AxlesNextImage');
            break;

          case "PreviousImage":
            common.notify('AxlesPreviousImage');
            break;
      }
    } catch (ex) {
      console.error('failed to handle plateSettings changes: ', ex);
    }
  }

  /**
   * determines if plate face exists for selected annotation
   * @returns 
   */
  const plateFacesExist = () => {
    try { 
      const ant = common.plates.selectedAnnotation;
      if (!ant) return false;
      const nation = ant?.Nation?.value;
      const region = ant?.Region?.value;
      const faces = common.plates.plateFaces;
      if (!region)
        return faces.some(pf => pf.nation === nation);

      return faces.some(pf => pf.nation === nation && pf.region === region);

    } catch (ex) {
      console.error('plateFacesExist error:', ex);
      return false;
    }
  }

  /**
   * should platefaces be disabled (no platefaces for selected annotation)
   * @returns 
   */
  

  /**
   * should keyboards be disabled
   * @returns 
   */


  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  },[]);

  const totalTags = common.axles.tags?.length;
  const tags = common.axles.tags;
  const settings = common.plates.settings;
  const height = common.axles.detailsHeight;
  const tagIndex = tags ? tags.indexOf(common.axles.tag) : -1;
  const imageIndex = common.axles.imageIndex;
  const totalImages = common.axles.thumbs?.length;
  const classes = useStyles();
  const w:number = props.width;
  const h:number = props.height;

  return <Box display="flex" flex="1" flexDirection="column" height={h} width={w}>
   {/* <Box display="flex" height="50px"  flexDirection="row" style={{}}>
  </Box>  */}

  <Box width={w} height={h} >
    <AxleDetails width={w} height={h}></AxleDetails>
  </Box>






  </Box>
}

const useStyles = makeStyles({
  radio: {
    margin: 0,
    padding: 0,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
  },
  combo: {
  },
  button: {
    margin: 4,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

  iconButton: {
    fintSize: 10,
    color: 'white',
    backgroundColor: 'black'
  }

});