
export class ScriptLogRow {
  time: number = 0;
  severity: string = 'info';
  message: string = '';
  id: number = 0;
}

export class ScriptLoop {
  count: number = 0;
  index: number = 0;
}

export class ScriptRow {
  request: string | null = null;
  arg: any = null;
  resolve: any = null;
  okReply: string | null = null;
  loop: any = null;
  children: ScriptRow[] = [];
  parent: ScriptRow | null = null;
  next: ScriptRow | null = null;
  direction: string = '';
}

export class ScriptFile {
  name: string  = '';
  rows: ScriptRow[]  = [];
  text: string = '';
}

export class ScriptFiles {
  header: any;
  scripts: ScriptFile[] = [];
}

export class ScriptLogs {
  logs: ScriptLogRow[] = [];
  gridApi: any = null;
}



export class ScriptsData {
  data: ScriptFiles | null = null;

  selectedScript: ScriptFile | null = null;
  text: string = '';
  progress: string = '';
  running: boolean = false;
  

  root: ScriptRow = new ScriptRow();
  tab: string = 'Code';
  logs: ScriptLogs = new ScriptLogs();
  

}