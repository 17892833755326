import React, {useState, useRef, useEffect, ChangeEvent} from 'react';
import { makeStyles } from "@material-ui/styles";
import Box from '@material-ui/core/Box'
import { Transits } from './transits';
import videoService from '../../services/videoService';
import common from '../../services/commonService';
import { VideoList } from './videoList';
import { Images } from './images';




export function Video(props: any) {

  const classes = useStyles();



  const loadDataset = () => {
    try {
      const path = "dummy";
      videoService.getDataset(path)
      .then (reply => reply.json())
      .then (reply => {
        common.video.records = reply.data.Transits;
        common.notify('VideoDatasetLoaded');
      })
      .catch (reason => {
        console.error(`failed to load video dataset: ${reason}`);
      });
    } catch (ex) {
      console.error('failed to load dataset:', ex); 
    }
  }

  const LoadTransit = (id: string) => {
    try {
      const datasetName = "dummy";
      videoService.getRecord(datasetName, id)
      .then( response => response.json())
      .then(response => {
        if (!response.data) {
          console.error('failed to get record:', response.message);
          return;
        }
        common.video.record = response.data;
        common.notify('VideoRecordLoaded');
      })
      .catch( reason => {
        console.error('failed to get record', reason);
      });

    } catch(ex) {
      console.error('failed to load record');
    }
  }


  const handleChanges = (arg: string, value: any) => {
    try {
      switch (arg) {
        case 'LoadTransit':
          LoadTransit(value as string);
          break;

        case 'VideoLoadRecord':
          LoadTransit(value as string);
          break;
      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  /**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleChanges(msg.name as string, msg.data);
  });
  loadDataset();

  return (() => {
    subscription.unsubscribe()});
}, []);


  return (

        <Box display="flex" flex="1" flexDirection="row"  height="100%" width="100%">
            <Box display="flex">
              <VideoList></VideoList>
            </Box>
            
            <Box display="flex" flex="1" flexDirection="column" >
              <Images></Images>
            </Box>
          </Box>

  );
}

const useStyles = makeStyles({
	paper: {
		width: "100%",
		height: "100%",
    display: "flex",
    flexDirection: "column",
    
  },

  main: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },

  canvasContainer: {
    display: "flex",
    backgroundColor: "gainsboro",
    padding: 10,
    overflow: "hidden",
  },

  bottomPanel: {
    display: "flex",
    height: 160,
    minHeight: 160,
    maxHeight: 160,
  },

  buffer: {
    display: "flex",
    flex: 1,
  },

  header: {
    height: 40,
    background: 'gainsboro',
  },

  canvas: {
    // position: 'relative',
    width: 1920,
    height: 1200,
    background: "gray",
  },

  combo: {
    margin: 10,
  },

  horizontal: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },

  column: {
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: 2,
    fontSize: 10,
    // backgroundColor: '#29367d',
    color: 'white',
    backgroundColor: 'black',
  },

  activeButton: {
    margin: 2,
    fontSize: 10,
    // backgroundColor: '#29367d',
    color: 'white',
    backgroundColor: 'dodgerBlue',
  },
  badge: {
    fontSize: 5,
  },


  

});