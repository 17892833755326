import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import {Box, Select, Button, MenuItem, Checkbox, RadioGroup, Radio,
  FormControlLabel, IconButton, Tooltip, Typography } from '@material-ui/core'
import { Check as CheckIcon, ErrorOutline as ErrorIcon } from '@material-ui/icons';

export function AuditPanel(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 


  const handleChanges = (key: string, value: any = null) => {
    try {
      switch(key) {
          case 'Passed':
              axles.auditStatus = 'passed';
              common.notify('AxlesSaveSequenceAndNext');
              break;

            case 'Failed':
                axles.auditStatus = 'failed';
                common.notify('AxlesSaveSequenceAndNext');
                break;

   
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);

  const classes = useStyles();
  const axles = common.axles;



  return <Box display="flex" flexDirection="column" flex="1" width="300px" minWidth="300px">
        <Box height="10px"/>

    <Box display="flex" flexDirection="row">
        <Box width="10px"/>
        <Button onClick={() => handleChanges('Passed')} style={{color:'green'}} startIcon={<CheckIcon/>} variant="contained">Passed</Button>
        <Box width="10px"/>
        <Button onClick={() => handleChanges('Failed')} style={{color:'red'}} startIcon={<ErrorIcon/>} variant="contained">Failed</Button>
    </Box>



    
</Box>



}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 9,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  combo: {
    width: 80,
  },
}));
