import common from './commonService';
import { ValueEntry} from './commonService';
import { history } from "../configureStore";



export class GatewayService {

  constructor() {
    common.notifier$.subscribe(msg => {
      const data = msg.data;
      switch (msg.name) {
        case "GatewayMessage":
          this.handleGatewayMessage(data as any);
          break;

        case "GatewayGetData":
          this.handleGetGatewayData(data as any);
          break;

        case 'BuildPropertyTree':
          this.buildPropertyTree();
          break;

        case 'QueryPropertySelected':
          this.generateQueryValues();
          break;

        case 'GatewayNavigate':
          this.navigate(data as string);
          break;
       
      }});

  }

  initialize = () => {
    try {
     
    } catch (ex) {
      console.error('failed to initialize query service:', ex);
    }
  }

  navigate = (path:string) => {
    try {
      history.push(path);
    } catch (ex) {
      console.error('failed to navigate:', ex);
    }
  }

  
  handleGatewayMessage = (data:string) => {
    try {
      console.assert(data, "no data");
      const data2 = JSON.parse(data);
      console.assert(data2, "failed to parse gateway data");
      console.assert(data2.key, "no key");
      common.notify(data2.key, data2.data);
    } catch (ex) {
      console.error('failed to handle gatewayMessage:', ex);
    }
  }

  getValue(o: any, s: string) {
    try {
      s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
      s = s.replace(/^\./, '');           // strip a leading dot
      var a = s.split('.');
      for (var i = 0, n = a.length; i < n; ++i) {
          var k = a[i];
          if (k in o) {
              o = o[k];
          } else {
              return;
          }
      }
      return o;
    } catch (ex) {
      console.error('failed to get value:', ex);
    }
  }

  handleGetGatewayData = (path: string) => {
    try {
      const value = this.getValue(common, path);
      const s = typeof value === 'string' ? value : JSON.stringify(value);
      common.notify('GatewayValueArrived', s as any);
    } catch (ex) {
      console.error('failed to get gateway data:', ex);
    }
  }

  buildPropertyTree = () => {
    try {
      const props:any[] = [];
      common.autoQuery.propNames = [];
      this.buildSubProps(common, 'common', props);
      common.autoQuery.propNames = props.map((p:string) => ({name: p.replace('common.', '')}));
      common.notify('AutoPropsChanged');

    } catch (ex) {
      console.error('failed to build property tree:', ex);
    }
  }



  buildSubProps = (o: any, path: string, props: string[]) => {
    try {
      

      const names = Object.getOwnPropertyNames(o);
      for (let i = 0; i < names.length; i++) {
        const name = names[i];
        if (name.endsWith('$'))
          continue;

        const sub = o[name];
        const type = typeof sub;
        switch (type) {
          case 'string':
          case 'number':
          case 'boolean':
            props.push(`${path}.${name}`);
            break;

          case 'object':
            if (Array.isArray(sub)) {
              props.push(`${path}.${name}[0]`);
              if(sub[0])
                this.buildSubProps(sub[0], `${path}.${name}[0]`, props);

              continue;
            } 

            props.push(`${path}.${name}`);
            if (sub)
              this.buildSubProps(sub, `${path}.${name}`, props);            
            break;

        }
      }


    } catch (ex) {
      console.error('failed to build property tree:', ex);
    }
  }

  getObjectValues = (o: object): ValueEntry[] => {
    try {
      if (!o) return [];
      const names = Object.getOwnPropertyNames(o);
      const vals = names.map(n => ({name:n, value: this.getValue(o,n)?.toString()}));
      return vals;
    } catch (ex) {
      console.error('failed to get object values:', ex);
      return [];
    }
  }

  generateQueryValues = () => {
    try {
      const values:ValueEntry[] = [];
      const path = common.autoQuery?.selectedPropName?.name;
      if (!path) return;
      const value = this.getValue(common, path);
      const tokens = path.split('.');
      const fieldName = tokens[tokens.length - 1];
      const type = typeof value;
      switch (type) {
        case "number":
        case "boolean":
        case "string":
          values.push({name: fieldName, value: value.toString()});
          break;

        case "object":
          values.push(...this.getObjectValues(value));
          break;
      }
      common.autoQuery.values = values;
      common.notify("QueryValuesChanged");

    } catch (ex) {
      console.error('gailed to generate query values: ',ex);
    }
  }

  
}
const gatewayService: GatewayService = new GatewayService();
export default gatewayService;