
export class VehicleBadges{
  vehicle: boolean = false;
  // details
  outline: boolean = false;
  windshield: boolean = false;
  phone: boolean = false;
  plate: boolean = false;
  seats: boolean[] = [];
  faces: boolean[] = [];
  belts: boolean[] = [];
  constructor() {
  }
}

interface Point {
  x: number;
  y: number;
}

export class Vehicle {
  windshield: Point[];
  seats: Point[][];
  phone: number[] | null;
  faces: (number[] | null)[];
  belts: Point[][];
  plate: number[] | null;
  outline: number[] | null;

  constructor(packed? : any) {
    this.seats = [[],[],[]];
    this.phone = null;
    this.faces = [null, null, null];
    this.belts = [[],[],[]];
    this.windshield = [];
    this.plate = null;
    this.outline = null;

    try {
      if (!packed)
        return;

      this.seats = (packed.seats as number[][][]).map(s => this.unpackArray(s));
      this.phone = packed.phone;
      this.faces = packed.faces;
      this.belts = (packed.belts as number[][][]).map(b => this.unpackArray(b));
      this.windshield = this.unpackArray(packed.windshield);
      this.plate = packed.plate;
      this.outline = packed.outline;
    } catch (ex) {
      console.error('failed to construct packed');
    }

   

  }

  unpack(a: number[]): Point {
    try {
      return {x: a[0], y: a[1]};
    } catch (ex) {
      console.error('failed to unpack');
      return {x: 0, y: 0};
    }
  }

  unpackArray(aa: number[][]): Point[] {
    try {
      return aa.map(a => this.unpack(a));
    } catch (ex) {
      console.error('failed to unpack array')
      return [];
    }
  }

  

  pack(pt: Point) {
    return [Math.round(pt.x), Math.round(pt.y)];
  }
  packedArray(pts: Point[]) {
    return pts.map(pt => this.pack(pt));
  }

  round(a: number[] | null) {
    if (!a)
      return null;

    return a.map(v => Math.round(v));
  }

  getPacked(): any {
    try {
      var packed = {} as any;
      packed.windshield = this.packedArray(this.windshield);
      packed.seats = this.seats.map(s => this.packedArray(s));
      packed.phone = this.round(this.phone);
      packed.faces = this.faces.map(f => this.round(f));
      packed.belts = this.belts.map(b => this.packedArray(b));
      packed.outline = this.round(this.outline);
      packed.plate = this.round(this.plate);
      return packed;

    } catch (ex) {
      console.error('failed to get packed vehicle:', ex);
    }
  }
}

export class VehicleData{
  badges: VehicleBadges = new VehicleBadges();
  vehicleType: string = '';
  occupancy: string[] = ["-", "-", "+"];
  vehicle: Vehicle;
  vehicles: Vehicle[];

  constructor() {
    this.vehicles = [new Vehicle(), new Vehicle(), new Vehicle()];
    this.vehicle = this.vehicles[0];

  }
}

export class InsideData {
  records: any[];
  datasetPath: string | null;
  totalRecords: number;
  taggedRecords: number;
  loadingDataset: boolean;
  loadingRecord: boolean;
  selectedRow: any;
  gridApi: any;
  mode: number;
  imageSize: number[];

  

  constructor() {
    this.records = [];
    this.datasetPath = null;
    this.totalRecords = 0;
    this.taggedRecords = 0;
    this.loadingDataset = false;
    this.loadingRecord = false;
    this.gridApi = null;
    this.selectedRow = null;
    this.mode = 0;
    this.imageSize = [];


  }

}