import React, {useState, useEffect} from 'react';
import  { FormControlLabel, Button, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { PlateEditor } from './plateEditor';
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';
import { BccmViewer } from './bccmViewer';
import { TagSelector } from './tagSelector';
import { VehicleEditor } from './vehicleEditor';
import { InsideEditor } from './insideEditor';
import { PassengerDialog } from './passengerDialog';
import { LightEditor } from './lightEditor';
import { HazardEditor } from './hazardEditor';
import { ReflectiveEditor } from './reflectiveEditor';

/**
 * editor for plate annotations - contains tag editors
 * handles most editing operations
 * @param props 
 * @returns 
 */
export function PlatesEditor(props: any) {

  const [value, setValue] = useState(0); // integer state
  const [platesWidth, setPlatesWidth] = useState(200);
  const [platesHeight, setPlatesHeight] = useState(200);
  const [passengerOpen, setPassengerOpen] = useState(false);

  /**
   * pass to next record
   */
  const handleOkNext = () => {
    try {
      // common.notify('OkNext', null);
      common.notify('PlatesSaveRecord', null);
    } catch (ex) {
      console.error('failed to handle okNext');
    }
  }

  /**
   * user pressed cancel - reload current record
   */
  const handleCancel = () => {
    try {
      const id = common.plates.selectedRow._id;
      if (!id) throw(new Error('no ID to cancel'));
      common.notify('PlatesCancelChanges', id);
    } catch (ex) {
      console.error('failed to handle okNext');
    }
  }

  /**
 * add a new annotation
 * @param ant 
 */
const addNewAnnotation = (ant: any) => {
  try {
    ant.Nation.value = common.plates.settings.defaultNation;
    ant.PlateNumber.value = '';
    ant.VehicleType.value = 2;
    ant.PlateType.value = 0;
    const annotations = common.plates.selectedRecord.Annotations;
    annotations.unshift(ant);

    // WTT-404 (note - expect a list in the future)
    ant.Bilingual.value = ant.Nation.value === 'OMN' ?  true : false;

    common.notify('AnnotationAdded');
  } catch (ex) {
    console.error('failed to add new annotation:', ex);
  }
}

/**
 * delete selected annotation
 * @returns 
 */
 const deleteAnnotation = (ant: any) => {
  try {
    if (!ant) throw('no annotation to delete');
    const annotations = common.plates.selectedRecord?.Annotations;
    const index = annotations.indexOf(ant);
    annotations.splice(index, 1);
    if (common.plates.selectedAnnotation === ant)
      common.plates.selectedAnnotation = null;
    common.notify('AnnotationDeleted');
  } catch (ex) {
    console.error('failed to delete selected annotation: ', ex);
  }
}

/**
 * WTT-356 PLATE-FACES ksa.
 * @param ant 
 * @returns 
 */
const getSelectedPlateFace = (ant: any) => {
  try {
    if (!ant) return;
    const nation = ant.Nation?.value;
    const region = ant.Region?.value;
    const cat = ant.Category?.value;
 

    const plateFaces = common.plates.plateFaces;

    // WTT-414 22/01/2024 
    const diamond = ant.PlateType?.value === 4;
    if (diamond) {
      return plateFaces.find(pf => pf.type === 'diamond' && pf.name === cat);
    }


    switch (nation) {

      // WTT-356 - nations with regions
      case "ARE":
        const catName = cat.split('.')[1] || '';
        const catRegion = cat.split('.')[0] || '';
        return plateFaces.find(pf => pf.nation === nation && pf.name === catName && pf.region === catRegion);

      // nations without regions 
      case "QAT":
      case "SAU":
        return plateFaces.find(pf => pf.nation === nation && pf.name === cat);

    }
  } catch (ex) {

  }
}

const handleHazardSelection = () => {
  try {
    const hazard = common.plates.selectedHazard;
    if (!hazard) return;

    const plateFaces = common.plates.plateFaces;
    plateFaces.forEach(pf => pf.selected = pf.id && pf.id === hazard.id);
    common.notify('SelectedPlateFaceChanged');
  } catch (ex) {
    console.error('failed to handle hazard selection:', ex);
  }
}



/**
 * an annotation has been selected (click on cutout image)
 * @returns 
 */
const handleAnnotationSelection = () => {
  try {
    // WTT-414
    if (common.plates.selectedHazard) 
      return handleHazardSelection();

    const ant = common.plates.selectedAnnotation;
    const plateFaces = common.plates.plateFaces;
    const cat = ant?.Category?.value;
    plateFaces.forEach(pf => pf.selected = false);

    if (!ant || !cat) 
      return;

    
    // ofer 18/12/2023 - QAT now adheres to convention - uphold
    // ofer, 18/12/2023 - QAT non segue questo convezione - 
    // elimina questo controllo
    // convention - region.name, or nazione.name
    const tokens = cat.split('.');
    if (tokens.length !== 2)
      return;
      
 

    // WTT-356 plateFaces ksa
    const selectedPlate = getSelectedPlateFace(ant);
    if (selectedPlate)
      selectedPlate.selected = true;

    common.notify('SelectedPlateFaceChanged');

    } catch (ex) {
      console.error('failed to handle annotation selection:', ex);
    } finally{
    common.notify('SelectedPlateChanged');
  }
}

/***
 * clear annotations
 */
const clearAnnotations = () => {
  try {
    var ants = common.plates.selectedRecord.Annotations;
    ants.splice(0,ants.length);
    common.plates.selectedAnnotation = null;
    common.notify('AnnotationDeleted');
  } catch (ex) {
    console.error('failed to clear annotations:', ex);
  }
}

  /**
   * common change handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      var record = common.plates.selectedRecord;
      switch (key) {

        case 'InsideLaunchPassengersDialog':
            setPassengerOpen(true);
            // wait for the canvas to be created
            setTimeout(() => common.notify('InsidePassengerOpened'), 100);
        break;

        case 'PassengerClosed':
          setPassengerOpen(false);
          break;

        case "PretaggingChanged":
        case "AnnotationDeleted":
          setValue(v => v + 1);
          break;

        case "Lighting":
          record.Lighting = parseInt(value);
          common.notify('AnnotationChanged');
          break;

        case "ColoredImage":
          record.ColoredImage = value;
          common.notify('AnnotationChanged');
          break;

        case 'Problematic': 
          record.Problematic = value;
          common.notify('AnnotationChanged');
          break;

        case "AnnotationChanged":
        case "PlateSettingsChanged":
        case "PlatesSelectedTabChanged":
          setValue(value => value + 1);
          break;


        case 'NewAnnotation':
          addNewAnnotation(value);
          common.plates.selectedAnnotation = value;
          common.notify('AnnotationSelected');
          setValue(value => value + 1);
          break;

        case 'DeleteAnnotation':
          deleteAnnotation(value as any);
          setValue(value => value + 1);
          break;

        case "ClearAnnotations":
          clearAnnotations();
          break;

          case 'AnnotationSelected':
            handleAnnotationSelection();
            setValue(value => value + 1);
            break;
  
          
        case 'InsideAdded':
        case 'InsideDeleted':
        case 'VehicleBoudingDeleted':
        case 'LightAdded':
        case 'LightDeleted':
        case 'HazardDeleted':
        case 'ReflectiveDeleted':
        case 'VehicleBoundingAdded':
        case "PlatesValidityChanged":
        case "PlatesRecordLoaded":
        case "TaggingStatusChanged":
        case "HazardAdded":
        case "ReflectAdded":
        case "PlatesTagTypeChanged2":

          setValue(value => value + 1);
          break;
    
      }
    } catch (ex) {
      console.error('failed to handle plateEditor changes: ', ex);
    }
  }

  /**
   * re
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  /**
   * render
   */
  const classes = useStyles();
  const record = common.plates.selectedRecord;
  const ants = common.plates.selectedRecord?.Annotations;
  const tooltips = common.plates.settings.tooltips;
  const bccm = common.plates.selectedRecord?.bccm;
  const vehicles = common.plates.vehicles || [];
  const insides = common.plates.insides || [];
  const lights = common.plates.lights || [];
  const hazards = common.plates.hazards || [];
  const reflectives = common.plates.reflectives || [];

  let first = common.plates.newTagType;

  return ( 



    <Box display="flex" flex={1} flexDirection="column" style={{ height:'100%'}}>
     <ResizeObserver onResize={(rect) => 
       { setPlatesHeight(Math.max(0, rect.height - 50));
         setPlatesWidth(Math.max(0, rect.width - 360)) }}>
       </ResizeObserver>
       <div style={{height: platesHeight, width: platesWidth, overflowX:'auto'}}>

{ record &&
         <Box display="flex" flexDirection="row">
           <Box display="flex" flexDirection="column" style={{margin:10}}>
           <Box display="flex" flexDirection="row" >
            <Box display="flex" flexDirection="column" >

            <b>Lighting</b>
         <RadioGroup aria-label="Lighting" name="Lighting" 
          value={record?.Lighting || 1}
          onChange={ e => handleChanges("Lighting", e.target.value)}  >
            <Tooltip title={tooltips ? 'Image is black':''} placement='left'>
          <FormControlLabel className={classes.formControl} value={1} control={<Radio className={classes.radio} />} 
          label={<Typography style={{fontSize: 14}}>Black</Typography>}/>
          </Tooltip>

          <Tooltip title={tooltips ? 'Details are partially visible': ''} placement='left'>
          <FormControlLabel className={classes.formControl}  value={2} control={<Radio  className={classes.radio} />} 
          label={<Typography style={{fontSize: 14}}>Partial</Typography>}/>
          </Tooltip>


        <Tooltip title={tooltips ? 'Details are visible': ''} placement='left'>
          <FormControlLabel className={classes.formControl}  value={3} control={<Radio  className={classes.radio} />} 
          label={<Typography style={{fontSize: 14}}>Visible</Typography>}/>
          </Tooltip>
          </RadioGroup>
            </Box>

            <Box display="flex" flexDirection="column" marginLeft={2}>
                 <b>Props</b>
       <Tooltip title={tooltips ? 'A color image': ''} placement='left'>
       <FormControlLabel className={classes.formControl} 
      control={<Checkbox checked={record?.ColoredImage || false}  className={classes.checkbox} 
      onChange={ e => handleChanges("ColoredImage", e.target.checked)}/>} 
      label={<Typography style={{fontSize: 14}}>Colored</Typography>}/>
      </Tooltip>

      <Tooltip title={tooltips ? 'Image is problematic': ''} placement='left'>
      <FormControlLabel className={classes.formControl} value={record?.Problematic || false} 
      control={<Checkbox checked={record?.Problematic || false}  className={classes.checkbox} 
      onChange={ e => handleChanges("Problematic", e.target.checked)} />} 
      label={<Typography style={{fontSize: 14}}>Problematic</Typography>} />
      </Tooltip>

            </Box>
            </Box>

  



          <Box flexDirection="row">
          <Tooltip title={tooltips ? 'Commit and move to next image': ''} placement='bottom'>
            <span>
              <Button className={classes.button} style={{marginTop: 5}} 
                disabled={!common.plates.annotationsAreValid}
                onClick={() => handleOkNext()} variant="contained">OK, next
              </Button>
            </span>
          </Tooltip>

       <Tooltip title={tooltips ? 'Reload image, cancel changes': ''} placement='bottom'>
          <Button className={classes.button} style={{marginTop: 5}} onClick={() => handleCancel()} variant="contained">Cancel</Button>
          </Tooltip>
         </Box>

         <Box flexDirection="row">
           { common.plates.annotationsAreValid === false && 
              <span>{common.plates.validationError}</span>
           }
         </Box>


           </Box>
    <Box display="flex" flexDirection="row">

    { (first === 'Ocr' || first === 'Chars') && record?.Annotations && Array.isArray(record.Annotations) &&  record.Annotations.map((ant: any, index: number) => 
        ( <PlateEditor ant={ant} index={index}></PlateEditor>)) 
      }

    { first === 'Lights' &&  lights.map((ant:any, index:number) => <LightEditor ant={ant} index={index}></LightEditor> )}
   
    { first === 'Hazard' &&  hazards.map((ant:any, index:number) => <HazardEditor ant={ant} index={index}></HazardEditor> )}

    { first === 'Reflective' &&  reflectives.map((ant:any, index:number) => <ReflectiveEditor ant={ant} index={index}></ReflectiveEditor> )}
  
    { first === 'Vehicle' &&  vehicles.map((ant: any, index:number) => <VehicleEditor ant={ant} index={index}/>)}
  
    { first === 'Inside' &&  insides.map((ant: any, index: number) => <InsideEditor ant={ant} index={index}></InsideEditor>)}

      { first !== 'Ocr' && first !== 'Chars' &&  record?.Annotations && Array.isArray(record.Annotations) && record.Annotations.map((ant: any, index: number) => 
        ( <PlateEditor ant={ant} index={index}></PlateEditor>)) 
      }

      { first !== 'Lights' &&  lights.map((ant:any, index:number) => <LightEditor ant={ant} index={index}></LightEditor> )}

      { first !== 'Hazard' &&  hazards.map((ant:any, index:number) => <HazardEditor ant={ant} index={index}></HazardEditor> )}

      { first !== 'Reflective' &&  reflectives.map((ant:any, index:number) => <ReflectiveEditor ant={ant} index={index}></ReflectiveEditor> )}


      { first !== 'Vehicle' &&  vehicles.map((ant: any, index:number) => <VehicleEditor ant={ant} index={index}/>)}

      { first !== 'Inside' &&  insides.map((ant: any, index: number) => <InsideEditor ant={ant} index={index}></InsideEditor>)}


      { bccm && 
        <Box display="flex" flex="1" flexDirection="column" border="blue solid 3px"  margin="4px" width="200px" maxWidth="200px">
        <b>BCCM {bccm.image_id}</b>

        <Box display="flex" flexDirection="row" alignItems="center" height="22px">
          <Checkbox checked={bccm.image_is_color === 'True'}></Checkbox>
          <Box display="inline">Color image</Box>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center" height="22px">
          <Checkbox checked={bccm.image_day_shot === 'True'}></Checkbox>
          <Box display="inline">Day shot</Box>
          </Box>
  
          <Box display="flex" flexDirection="row" alignItems="center" marginLeft="12px" marginTop="6px" >
          <Box display="inline">Rotation: <b>{bccm.image_inplane_rotation_angle}</b></Box>
           </Box>

           <Box display="flex" flexDirection="row" alignItems="center" marginLeft="12px" marginTop="6px" >
            <Box display="inline" >View: <b>{bccm.image_view}</b></Box>
           </Box>

           <Box display="flex" flex="1" flexDirection="column"></Box>

           <Box display="flex" flexDirection="row" margin="10px">
             <b>Check</b> - Manual tagging
            </Box>

          </Box>
      }

      { record?.bccm?.Annotations && record.bccm.Annotations.map((ant: any, index: number) => 
        <BccmViewer ant={ant} index={index}></BccmViewer>)
      }

   
    
    

    
    


    </Box>
    
    </Box>
}
       </div>
       <PassengerDialog open={passengerOpen} onClose={(e:any) => handleChanges('PassengerClosed',e)}></PassengerDialog>
    </Box>
  
  
  )

}

const useStyles = makeStyles({

  radio: {
    margin: 0,
    padding: 0,
    fontSize: 40,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
    fontSize:40,
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

});