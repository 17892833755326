import { LightItem, LightsBounding } from '../data/platesData';
import common from './commonService';


interface Point {
  x: number;
  y: number;
}

/**
 * traffic lights annotations services
 */
class LightsService {
    constructor() {
        common.notifier$.subscribe(msg => {
            switch(msg.name) {

                case 'PlatesDeleteLight':
                    this.deleteLight(msg.data);
                    break;

                case 'PlatesDeleteHazard':
                  this.deleteHazard(msg.data);
                  break;

                case 'CopyLightsGeometry':
                  this.copyLightsGeometry();
                  break;

                case 'PasteLightsGeometry':
                  this.pasteLightsGeometry();
                  break;


            }
        });
    }

    /**
     *   initialize
     */
    async initialize() {
        try {

        } catch (ex) {
            console.error('failed to initialize lights service:', ex);
        }
    }

    /**
     * returns native traffic light annotations structure
     * @param lights 
     * @returns 
     */
    getNativeLights(lights:LightsBounding[]) {
        try {
            return lights.map(l => this.getNativeLightAnnotation(l));
        } catch(ex) {
            console.error('failed to get native ligths:', ex);
            return [];
        }
    }

    /**
     * get native light annotation data
     * @param light 
     * @returns 
     */
    getNativeLightAnnotation(light: LightsBounding) {
        try {
            const nativeLightItems = light.lights.map(l => this.getNativeLightItem(l));
            return {rect: light.rect, lights: nativeLightItems};
        } catch(ex) {
            console.error('failed to get light annotation:', ex);
            return undefined;
        }
    }

    getNativeLightItem(l:LightItem) {
      try {
        return {rect: l.rect, type: l.type, status: l.status};
    } catch(ex) {
        console.error('failed to get light annotation:', ex);
        return undefined;
    }
    }

    /**
     * convert native traffic lights annotations to application data structures
     * @param nativeRecord 
     * @returns 
     */
    getLightsFromNative(nativeRecord:any) {
        try {
          let natives = nativeRecord?.image_library?.human?.trafficlight?.Annotations || [];
          const lights = natives.map((n:any) => this.getLightAnnotationFromNative(n));
          return lights;
        } catch (ex) {
          console.error('failed to get lights from native:', ex);
          return [];
        }
      }

      /**
       * get application lights structure from native data structure
       * @param n 
       * @returns 
       */
      getLightAnnotationFromNative(n:any) {
        try {
            const l = new LightsBounding();
            // spread to clone
            l.rect = [...n.rect];
            l.lights = n.lights.map((a:any) => ({rect: [...a.rect], type: a.type, status: a.status}));
            return l;
        } catch (ex) {
          console.error('failed to get lights from native:', ex);
          return [];
        }
      }

      /**
       *  generate a new default light structure for a given rect
       * @param rect 
       * @returns 
       */
      getLightFromRect(rect: number[]) {
        try {
            const l = new LightsBounding();
            l.rect = [...rect];
            l.lights = [
                {type:'red', rect:this.getSubRect(l.rect, 0), status:'off', hoverIndex: -1},
                {type:'yellow', rect:this.getSubRect(l.rect, 1), status:'off', hoverIndex: -1},
                {type:'green', rect:this.getSubRect(l.rect, 2), status:'off', hoverIndex: -1},
            ]
            return l;
        } catch (ex) {
            console.error('failed to get light from rect:', ex);
        }
      }

      /**
       * get initial subrects for a new lights (3 equally spaced)
       * @param r 
       * @param index 
       * @returns 
       */
      getSubRect(r:number[], index: number) {
        try {
            const verticalOffset = r[3] > r[2] ? 3 : 0;
            switch(index + verticalOffset) {
                case 0: return [r[0] + r[2]*0.04, r[1] + r[3]*0.04, r[2]*0.28, r[3]*0.92];
                case 1: return [r[0] + r[2]*0.36, r[1] + r[3]*0.04, r[2]*0.28, r[3]*0.92];
                case 2: return [r[0] + r[2]*0.68, r[1] + r[3]*0.04, r[2]*0.28, r[3]*0.92];
                case 3: return [r[0] + r[2]*0.04, r[1] + r[3]*0.04, r[2]*0.92, r[3]*0.28];
                case 4: return [r[0] + r[2]*0.04, r[1] + r[3]*0.36, r[2]*0.92, r[3]*0.28];
                case 5: return [r[0] + r[2]*0.04, r[1] + r[3]*0.68, r[2]*0.92, r[3]*0.28];
            }
            return [0,0,0,0];
        } catch (ex) {
            console.error('failed to get subRect:', ex);
            return [0,0,0,0];
        }
      }

          /**
     * delete the specified tagging
     * @param inside 
     * @returns 
     */
    deleteLight = (inside:any) => {
        try {
          const lights = common.plates?.lights || [];
          const index = lights.indexOf(inside);
          if (index < 0)
            return;
    
          lights.splice(index,1);
          common.notify('LightDeleted');
    
        } catch (ex) {
          console.error('failed to delete vehicle:', ex);
        }
      }

      deleteHazard = (hazard:any) => {
        try {
          const hazards = common.plates?.hazards || [];
          const index = hazards.indexOf(hazard);
          if (index < 0)
            return;
    
          hazards.splice(index,1);
          common.notify('HazardDeleted');
          common.plates.selectedHazard = undefined;
          common.notify('AnnotationSelected');
    
        } catch (ex) {
          console.error('failed to delete vehicle:', ex);
        }
      }

      /**
       *  returns 8 drag points of a rectangle
       * @param rect 
       * @returns 
       */
      getPointsEx(rect:number[] | null) {
        try {
          if (!rect || rect.length !== 4)
            return [];
    
            const l = rect[0];
            const t = rect[1];
            const w = rect[2];
            const h = rect[3];
            const top = {x:l+w/2, y:t};
            const right = {x:l+w, y:t+h/2 };
            const bottom = {x:l+w/2,y:t+h};
            const left = {x:l,y:t+h/2};
            const pts =  [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h},top, right, bottom, left];
            return pts;
        } catch (ex) {
          console.error("failed to get points: " + ex);
          return [];
        }
      }

      /**
       * calculate distance between two points
       * @param pt0 
       * @param pt1 
       * @returns 
       */
      getDistance = (pt0:Point, pt1: Point) => {
        try {
          const dx = pt0.x - pt1.x;
          const dy = pt0.y - pt1.y;
          return Math.sqrt(dx*dx + dy*dy);
        } catch (ex) {
          console.error('failed to get point: ', ex);
          return 0;
        }
      };

      /**
       * reset lights hover index
       */
      resetHoverIndex() {
        try {
          const lights = common.plates.lights || [];
          for (let l of lights) {
              l.hoverIndex = -1;
              for (let ll of l.lights)
                ll.hoverIndex = -1;
          }
        } catch (ex) {
          console.error('failed to reset hover index:', ex);
        }
      }

      /**
       * set hover index for the given mouse position
       * @param ptMouse 
       * @param radius 
       * @returns 
       */
      updateHoverIndex(ptMouse:any, radius: number) {
        try {
          this.resetHoverIndex();
          // only selected light can be resized
          const selectedLight = common.plates.selectedLights;
          if (!selectedLight)
            return false;

            const pts2 = this.getPointsEx(selectedLight.rect);
            selectedLight.hoverIndex = pts2?.findIndex(pt => this.getDistance(pt, ptMouse) < radius);
            if (selectedLight.hoverIndex > -1)
              return true;

            for (let ll of selectedLight.lights) {
              const pts3 = this.getPointsEx(ll.rect);
              ll.hoverIndex = pts3?.findIndex(pt => this.getDistance(pt, ptMouse) < radius);
              if (ll.hoverIndex > -1) {
                return true;                
              }
            }
     
        } catch (ex) {
          console.error('failed to update hover index:', ex);
          return false;
        }
      }

      /**
       *  determine wether hovering over lights
       */
      isHovering = (): boolean => {
        try {
          const lights = common.plates.lights;
          for (let l of lights) {
            if (l.hoverIndex > -1) return true;
            for (let ll of l.lights)
              if (ll.hoverIndex > -1) return true;
        }
        return false;
        } catch (ex) {
          console.error('failed on vehicle hovering:', ex);
          return false;
        }
      }

      /**
       * resize the given rect
       * @param r - rect to resize
       * @param hoverIndex - hover index
       * @param pt - mouse position
       */
      resizeRect(r:number[], hoverIndex:number, pt:any) {
        try {
          if (hoverIndex < 0) 
            return;

          const right = r[0] + r[2];
          const bottom = r[1] + r[3];
          let rect:number[] = [0,0,0,0];
          switch(hoverIndex) {
            case 0:
              rect = [pt.x, pt.y, right - pt.x, bottom - pt.y];
              break;
    
            case 1:
              rect = [r[0], pt.y, pt.x - r[0], bottom - pt.y];
              break;
    
            case 2:
              rect = [r[0], r[1], pt.x - r[0], pt.y - r[1]];
              break;
    
            case 3:
              rect = [pt.x, r[1], right - pt.x, pt.y - r[1]];
              break;
    
              // mid top
            case 4:
              rect = [r[0], pt.y, r[2], bottom - pt.y];
              break;
    
              // mid right
            case 5:
              rect = [r[0], r[1], pt.x - r[0], r[3]];
              break;
    
              // mid bottom
            case 6:
              rect = [r[0], r[1], r[2], pt.y - r[1]];
              break;
    
            case 7:
              rect = [pt.x, r[1], right - pt.x, r[3]];
              break;
          }
          for (let i = 0; i < 4; i++)
            r[i] = rect[i];

            return true;
        } catch (ex) {
          console.error('failed to resize rect:', ex);
        }
      }

      /**
       * resize a light element with the give drag point
       * @param pt 
       */
      handleResize = (pt: any) => {
        try {
          const lights = common.plates.lights || [];

          for (let l of lights) {
            if (l.hoverIndex > -1) {
              this.resizeRect(l.rect, l.hoverIndex, pt);
            }
            for (let ll of l.lights)
              this.resizeRect(ll.rect, ll.hoverIndex, pt);
          }
        } catch (ex) {
          console.error('failed to handle resize:', ex);
        }
      }

      async copyLightsGeometry() {
        try {
          const lights = common.plates.lights || [];
          if (lights.length === 0) {
            await common.alert('Traffic lights', 'Record does not contain traffic lights tagging');
            return;
          }
          common.plates.clipboardLights = JSON.parse(JSON.stringify(lights));
          // turn off all lights, reset hover index
          for (let l of common.plates.clipboardLights) {
            l.hoverIndex = -1;
            for (let ll of l.lights) {
              ll.status = 'off';
              ll.hoverIndex = -1;
            }
          }
          common.notify('clipboardLightsChanged');
        } catch (ex) {
          common.alert('Traffic lights', `failed to copy lights geometry:${ex}`);
        }
      }

      async pasteLightsGeometry() {
        try {
          const lights = common.plates.lights || [];
     
          if (lights.length > 0) {
            await common.alert('Traffic lights', 'Please delete existing taggings before pasting');
            return;
          }
          const clipboard = common.plates.clipboardLights || [];
          if (clipboard.length === 0) {
            await common.alert('Traffic lights', 'Clipboard is empty, please copy geometry from tagged record');
            return;
          }

          if (common.plates.newTagType !== 'Lights') {
            await common.alert('Traffic lights', 'Please switch to traffic light tagging before pasting');
            return;           
          }

          common.plates.lights = JSON.parse(JSON.stringify(common.plates.clipboardLights));
          common.notify('LightChanged');

        } catch (ex) {
          common.alert('Traffic lights', `failed to paste lights geometry:${ex}`);
        }
      }

      /**
       * 
       * @param original and current lights data
       */
      getOriginalAndCurrent(original:any) {
        try {
          const previous = original?.image_library?.human?.trafficlight?.Annotations || [];
          const current = this.getNativeLights(common.plates.lights);
          return {previous, current};
        } catch (ex) {
          console.error('failed to get original and current');
          return {previous:null, current:null};
        }
      }

      
    
}
const lightsService:LightsService = new LightsService();
export default lightsService;