import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { Theme } from "@material-ui/core/styles";
import { makeStyles, withStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import { Box,  List, ListItem,
    LinearProgress, CircularProgress, Typography, Tabs, Tab, Divider} from '@material-ui/core'
import { PieChart } from 'react-minimal-pie-chart';



const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

function CircularProgressWithLabel(props:any) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export function StatisticsPanel(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 
  const[ width, setWidth] = useState(0);
  const[ height, setHeight] = useState(0);



  const classes = useStyles();
  const handleCallback = (key: string, value: any) => {
    try {
      switch(key) {

        case 'StatisticsTabsChanged':
        case "DatasetRowSelected":
        case "StatisticPercentageChanged":
        case "StatisticsStateChanged":
        case "StatisticsSelectedTabChanged":
          setValue(v => v + 1);
          break;

        case 'StatisticType':
            common.datasets.selectedStatistic = value;
            // cache
            common.datasets.prevSelectedStatistics = value;
            setValue(v => v + 1);
            break;

       
        case "SelectedTab":
          common.datasets.selectedTab = value;
          const tabNames = getTabNames();
          // cache selected tab name for restore WTT-345
          common.datasets.selectedTabName = tabNames[value];
          setValue(v => v + 1);
          break;
   
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

      /**
     * this order has to be maintained
     */
       const getTabNames = ():string[] => {
        try {
          const ds = common.datasets;
          const tabs:string[] = [];
          if (ds.statTabOcr) tabs.push('OCR');
          if (ds.statTabAccuracy) tabs.push('Accuracy');
          if (ds.statTabBccm) tabs.push('Bccm');
          if (ds.statTabSeq) tabs.push('Sequence');
          if (ds.statTabAudit) tabs.push('Audit');
          if (ds.statTabVehicles) tabs.push('Vehicles');
          if (ds.statTabInside) tabs.push('Inside');
          if (ds.statTabCustom) tabs.push('Custom');
          return tabs;
        } catch (ex) {
          console.error('failed to get tab names:', ex);
          return [];
        }
      }
  

  const tabIsSelected = (name: string):boolean => {
    try {
      const index = common.datasets.selectedTab
      const ds = common.datasets;
      const tabs = getTabNames();
      return tabs[index] === name;
    } catch (ex) {
      console.error('failed on tabIsSelected:', ex);
      return false;
    }
  }

  const getTabCount = () => {
    try {
      const tabs = ["statTabOcr", "statTabAccuracy","statTabBccm","statTabSq","statTabAudit","statTabVehicles","statTabInside", "statTabCustom"];
      let count = 0;
      tabs.forEach(tab => { if ((ds as any)[tab]) count++ });
      return count;
    } catch (ex) {
      console.error('failed to get tab count:', ex);
      return 0;
    }
  }

  const getPercentage = (n: number, total: number): number => {
    try {
      if (total === 0)
        return 0;

      return Math.round(100 * n / total);
    } catch (ex) {
      console.error('failed to get percentage:', ex);
      return 0;
    }
  }

  const hackPercent = (n: number) => {
    try {
      return n === 1 ? 2 : n;
    } catch (ex) {
      console.error('failed to hack percent');
    }
  }




  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleCallback(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);

  const stats = common.plates.stats;
  const taggingBackgrounds:string[] = ["gray", "green", "orange", "red"]
  const selectedTab = common.datasets.selectedTab;
  const types = common.datasets.statisticTypes; 
  const selectedType = common.datasets.selectedStatistic;
  const ds = common.datasets;

  const driverPositions = stats.totalDriverPositions;
  const totalDrivers = driverPositions[0] + driverPositions[1];
  const leftDrivers = getPercentage(driverPositions[0], totalDrivers);
  const rightDrivers = getPercentage(driverPositions[1], totalDrivers);

  const labels = ds.statisticsLabels as any;
  const label = labels[selectedType || ''] || '';
  

  // 03/05/2022 VAL-259 avoid selected tab error
  const selectedTab2 = Math.min(selectedTab, getTabCount() - 1);

  const listWidth = "200px";
  const mainWidth = "500px";

  const mainHeight="400px";

  return <Box display="flex" flex={1} flexDirection="column" style={{ height:'100%'}}>
  

    <Box display="flex" flex="1" flexDirection="row">
        <Box display="flex" flexDirection="column" margin="4px">
            <Typography style={{fontWeight:'bold', margin:'4px'}}>Statistics</Typography>
        <List>
            {types.map(t => <ListItem selected={t === selectedType} style={{cursor:'pointer', height: '23px'}}
            onClick={() => handleCallback('StatisticType', t)}>{t}</ListItem>)}
        </List>

        </Box>
    
        <Divider orientation='vertical'/>

        <Box display="flex" flexDirection="column" flex="1" width={mainWidth} minWidth={mainWidth} maxWidth={mainWidth}>
          {selectedType && 
            <Typography style={{fontWeight:'bold', margin:'10px'}}>{label}</Typography>
          }

        <Box display="flex" flexDirection="column" flex="1" overflow="hidden" height={mainHeight} maxHeight={mainHeight} minHeight={mainHeight}>
            <Box display="flex" flexDirection="column" flex="1" overflow="auto">
                     
            {selectedType === 'Accuracy' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.accuracyPie.map( (tp,index) => (
                    <Box display="flex" flexDirection="row" alignItems="center"> 
                    <div style={{width:16, height:16, background:tp.color, marginLeft:10}}></div> 
                    &nbsp;{tp.title}
                    </Box>        
                    ))}
                    </Box>

                    <Box width="20px"/>
            
                    <Box width={160} style={{}}><PieChart  data= { stats.accuracyPie} /></Box>
                    </Box>
                </Box>
            }

            {selectedType === 'Tagging' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.taggingPie.map( (tp,index) => (
                        <Box display="flex" flexDirection="row" alignItems="center"> 
                        <div style={{width:16, height:16, background:taggingBackgrounds[index], marginLeft:10}}></div> 
                        &nbsp;{tp.title}
                    </Box>        
                        ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.taggingPie} /></Box>
                    </Box>
                </Box>
            }

            
            {selectedType === 'Plates' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.platesPie.map(vt => ( 
            <Box display="flex" flexDirection="row"> 
              <div style={{width:16,height:16, background:vt.color, marginLeft:10}}></div>   
              &nbsp;{vt.title}
            </Box>
            ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.platesPie} /></Box>
                    </Box>
                </Box>
            }

            {selectedType === 'Vehicles' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.vehiclesPie.map(vt => ( 
            <Box display="flex" flexDirection="row"> 
              <div style={{width:16,height:16, background:vt.color, marginLeft:10}}></div>   
              &nbsp;{vt.title}
            </Box>
            ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.vehiclesPie} /></Box>
                    </Box>
                </Box>
            }

            {selectedType === 'BCCM' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.bccmPie.map( (tp,index) => (
                        <Box display="flex" flexDirection="row" alignItems="center"> 
                        <div style={{width:16, height:16, background:tp.color, marginLeft:10}}></div> 
                        &nbsp;{tp.title}
                        </Box>        
                        ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.bccmPie} /></Box>
                    </Box>
                </Box>
            }

            {selectedType === 'Colors' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.colorsPie.map(vt => ( 
                    <Box display="flex" flexDirection="row"> 
                    <div style={{width:16,height:16, background:vt.color,  marginLeft:10}}></div>   
                    &nbsp;{vt.title}
                    </Box>
                    ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.colorsPie} /></Box>
                    </Box>
                </Box>
            }

            {selectedType === 'Makers' && <Box display="flex" flexDirection="column" flex="1" marginLeft="10px">
                {stats.makersPie.map((e:any) => ( <Box display="flex" flexDirection="row">
                <Box display="flex" width={stats.makersWidth} height="20px" margin="1px" style={{background:'gainsboro'}}>
                        <Box display="flex" width={`${e.percentage}px` } height="18px" style={{background:'black'}}></Box>
                        </Box>
                        &nbsp;{e.title}
                </Box>))}
                </Box>
            }
            
            {selectedType === 'Boxing' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.boxingPie.map( (tp,index) => (
                        <Box display="flex" flexDirection="row" alignItems="center"> 
                        <div style={{width:16, height:16, background:tp.color, marginLeft:10}}></div> 
                        &nbsp;{tp.title}
                        </Box>        
                        ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.boxingPie} /></Box>
                    </Box>
                </Box>
            }

          {selectedType === 'Chars' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.charsPie.map( (tp,index) => (
                        <Box display="flex" flexDirection="row" alignItems="center"> 
                        <div style={{width:16, height:16, background:tp.color, marginLeft:10}}></div> 
                        &nbsp;{tp.title}
                        </Box>        
                        ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.charsPie} /></Box>
                    </Box>
                </Box>
            }

          {selectedType === 'Splits' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.splitsPie.map( (tp,index) => (
                        <Box display="flex" flexDirection="row" alignItems="center"> 
                        <div style={{width:16, height:16, background:tp.color, marginLeft:10}}></div> 
                        &nbsp;{tp.title}
                        </Box>        
                        ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.splitsPie} /></Box>
                    </Box>
                </Box>
            }

            {selectedType === 'Audit' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.auditPie.map( (tp,index) => (
                    <Box display="flex" flexDirection="row" alignItems="center"> 
                    <div style={{width:16, height:16, background:tp.color, marginLeft:10}}></div> 
                    &nbsp;{tp.title}
                    </Box>        
                    ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.auditPie} /></Box>
                    </Box>
                </Box>
            }

            {selectedType === 'Inside seats' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.occupationPie.map( (tp,index) => (
                    <Box display="flex" flexDirection="row" alignItems="center"> 
                    {index > 0 &&  <Box display="flex" flexDirection="row" alignItems="center"> 
                    <div style={{width:16, height:16, background:tp.color, marginLeft:10}}></div> 
                    &nbsp;{tp.title}
                    </Box>
                    }
                    </Box>        
                    ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.occupationPie} /></Box>
                    </Box>
                </Box>
            }

            {selectedType === 'Inside features' && <Box display="flex" flexDirection="column" flex="1">
            <Box display="flex" flexDirection="column" margin={2}>

                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography style={{width:'100px'}}>Tagged:</Typography>&nbsp;
                    <LinearProgress style={{width:'50px', height:'20px'}} variant="determinate" value={stats.insideTaggedPercentage}></LinearProgress>&nbsp;
                    <Typography>{stats.insideTaggedPercentage}%</Typography>
                </Box>

                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography style={{width:'100px'}}>Belts:</Typography>&nbsp;
                    <LinearProgress style={{width:'50px', height:'20px'}} variant="determinate" value={stats.beltPie[1].percentage}></LinearProgress>&nbsp;
                    <Typography>{stats.beltPie[1].percentage}%</Typography>
                </Box>

                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography  style={{width:'100px'}}>Phones:</Typography>&nbsp;
                    <LinearProgress style={{width:'50px', height:'20px'}} variant="determinate" value={stats.phonePie[1].percentage}></LinearProgress>&nbsp;
                    <Typography>{stats.phonePie[1].percentage}%</Typography>
                </Box>

                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography  style={{width:'100px'}}>Left drivers:</Typography>&nbsp;
                    <LinearProgress style={{width:'50px', height:'20px'}} variant="determinate" value={leftDrivers}></LinearProgress>&nbsp;
                    <Typography>{leftDrivers}%</Typography>
                </Box>

                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography  style={{width:'100px'}}>Right drivers:</Typography>&nbsp;
                    <LinearProgress style={{width:'50px', height:'20px'}} variant="determinate" value={rightDrivers}></LinearProgress>&nbsp;
                    <Typography>{rightDrivers}%</Typography>
                </Box>

                </Box>
                </Box>

            }

            {selectedType === 'Axles' && <Box display="flex" flexDirection="column" flex="1" marginLeft="10px">
                { stats.axlesPie.map((vt:any, index:number) => ( 
                <Box display="flex" flexDirection="row"> 
                <Box width="20px" minWidth="20px">{index}</Box>
                <Box display="flex" width="100px" height="20px" margin="1px" style={{background:'gainsboro'}}>
                    <Box display="flex" width={`${vt.percentage}px` }  height="18px" style={{background:'black'}}></Box>
                </Box>
                &nbsp;{vt.title}
                </Box>
                ))}
                </Box>
            }

            {selectedType === 'Sequences' && <Box display="flex" flexDirection="column" flex="1">
                <Box display="flex" flexDirection="row">
                    <Box display="flex" flexDirection="column">
                    { stats.sequencePie.map( (tp,index) => (
                    <Box display="flex" flexDirection="row" alignItems="center"> 
                    <div style={{width:16, height:16, background:tp.color, marginLeft:10}}></div> 
                    &nbsp;{tp.title}
                    </Box>        
                    ))}
                    </Box>
                    <Box width="20px"/>
                    <Box width={160} style={{}}><PieChart  data= { stats.sequencePie} /></Box>
                    </Box>
                </Box>
            }


            {selectedType === 'Nations' && <Box display="flex" flexDirection="column" flex="1" marginLeft="10px">
            { stats.nations.map((vt:any, index:number) => ( 
              <Box display="flex" flexDirection="row" marginRight="4px"> 
                <Box display="flex" width="100px" height="20px" margin="1px" style={{background:'gainsboro'}}>
                  <Box display="flex" width={hackPercent(vt.percentage) || 0} height="18px" style={{background:'black'}}></Box>
                </Box>
                &nbsp;{vt.title}
              </Box>
              ))}
                </Box>
            }

            {selectedType === 'Years' && <Box display="flex" flexDirection="column" flex="1" marginLeft="10px">
            { stats.years.map((vt:any, index:number) => ( 
              <Box display="flex" flexDirection="row" marginRight="4px"> 
                <Box display="flex" width="100px" height="20px" margin="1px" style={{background:'gainsboro'}}>
                  <Box display="flex" width={hackPercent(vt.percentage) || 0} height="18px" style={{background:'black'}}></Box>
                </Box>
                &nbsp;{vt.title}
              </Box>
              ))}
                </Box>
            }

      {selectedType === 'Hazards' && <Box display="flex" flexDirection="column" flex="1" marginLeft="10px">
            { stats.hazards.map((vt:any, index:number) => ( 
              <Box display="flex" flexDirection="row" marginRight="4px"> 
                <Box display="flex" width="100px" height="20px" margin="1px" style={{background:'gainsboro'}}>
                  <Box display="flex" width={hackPercent(vt.percentage) || 0} height="18px" style={{background:'black'}}></Box>
                </Box>
                &nbsp;{vt.title}
              </Box>
              ))} </Box>
            }


      {selectedType === 'Lights' && <Box display="flex" flexDirection="column" flex="1">
          <Box display="flex" flexDirection="row">
              <Box display="flex" flexDirection="column">
              { stats.lightsPie.map( (tp,index) => (
              <Box display="flex" flexDirection="row" alignItems="center"> 
              <div style={{width:16, height:16, background:tp.color, marginLeft:10}}></div> 
              &nbsp;{tp.title}
              </Box>        
              ))}
              </Box>


              <Box width="20px"/>
              <Box width={160} style={{}}><PieChart  data= { stats.lightsPie} /></Box>
              </Box>
          </Box>
}

</Box>
</Box>
</Box>
</Box>
</Box>

}

const tabHeight = '24px' 
const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
  },
  formControl: {
    margin: 0,
    padding: 0,
  },
  tabsRoot: {
    minHeight: tabHeight,
    height: tabHeight
  },
  tabRoot: {
    minHeight: tabHeight,
    height: tabHeight

  },
}));
