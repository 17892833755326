import { HazardData } from '../data/platesData';
import common from './commonService';

interface Point {
  x: number;
  y: number;
}

export class CharsService {


    /**
     * standard constructor
     */
    constructor() {
        common.notifier$.subscribe(msg => {
            switch(msg.name) {
                case 'AnnotationSelected':
                  this.resetIndex();
                  break;

                case 'CharsReset':
                  this.resetCharsPolygons();
                  break;
                  
                  case 'SelectedCharIndexChanged':
                  this.handleCharSelection(msg.data);
                  break;

                case 'CharsCancel':
                  this.handleCancel();
                  break;

            }
        })
    }

    /**
     * standard initializer
     */
    async initialize() {
        try {

        } catch (ex) {
            console.error('failed to initialize diamonds:', ex);
        }
    }

    resetIndex() {
      try {
        common.plates.selectedCharIndex = -1;
      } catch (ex) {
        console.error('failed to reset index');
      }
    }

    /**
     *   Initialize chars annotations
     * @returns 
     */
    handleCharSelection(index:number) {
        try {
            // if(!common.plates.settings.tagChars)
            //   return;
       
            // // initialize selected annotation to cover changes
            // // and creation of new annotation
            if (this.needsInitializing())
              this.generateDefaultPolygons();

            common.plates.selectedCharIndex = index;
            const ant = common.plates.selectedAnnotation;
            delete ant.CharsPoly.value[index].pristine;

            common.notify('CharsSelected');
    
        } catch (ex) {
            console.error('failed to initialize annotation:', ex);
        }
    }

    resetCharsPolygons() {
      try {

        const ant = common.plates.selectedAnnotation;
        if (!ant) return;
        ant.CharsPoly = {status:'tagged', value: []};
      } catch (ex) {
        console.error('Failed to reset chars polygons:', ex);
      }
    }

    /**
     * created default polygons
     */
    generateDefaultPolygons() {
      try {
        const ant = common.plates.selectedAnnotation;
        ant.CharsPoly = {status:'tagged', value: []};

        // WTT-424 - do not sanitize internal poly - needs to be fixed before proceeding!!
        const ip = ant.InternalPoly.value;
        const w = ip[1] - ip[3];
        const h = ip[2] - ip[0];
        common.assert(w > 0 && h > 0, "Internal poly error");
  
        const plateNumber = ant.PlateNumber.value;
        common.assert(plateNumber, "No plate number");

        const ambiguous = ant.ExtraType.value === 2;
  
        const totalChars = plateNumber.length;
        const charWidth = w / totalChars;
  
        const val = [];
        for (let i = 0; i < totalChars; i++) {
          // top, right, bottom left, as internal poly
          const poly = [ip[0], ip[3] + (i+1)*charWidth, ip[2], ip[3] + i*charWidth];
          // no pristine for question mark
           if (plateNumber[i] === '?' || ambiguous)
            val.push({poly, "text": plateNumber[i]});
          else 
            val.push({poly, "text": plateNumber[i], pristine:true});
        }
        ant.CharsPoly.value = val;
        common.notify('CharsPolyChanged');

      } catch (ex) {
        console.error('failed to initialize char polygons');
      }
    }

    

    generateCharsData() {
      try {
        const ant = common.plates.selectedAnnotation;
        ant.CharsPoly = {status:'tagged', value: []};

        // WTT-424 - do not sanitize internal poly - needs to be fixed
        const ip = ant.InternalPoly.value;
        const w = ip[1] - ip[3];
        const h = ip[2] - ip[0];
        common.assert(w > 0 && h > 0, "Internal poly error");
  
        const plateNumber = ant.PlateNumber.value;
        common.assert(plateNumber, "No plate number");
  
        const totalChars = plateNumber.length;
        const charWidth = w / totalChars;
  
        const val = [];
        for (let i = 0; i < totalChars; i++) {
          // top, right, bottom left, as internal poly
          const poly = [ip[0], ip[3] + (i+1)*charWidth, ip[2], ip[3] + i*charWidth];
          // leave room for other attributes
          const pristine = plateNumber[i] !== '?';
          val.push({poly, "text": plateNumber[i], pristine});
        }
        ant.CharsPoly.value = val;
        common.notify('CharsPolyChanged');

      } catch (ex) {
        console.error('failed to initialize char polygons');
      }
    }

    /**
     *   wether charsPoly needs initializing (platenumber does not correspond to chars)
     * @returns 
     */
    needsInitializing() {
      try {
        const ant = common.plates.selectedAnnotation;
        if (!ant) return false;

        const plateNumber = ant.PlateNumber?.value;
        if (!plateNumber) return false;

        if (!ant.CharsPoly)
          return true;

          if (ant.CharsPoly?.value.length !== plateNumber.length)
            return true;

          for (let i = 0; i < plateNumber.length; i++) 
            if (plateNumber[i] !== ant.CharsPoly.value[i].text)
              return true;

          return false;


      } catch (ex) {
          console.error('failed on needsInitializing');
      }
    }

    selectCharByPoint(pt:Point) {
      try {

        const ant = common.plates.selectedAnnotation;
        if (!ant || !ant.CharsPoly) return;

        const chars = ant.CharsPoly.value;
        for (let i = 0; i < chars.length; i++) {

          const poly = chars[i].poly;
          const segments = this.getCharSegments(ant, poly);
          if (!segments) return;
          const p = [segments[0][0], segments[0][1], segments[2][0], segments[2][1]] as Point[];
          if (this.inside(pt,p)) {
            common.notify('CharsSelectIndex', i as any);
          }
        }
      } catch(ex) {
        console.error('failed to select char by point:', ex);
      }
    }

      /**
       *   interface - hovering over chars
       * @returns 
       */
      isHovering = (): boolean => {
        try {
          return common.plates.charHoverIndex > -1;
        } catch (ex) {
          console.error('failed on vehicle hovering:', ex);
          return false;
        }
      }

      /**
       *  get distance between two points
       * @param pt0 
       * @param pt1 
       * @returns 
       */
      getDistance = (pt0:Point, pt1: Point) => {
        try {
          const dx = pt0.x - pt1.x;
          const dy = pt0.y - pt1.y;
          return Math.sqrt(dx*dx + dy*dy);
        } catch (ex) {
          console.error('failed to get point: ', ex);
          return 0;
        }
      };

      /**
       *  sets the charHoverIndex as a result of mouse move
       * @param ptMouse 
       * @param radius 
       * @returns 
       */
      updateHoverIndex(ptMouse:any, radius: number) {
        try {

            common.plates.charHoverIndex = -1;
            const ant = common.plates.selectedAnnotation;
            if(!ant?.CharsPoly)  return false;

            const charIndex = common.plates.selectedCharIndex;
            if (charIndex < 0) return false;

            const poly = ant.CharsPoly.value[charIndex]?.poly;
            if (!poly) return;
            const segments = this.getCharSegments(ant, poly);
            if (!segments) return;

            common.plates.charHoverIndex = segments.findIndex((sg:any) => this.getSegmentDistance(ptMouse, sg[0], sg[1]) < radius);
            if (common.plates.charHoverIndex > -1)
                return true;
        } catch (ex) {
          console.error('failed to update hover index:', ex);
          return false;
        }
      }

      /**
       *   forward to internal implementation
       * @param pt 
       * @returns 
       */
      handleResize = (pt: any) => {
        try {
          return this.handleSegmentResize(pt);
        } catch (ex) {
          console.error('failed to handle resize:', ex);
        }
      }

      /**
       *   get distance from segment to points
       *   NOTE - uses ends
       * @param pt 
       * @param pt1 
       * @param pt2 
       * @returns 
       */
      getSegmentDistance(pt: Point, pt1: Point, pt2: Point) {
        try {
          var A = pt.x - pt1.x;
          var B = pt.y - pt1.y;
          var C = pt2.x - pt1.x;
          var D = pt2.y - pt1.y;
        
          var dot = A * C + B * D;
          var len_sq = C * C + D * D;
          var param = -1;
          if (len_sq != 0) //in case of 0 length line
              param = dot / len_sq;
        
          var xx, yy;
        
          if (param < 0) {
            xx = pt1.x;
            yy = pt1.y;
          }
          else if (param > 1) {
            xx = pt2.x;
            yy = pt2.y;
          }
          else {
            xx = pt1.x + param * C;
            yy = pt1.y + param * D;
          }
        
          var dx = pt.x - xx;
          var dy = pt.y - yy;
          return Math.sqrt(dx * dx + dy * dy);
    
        } catch (ex) {
          console.error('failed to get distance from segment:', ex);
          return -1;
        }
      }

      /**
       * produce segments from annotation information
       * @param tag 
       * @param ip 
       * @returns 
       */
      getCharSegments = (tag:any, ip: number[]) => {
        try  {
    
          if (!tag?.Poly?.value)
            return [];
    
          const pts = this.polyToPoints(tag.Poly.value) as Point[];
          const pt0 = this.getMidPoint(pts[0], pts[3], ip[0]);
          const pt1 = this.getMidPoint(pts[1], pts[2], ip[0]);
          const pt2 = this.getMidPoint(pts[0], pts[1], ip[1]);
          const pt3 = this.getMidPoint(pts[3], pts[2], ip[1]);
          const pt4 = this.getMidPoint(pts[0], pts[3], ip[2]);
          const pt5 = this.getMidPoint(pts[1], pts[2], ip[2]);
          const pt6 = this.getMidPoint(pts[0], pts[1], ip[3]);
          const pt7 = this.getMidPoint(pts[3], pts[2], ip[3]);
    
          const topRight = this.getIntersection(pt0, pt1, pt2, pt3);
          const bottomRight = this.getIntersection(pt2, pt3, pt4, pt5);
          const bottomLeft = this.getIntersection(pt4, pt5, pt6, pt7);
          const topLeft = this.getIntersection(pt6, pt7, pt0, pt1);
    
          // keep original order of top, right, bottom, left
          const poly = [[topLeft, topRight], [topRight, bottomRight], [bottomRight, bottomLeft], [bottomLeft, topLeft]];
          return poly;
        } catch (ex) {
          console.error('failed to get internal segments:', ex);
        }
      }

      /**
       * point inside polygon
       * @param pt 
       * @param vs 
       * @returns 
       */
      inside(pt:Point, vs:Point[]) {
        var x = pt.x, y = pt.y;
        var inside = false;
        for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
            var xi = vs[i].x, yi = vs[i].y;
            var xj = vs[j].x, yj = vs[j].y;
            
            var intersect = ((yi > y) != (yj > y))
                && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) inside = !inside;
        }
        
        return inside;
    };

      /**
       * returns a point of intersection of two segments
       * @param pt0 
       * @param pt1 
       * @param pt2 
       * @param pt3 
       * @returns 
       */
      getIntersection(pt0:any, pt1:any, pt2:any, pt3:any)
      {
        const x1 = pt0.x;
        const y1 = pt0.y;
        const x2 = pt1.x;
        const y2 = pt1.y;
        const x3 = pt2.x;
        const y3 = pt2.y;
        const x4 = pt3.x;
        const y4 = pt3.y;

        var ua, ub, denom = (y4 - y3)*(x2 - x1) - (x4 - x3)*(y2 - y1);
        if (denom == 0) {
            return null;
        }
        ua = ((x4 - x3)*(y1 - y3) - (y4 - y3)*(x1 - x3))/denom;
        ub = ((x2 - x1)*(y1 - y3) - (y2 - y1)*(x1 - x3))/denom;
        return {x: x1 + ua * (x2 - x1), y: y1 + ua * (y2 - y1) };
}

    /**
     * get percentaged point
     * @param pt0 
     * @param pt1 
     * @param percentage 
     * @returns 
     */
    getMidPoint = (pt0: Point, pt1: Point, percentage: number): Point | null => {
      try {
        const x = pt0.x + percentage * (pt1.x - pt0.x);
        const y = pt0.y + percentage * (pt1.y - pt0.y);
        return {x, y};
      } catch (ex) {
        console.error('failed to get midPoint:', ex);
        return null;
      }
    }

      /**
       * return the poly points from a comma delimited string
       * @param poly 
       * @returns 
       */
      polyToPoints = (poly: string): Point[] | null => {
        try {
          if(!poly)
            return [];
    
          const tokens = poly.split(',');
          const ints = tokens.map(t => parseInt(t));
          const pts = [];
          for (let i = 0; i < ints.length / 2; i++)
            pts.push({x: ints[i*2], y: ints[i*2+1]});

          
          return pts;
        } catch (ex) {
          console.error('failed on polyToPoints:', ex);
          return null;
        }
      }

      /**
       *   handle resize: search a matching segment which intersects pt
       *   and write it on the hoverIndex position
       * @param pt 
       * @returns 
       */
      handleSegmentResize = (pt: any) => {
        try {

          const ant = common.plates.selectedAnnotation;
          const charIndex = common.plates.selectedCharIndex;
          const ch = ant.CharsPoly.value[charIndex];
          const poly = ch.poly;
          const pts = this.polyToPoints(ant.Poly?.value) as Point[];
          if (!pts) throw new Error('failed on polyToPoints');

          const hoverIndex = common.plates.charHoverIndex;
    
          const indexToVertices = [[0,3,1,2], [3,2,0,1],[0,3,1,2], [3,2,0,1]]
          const verticeIndices = indexToVertices[hoverIndex];
          const vertices = verticeIndices.map(i => pts[i]);
    
          // iterative procedure for generating new segment pos
          let low = 0;
          let high = 1;
          for (let i = 0; i < 20; i++) {
            const f = (low + high) / 2;
            const seg = this.getMidSegment(vertices, f);
            if (!seg || !seg[0] || !seg[1]) throw new Error('failed to get seg');
            const d = this.getSignedDistance(pt, seg[0], seg[1]);
            if (Math.abs(d) < 1)
              break;
    
            if (d > 0) 
              high = f;
            else
              low = f;
          }

          // calculated value
          poly[hoverIndex] = (low + high)/2;
          common.notify('TaggingStatusChanged');
          return true;
        } catch (ex) {
          console.error('failed to handle resize:', ex);
        }
      }

      /**
       * returns a percentaged segment
       * @param pts 
       * @param percentage 
       * @returns 
       */
      getMidSegment = (pts: Point[], percentage: number): Point[] | null => {
        try {
          const pt0 = this.getMidPoint(pts[0], pts[1], percentage);
          const pt1 = this.getMidPoint(pts[2], pts[3], percentage);
          if (!pt0 || !pt1) throw('failed to get midpoints');
          return [pt0, pt1];
        } catch (ex) {
          return null;
        }
      }

      /**
       *   get the signed distance (positive, negative)
       * @param pt0 
       * @param pt1 
       * @param pt2 
       * @returns 
       */
      getSignedDistance = (pt0: Point, pt1: Point, pt2: Point) => {
        try  {
          var d = this.getDistance(pt1, pt2);
          var dx = (pt2.x - pt1.x)*(pt1.y - pt0.y) - (pt1.x - pt0.x)*(pt2.y - pt1.y);
          return dx / d;
        } catch (ex) {
          console.error('failed to get signed distance');
          return -1;
        }
      }

      handleCancel() {
        try {
          const ant = common.plates.selectedAnnotation;
          common.assert(ant, "no selected record");
          delete ant.CharsPoly;
          this.resetIndex();
          common.notify('CharsPolyChanged');
          common.notify('CharsCancelled');
        } catch (ex) {
          console.error('failed to handle cancel:', ex);
        }
      }


}
const charsService:CharsService = new CharsService();
export default charsService;