import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { TextField, Box, Button } from "@material-ui/core";
import { Person as PersonIcon, Warning as WarningIcon } from '@material-ui/icons';
import * as React from "react";
import  common  from '../services/commonService';

export function LoginPage(props: any) {
  const classes = useStyles();

  const [username, setUsername] = React.useState('');
  const [password, setPassword ] = React.useState('');
  const [value, setValue] = React.useState(0); // integer state

  const origin = props?.location?.state?.from?.pathname || '/plates';

  const handleCallbacks = (key: string, data: any) => {
    try {
      switch(key) {
        case "Login":
          common.notify('Authenticate', {username, password, origin} as any)
          break;

        case 'LoginFailedChanged':
          setValue(v => v + 1);
          break;

        case 'PasswordKeyDown':
          if (username && password && data.keyCode === 13) {
            common.notify('Authenticate', {username, password, origin} as any)
          }
          break;

              }
    } catch (ex) {
      console.error('failed to handle callbacks:', ex);
    }

  }



  React.useEffect(() => {
 
    const subscription = common.notifier$.subscribe(msg => {
      handleCallbacks(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);
  

	return (
	
    <Box display="flex" flexDirection="column" flex="1" >
</Box>


	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  textField: {
		width: "200px",
		margin: "6px",
	},
  button: {
    margin: "2px",
    marginTop: '10px',
    color: 'white',
    height:'25px',
    width: '50px',
    fontSize: '11px',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

}));
