import React, {useState, useEffect } from 'react';
import {Box, Button, Checkbox, Dialog, DialogTitle, FormControlLabel, Select, MenuItem, Typography, Tooltip, TextField } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import common from '../services/commonService';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import copy from 'copy-to-clipboard';
import { useHistory } from "react-router-dom";



export function SettingsDialog (props:any) {
  const { onClose, open } = props;

  const [value, setValue] = useState(0); 
  const classes = useStyles();

  const [defaultNation, setDefaultNation] = useState('');
  const [defaultRegion, setDefaultRegion] = useState('');
  const [showLanes, setShowLanes] = useState(false);
  const [showPlate, setShowPlate] = useState(false);
  const [filterFaces, setFilterFaces] = useState(false);
  const [arabicChars, setArabicChars] = useState(false);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState('');

  const [tagOcr, setTagOcr] = useState(false);
  const [tagVehicle, setTagVehicle] = useState(false);
  const [tagInside, setTagInside] = useState(false);
  const [tagLights, setTagLights] = useState(false);
  const [tagAxle, setTagAxle] = useState(false);
  const [tagCab, setTagCab] = useState(false);
  const[ tagHazard, setTagHazard] = useState(false);
  const [tagChars, setTagChars] = useState(false);
  const [tagReflective, setTagReflective]= useState(false);

  const [audit, setAudit] = useState(false);

  const [orderBy, setOrderBy] = useState(false);
  const [orderField0, setOrderField0] = useState('');
  const [orderField1, setOrderField1] = useState('');
  
  


  const initialize = () => {
    try {
      const settings = common.plates.settings;
      setDefaultNation(settings.defaultNation as string);
      setShowLanes(settings.showLanes);
      setShowPlate(settings.showPlate);
      setFilterFaces(settings.filterFaces);
      setArabicChars(settings.arabicChars);
      updateRegions(settings.defaultNation as string);
      setRegion(settings.region);

      setTagInside(settings.tagInside);
      setTagLights(settings.tagLights);
      setTagOcr(settings.tagOcr);
      setTagVehicle(settings.tagVehicle);
      setTagHazard(settings.tagHazard);
      setTagChars(settings.tagChars);
      setTagAxle(settings.tagAxle);
      setTagCab(settings.tagCab);
      setAudit(settings.auditMode);
      setTagReflective(settings.tagReflective);

      // WTT-361
      setOrderBy(settings.orderBy);
      setOrderField0(settings.orderField0);
      setOrderField1(settings.orderField1);



    } catch (ex) {
      console.error('failed to initialize:', ex);
    }
  }


  const saveSettings = () => {
    try {
      const settings = common.plates.settings;
      settings.defaultNation = defaultNation;
      settings.showLanes = showLanes;
      settings.filterFaces = filterFaces;
      settings.arabicChars = arabicChars;
      settings.region = regions.length === 0 ? '' : region;


      settings.tagInside = tagInside;
      settings.tagLights = tagLights;
      settings.tagOcr = tagOcr;
      settings.tagVehicle = tagVehicle;
      settings.tagHazard = tagHazard;
      settings.tagReflective = tagReflective;
      settings.tagChars = tagChars;
      settings.tagAxle = tagAxle;
      settings.tagCab = tagCab;
      settings.showPlate = showPlate;
      settings.auditMode = audit;

      // detect change in order - 
      const orderChanged = settings.orderBy !== orderBy ||
      settings.orderField0 !== orderField0 ||
      settings.orderField1 !== orderField1;
      common.app.orderChanged = orderChanged;

      settings.orderBy = orderBy;
      settings.orderField0 = orderField0;
      settings.orderField1 = orderField1;

      common.notify('SaveSettings');
      common.notify('PlateSettingsChanged');
      common.notify('AxlesSettingsChanged');

    } catch (ex) {
      console.error('failed to save settings:', )
    }
  }

  const updateRegions = (nation: string) => {
    try {
      const nationRegs = common.plates.regions.find(r => r.nation === nation);
      const regs = nationRegs && nationRegs.regions ? nationRegs.regions : [];
      setRegions(regs);

    } catch (ex) {
      console.error('failed to update regions:', ex);
    }
  }

  const handleChanges = (key: string, value: any ) => {
    try {
      switch(key) {
        case 'SettingsDialogOpened':
          initialize();
          break;

        case 'Nation':
          setDefaultNation(value);
          updateRegions(value);
          if (value !== 'THA' && tagOcr)
            setShowPlate(false);
          break;

        case 'Lanes':
          setShowLanes(value);
          break;

        case 'Filter':
          setFilterFaces(value);
          break;

        case 'Region':
          setRegion(value);
          break;

        case "tagOcr":
          setTagOcr(value);
          if (value && defaultNation !== 'THA') {
            setShowPlate(false);
          }
          if (value) setAudit(false);
          break;
      
        case "tagVehicle":
          setTagVehicle(value);
          if (value) setAudit(false);
          break;

        case "tagAxle":
          setTagAxle(value);
          if (value) setAudit(false);
          break;

        case "tagVehicleSeq":
          setTagCab(value);
          if (value) setAudit(false);
          break;  
          
        case "Plate":
          setShowPlate(value);
          break;

        case 'tagInside':
          setTagInside(value);
          if (value) setAudit(false);
          break;

        case 'tagLights':
          setTagLights(value);
          if (value) setAudit(false);
          break;

        case 'tagHazard':
          setTagHazard(value);
          if (value) setAudit(false);
          break;

        case 'tagReflective':
          setTagReflective(value);
          if (value) setAudit(false);
          break;

          case 'tagChars':
            setTagChars(value);
            if (value) setAudit(false);
            break;

        case 'Audit':
          setAudit(value);
          if (value) {
            setShowPlate(true);
            setTagOcr(false);
            setTagHazard(false);
            setTagChars(false);
            setTagVehicle(false);
            setTagCab(false);
            setTagInside(false);
            setTagAxle(false);
          }
          break;

          case 'OrderBy':
            setOrderBy(value);
            break;

          case 'OrderField0':
            setOrderField0(value);
            break;

          case 'OrderField1':
            setOrderField1(value);
            break;
          
      }
    } catch (ex) {
      console.error('failed on handle changes:', ex);
    }

  }



  const handleClose = async (apply: boolean) => {
    try {
      if (orderBy && !orderField0) {
        await common.alert('Plate order', 'Please specify order field');
        return;
      }

      if (apply)
      saveSettings();

    onClose();
    } catch (ex) {
      console.error('failed to handle close:', ex);
    }
  };

  const showPlateDisabled = () => {
    try {
      return tagOcr && defaultNation !== 'THA';
    } catch (ex) {
      console.error('failed on show plate disabled:', ex);
      return false;
    }
  }







  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  
  }, []);
  

  return (
    <Dialog onClose={handleClose} fullWidth={true}
    aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="Application logger">
        <Typography variant="h5">Application settings</Typography>
      </DialogTitle>


      <Box display="flex" flex="1" flexDirection="column" style={{height:'200px', minHeight: '200px'}} >

        <Box display="flex" flex="1" flexDirection="column" marginLeft={4}>


        <Box display="flex" flexDirection="column" >


          <Box display="flex" flexDirection="row">

            <Box display="flex" flexDirection="column">
            <label >Default nation:</label>
              <Select className={classes.combo} 
                        value={defaultNation } 
                        onChange={e =>handleChanges("Nation", e.target.value)}>
                            {common.plates.nations?.map((pt:any) => ( <MenuItem value={pt}>{pt}</MenuItem>))}
              </Select>


              <Box display={regions.length === 0 ? 'none' : 'flex'} flexDirection="column">
            <label >Default region:</label>
              <Select className={classes.combo} 
                        value={region || '' } 
                        onChange={e =>handleChanges("Region", e.target.value)}>
                            {regions?.map((pt:any) => ( <MenuItem value={pt}>{pt}</MenuItem>))}
              </Select>
            </Box>


            </Box>

            <Box display="flex" width={70}></Box>
            <Box display="flex" flexDirection="column">
              <label><b>Annotation types:</b></label>

              <FormControlLabel className={classes.formControl}
              control={<Checkbox checked={tagOcr || false}  className={classes.checkbox} 
              onChange={ e => handleChanges("tagOcr", e.target.checked)}/>} label="OCR" />

              <FormControlLabel className={classes.formControl} 
              control={<Checkbox checked={tagChars || false}  className={classes.checkbox} 
              onChange={ e => handleChanges("tagChars", e.target.checked)}/>} label="Chars" />

            <FormControlLabel className={classes.formControl} 
              control={<Checkbox checked={tagVehicle || false}  className={classes.checkbox} 
              onChange={ e => handleChanges("tagVehicle", e.target.checked)}/>} label="Vehicle" />

            <FormControlLabel className={classes.formControl} 
              control={<Checkbox checked={tagInside || false}  className={classes.checkbox} 
              onChange={ e => handleChanges("tagInside", e.target.checked)}/>} label="Inside inspection" />

            <FormControlLabel className={classes.formControl} 
              control={<Checkbox checked={tagLights || false}  className={classes.checkbox} 
              onChange={ e => handleChanges("tagLights", e.target.checked)}/>} label="TrafficLights" />

              <FormControlLabel className={classes.formControl} 
              control={<Checkbox checked={tagHazard || false}  className={classes.checkbox} 
              onChange={ e => handleChanges("tagHazard", e.target.checked)}/>} label="Hazardous" />

              <FormControlLabel className={classes.formControl} 
              control={<Checkbox checked={tagReflective || false}  className={classes.checkbox} 
              onChange={ e => handleChanges("tagReflective", e.target.checked)}/>} label="Reflective" />
        

   
            <label>Axle counter:</label>

            <FormControlLabel className={classes.formControl} 
              control={<Checkbox checked={tagAxle || false}  className={classes.checkbox} 
              onChange={ e => handleChanges("tagAxle", e.target.checked)}/>} label="Axle" />

              <FormControlLabel className={classes.formControl} 
              control={<Checkbox checked={tagCab || false}  className={classes.checkbox} 
              onChange={ e => handleChanges("tagVehicleSeq", e.target.checked)}/>} label="Vehicle" /> 

            </Box>
            <Box display="flex" width={10}></Box>
          </Box>
 

    <Box display="flex" height={10}></Box>



    <Box display="flex" flexDirection="row" style={{margin:5}}>
    <Tooltip title={tagOcr ? "Not available when tagging OCR" : ""}>
    <FormControlLabel className={classes.formControl} 
      control={<Checkbox checked={audit || false}  className={classes.checkbox} 
      onChange={ e => handleChanges("Audit", e.target.checked)}/>} label="Audit mode" />
      </Tooltip>
  </Box>


    <Box display="flex" flexDirection="row" style={{margin:5}}>
    <Tooltip title={tagOcr ? "Not available when tagging OCR" : ""}>
    <FormControlLabel className={classes.formControl} 
      control={<Checkbox disabled={showPlateDisabled()} checked={showPlate || false}  className={classes.checkbox} 
      onChange={ e => handleChanges("Plate", e.target.checked)}/>} label="Show plate" />
      </Tooltip>
  </Box>


    <Box display="flex" flexDirection="row" style={{margin:5}}>
    <FormControlLabel className={classes.formControl} 
      control={<Checkbox checked={showLanes || false}  className={classes.checkbox} 
      onChange={ e => handleChanges("Lanes", e.target.checked)}/>} label="Show lanes" />
  </Box>

  <Box display="flex" flexDirection="row" style={{margin:5}}>
    <FormControlLabel className={classes.formControl} 
      control={<Checkbox checked={filterFaces || false}  className={classes.checkbox} 
      onChange={ e => handleChanges("Filter", e.target.checked)}/>} label="Plate face filter" />
  </Box>

  <Box display="flex" flexDirection="row" style={{margin:5}} alignItems="center">
    <Tooltip title={ "Order dataset items" }>
      <FormControlLabel className={classes.formControl} 
      control={<Checkbox checked={orderBy || false}  className={classes.checkbox} 
      onChange={ e => handleChanges("OrderBy", e.target.checked)}/>} label="Order by" />
    </Tooltip>
    {orderBy && <Box display="flex" flexDirection="row" alignItems="center">
    <Box width="4px"/>
    <Typography>Field:</Typography>
    <Box width="4px"/>
    <TextField style={{width:'300px'}} value={orderField0} onChange={(e) => handleChanges('OrderField0', e.target.value)}></TextField>
    
      </Box>}
    </Box>
  <Box display="flex" flexDirection="row" style={{margin:5}} alignItems="Center">
    {orderBy && <Box display="flex" flexDirection="row" alignItems="center">
    <Box width="30px"/>
    <Typography>And (Optional):</Typography>
    <Box width="4px"/>
    <TextField style={{width:'300px'}} value={orderField1} onChange={(e) => handleChanges('OrderField1', e.target.value)}></TextField>
       
      </Box>}
 
  </Box>








  </Box>
        </Box>

        <Box display="flex"  flexDirection="row" justifyContent="flex-end">
          <Button  className={classes.button}
            style={{margin:4,fontSize:10}} variant="contained" onClick={() => handleClose(true)}>OK
          </Button>

          <Button  className={classes.button}
            style={{margin:4,fontSize:10}} variant="contained" onClick={() => handleClose(false)}>Cancel
          </Button>
        </Box>
      </Box>

    </Dialog>
  );
}

SettingsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },


  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
  },
  combo: {
    width:200

  }
}));