class ArabicConverter {

        // std::map<std::string, std::regex> regex_expressions_egy = {
        //         { "moto1 AAANNNN", std::regex("...\\d\\d\\d\\d")},
        //         { "moto2 AAANNN", std::regex("...\\d\\d\\d")},
        //         { "moto3 AANNNN", std::regex("..\\d\\d\\d\\d")},
        // };  


        motoRegs = [ /...\d\d\d\d/,/...\d\d\d/, /..\d\d\d\d/ ];
    constructor() {

    }

    add_non_joiner(c:string, c1: string) {
        try {
            if (!c1) return false;
            if (/\d+$/.test(c1)) return false;
            if (!/[a-zA-Z]/.test(c)) return false;
            return true;

        } catch (ex) {
            console.error("Failed on add_non_joiner");
            return false;
        }
    }

    

    asciiToEgyChar(string_ascii:string, src_index: number) {
        try {
            const c = string_ascii[src_index];
            const c1 = string_ascii[src_index+1];
            let len_output = 0;
            switch(c) {
                case 'I':
                case 'J':
                case 'O':
                case 'P':
                case 'Q':
                case 'U':
                case 'V':
                case 'X':
                case 'Z':
                        len_output = 1; break;
                default:
                        len_output = 2; break;
            }
            len_output += this.add_non_joiner(c,c1) ? 3 : 0;

            let string_utf8:number[] = [0,0];
            let j = 0;

            let is_ascii = false;

            switch(c) {
                case '0':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA0;
            break;
            
            case '1':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA1;
            break;

            case '2':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA2;
            break;

            case '3':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA3;
            break;

            case '4':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA4;
            break;

            case '5':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA5;
            break;

            case '6':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA6;
            break;

            case '7':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA7;
            break;

            case '8':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA8;
            break;

            case '9':
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0xA9;
            break;

            case 'A': // Alef With Hamza above
                    string_utf8[j++] = 0xD8;
                    string_utf8[j++] = 0xA3;       // 0xA7 in PR
            break;

            case 'B': // Beh (U+0628)
                    string_utf8[j++] = 0xD8;
                    string_utf8[j++] = 0xA8;
            break;

            case 'C': // Sad (U+0635)
                    string_utf8[j++] = 0xD8;
                    string_utf8[j++] = 0xB5;
            break;

            case 'D': // Dal (U+062F)
                    string_utf8[j++] = 0xD8;
                    string_utf8[j++] = 0xAF;
            break;

            case 'E': // Ain (U+0639)
                    string_utf8[j++] = 0xD8;
                    string_utf8[j++] = 0xB9;
            break;

            case 'F': // Feh (U+0641)
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0x81;
            break;

            case 'G': // Jeem (U+062C)
                    string_utf8[j++] = 0xD8;
                    string_utf8[j++] = 0xAC;
            break;

            case 'H': // Heh (U+06BE)
                    string_utf8[j++] = 0xDA;
                    string_utf8[j++] = 0xBE;
            break;

            case 'K': // Qaf (U+0642)
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0x82;
            break;

            case 'L': // Lam (U+0644)
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0x84;
            break;

            case 'M': // Meem (U+0645)
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0x85;
            break;

            case 'N': // Noon (U+0646)
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0x86;
            break;

            case 'R': // Reh (U+0631)
                    string_utf8[j++] = 0xD8;
                    string_utf8[j++] = 0xB1;
            break;

            case 'S': // Seen (U+0633)
                    string_utf8[j++] = 0xD8;
                    string_utf8[j++] = 0xB3;
            break;

            case 'T': // Tah (U+0637)
                    string_utf8[j++] = 0xD8;
                    string_utf8[j++] = 0xB7;
            break;

            case 'W': // Waw (U+0648)
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0x88;
            break;

            case 'Y': // Alef Maksura (U+0649)
                    string_utf8[j++] = 0xD9;
                    string_utf8[j++] = 0x89;
            break;

            default:
                    is_ascii = true;
                    // carattere ASCII
                    // string_utf8[j++] = c;
            break;
            }

            

            var a = string_utf8[0] & 0x1F;
            var b = string_utf8[1] & 0x3F;
            var unicode = 64 * a + b;
            let final = is_ascii ? c : String.fromCharCode(unicode);
            if (this.add_non_joiner(c, c1)) {
                final += '\u200C';// 0xE2,0x80,0x8C;
            }
            return final;
        } catch(ex) {
            console.error('failed asciiToEgyChar:', ex);
        }
    }

    reorder_plate_if_moto(plate:string) {
        try {
        const pos = plate.search(/\d/);
        if (pos < 0) return plate;
        let digitsFirst = plate.substring(pos);
        digitsFirst += plate.substring(0, pos);
        return digitsFirst;
        } catch (ex) {
                console.error('failed to reorder');
                return '';
        }
    }


    /**
     * 
     * @param latin 
     * @returns 
     */
    apply_plate_changes_based_on_format_egy(latin:string) {
        try {
                for (let i = 0; i < this.motoRegs.length; i++) {
                        const reg = this.motoRegs[i];
                    if (latin.match(reg)) {
                        return this.reorder_plate_if_moto(latin);
                    }    
                }
                return latin;

        } catch (ex) {
                console.error('failed to apply plate change:', ex);
                return '';
        }
    }

    isAlpha = (ch:any) =>{
        if (ch >= "A" && ch <= "z"){
            return true
        }
        return false;
    }

    isDigit = (ch:any) =>{
        if (ch >= "0" && ch <= "9"){
            return true
        }
        return false;
    }

    convert_to_utf8(latin: string) {
        try {
                // WTT-371 - 18/04/2023
                if (!latin)
                        return '';

                var utf8 = '';
                utf8 += '\u202D';
                utf8 += this.asciiToEgyChar(latin, 0);
                for (let i = 1; i < latin.length; i++) {
                        var ch0 = latin.charAt(i-1);
                        var ch = latin.charAt(i);

                        var newGroup = (!this.isAlpha(ch0) && this.isAlpha(ch)) || (!this.isDigit(ch0) && this.isDigit(ch));
                        if (newGroup) {
                                utf8 += '\u202C';
                                utf8 += '\u202D';
                        }
                        utf8 += this.asciiToEgyChar(latin, i);
                }
                utf8 += '\u202C';
                return utf8;
        } catch (ex) {
                console.error("failed to convert to utf8");
        }
    }

    /**
     * convert latin to arabic (egy)
     * @param latin 
     * @returns 
     */
    latinToEgyptian(latin:string) {
        try {
            
            latin = this.apply_plate_changes_based_on_format_egy(latin);
            var arabic = this.convert_to_utf8(latin);
            return arabic;
        } catch(ex) {
            console.error("Failed on latin to arabic:", ex);
            return null;
        }
    }


}

const arabicConverter:ArabicConverter = new ArabicConverter();
export default arabicConverter;



// int map_ascii_char_to_utf8_egitto(const char *string_ascii, size_t src_index, unsigned char *string_utf8,
//     size_t string_utf8_dim, size_t *dst_index)
// {
// size_t j = *dst_index;
// char c = string_ascii[src_index];

// // Se sto elaborando una lettera e non sono in fondo alla stringa aggiungo
// // il carattere di controllo ZERO WIDTH NON-JOINER (U+200C --> 0xE2808C)
// int add_non_joiner =  (string_ascii[src_index + 1] != '\0' && c >= 'A' && c <= 'Z' && !isdigit(string_ascii[src_index + 1]));
// size_t len_output;

// // Calculate required destination space
// switch(c)
// {
// //Characters not expected in an Egyptian plates are converted as-is
// case 'I':
// case 'J':
// case 'O':
// case 'P':
// case 'Q':
// case 'U':
// case 'V':
// case 'X':
// case 'Z':
// len_output = 1; break;
// default:
// len_output = 2; break;
// }
// len_output += add_non_joiner ? 3 : 0;

// // Make sure there is enough space in the destination
// if(j + len_output > string_utf8_dim)
// return -1;

// switch(c)
// {
// case '0':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA0;
// break;

// case '1':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA1;
// break;

// case '2':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA2;
// break;

// case '3':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA3;
// break;

// case '4':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA4;
// break;

// case '5':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA5;
// break;

// case '6':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA6;
// break;

// case '7':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA7;
// break;

// case '8':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA8;
// break;

// case '9':
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0xA9;
// break;

// case 'A': // Alef With Hamza above
// string_utf8[j++] = 0xD8;
// string_utf8[j++] = 0xA3;       // 0xA7 in PR
// break;

// case 'B': // Beh (U+0628)
// string_utf8[j++] = 0xD8;
// string_utf8[j++] = 0xA8;
// break;

// case 'C': // Sad (U+0635)
// string_utf8[j++] = 0xD8;
// string_utf8[j++] = 0xB5;
// break;

// case 'D': // Dal (U+062F)
// string_utf8[j++] = 0xD8;
// string_utf8[j++] = 0xAF;
// break;

// case 'E': // Ain (U+0639)
// string_utf8[j++] = 0xD8;
// string_utf8[j++] = 0xB9;
// break;

// case 'F': // Feh (U+0641)
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0x81;
// break;

// case 'G': // Jeem (U+062C)
// string_utf8[j++] = 0xD8;
// string_utf8[j++] = 0xAC;
// break;

// case 'H': // Heh (U+06BE)
// string_utf8[j++] = 0xDA;
// string_utf8[j++] = 0xBE;
// break;

// case 'K': // Qaf (U+0642)
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0x82;
// break;

// case 'L': // Lam (U+0644)
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0x84;
// break;

// case 'M': // Meem (U+0645)
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0x85;
// break;

// case 'N': // Noon (U+0646)
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0x86;
// break;

// case 'R': // Reh (U+0631)
// string_utf8[j++] = 0xD8;
// string_utf8[j++] = 0xB1;
// break;

// case 'S': // Seen (U+0633)
// string_utf8[j++] = 0xD8;
// string_utf8[j++] = 0xB3;
// break;

// case 'T': // Tah (U+0637)
// string_utf8[j++] = 0xD8;
// string_utf8[j++] = 0xB7;
// break;

// case 'W': // Waw (U+0648)
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0x88;
// break;

// case 'Y': // Alef Maksura (U+0649)
// string_utf8[j++] = 0xD9;
// string_utf8[j++] = 0x89;
// break;

// default:
// // carattere ASCII
// string_utf8[j++] = c;
// break;
// }

// if (add_non_joiner)
// {
// string_utf8[j++] = 0xE2;
// string_utf8[j++] = 0x80;
// string_utf8[j++] = 0x8C;
// }

// *dst_index = j;

// return 0;
// }
