
import { TocTwoTone } from '@material-ui/icons';
import common from './commonService';
interface Point {
  x: number;
  y: number;
}

export class Vehicle {
  windshield: Point[];
  seats: Point[][];
  phone: number[] | null;
  faces: (number[] | null)[];
  belts: Point[][];
  plate: number[] | null;
  outline: number[] | null;
  occupancy: string[] = ["-", "-", "+"];
  vehicleType: string = '';

  constructor(packed? : any) {
    this.seats = [[],[],[]];
    this.phone = null;
    this.faces = [null, null, null];
    this.belts = [[],[],[]];
    this.windshield = [];
    this.plate = null;
    this.outline = null;

    try {
      if (!packed)
        return;

      this.seats = (packed.seats as number[][][]).map(s => this.unpackArray(s));
      this.phone = packed.phone;
      this.faces = packed.faces;
      this.belts = (packed.belts as number[][][]).map(b => this.unpackArray(b));
      this.windshield = this.unpackArray(packed.windshield);
      this.plate = packed.plate;
      this.outline = packed.outline;
    } catch (ex) {
      console.error('failed to construct packed');
    }

   

  }

  unpack(a: number[]): Point {
    try {
      return {x: a[0], y: a[1]};
    } catch (ex) {
      console.error('failed to unpack');
      return {x: 0, y: 0};
    }
  }

  unpackArray(aa: number[][]): Point[] {
    try {
      return aa.map(a => this.unpack(a));
    } catch (ex) {
      console.error('failed to unpack array')
      return [];
    }
  }

  

  pack(pt: Point) {
    return [Math.round(pt.x), Math.round(pt.y)];
  }
  packedArray(pts: Point[]) {
    return pts.map(pt => this.pack(pt));
  }

  round(a: number[] | null) {
    if (!a)
      return null;

    return a.map(v => Math.round(v));
  }

  getPacked(): any {
    try {
      var packed = {} as any;
      packed.windshield = this.packedArray(this.windshield);
      packed.seats = this.seats.map(s => this.packedArray(s));
      packed.phone = this.round(this.phone);
      packed.faces = this.faces.map(f => this.round(f));
      packed.belts = this.belts.map(b => this.packedArray(b));
      packed.outline = this.round(this.outline);
      packed.plate = this.round(this.plate);
      return packed;

    } catch (ex) {
      console.error('failed to get packed vehicle:', ex);
    }
  }
}

export class InsideRecord {
  id: number;
  status: string;
  vehicles: Vehicle[];
  base64: string | null;
  night: boolean;

  constructor() {
    this.vehicles = [];

    this.base64 = null;
    this.id = 0;
    this.night = false;
    this.status = 'none';
  }

}


class Tags
{
  canvas:any;
  ctx: any;
  matrix: number[];
  mode: number;
  hover: Point | null;
  dragAnchor: Point | null;
  resizeIndex: number | null;
  objectIndex: number;
  base64: string | null;
  selectedObject: any;
  vehicle: Vehicle;
  vehicles: Vehicle[];
  vehicleIndex: number = 0;
  mousePos: Point | null;
  recordIndex: number;
  undoStack: any[];
  maxStack: number = 10;
  // hack - duplicate the store!!
  selectedRowId: number = 0;
  img: any;
  opaque: boolean = true;
  night: boolean = true;

  constructor() {
    this.canvas = null;
    this.matrix = [1,0,0,1,0,0];
    this.mode = 0;
    this.hover = null;
    this.dragAnchor = null;
    this.resizeIndex = null;
    this.objectIndex = 0;
    this.selectedObject = null;
    this.mousePos = null;
    this.vehicles = this.getDefaultVehicles();
    this.vehicle = this.vehicles[0];
    this.recordIndex = 0;
    this.base64 = null;
    this.undoStack = [];
    this.img = new Image();
    this.img.onload = () => {
      this.paint();
    };

    common.notifier$.subscribe(msg =>  {
      switch(msg.name) {
      
        case "InsideMouseUp":
        case "InsideMouseMove":
        case "InsideMouseClick":
        case "InsideMouseDown":
        case "InsideMouseWheel":
        case "InsidePropertyChanged":
          this.paint();
          break;

        case "InsideTagsAdded":
          this.updateTagCount();
          break;
      }
    });
  }

  undoAdd() {
    try {
      this.undoStack.push(this.getVehicles());
      if (this.undoStack.length > this.maxStack)
        this.undoStack.shift();
    } catch (ex) {
      console.error('failed to stack undo');
    }
  }

  undoPop() {
    try {
      if (this.undoStack.length === 0)
        return;

    this.undoStack.pop();  
    const data = this.undoStack[this.undoStack.length -1];
    this.setVehicles(data);
    } catch (ex) {
      console.error('failed to pop undo stack');
    }
  }

  getVehicles(): any[] {
    try {
      if (!this.vehicles)
        return [];

      return this.vehicles.map(v => (v as Vehicle).getPacked());
    } catch (ex) {
      console.error('failed to get vehicles: ', ex);
      return [];
    }
  }

  setVehicles(packed: any[]) {
    try {
      if (!packed || packed.length !== 3) 
        this.vehicles = this.getDefaultVehicles();
      else
        this.vehicles = packed.map(p => new Vehicle(p));
      // should be 3 long
      this.vehicle = this.vehicles[0];
    } catch(ex) {
      console.error('failed to set vehicles:', ex);
    }
  }


  getDefaultVehicles(): Vehicle[] {
    const vehicles = [];
    for(let i = 0; i < 3; i++)
      vehicles.push(new Vehicle());
    return vehicles;
  }

  // update selected object after object has changed
  updateObject() {
    try {
      this.setMode(this.mode);
    } catch (ex) {
      console.error('failed to update object:', ex);
    }
   
  }

  /**
   * determine which object is being tagged
   * @param mode 
   */
  setMode(mode: number) {
    try {
      let obj: any = null;
      const v = this.vehicle;
      switch(mode) {
        case 1: obj = v.windshield; break;
        case 2: obj = v.seats[0]; break;
        case 3: obj = v.seats[1]; break;
        case 4: obj = v.seats[2]; break;
        case 5: obj = v.faces[0]; break;
        case 6: obj = v.faces[1]; break;
        case 7: obj = v.faces[2]; break;
        case 8: obj = v.belts[0]; break;
        case 9: obj = v.belts[1]; break;
        case 10: obj = v.belts[2]; break;
        case 11: obj = v.phone; break;
        case 12: obj = v.plate; break;
        case 13: obj = v.outline; break;
      }
      this.mode = mode;
      this.selectedObject = obj;
      this.objectIndex = (mode - 2) % 3;
    } catch (ex) {
      console.error('failed to setMode:', ex);
    }
  }

  /**
   * select a vehicle to tag
   * @param index 
   */
  setVehicle(index: number) {
    try {
      this.vehicle = this.vehicles[index];
      this.setMode(this.mode);
    } catch (ex) {
      console.error('failed to set vehicle:', ex);
    }
  }

  /**
   * get point from mouse event
   * @param e 
   * @returns 
   */
  getPoint = (e: any): Point => {
    try {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      return (this.ctx as any).transformedPoint(x, y);
    } catch (ex) {
      console.error('failed to getPoint');
      return {x: 0, y: 0};
    }
  }

  /**
   * compare two points
   * @param pt0 
   * @param pt1 
   * @returns 
   */
  pointsEqual = (pt0: Point | null, pt1: Point | null) => {
    if (!pt0 || !pt1)
      return false;

    return pt0.x === pt1.x && pt0.y === pt1.y;
  }

  /**
   * extract vertices
   * @param rect 
   * @returns 
   */
  getPoints(rect:number[] | null) {
    try {
      if (!rect || rect.length !== 4)
        return [];

        const l = rect[0];
        const t = rect[1];
        const w = rect[2];
        const h = rect[3];
        return [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h}];
    } catch (ex) {
      console.error("failed to get points: " + ex);
      return [];
    }
  }

  /**
   * distance between two points
   * @param pt0 
   * @param pt1 
   * @returns 
   */
  getDistance = (pt0:Point, pt1: Point) => {
    try {
      const dx = pt0.x - pt1.x;
      const dy = pt0.y - pt1.y;
      return Math.sqrt(dx*dx + dy*dy);
    } catch (ex) {
      console.error('failed to get point: ', ex);
      return 0;
    }
  };

  /**
   * initialize canvas
   * @param canvas 
   */
  setCanvas = (canvas: HTMLCanvasElement) => {
    try {
      this.canvas = canvas;
      this.ctx = canvas.getContext('2d') as any;
      this.trackTransforms(this.ctx);
    } catch (ex) {
      console.error('failed to set canvas: ', ex);
    }
  }

  /**
   * add pan zoom support to canvas
   * @param ctx 
   */
  trackTransforms = (ctx:any) => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let xform = svg.createSVGMatrix();
    ctx.getTransform = function() { return xform; };
  
    const savedTransforms:any = [];
    const save = ctx.save;
    ctx.save = function() {
      savedTransforms.push(xform.translate(0, 0));
      return save.call(ctx);
    };
    const restore = ctx.restore;
    ctx.restore = function() {
      xform = savedTransforms.pop();
      return restore.call(ctx);
    };
  
    const scale = ctx.scale;
    ctx.scale = function(sx:number, sy:number) {
      xform = (xform as any).scaleNonUniform(sx, sy);
      return scale.call(ctx, sx, sy);
    };
    const rotate = ctx.rotate;
    ctx.rotate = function(radians:number) {
      xform = xform.rotate(radians * 180 / Math.PI);
      return rotate.call(ctx, radians);
    };
    const translate = ctx.translate;
    ctx.translate = function(dx:number, dy:number) {
      xform = xform.translate(dx, dy);
      return translate.call(ctx, dx, dy);
    };
    const transform = ctx.transform;
    ctx.transform = function(a:number, b:number, c:number, d:number, e:number, f:number) {
      const m2 = svg.createSVGMatrix();
      m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
      xform = xform.multiply(m2);
      return transform.call(ctx, a, b, c, d, e, f);
    };
    const setTransform = ctx.setTransform;
    ctx.setTransform = function(a:number, b:number, c:number, d:number, e:number, f:number) {
      xform.a = a;
      xform.b = b;
      xform.c = c;
      xform.d = d;
      xform.e = e;
      xform.f = f;
      return setTransform.call(ctx, a, b, c, d, e, f);
    };
    const pt  = svg.createSVGPoint();
    ctx.transformedPoint = function(x:number , y:number) {
      pt.x = x; pt.y = y;
      return pt.matrixTransform(xform.inverse());
    };
  }

  /**
   * save canvas matrix
   */
  saveMatrix = () => {
    try {
      const m = this.ctx.getTransform();
      this.matrix = [m.a, m.b, m.c, m.d, m.e, m.f];
    } catch (ex) {
      console.error('failed to save matrix:', ex);
    }
  };

  /**
   * restore canvas matrix
   */
  restoreMatrix = () => {
    try {
      const m = this.matrix;
      this.ctx.setTransform(...m);
    }  catch (ex) {
      console.error('failed to restoreMatrix:', ex);
    }
  }

  /**
   * get a badge content for the specified object
   * @param obj 
   * @returns 
   */
  getObjectBadge = (obj: any) => {
    try {
      if (!obj)
        return 0;

      return obj.length === 4 ? 1 : 0;
      
    } catch (ex) {
      console.error('failed to get object badge:', ex);
      return 0;
    }
  };

  /**
   * return badges for the selected vehicle
   * @returns 
   */
  getVehiclesBadge = () => {
    const badges = [0, 0, 0];
    try {
      for (let i = 0; i < 3; i++) {
        const v = this.getVehicleBadge(this.vehicles[i]);
        if (v.find(x => x === 1))
          badges[i] = 1;
      }
      return badges;
    } catch (ex) {
      console.error('failed to getVeicleBadge:', ex);
      return badges;
    }
  }

  /**
   * return badges for all vehicles
   * @returns 
   */
  getBadges = () => {
    return this.getVehicleBadge(this.vehicle);
  }

  /**
   * returns the badgets to decorate the buttons
   * @param v 
   * @returns 
   */
  getVehicleBadge = (v: any) => {
    const badges = [0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    try {
      badges[1] = this.getObjectBadge(v.windshield);
      badges[2] = this.getObjectBadge(v.seats[0]);
      badges[3] = this.getObjectBadge(v.seats[1]);
      badges[4] = this.getObjectBadge(v.seats[2]);
      badges[5] = this.getObjectBadge(v.faces[0]);
      badges[6] = this.getObjectBadge(v.faces[1]);
      badges[7] = this.getObjectBadge(v.faces[2]);
      badges[8] = this.getObjectBadge(v.belts[0]);
      badges[9] = this.getObjectBadge(v.belts[1]);
      badges[10] = this.getObjectBadge(v.belts[2]);
      badges[11] = this.getObjectBadge(v.phone);
      badges[12] = this.getObjectBadge(v.plate);
      badges[13] = this.getObjectBadge(v.outline);
      return badges;
    } catch (ex) {
      console.error('failed to get vehicle badge:', ex);
      return badges;

    }
  }

  /**
   * clear all vehicle tags
   */
  clearVehicle = () => {
    try {
      const v = this.vehicle;
      v.seats = [[],[],[]];
      v.phone = null;
      v.faces = [null, null, null];
      v.belts = [[],[],[]];
      v.windshield = [];
      v.plate = null;
      v.outline = null;
      this.setMode(this.mode);
      this.updateTagCount();
    } catch (ex) {
      console.error('failed to clear vehicle: ', ex);
    }
  }

  /**
   * set the background image
   * @param base64 
   */
  setImage = (base64: string) => {
    try {
      this.img.setAttribute("src", base64);
    } catch (ex) {
      console.error('failed to set image: ', ex);
    }
  }

  /**
   * is specified object is selected
   * @param obj 
   * @returns 
   */
  isSelected = (obj: any) => {
    return obj === this.selectedObject;
  }

  /**
   * draw a polygon vertex
   * @param ctx 
   * @param pt 
   * @param selected 
   */
  drawAnchor = (ctx:any, pt:Point, selected: boolean) => {
    try {
      const trans = ctx.getTransform();
      let r = 5 / trans.a;
      r = Math.max(r,5);
      r = Math.min(r, 20);
      const color = tags.pointsEqual(pt,tags.hover) ? "cyan": selected ? "fuchsia" : "black";
      ctx.strokeStyle = color;
      ctx.beginPath();
      ctx.arc(pt.x, pt.y, r, 0, 2 * Math.PI);
      ctx.stroke();
    } catch (ex) {
      console.error('failed to draw anchor:', ex);
    }
  }

  /**
   * draw a polygon of four points
   * @param ctx 
   * @param points 
   * @param selected 
   * @returns 
   */
  drawQuad = (ctx:any, points:Point[], selected: boolean) => {
    try {
      if (!points || points.length === 0)
        return;

      if (selected) 
        points.forEach(pt => {
          this.drawAnchor(ctx, pt, selected);
        });

      ctx.strokeStyle = selected ? "magenta" : "teal" ;
      const trans = ctx.getTransform();
      let lineWidth = 3 / trans.a;
      lineWidth = Math.max(lineWidth,1);
      lineWidth = Math.min(lineWidth, 10);

      ctx.lineWidth = lineWidth;
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      for (let i = 1; i < points.length; i++)
        ctx.lineTo(points[i].x, points[i].y);
      if (points.length === 4)
        ctx.lineTo(points[0].x, points[0].y);
      ctx.stroke();

      // incomplete tag - add a line
      if (points.length < 4 && this.mousePos) {
        const lastPoint = points[points.length -1];
        const mousePos = this.mousePos;
        ctx.beginPath();
        ctx.moveTo(lastPoint.x, lastPoint.y);
        ctx.lineTo(mousePos?.x, mousePos?.y);
        ctx.stroke();
      }

    } catch (ex) {
      console.error('failed to draw quad:', ex);
    }
  }

  /**
   * draw tooltip beneath cursor when designing an element
   * @param ctx 
   * @param pos 
   * @param mode 
   */
  drawTooltip = (ctx: any, pos: any, mode: number) => {
    try {
      const modes = ['Pan', 'Glass', 'Seat 1', 'Seat 2', 'Seat 3', 'Face 1', 
      'Face 2', 'Face 3', 'Belt 1', 'Belt 2', 'Belt 3', 'Phone', 'Plate', 'Car'];
      const x = pos.x;
      const y = pos.y;
      const label = `${modes[mode]}`;
      ctx.font = "300 10px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "start";
      ctx.textBaseline = "hanging";
      ctx.fillText(label, x+2, y+2);

    } catch (ex) {
      console.log('filed to draw tooltip');
    }
  }

  /**
   * draw vehicle label when unselected
   * @param ctx 
   * @param v 
   * @returns 
   */
  drawLabel = (ctx: any, v: any) => {
    try {
      const outline = v.outline;
      if (!outline || outline.length != 4)
        return;

      const x = outline[0] + outline[2] / 2;
      let y = outline[1] + outline[3] / 2;

      const selected = v === tags.vehicle;
      if (selected)
        y = outline[1] + outline[3] + 10;

      ctx.strokeStyle = 'white';
      ctx.fillStyle = 'white';
      ctx.beginPath();
      ctx.arc(x, y, 17, 0, 2 * Math.PI);
      ctx.fill();

      const index = tags.vehicles.indexOf(v);
      const label = `${index+1}`;
      ctx.font = "900 30px Arial";
      ctx.fillStyle = "black";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(label, x, y);
      

      
    } catch (ex) {
      console.error('failed to draw label: ', ex);
    }
  } 

  /**
   * Paint tags
   */
  paint = () => {
    try {
      const ctx = this.ctx;
      ctx.save();
      ctx.setTransform(1,0,0,1,0,0);
      // Will always clear the right space
      ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
      ctx.restore();

      ctx.drawImage(this.img, 0, 0);

      let selected = this.isSelected(tags.vehicle.windshield);

      this.drawQuad(ctx, tags.vehicle.windshield, selected);

      tags.vehicle.seats.forEach( seat => {
        selected = this.isSelected(seat);
        this.drawQuad(ctx, seat, selected);
      });

      tags.vehicle.faces.forEach( face => {
        selected = this.isSelected(face);
        const pts = tags.getPoints(face);
        this.drawQuad(ctx, pts, selected);
      });

      tags.vehicle.belts.forEach( seat => {
        selected = this.isSelected(seat);
        this.drawQuad(ctx, seat, selected);
      });

      selected = this.isSelected(tags.vehicle.phone);
      const phone = tags.getPoints(tags.vehicle.phone);
      this.drawQuad(ctx, phone, selected);

      selected = this.isSelected(tags.vehicle.plate);
      const plate = tags.getPoints(tags.vehicle.plate);
      this.drawQuad(ctx, plate, selected);

      selected = this.isSelected(tags.vehicle.outline);
      const outline = tags.getPoints(tags.vehicle.outline);
      this.drawQuad(ctx, outline, selected);

      tags.vehicles.forEach(v => this.drawLabel(ctx, v));
      const mode = tags.mode;
      const pos = tags.mousePos;
      if (mode > 0 && pos) {
        this.drawTooltip(ctx, pos, mode);
      }

    } catch (ex) {
      console.error('failed to paint');
    }
  }

  /**
   * update the tag count
   */
  updateTagCount = () => {
    try {
      let count = 0;
      this.vehicles.forEach( v => {
        if (v.outline) count++;
        if (v.windshield?.length === 4) count++;
        if (v.phone) count++;
        if (v.plate) count++;
        v.seats.forEach (s => {
          if (s.length === 4) count++;
        });
        v.faces.forEach(f => {
          if (f?.length === 4) count++;
        });
        v.belts.forEach(b => {
          if (b.length === 4) count++;
        });
      });

      const row = common.inside.selectedRow;
      if (!row)
        return;

      if (row.TagCount === count)
        return;

      row.TagCount = count;
      row.Status = count === 0 ? 0 : 1;
      common.notify('InsideTagCount');

    } catch (ex) {
      console.error('failed to update tag count:', ex);
    }
  }
}

const tags: Tags  = new Tags();

export default tags

