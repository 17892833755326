import React, { useEffect} from 'react';
import { Box, FormControl, RadioGroup, Radio, FormControlLabel} from "@material-ui/core";
import { PlateFaces } from './plateFaces';
import { KeyboardPanel } from './keyboard';
import common  from '../../services/commonService';
import { PlateSettings } from './plateSettings';
import  ResizeObserver  from 'react-resize-observer';
import { TagSelector } from './tagSelector';

/**
 * details panel - hosts various tabs
 * @param props 
 * @returns 
 */
export function PlateDetails(props: any) {


  const [value, setValue] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const handleChange = (key: string, value: any) => {
    switch(key) {
      case "PlatesSelectedTabChanged":
      case "PlatesRecordLoaded":
        setValue(v => v + 1);
        break;
    }


  };

  const isDisabled = (key:string) : boolean => {
    try {
      return false;
    } catch (ex) {
      console.error('failed on isDisabled:', ex);
      return false;
    }
  } 

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChange(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  const selectTab = common.plates.selectedTab;

  return  <Box display="flex" flex="1" flexDirection="column" style={{ height:'100%', overflow:'hidden'}}>

  <ResizeObserver onResize={(rect) => 
    {setWidth(Math.max(rect.width - 320, 0)); setHeight(rect.height)}}>
    </ResizeObserver>
    <PlateSettings></PlateSettings>

    <div style={{width:width, height:height}}>
    <Box display="flex" flex="1"  flexDirection="column" style={{height:'100%'}}>
   <Box display={selectTab === 1 ? 'flex': 'none'} flex="1" >
       <KeyboardPanel></KeyboardPanel>
     </Box>

        <Box display={selectTab === 0 ? 'flex': 'none'} flex="1" >
      <PlateFaces ></PlateFaces>
    </Box>
    </Box>
    </div>
    <TagSelector></TagSelector>
      </Box>


}