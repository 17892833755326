


export class GenData {
  selectedDatasetType: string;
  datasetTypes: string[];
  max: number;
  progress: any;
  generating: boolean;

  constructor() {
    this.selectedDatasetType = "Axles";
    this.datasetTypes = ["Plates", "Axles", "Inside"];
    this.max = 100;
    this.progress = null;
    this.generating = false;
  }

}

export interface DatasetInfo {
  id: string;
  filename: string;
  total: number;
  tagged: number;
  percentage: number;
  action: string;
  Path: string;
}


export class DatasetsData {
  gen: GenData;
  showGenerator: boolean;
  selectedPath: string | null;
  directories: string[];
  filteredDatasets: DatasetInfo[];
  datasets: DatasetInfo[];
  fetchingDataset: boolean;
  monoThumbnails: string | null;
  datasetsFilter: string | null;
  selectedTab: number = 0;
  selectedTabName?: string;
  statisticTypes: string[] = [ ];
  statisticTypesUnfiltered: string[] = ['Axles', 'Sequences', 'Accuracy', 'Tagging', 'Plates', 'Vehicles', 'BCCM', 'Colors', 'Makers', 'Boxing', 'Audit', 'Inside seats', 'Inside features', 'Nations', 'Splits', 'Hazards' ];
  statisticsLabels = {
    "Axles": "Axles per vehicle distribution",
    "Sequences": "Sequences tagging",
    "Accuracy": "OCR tagging accuracy",
    "Tagging": "OCR tagging percentages",
    "Plates": "Plate types distribution",
    "Vehicles": "Vehicle types distribution",
    "BCCM": "BCCM tagging percentages",
    "Colors": "Vehicle color distribution",
    "Makers": "Vehicle makers distribution", 
    "Boxing": "Vehicle boxing percentages",
    "Audit": "Tagging audit percentages",
    "Inside seats": "Seats occupancy distribution",
    "Inside features": "Inside features percentages",
    "Nations": "OCR Nation distribution",
    "Splits": "Dataset splits distribution",
    "Years": "Dataset years distribution",
    "Hazards": "Hazard class ditribution",
    "Chars": "Characters tagging",
  };

  selectedStatistic?: string;
  // cache selection to retain on ds selection
  prevSelectedStatistics?: string;

  // tabs
  statTabOcr: boolean = false;
  statTabBccm: boolean = false;
  statTabSeq: boolean = false;
  statTabAudit: boolean = false;
  statTabVehicles: boolean = false;
  statTabAccuracy: boolean = false;
  statTabInside: boolean = false;
  statTabCustom: boolean = false;

  // single statitic items
  taggingsStatistics: boolean = false;
  sequenceStatistics: boolean = false;
  makersStatistics: boolean = false;
  colorsStatistics: boolean = false;
  axlesStatistics: boolean = false;
  vehiclesStatistics: boolean = false;
  nationsStatistics: boolean = false;
  insideStatistics: boolean = false;
  yearsStatistics: boolean = false;
  hazardStatistics: boolean = false;
  lightsStatistics: boolean = false;
  charsStatistics: boolean = false;

  constructor() {
    this.gen = new GenData();
    this.showGenerator = false;
    this.selectedPath = null;
    this.directories = [];
    this.filteredDatasets = [];
    this.datasets = [];
    this.fetchingDataset = false;
    this.monoThumbnails = null;
    this.datasetsFilter = null;
  }

}