import common from './commonService';
import {TreeNode} from '../data/platesData';
const axios = require ('axios');
class TreeService {

  constructor() {
    common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'GenerateDatasetsTree':
          this.updateTreeFilter();
          break;

        case 'TreeFilterChanged':
          this.updateTreeFilter();
          break;

        case 'TreeNodeExpanded':
          common.plates.pendingNode = msg.data;
          break;
      }
    });
  }

  initialize() {
    try {
      this.pendingNodesHandler();
    } catch (ex) {
      console.error('failed to initialize tree service:', ex);
    }
  }

  pendingNodesHandler() {
    try {
      setInterval(() => {
        if (common.plates.pendingNode && !common.plates.populatingNodes) {
          this.populateNode(common.plates.pendingNode);
          common.plates.pendingNode = '';
        }
      }, 200);

    } catch (ex) {
      console.error('failed to handle pending nodes:', ex);
    }
  }

  treeDialogOpened() {
    try {
      common.plates.scanPolicy.quitRequest();
    } catch (ex) {
      console.error('failed to handle tree dialog opened:', ex);
    }
  }



  /**
   * generate tree
   * @returns 
   */
  generateTree() {
    try {
   
      const datasets = common.plates.filteredDatasets;
      if (!datasets)
        return;

      let id = 0;
      const root = new TreeNode();
      root.name='Datasets';
      root.id = 'root';
      datasets.forEach(ds => {
        const path = ds.dataset.path || "Orphans";
        const tokens = path?.split('/') || [];
        let parent = root;
        for (let i = 0; i < tokens.length; i++) {
          const token = tokens[i];
          let node:TreeNode | undefined = parent.nodes.find(n => n.name === token);
          if (!node) {
            node = new TreeNode();
            node.id = `node${id++}`;
            node.name = token;
            parent.nodes.push(node);
          }
          parent = node;
        }
        parent.datasets.push(ds);
      });
      common.plates.datasetsRoot = root;
      // WTT-403
      this.sortTree(root);
      common.notify("DatasetsRootChanged");
      
    } catch (ex) {
      console.error('failed to generate tree:', ex);
    }
  }

  // WTT-403
  sortTree(node: TreeNode) {
    try {
      // WTT-403 - sort
      node.nodes.sort((a,b) => a.name.localeCompare(b.name));
      node.datasets.sort((a, b) => a.dataset.alias?.localeCompare(b.dataset?.alias));
      node.nodes.forEach(n => this.sortTree(n));
    } catch (ex) {
      console.error('failed to sort tree:', ex);
    }
  }


  getNode(node: TreeNode, id: string): TreeNode | null {
    try {
      if (node.id === id)
        return node;
      for (let i = 0; i < node.nodes.length; i++) {
        const found = this.getNode(node.nodes[i], id);
        if (found)
          return found;
      }
      return null;
    } catch(ex) {
      console.error('failed to getNode:', ex);
      return null;
    }
  }


  /**
   * fill node with datase sizes
   */
  async populateNode(nodeId: string) {
    try {
      await common.assureLogin();
      const serverUrl =  process.env.REACT_APP_SERVER_URL;
      common.plates.populatingNodes = true;
      const node = this.getNode(common.plates.datasetsRoot, nodeId);
      if (!node)
        return;

      if (node.populated)
        return;

      for(let i = 0; i < node.datasets.length; i++) {
        const ds = node.datasets[i];
        const url = `${serverUrl}/dataset/${ds._id}`;
        const reply = await axios.get(url, {headers: {'Authorization': common.loginToken}});
        const data = reply.data;
        ds.imageCount = data.images?.length || -1;
        ds.sequenceCount = data.sequences?.length || -1;
      }
      node.populated = true;
      common.notify('TreeNodePopulated');
    } catch (ex) {
      console.error('failed to populate node:', ex);
    } finally {
      common.plates.populatingNodes = false;
    }
  }



  /**
   * aggiorna l'albero
   */
  updateTreeFilter() {
    try {
      const filter = common.plates.treeFilter.toLowerCase();
      if (!filter)
        common.plates.filteredDatasets = common.plates.rawDatasets;
      else
        common.plates.filteredDatasets = common.plates.rawDatasets.filter(ds => ds.dataset.alias.toLowerCase().includes(filter));

      this.generateTree();

    } catch (ex) {
      console.error('failed to update tree filter:', ex);
    }
  }

 



}

const treeService:TreeService = new TreeService();
export default treeService;