import React, {useState, useEffect} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import {Box} from '@material-ui/core'






export function TriSwitch(props: any) {

  const [value, setValue] = useState(0); 

  const [state, setState] = useState<boolean | null>(null);

  const handleCallback = (key: string, value: any) => {
    try {
      switch(key) {
        case "Click":
          switch(state)  {
            case false:
              setState(null);
              if (props.onChange) props.onChange(null);
              break;

            case null:
              setState(true);
              if (props.onChange) props.onChange(true);
              break;

            case true:
              setState(false);
              if (props.onChange) props.onChange(false);
              break;
          }
          
          break;
  
  
      }
    } catch (ex) {
      console.error('faile on callback:', ex);
    }
  }

  useEffect(() => {
    setState(props.value);
  }, []);

  const getBackground = () => {
    switch(state) {
      case null: return 'gray';
      case false: return 'red';
      case true: return 'limegreen';
    }
  }

  const getPos = () => {
    switch(state) {
      case null: return 20;
      case false: return 10;
      case true: return 30;
    }
  }

return <Box display="flex" flexDirection="row" margin="2px"> 
  

  <svg cursor="pointer" height="20" width="60" onClick={() => handleCallback("Click", null)}>
  <path d="M 10 0 L 30 0  a 10 10 0 0 1 0 20 l -20 0 a 10 10 0 0 1 0 -20" stroke={getBackground()} fill={getBackground()} strokeWidth="0"/>
  <circle cx={getPos()} cy="10" r="8" fill="white"/>
</svg>
</Box>
}