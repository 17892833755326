import React, {useState} from 'react';
import {Box, Button, Checkbox, Dialog, DialogTitle, 
  DialogActions, DialogContent,
  FormControlLabel } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import common from '../services/commonService';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import copy from 'copy-to-clipboard';

const  dateFormatter = (params:any) => {
  const time = params.data.time;
  const tm = new Date(time);
  return tm.toLocaleTimeString();
}

export function ChangelogDialog (props:any) {
  const { onClose, open } = props;

  const [value, setValue] = useState(0); 
  const classes = useStyles();

  const columnDefs = [
    {headerName: 'Git commit tag',  field: 'tag',width: 300, resizable: true },
    {headerName: 'Description', field: 'description', width:2000},
  ];

  const handleClose = () => {
    onClose();
  };



  function onGridReady(params:any) {
    common.app.changelog.gridApi = params.api;
}



const copyToClipboard = () => {
  try {
    const text = JSON.stringify(common.app.changelog, null, 2);
    copy(text);
  } catch (ex) {
    console.error('failed to copy to clipboard: ', ex);
  }
}

  var logs = common.app.changelog;


  return (
    <Dialog onClose={handleClose} fullWidth={true}
    maxWidth='lg'
    aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="Application changelog">
        <Box display="flex" flexDirection="column" width={500}>
          <b>Changes from last published version (V0.259 - 278):</b>
          <Box display="flex" flex="1" flexDirection="row"></Box>
            <Box display="flex" flexDirection="row">

      
          
            </Box>

          <Box display="flex" flexDirection="row">
  
          </Box>
          

         </Box>
      </DialogTitle>
      <DialogContent>




      <Box display="flex" flexDirection="column"
                className="ag-theme-balham"
                style={{ height: '500px', width: '100%' }}>
                <AgGridReact
                    rowSelection={'single'}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    rowData={common.app.changelog?.changes || []}>
              </AgGridReact>
      </Box>
       </DialogContent>
       <DialogActions>
       <Button className={classes.button}
          style={{margin:4, fontSize:10}} variant="contained" onClick={() => copyToClipboard()}>Copy</Button>      
          <Button  className={classes.button}
          style={{margin:4,fontSize:10}} variant="contained" onClick={() => handleClose()}>Close</Button>
       </DialogActions>

    </Dialog>
  );
}

ChangelogDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
}));