export class ScanPolicy {
  firstRow: number = 0;
  lastRow: number = 0;
  prevFirstRow: number = 0;
  prevLastRow: number = 0;
  moveTime: number = 0;
  scanStatus: string = 'none';
  dueScan: string = 'none';
  lastMovement: number = 0;
  unmovingThreshold: number = 1000;
  fullScanThreshold: number = 5 * 60 * 1000;
  miniScanThreshold: number = 60 * 1000;
  statusCounter: number = 0; 
  statusFirstRow: number = 0;
  lastStatusScan: number = 0;
  lastFullScan: number = 0;
  scanning: boolean = false;
  busy: boolean = false;
  loadingDataset: boolean = false;
  abortRequest: boolean = false;
  unbusyPromise: any = null;
  forced: boolean = false;


  getDueScan = () => {
    if (this.scanning)
      return "none";
    if (Date.now() - this.lastFullScan > this.fullScanThreshold)
      return "full";
    if (Date.now() - this.lastStatusScan > this.miniScanThreshold)
      return "mini";
    return "none";
  }

  setFirstRow = (row:number) => {
    this.prevFirstRow = this.firstRow;
    this.firstRow = row;
  }

  setLastRow = (row:number) => {
    this.prevLastRow = this.lastRow;
    this.lastRow = row;
  }

  updateMoveStatus = () => {
    if (this.statusCounter === 0) {
     this.scanStatus = "initialQuery";
    } else if (this.prevFirstRow !== this.firstRow) {
      this.scanStatus = "moving";
      this.moveTime = Date.now();
    } else if (this.scanStatus === "moving") {
      this.scanStatus = "unmoving";
    } else if (this.scanStatus === "unmoving") {
      if (Date.now() - this.moveTime > this.unmovingThreshold)
        this.scanStatus = "moved";
    } else {
      this.scanStatus = this.getDueScan(); 
    }
  }

  reset = (forced: boolean = false) => {
    this.forced = forced;
    this.statusCounter = 0;
    this.scanStatus = "initialQuery";
  }

  startScan = () =>   {
    this.scanning = true;
  }

  quitRequest = () => {
    if (this.busy)
      this.abortRequest = true;
  }

  delay = (n: number) => {
    return new Promise(function(resolve){
        setTimeout(resolve,n);
    });
  }

  setBusy = (b: boolean) => {
    this.busy = b;
  }

  asyncQuit = async () => {
      if (!this.busy)
        return Promise.resolve();

      this.abortRequest = true;
      const p = new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (!this.busy) {
            clearInterval(interval);
            resolve(true);
          }
        }, 100)});
        return p;
  }


  endScan = (full: boolean) => {
    this.statusCounter++;
    this.scanStatus = "none";
    this.scanning = false;
    this.abortRequest = false;
    this.lastStatusScan = Date.now();
    this.forced = false;
    if (full)
      this.lastFullScan = Date.now();
  }
}