import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import * as React from "react";
import { InsideInspection } from '../components/inside/insideInspection';



export function InsidePage2() {
  const classes = useStyles();
  React.useEffect(() => {
    try {
    
    } catch (ex) {
      console.error('failed to initialize inside page:', ex);
    }
  }, []);


  const handleCallback = (arg: string, value: any) => {
    try {
      switch (arg) {
     
      }

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

	return (
		<div className={classes.root}>
      <div className={classes.panelContainer}>
        <InsideInspection></InsideInspection>
      </div>
		
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
  },
  tableContainer: {
    display: "flex",
    marginLeft: 10,
  },

  panelContainer: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    background: "gainsboro",
    marginLeft: 10,
  },
  




}));
