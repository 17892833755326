import React from 'react';
import {Button, Box} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { Warning as WarningIcon } from '@material-ui/icons';
import common from '../services/commonService';

export default function AlertDialog(props:any) {
  // from props
  const { onClose, open, dlgMessage, dlgTitle } = props;


  const handleClose = (confirm: boolean) => {
    onClose(confirm);
  };

  const classes = useStyles();

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" flexDirection="row" alignItems="center">
            <WarningIcon/>
            <Box width="10px"/>
          {common.app.alert.title}
          </Box>
          </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {common.app.alert.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} onClick={() => handleClose(true)} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({ 
  button: {
    margin: 4,
    color: 'white',
    height: '30px',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  combo: {
    width: 80,
  },
  grid: {

  },
}));