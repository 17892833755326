import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import common from '../../services/commonService';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import copy  from 'copy-to-clipboard';
import { AttachFile as AttachIcon } from '@material-ui/icons';

export default function EntryJsonDialog(props:any) {
  // from props
  const { onClose, open, dlgMessage, dlgTitle } = props;


  const copyToClipboard = () => {
    try {
      copy(context.json);
    } catch (ex) {
      console.error('failed to copy to clipboard:', ex);
    }
  }

  const handleClose = (confirm: boolean) => {
    onClose(confirm);
  };

  const classes = useStyles();
  const context = common.app.context;

  return (
      <Dialog style={{overflow:'hidden'}}
      maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{}} id="alert-dialog-title">{context.id}</DialogTitle>
        <DialogContent  style={{overflow: 'hidden'}}>
          <DialogContentText id="alert-dialog-description" style={{overflow: 'hidden'}}>
            <Box style={{ color: 'black'}} fontFamily="consolas" fontSize="12px">
              <textarea  spellCheck="false" style={{resize: 'none', width:'1000px', height:'400px'}}  defaultValue={context.json}></textarea>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Box display="flex" flex="1" flexDirection="row">
            <Tooltip title="Copy to clipboard">
         <IconButton  onClick={() => copyToClipboard()} color="primary" >
           <AttachIcon/></IconButton>
           </Tooltip>
          <Box flexDirection="column" flex="1"/>
          <Button className={classes.button} onClick={() => handleClose(true)} color="primary" autoFocus>Ok</Button>
          </Box>
        </DialogActions>
      </Dialog>
  );
}

const useStyles = makeStyles((theme: Theme) => ({ 
  button: {
    margin: 4,
    color: 'white',
    height: '25px',
    fontSize: '12px',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  combo: {
    width: 80,
  },
  grid: {

  },
}));