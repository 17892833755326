import React, {useState, useRef, useEffect} from 'react';
import {Box, IconButton, MenuItem,Tooltip, Typography, Button, Select, Switch } from '@material-ui/core'
import {Check as CheckIcon, Cancel as CancelIcon, Delete as DeleteIcon, DragHandleRounded, Edit as EditIcon } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import platesService from '../../services/platesService';
import './table.css';


/**
 * editor for plate annotations - contains tag editors
 * handles most editing operations
 * @param props 
 * @returns 
 */
export function CharsEditor(props: any) {

    
    const {ant } = props;

  const [value, setValue] = useState(0); // integer state
  const [passengerOpen, setPassengerOpen] = useState(false);

  const update = () => setValue(v => v + 1);

  /**
   * common change handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      switch (key) {

        case 'CharsCancelled':
        case 'CharsSelected':
          update();
          break;

         case 'Click':
          if (selected)
            common.notify('SelectedCharIndexChanged', value);
          break;

        case 'Cancel':
          common.notify('CharsCancel');
            break;
    
     
    
      }
    } catch (ex) {
      console.error('failed to handle charsEditor changes: ', ex);
    }
  }

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  const getBackground = (index:number) => {
    try {
        const ambiguous = ant?.ExtraType?.value === 2;
        const exists = ant?.CharsPoly?.value[index];
        const pristine = ant?.CharsPoly?.value[index]?.pristine || false;
        const tagged = exists && !pristine;
        const charIndex = common.plates.selectedCharIndex;
        if (selected && index === charIndex)
          return 'orange';

        if (tagged)
          return ambiguous ? '#E3FDD8' : 'paleGreen';

        return undefined;
    } catch (ex) {
        console.error('failed to get border');
        return 'black'
    }
  }


  const classes = useStyles();
  const tooltips: boolean = true;
  const selected = ant === common.plates.selectedAnnotation;

  const plateNumber = ant?.PlateNumber?.value || '';
  const chars = plateNumber.split('');

  const cursor = selected ? 'pointer': undefined;

  

  return ( 
    <Box display="flex" flex="1" flexDirection="row"  maxWidth="410px"  marginRight="4px" alignItems="center"  >
        {chars.map((ch:string, index: number) => 
        <Box onClick={() => handleChanges('Click', index)}
        style={{width:'20px', maxWidth:'20px', cursor:cursor, 
        alignContent:'center', textAlign: 'center', backgroundColor: getBackground(index),
        border:'solid 1px gray', borderRadius:'3px', margin:'2px'}} >
            <Typography>{ch}</Typography>
         </Box>
        )}

        {/* <IconButton style={{height:'20px', width:'25px'}} onClick={() => handleChanges('Cancel', null)}><CancelIcon/></IconButton> */}
      
    </Box>
  )

}

const useStyles = makeStyles({

  radio: {
    margin: 0,
    padding: 0,
    fontSize: 40,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
    fontSize:40,
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

  combo: {
    width: 90,
  },

});