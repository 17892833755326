import { DoubleArrow } from '@material-ui/icons';
import { HazardData, InsideEntry, InsideItem, LightsBounding, ReflectiveData, VehicleBounding } from '../data/platesData';
import common from './commonService';

/**
 * duplicate point definition
 */
interface Point {
  x: number;
  y: number;
}

/**
 * plate canvas wrapper
 */
export class PlatesCanvas {

  canvas:any;
  ctx: any;
  matrix: number[];
  base64: string | null;
  img: any;
  mousePos: Point | null;
  imageSize: number[];
  windowSize: number[];
  tags: any;
  hover: any;
  resizeIndex: number | null;
  thumbnails: any[];
  vehicleThumbs: any[] = [null, null, null, null, null, null];
  insideThumbs: any[] = [null, null, null, null, null, null, null, null];
  lightThumbs: any[] = [null,null,null,null,null,null,null,null,null];
  hazardThumbs: any[] = [null,null,null,null,null,null,null,null,null];
  reflectiveThumbs: any[] = [null,null,null,null,null,null,null,null,null];
  
  resizing: boolean;
  segResizing: boolean;
  vehicleResizing: boolean = false;
  insideResizing: boolean = false;
  lightResizing: boolean = false;
  adding: boolean;
  hotPoly: Point[] | null;
  hotMouse: Point | null;
  plateRect: number[] | null;
  measuring: boolean;
  measureStart: Point | null;
  measureEnd: Point | null;

  // vehicle bounds
  bounding: boolean = false;
  boundingStart: Point | null = null;
  boundingEnd: Point | null = null;

  // inside inspecting
  insiding: boolean = false;
  insideStart: Point | null = null;
  insideEnd: Point | null = null;

  // lights
  lighting: boolean = false;
  lightStart: Point | null = null;
  lightEnd: Point | null = null;


  
  // WTT-414
  hazarding: boolean = false;
  hazardStart: Point | null = null;
  hazardEnd: Point | null = null;
  hazardResizing: boolean = false;

  // WTT-429
  reflecting: boolean = false;
  reflectStart: Point | null = null;
  reflectEnd: Point | null = null;
  reflectResizing: boolean = false;

  // WTT 421
  charsResizing: boolean = false;

  lanes: number[][] | null;
  delimiters: number[] | null;
  pendingPaint: boolean = false;
  pendingRestore: boolean = false;
  loadingImage: boolean = false;




  /**
   * standard constructor
   */
  constructor() {
    this.canvas = null;
    this.ctx = null;
    this.matrix = [1,0,0,1,0,0];
    this.base64 = null;
    this.img = new Image();
    this.mousePos = null;
    this.imageSize = [0, 0];
    this.windowSize = [0, 0];
    this.tags = null;
    this.hover = null;
    this.resizeIndex = null;
    this.thumbnails = [null, null, null, null, null, null];
    this.resizing = false;
    this.segResizing = false;
    this.hotPoly = null;
    this.hotMouse = null;
    this.adding = false;
    this.plateRect = null;
    this.measureStart = null;
    this.measureEnd = null;
    this.measuring = false;
    this.lanes = null;
    this.delimiters = null;

    setInterval(() => {
      if (this.pendingRestore) {
        this.pendingRestore = false;
        this.restoreMatrix();
      }

      if (this.pendingPaint && !this.loadingImage) {
        this.pendingPaint = false;
        this.paint("interval");
      }
    },100);
  }



  /**
   * set vehicle image
   * @param imageUrl 
   */
  setImage = (imageUrl: string) => {
    try {
      if (!imageUrl) throw(new Error('no image'));
      if (imageUrl.endsWith('null')) {
        console.warn('base64 is null');
        return;
      }
      
      this.loadingImage = true;
      this.img.crossOrigin = '';
      this.img.setAttribute("src", imageUrl);
      // placeholder
      this.img.onload = () => {
        this.loadingImage = false;
        this.imageSize = [this.img.naturalWidth, this.img.naturalHeight];
        this.unzoom();
        this.paint('setImage');
    };
    } catch (ex) {
      console.error('failed to set image:', ex);
    }
  }

  /**
   * clear the canvas
   */
  clear = () => {
    try {
      const ctx = this.ctx;
      ctx.save();
      ctx.setTransform(1,0,0,1,0,0);
      ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
      ctx.restore();
      this.img.setAttribute("src", null);
    } catch (ex) {
      console.error('failed to clear canvas:');
    }
  }

  /**
   * set metadata plate rect
   * @param rect 
   */
  setPlateRect = (rect: number[] | null) => {
      try {
        this.plateRect = null;
        if (!rect || rect.length !== 4)
          return;

        this.plateRect = [rect[0], rect[1], rect[2]-rect[0], rect[3] - rect[1]];

      } catch (ex) {
        console.error('failed to set plate rect');
      }
  }

  /**
   * lanes, if defined
   * @param lanes 
   */
  setLanes = (lanes: any) => {
    try {
      this.lanes = lanes?.lanes;
      this.delimiters = lanes?.delimiters;
    } catch (ex) {
      console.error('failed to set lanes:', ex);
    }
  }

  /**
   * distance from point (pt0) to a segment (pt1, pt2)
   * @param pt0 
   * @param pt1 
   * @param pt2 
   */
  getLineDistance = (pt0: Point, pt1: Point, pt2: Point) => {
    try  {
      var d = this.getDistance(pt1, pt2);
      var dx = Math.abs((pt2.x - pt1.x)*(pt1.y - pt0.y) - (pt1.x - pt0.x)*(pt2.y - pt1.y));
      return dx / d;
    } catch (ex) {
      console.error('failed to get distance');
      return -1;
    }
  }

  getSegmentDistance(pt: Point, pt1: Point, pt2: Point) {
    try {
      var A = pt.x - pt1.x;
      var B = pt.y - pt1.y;
      var C = pt2.x - pt1.x;
      var D = pt2.y - pt1.y;
    
      var dot = A * C + B * D;
      var len_sq = C * C + D * D;
      var param = -1;
      if (len_sq != 0) //in case of 0 length line
          param = dot / len_sq;
    
      var xx, yy;
    
      if (param < 0) {
        xx = pt1.x;
        yy = pt1.y;
      }
      else if (param > 1) {
        xx = pt2.x;
        yy = pt2.y;
      }
      else {
        xx = pt1.x + param * C;
        yy = pt1.y + param * D;
      }
    
      var dx = pt.x - xx;
      var dy = pt.y - yy;
      return Math.sqrt(dx * dx + dy * dy);

    } catch (ex) {
      console.error('failed to get distance from segment:', ex);
      return -1;
    }
  }

  /**
   * get a signed distance from point (pt0) to a segment (pt1, pt2)
   * @param pt0 
   * @param pt1 
   * @param pt2 
   */
  getSignedDistance = (pt0: Point, pt1: Point, pt2: Point) => {
    try  {
      var d = this.getDistance(pt1, pt2);
      var dx = (pt2.x - pt1.x)*(pt1.y - pt0.y) - (pt1.x - pt0.x)*(pt2.y - pt1.y);
      return dx / d;
    } catch (ex) {
      console.error('failed to get signed distance');
      return -1;
    }
  }

  /**
   * distance between two points
   * @param pt0 
   * @param pt1 
   */
  getDistance = (pt0:Point, pt1: Point) => {
    try {
      const dx = pt0.x - pt1.x;
      const dy = pt0.y - pt1.y;
      return Math.sqrt(dx*dx + dy*dy);
    } catch (ex) {
      console.error('failed to get point: ', ex);
      return 0;
    }
  };

  /**
   * pass a ref to ui canvas 
   * @param canvas 
   */
  setCanvas = (canvas: HTMLCanvasElement) => {
    try {
      this.canvas = canvas;
      this.ctx = canvas.getContext('2d') as any;
      this.trackTransforms(this.ctx);
    } catch (ex) {
      console.error('failed to set canvas: ', ex);
    }
  }

  /**
   * pass a reference of thumbnail canvases
   * @param index 
   * @param canvas 
   */
  setPlateThumbnail = (index: number, canvas: HTMLCanvasElement | null) => {
    try {
      this.thumbnails[index] = canvas;
    } catch (ex) {
      console.error('failed to set plateThumbnail', ex);
    }
  }

  setVehicleThumbnail = (index: number, canvas: HTMLCanvasElement | null) => {
    try {
      this.vehicleThumbs[index] = canvas;
    } catch (ex) {
      console.error('failed to set plateThumbnail', ex);
    }
  }

  setInsideThumbnail = (index: number, canvas: HTMLCanvasElement | null) => {
    try {
      this.insideThumbs[index] = canvas;
    } catch (ex) {
      console.error('failed to set plateThumbnail', ex);
    }
  }

  setLightThumbnail = (index: number, canvas: HTMLCanvasElement | null) => {
    try {
      this.lightThumbs[index] = canvas;
    } catch (ex) {
      console.error('failed to set plateThumbnail', ex);
    }
  }
  
  setHazardThumbnail = (index: number, canvas: HTMLCanvasElement | null) => {
    try {
      this.hazardThumbs[index] = canvas;
    } catch (ex) {
      console.error('failed to set plateThumbnail', ex);
    }
  }

  setReflectiveThumbnail = (index: number, canvas: HTMLCanvasElement | null) => {
    try {
      this.reflectiveThumbs[index] = canvas;
    } catch (ex) {
      console.error('failed to set plateThumbnail', ex);
    }
  }
  

  getVehicleTag = (pt: Point, role: string) => {
    try {
      const vehicles = common.plates.vehicles;
      if (!vehicles)
        return null;

      const r = this.getAnchorSize();
      for (let i = 0; i < vehicles.length; i++) {
        // 31/01/2022 WTT-208
        const vr = vehicles[i].rect.value;
        const rect = role === 'Delete' ?  [vr[0] + r, vr[1] - 2*r, 2*r , 2*r] : [vr[0] + 4*r, vr[1] - 2*r, 2*r , 2*r];
        if (pt.x >= rect[0] && pt.y >= rect[1] && pt.x <= rect[0] + rect[2] && pt.y <= rect[1] + rect[3])
          return vehicles[i];
      }
      return null;

    } catch (ex) {
      console.error('failed on getDeleteVehicleTag:', ex);
    }
  }



  getContainingTag = (pt: Point) => {
    try {
      const tags = this.tags;
      if (!tags || tags.length === 0)
        return null;

      for (let i = 0; i < tags.length; i++) {
        const poly = this.polyToPoints(tags[i].Poly.value);
        if (this.inside(pt, poly as Point[]))
          return tags[i];
      }
      return null;
    } catch (ex) {
      console.error('failed to get containingTag:', ex);
    }
  }

  updateCharHeight = () => {
    try {
      const tag = this.getContainingTag(this.measureStart as Point);
      if (tag) {
        var pt0 = this.measureStart as Point;
        var pt1 = this.measureEnd as Point;
        const h = Math.max(pt0.y, pt1.y ) - Math.min (pt0.y, pt1.y);
        tag.CharHeight.value = Math.round(h);
        if (tag.CharHeight.status !== "tagged") {
          tag.CharHeight.status="tagged";
          common.notify('TaggingStatusChanged');
        }
        common.notify("CharHeightChanged");
      }

    } catch (ex) {
      console.error('failed to update charHeight: ', ex);
    }
  }



  /**
   * determine if a point is inside a polygon
   * @param point 
   * @param vs 
   * @returns 
   */
  inside = (point: Point, vs: Point[]) => {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html
    
    var x = point.x, y = point.y;
    
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i].x, yi = vs[i].y;
        var xj = vs[j].x, yj = vs[j].y;
        
        var intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
};

/**
 * measure character height
 * @param op 
 * @param pt 
 */
measureCharHeight(op: string, pt: Point): boolean {
  try {
    switch (op) {
      case "Start":
        this.measureStart = pt;
        this.measureEnd = pt;
        this.measuring = true;
        break;

      case "MouseMove":
        this.measureEnd = pt;
        this.paint('measureCharHeight-mouseMove');
        break;

      case "MouseDown":
      case "MouseUp":
        this.updateCharHeight();
        this.measureStart = null;
        this.measureEnd = null;
        this.measuring = false;
        this.paint('measureCharHeight');
        break;
    }
    return true;

  } catch (ex) {
    console.error('failed to measure char height:', ex);
    return false;
  }
}



boundVehicle(op: string, pt: Point) : boolean {
  try {
    switch (op) {
      case "Start":
        this.boundingStart = pt;
        this.boundingEnd = pt;
        this.bounding = true;
        break;

      case "MouseMove":
        this.boundingEnd = pt;
        this.paint('boundVehicle-move');
        break;

      case "MouseUp":
        this.boundingStart = null;
        this.boundingEnd = null;
        this.bounding = false;
        this.paint('boundVehicle-up');
        break;
    }
    return true;

  } catch (ex) {
    console.error('failed to measure char height:', ex);
    return false;
  }
}

boundLights(op: string, pt: Point) : boolean {
  try {
    switch (op) {
      case "Start":
        this.lightStart = pt;
        this.lightEnd = pt;
        this.lighting = true;
        break;

      case "MouseMove":
        this.lightEnd = pt;
        this.paint('boundLight-move');
        break;

      case "MouseUp":
        this.lightStart = null;
        this.lightEnd = null;
        this.lighting = false;
        this.paint('boundLights-up');
        break;
    }
    return true;

  } catch (ex) {
    console.error('failed to measure char height:', ex);
    return false;
  }
}

boundHazard(op: string, pt: Point) : boolean {
  try {
    switch (op) {
      case "Start":
        this.hazardStart = pt;
        this.hazardEnd = pt;
        this.hazarding = true;
        break;

      case "MouseMove":
        this.hazardEnd = pt;
        this.paint('');
        break;

      case "MouseUp":
        this.hazardStart = null;
        this.hazardEnd = null;
        this.hazarding = false;
        this.paint('p');
        break;
    }
    return true;

  } catch (ex) {
    console.error('failed to measure char height:', ex);
    return false;
  }
}

boundReflect(op: string, pt: Point) : boolean {
  try {
    switch (op) {
      case "Start":
        this.reflectStart = pt;
        this.reflectEnd = pt;
        this.reflecting = true;
        break;

      case "MouseMove":
        this.reflectEnd = pt;
        this.paint('');
        break;

      case "MouseUp":
        this.reflectStart = null;
        this.reflectEnd = null;
        this.reflecting = false;
        this.paint('p');
        break;
    }
    return true;

  } catch (ex) {
    console.error('failed to measure char height:', ex);
    return false;
  }
}

boundInside(op: string, pt: Point) : boolean {
  try {
    switch (op) {
      case "Start":
        this.insideStart = pt;
        this.insideEnd = pt;
        this.insiding = true;
        break;

      case "MouseMove":
        this.insideEnd = pt;
        this.paint('');
        break;

      case "MouseUp":
        this.insideStart = null;
        this.insideEnd = null;
        this.insiding = false;
        this.paint('');
        break;
    }
    return true;

  } catch (ex) {
    console.error('failed to measure char height:', ex);
    return false;
  }
}


/**
 * cancel currently drawn poly
 * @returns 
 */
cancelCurrentPoly() {
  try {

    // only when adding a new poly
    if (!this.adding)
      return;

    this.adding = false;
    this.hotPoly = null;
    this.paint('cancelCurrentPoly');

  } catch (ex) {
    console.error('failed to cancel current poly:', ex);
  }
}

  /**
   * draw a new poly
   * @param op operation
   * @param pt point
   */
  setNewPoly(op: string, pt:Point) {
    try {
      switch (op) {
        case "New":
          this.hotPoly = [ pt ];
          this.adding = true;
          break;

        case "MouseMove":
          this.hotMouse = pt;
          this.paint('setNewPoly-move');
          break;

        case "AddPoint":
          this.hotPoly?.push(pt);
          if (this.hotPoly?.length === 4) {
            this.adding = false;
            this.paint('setNewPoly-add');
          }
          break;
      }
      return true;
    } catch (ex) {
      console.error('failed to set new poly:', ex);
      return false;
    }
  }

  /**
   * paint a plate thumbnail
   * @param ant annotation
   * @param canvas 
   */
  paintThumbnail = (ant: any, canvas: HTMLCanvasElement) => {
    try {
      if (!ant?.Poly?.value || !canvas )
        return;

      const pts = this.polyToPoints(ant.Poly.value);
      const xx = pts?.map(pt => pt.x) as number[];
      const yy = pts?.map(pt => pt.y) as number[];
      const left = Math.min(...xx);
      const top = Math.min(...yy);
      const right = Math.max(...xx);
      const bottom = Math.max (...yy);
      const ctx = canvas.getContext('2d') as any;
      ctx.drawImage(this.img, left, top, right - left, bottom - top, 0, 0, canvas.width, canvas.height);
    } catch (ex) {
      console.error('failed to paint thumbnail:', ex);
    }
  }

  paintVehicleThumb = (v: VehicleBounding, canvas: HTMLCanvasElement) => {
    try {
      if (!v.rect || !canvas )
        return;

      // 31/01/2022 WTT-208
      const rect = v.rect.value;
   
      const left =rect[0];
      const top = rect[1];
      const width = rect[2];
      const height = rect[3];
      const ctx = canvas.getContext('2d') as any;
      ctx.drawImage(this.img, left, top, width, height, 0, 0, canvas.width, canvas.height);
    } catch (ex) {
      console.error('failed to paint thumbnail:', ex);
    }
  }

  /**
   * draw traffic lights cutout
   * @param v 
   * @param canvas 
   * @returns 
   */
  paintLightThumb = (v: LightsBounding, canvas: HTMLCanvasElement) => {
    try {
      if (!v.rect || v.rect.length < 4 || !canvas )
        return;

      // 31/01/2022 WTT-208
      const rect = v.rect;
   
      const left =rect[0];
      const top = rect[1];
      const width = rect[2];
      const height = rect[3];
      const ctx = canvas.getContext('2d') as any;
      ctx.drawImage(this.img, left, top, width, height, 0, 0, canvas.width, canvas.height);
    } catch (ex) {
      console.error('failed to paint thumbnail:', ex);
    }
  }

  paintHazardThumb = (v: HazardData, canvas: HTMLCanvasElement) => {
    try {
      if (!v.rect || v.rect.length < 4 || !canvas )
        return;

      // 31/01/2022 WTT-208
      const rect = v.rect;
   
      const left =rect[0];
      const top = rect[1];
      const width = rect[2];
      const height = rect[3];
      const ctx = canvas.getContext('2d') as any;
      ctx.drawImage(this.img, left, top, width, height, 0, 0, canvas.width, canvas.height);
    } catch (ex) {
      console.error('failed to paint thumbnail:', ex);
    }
  }

  
  paintReflectiveThumb = (v: ReflectiveData, canvas: HTMLCanvasElement) => {
    try {
      if (!v.rect || v.rect.length < 4 || !canvas )
        return;

      // 31/01/2022 WTT-208
      const rect = v.rect;
      const left =rect[0];
      const top = rect[1];
      const width = rect[2];
      const height = rect[3];
      const ctx = canvas.getContext('2d') as any;
      ctx.drawImage(this.img, left, top, width, height, 0, 0, canvas.width, canvas.height);
    } catch (ex) {
      console.error('failed to paint thumbnail:', ex);
    }
  }

  paintInsideThumb = (v: InsideEntry, canvas: HTMLCanvasElement) => {
    try {
      if (!v?.windshield?.value || !canvas )
        return;

      const pts = this.polyToPoints(v.windshield.value);
      const xx = pts?.map(pt => pt.x) as number[];
      const yy = pts?.map(pt => pt.y) as number[];
      const left = Math.min(...xx);
      const top = Math.min(...yy);
      const right = Math.max(...xx);
      const bottom = Math.max (...yy);
      const ctx = canvas.getContext('2d') as any;
      ctx.drawImage(this.img, left, top, right - left, bottom - top, 0, 0, canvas.width, canvas.height);
    } catch (ex) {
      console.error('failed to paint thumbnail:', ex);
    }
  }

  /**
   * draw plate thumbnails
   */
  paintThumbnails = () => {
    try {
      const tags = this.tags;
      if (!tags)
        return;

      for (let i = 0; i < tags.length; i++) {
        const canvas = this.thumbnails[i];
        const ant = tags[i];
        this.paintThumbnail(ant, canvas);
      }

      const vehicles = common.plates.vehicles;
      for (let i = 0; i < vehicles.length; i++) {
        const canvas = this.vehicleThumbs[i];
        const v = vehicles[i];
        this.paintVehicleThumb(v, canvas);
      }

      const insides = common.plates.insides;
      for (let i = 0; i < insides.length; i++) {
        const canvas = this.insideThumbs[i];
        const v = insides[i];
        this.paintInsideThumb(v, canvas);
      }

      const lights = common.plates.lights;
      for (let i = 0; i < lights.length; i++) {
        const canvas = this.lightThumbs[i];
        const v = lights[i];
        this.paintLightThumb(v, canvas);
      }

      const hazards = common.plates.hazards || [];
      for (let i = 0; i < hazards.length; i++) {
        const canvas = this.hazardThumbs[i];
        const h = hazards[i];
        this.paintHazardThumb(h, canvas);
      }

      const reflectives = common.plates.reflectives || [];
      for (let i = 0; i < reflectives.length; i++) {
        const canvas = this.reflectiveThumbs[i];
        const h = reflectives[i];
        this.paintReflectiveThumb(h, canvas);
      }



    } catch (ex) {
      console.error('failed to paint thumbnails:', ex);
    }
  }

  /**
   * return a list of internal segments
   * @param tag annotation
   */
  getInternalSegments = (tag:any) => {
    try  {
      const poly = [];
      if (!tag?.Poly?.value)
        return [];

      const pts = this.polyToPoints(tag.Poly.value) as Point[];

      const ip = tag.InternalPoly.value as number[];
      let pt0 = this.getMidPoint(pts[0], pts[3], ip[0]);
      let pt1 = this.getMidPoint(pts[1], pts[2], ip[0]);
      poly.push([pt0, pt1]);
      pt0 = this.getMidPoint(pts[0], pts[1], ip[1]);
      pt1 = this.getMidPoint(pts[3], pts[2], ip[1]);
      poly.push([pt0, pt1]);
      pt0 = this.getMidPoint(pts[0], pts[3], ip[2]);
      pt1 = this.getMidPoint(pts[1], pts[2], ip[2]);
      poly.push([pt0, pt1]);
      pt0 = this.getMidPoint(pts[0], pts[1], ip[3]);
      pt1 = this.getMidPoint(pts[3], pts[2], ip[3]);
      poly.push([pt0, pt1]);
      return poly;
    } catch (ex) {
      console.error('failed to get internal segments:', ex);
    }
  }

  getCharSegments2 = (tag:any, ip: number[]) => {
    try  {
      const poly = [];
      if (!tag?.Poly?.value)
        return [];

      const pts = this.polyToPoints(tag.Poly.value) as Point[];
      let pt0 = this.getMidPoint(pts[0], pts[3], ip[0]);
      let pt1 = this.getMidPoint(pts[1], pts[2], ip[0]);
      poly.push([pt0, pt1]);
      pt0 = this.getMidPoint(pts[0], pts[1], ip[1]);
      pt1 = this.getMidPoint(pts[3], pts[2], ip[1]);
      poly.push([pt0, pt1]);
      pt0 = this.getMidPoint(pts[0], pts[3], ip[2]);
      pt1 = this.getMidPoint(pts[1], pts[2], ip[2]);
      poly.push([pt0, pt1]);
      pt0 = this.getMidPoint(pts[0], pts[1], ip[3]);
      pt1 = this.getMidPoint(pts[3], pts[2], ip[3]);
      poly.push([pt0, pt1]);

      return poly;
    } catch (ex) {
      console.error('failed to get internal segments:', ex);
    }
  }

  getCharSegments = (tag:any, ip: number[]) => {
    try  {

      if (!tag?.Poly?.value)
        return [];

      const pts = this.polyToPoints(tag.Poly.value) as Point[];
      const pt0 = this.getMidPoint(pts[0], pts[3], ip[0]);
      const pt1 = this.getMidPoint(pts[1], pts[2], ip[0]);
      const pt2 = this.getMidPoint(pts[0], pts[1], ip[1]);
      const pt3 = this.getMidPoint(pts[3], pts[2], ip[1]);
      const pt4 = this.getMidPoint(pts[0], pts[3], ip[2]);
      const pt5 = this.getMidPoint(pts[1], pts[2], ip[2]);
      const pt6 = this.getMidPoint(pts[0], pts[1], ip[3]);
      const pt7 = this.getMidPoint(pts[3], pts[2], ip[3]);

      const topRight = this.getIntersection(pt0, pt1, pt2, pt3);
      const bottomRight = this.getIntersection(pt2, pt3, pt4, pt5);
      const bottomLeft = this.getIntersection(pt4, pt5, pt6, pt7);
      const topLeft = this.getIntersection(pt6, pt7, pt0, pt1);

      const poly = [[topLeft, topRight], [topRight, bottomRight], [bottomRight, bottomLeft], [bottomLeft, topLeft]];
      return poly;
    } catch (ex) {
      console.error('failed to get internal segments:', ex);
    }
  }

  /**
   * calc internal segments of all tags
   */
  calcInternalSegments() {
    try {
      const tags = this.tags;
      if (!tags)
        return;

      tags.forEach((t:any) => {
        t.InternalSegments = this.getInternalSegments(t);
      });

    } catch (ex) {
      console.error('failed to calc internal segments:', ex);
    }
  }

  /**
   * paint the annotation currently being designed
   */
  paintHotPoly = () => {
    try {
      if (this.hotPoly === null)
        return;

        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = "red";
        const origin = this.hotPoly[0];
        ctx.beginPath();
        ctx.moveTo(origin.x, origin.y);
        for (let i = 1; i < this.hotPoly.length; i++)
          ctx.lineTo(this.hotPoly[i].x, this.hotPoly[i].y);

      if (this.hotMouse !== null) {
         ctx.lineTo(this.hotMouse.x, this.hotMouse.y);
      }
      ctx.stroke();

    } catch (ex) {
      console.error('failed to paint hot poly: ', ex);
    }
  }

  paintMeasureRect = () => {
    try {
      const pt0 = this.measureStart;
      const pt1 = this.measureEnd;
      if (pt0 && pt1) {
        const x = Math.min(pt0.x, pt1.x);
        const y = Math.min(pt0.y, pt1.y);
        const w = Math.max(pt0.x, pt1.x) - x;
        const h = Math.max(pt0.y, pt1.y) - y;
        const vertices = this.getPoints([x,y,w,h]);
        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = "magenta";
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        ctx.lineTo(vertices[1].x, vertices[1].y);
        ctx.lineTo(vertices[2].x, vertices[2].y);
        ctx.lineTo(vertices[3].x, vertices[3].y);
        ctx.lineTo(vertices[0].x, vertices[0].y);
        ctx.stroke();        
      }
    } catch (ex) {
      console.error('failed to measure rect:', ex);
    }
  }

  paintVehicleBounds = (v: VehicleBounding) => {
    try {
      // ofer 31/01/2022 WTT-208
      const vertices = this.getPoints(v.rect.value);
      // WTT-363 - (handled out of context to clear errors)
      if (vertices.length < 4) return;
      const ctx = this.ctx;
      ctx.lineWidth = this.getLineWidth();
      // WTT-434
      ctx.strokeStyle = "rgb(255,254,9)";
      // ctx.strokeStyle = "magenta";
      ctx.beginPath();
      ctx.moveTo(vertices[0].x, vertices[0].y);
      ctx.lineTo(vertices[1].x, vertices[1].y);
      ctx.lineTo(vertices[2].x, vertices[2].y);
      ctx.lineTo(vertices[3].x, vertices[3].y);
      ctx.lineTo(vertices[0].x, vertices[0].y);
      ctx.stroke();    
      const r = this.getAnchorSize();
    } catch (ex) {
      console.error('failed to paint vehicle bounds', ex);
    }
  }

  paintLightBounds = (v: LightsBounding) => {
    try {
      const selected = v === common.plates.selectedLights;
      this.paintRect(v.rect, "magenta", selected);
      v.lights.forEach(l => {
        this.paintRect(l.rect, l.type, selected);
      });
    } catch (ex) {
      console.error('failed to paint vehicle bounds', ex);
    }
  }

  paintHazardBounds = (v: HazardData) => {
    try {
      const hazardTagging = common.plates.newTagType === 'Hazard';
      const selected = v === common.plates.selectedHazard && hazardTagging;
      this.paintRect(v.rect, "rgb(243, 61, 42)", selected);
    } catch (ex) {
      console.error('failed to paint hazard bounds', ex);
    }
  }

  paintReflectiveBounds = (v: ReflectiveData) => {
    try {
      const tagging = common.plates.newTagType === 'Reflective';
      const selected = v === common.plates.selectedReflective && tagging;
      this.paintRect(v.rect, "magenta", selected);
    } catch (ex) {
      console.error('failed to paint reflective bounds', ex);
    }
  }

  paintRect(rect:number[], color: string, drawAnchors: boolean) {
    try {
      if (color === 'none') return;
      const vertices = this.getPoints(rect);
      if (vertices.length < 4) return;
      const ctx = this.ctx;
      ctx.lineWidth = this.getLineWidth();
      ctx.strokeStyle = color;
      ctx.beginPath();
      ctx.moveTo(vertices[0].x, vertices[0].y);
      ctx.lineTo(vertices[1].x, vertices[1].y);
      ctx.lineTo(vertices[2].x, vertices[2].y);
      ctx.lineTo(vertices[3].x, vertices[3].y);
      ctx.lineTo(vertices[0].x, vertices[0].y);
      ctx.stroke();    
      if (drawAnchors) {
        const pts = this.getPointsEx(rect);
        this.paintAnchorPositions(pts, color);
      }

    } catch (ex) {
      console.error('failed to paint rect:', ex);
    }
  }

  paintInside = (v: InsideEntry) => {
    try {

      if (!v.windshield)
        return;

      const selected = v == common.plates.selectedInside;

      const pts = this.polyToPoints(v.windshield.value) as Point[];

      // ofer 31/01/2022 WTT-208
      // const vertices = this.getPoints(v.rect.value);
      const ctx = this.ctx;
      ctx.lineWidth = this.getLineWidth();
      ctx.strokeStyle = selected ? "cyan" : "gray";
      ctx.beginPath();
      ctx.moveTo(pts[0].x, pts[0].y);
      ctx.lineTo(pts[1].x, pts[1].y);
      ctx.lineTo(pts[2].x, pts[2].y);
      ctx.lineTo(pts[3].x, pts[3].y);
      ctx.lineTo(pts[0].x, pts[0].y);
      ctx.stroke();    
      const r = this.getAnchorSize();
    } catch (ex) {
      console.error('failed to paint inside', ex);
    }
  }


  paintAnchorPositions = (pts:any[], color: string) => {
    try {
      const ctx = this.ctx;
      ctx.fillStyle = color;
      ctx.globalAlpha = 0.6;
      let r = this.getAnchorSize();
      r = r / 2;
      pts.forEach(pt => {
        ctx.fillRect(pt.x - r, pt.y - r, r * 2, r * 2);
      });
      ctx.globalAlpha = 1.0;

    } catch (ex) {
      console.error('failed to paint anchor positions: ', ex);
    }
  }

  
  /**
   * paint vehicles bounding box
   * @returns 
   */
  paintVehiclesBounds = () => {
    try {
      // const bounds = common.plates.selectedRecord?.vehicles;
      const tagging = common.plates.newTagType === 'Vehicle';
      const bounds = common.plates.vehicles;
      if (!bounds) return;
      bounds.forEach((b:VehicleBounding) => {
        const selected = b === common.plates.selectedVehicle;
        this.paintVehicleBounds(b);
        // ofer 31/01/2022 WTT-208
        const pts = this.getPointsEx(b.rect.value);
        // WTT-434
        if (selected && tagging)
          this.paintAnchorPositions(pts, "rgb(255,254,9)");

        if (b.hoverIndex >= 0 && selected) {
          this.drawAnchor(this.ctx, pts[b.hoverIndex]);
        }
        
      });

    } catch (ex) {
      console.error('failed to paint vehicle bounds', ex);
    }
  }

  paintHazards = () => {
    try {
      const bounds = common.plates.hazards;
      if (!bounds) return;
      bounds.forEach((l:HazardData) => {
        this.paintHazardBounds(l);
         if (l.hoverIndex >= 0) {
          const pts = this.getPointsEx(l.rect);
          this.drawAnchor(this.ctx, pts[l.hoverIndex]);
        }
      });

    } catch(ex) {
      console.error('failed to paint hazards:', ex);
    }
  }
  
  paintReflectives = () => {
    try {
      const bounds = common.plates.reflectives;
      if (!bounds) return;
      bounds.forEach((l:ReflectiveData) => {
        this.paintReflectiveBounds(l);
         if (l.hoverIndex >= 0) {
          const pts = this.getPointsEx(l.rect);
          this.drawAnchor(this.ctx, pts[l.hoverIndex]);
        }
      });

    } catch(ex) {
      console.error('failed to paint hazards:', ex);
    }
  }

  /**
   * paint traffic lights rectangles
   * @returns 
   */
  paintLightsBounds = () => {
    try {
      // const bounds = common.plates.selectedRecord?.vehicles;
      const bounds = common.plates.lights;
      if (!bounds) return;
      bounds.forEach((l:LightsBounding) => {
        this.paintLightBounds(l);
         if (l.hoverIndex >= 0) {
          const pts = this.getPointsEx(l.rect);
          this.drawAnchor(this.ctx, pts[l.hoverIndex]);
        }
        for (let ll of l.lights) {
          if (ll.hoverIndex >= 0) {
            const pts2 = this.getPointsEx(ll.rect);
            this.drawAnchor(this.ctx, pts2[ll.hoverIndex]);
          }
        }
        
      });

    } catch (ex) {
      console.error('failed to paint vehicle bounds', ex);
    }
  }


  getHotBounding = () => {
    try {
      if (!this.boundingStart || !this.boundingEnd)
        return null;
      
      const x = Math.min(this.boundingStart.x, this.boundingEnd.x);
      const y = Math.min(this.boundingStart.y, this.boundingEnd.y);
      const w = Math.abs(this.boundingEnd.x - this.boundingStart.x);
      const h = Math.abs(this.boundingEnd.y - this.boundingStart.y);
      return [Math.round(x),Math.round(y),Math.round(w),Math.round(h)];
    } catch (ex) {
      console.error('failed to get hot bounding:', ex);
    }
  }

  getHotLight = () => {
    try {
      if (!this.lightStart || !this.lightEnd)
        return null;
      
      const x = Math.min(this.lightStart.x, this.lightEnd.x);
      const y = Math.min(this.lightStart.y, this.lightEnd.y);
      const w = Math.abs(this.lightEnd.x - this.lightStart.x);
      const h = Math.abs(this.lightEnd.y - this.lightStart.y);
      return [Math.round(x),Math.round(y),Math.round(w),Math.round(h)];
    } catch (ex) {
      console.error('failed to get hot bounding:', ex);
    }
  }

  getHot = (start: Point | null, end: Point | null) => {
    try {
      if (!start || !end)
        return null;
      
      const x = Math.min(start.x, end.x);
      const y = Math.min(start.y, end.y);
      const w = Math.abs(end.x - start.x);
      const h = Math.abs(end.y - start.y);
      return [Math.round(x),Math.round(y),Math.round(w),Math.round(h)];
    } catch (ex) {
      console.error('failed to get hot bounding:', ex);
    }
  }

  /**
   * WTT-414 hot hazard
   * @returns 
   */
  getHotHazard() {
    try {
      return this.getHot(this.hazardStart, this.hazardEnd);
    } catch (ex) {
      console.error('failed to get hot hazard:', ex);
    }
  }

  
  getHotReflect() {
    try {
      return this.getHot(this.reflectStart, this.reflectEnd);
    } catch (ex) {
      console.error('failed to get hot hazard:', ex);
    }
  }

  paintInsides = () => {
    try {
      // const bounds = common.plates.selectedRecord?.vehicles;
      const insides = common.plates.insides;
      if (!insides) return;
      insides.forEach((b:InsideEntry) => {
        const selected = b === common.plates.selectedInside;
        this.paintInside(b);
        // ofer 31/01/2022 WTT-208
        const pts = this.polyToPoints(b.windshield?.value) as Point[];
        const anchorColor = selected ? "cyan" : "gray";
        this.paintAnchorPositions(pts, anchorColor);
        if (b.hoverIndex >= 0) {
          this.drawAnchor(this.ctx, pts[b.hoverIndex]);
        }
        
        // paint passengers
        this.ctx.globalAlpha = selected ? 1.0 : 0.5;
        const passengers = b.getPassengers();
        passengers.forEach(p => this.paintPassenger(p));
        this.ctx.globalAlpha = 1.0;

      });
    } catch (ex) {
      console.error('failed to paint insides', ex);
    }
  }

  paintPassenger = (p: InsideItem) => {
    try {
      if (!p.occupied.value)
        return;

      const r = p.faceRect.value;
      if (r?.length !== 4)
        return;

        const vertices = this.getPoints(r);
        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = "magenta";
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        ctx.lineTo(vertices[1].x, vertices[1].y);
        ctx.lineTo(vertices[2].x, vertices[2].y);
        ctx.lineTo(vertices[3].x, vertices[3].y);
        ctx.lineTo(vertices[0].x, vertices[0].y);
        ctx.stroke();        
    } catch (ex) {
      console.error('failed to paint passenger:', ex);
    }
  }

  getHotInside = () => {
    try {
      if (!this.insideStart || !this.insideEnd)
        return null;
      
      const x = Math.min(this.insideStart.x, this.insideEnd.x);
      const y = Math.min(this.insideStart.y, this.insideEnd.y);
      const w = Math.abs(this.insideEnd.x - this.insideStart.x);
      const h = Math.abs(this.insideEnd.y - this.insideStart.y);
      return [Math.round(x),Math.round(y),Math.round(w),Math.round(h)];
    } catch (ex) {
      console.error('failed to get hot bounding:', ex);
    }
  }

  paintHotVehicleBounding = () => {
    try {
      const pt0 = this.boundingStart;
      const pt1 = this.boundingEnd;
      if (pt0 && pt1) {
        const x = Math.min(pt0.x, pt1.x);
        const y = Math.min(pt0.y, pt1.y);
        const w = Math.max(pt0.x, pt1.x) - x;
        const h = Math.max(pt0.y, pt1.y) - y;
        const vertices = this.getPoints([x,y,w,h]);
        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        // WTT-434
        ctx.strokeStyle = "rgb(255,254,9)";
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        ctx.lineTo(vertices[1].x, vertices[1].y);
        ctx.lineTo(vertices[2].x, vertices[2].y);
        ctx.lineTo(vertices[3].x, vertices[3].y);
        ctx.lineTo(vertices[0].x, vertices[0].y);
        ctx.stroke();        
      }
    } catch (ex) {
      console.error('failed to measure rect:', ex);
    }
  }

  paintHotLight = () => {
    try {
      const pt0 = this.lightStart;
      const pt1 = this.lightEnd;
      if (pt0 && pt1) {
        const x = Math.min(pt0.x, pt1.x);
        const y = Math.min(pt0.y, pt1.y);
        const w = Math.max(pt0.x, pt1.x) - x;
        const h = Math.max(pt0.y, pt1.y) - y;
        const vertices = this.getPoints([x,y,w,h]);
        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = "magenta";
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        ctx.lineTo(vertices[1].x, vertices[1].y);
        ctx.lineTo(vertices[2].x, vertices[2].y);
        ctx.lineTo(vertices[3].x, vertices[3].y);
        ctx.lineTo(vertices[0].x, vertices[0].y);
        ctx.stroke();        
      }
    } catch (ex) {
      console.error('failed to measure rect:', ex);
    }
  }

  paintHotHazard = () => {
    try {
      const pt0 = this.hazardStart;
      const pt1 = this.hazardEnd;
      if (pt0 && pt1) {
        const x = Math.min(pt0.x, pt1.x);
        const y = Math.min(pt0.y, pt1.y);
        const w = Math.max(pt0.x, pt1.x) - x;
        const h = Math.max(pt0.y, pt1.y) - y;
        const vertices = this.getPoints([x,y,w,h]);
        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = "rgb(243, 61, 42)";
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        ctx.lineTo(vertices[1].x, vertices[1].y);
        ctx.lineTo(vertices[2].x, vertices[2].y);
        ctx.lineTo(vertices[3].x, vertices[3].y);
        ctx.lineTo(vertices[0].x, vertices[0].y);
        ctx.stroke();        
      }
    } catch (ex) {
      console.error('failed to measure rect:', ex);
    }
  }

  paintHotReflective = () => {
    try {
      this.paintHot(this.reflectStart, this.reflectEnd);
    } catch (ex) {
      console.error('failed to paint hot rect:', ex);
    }
  }

  paintHot(pt0:Point | null, pt1: Point | null) {
    try {
      if (pt0 && pt1) {
        const x = Math.min(pt0.x, pt1.x);
        const y = Math.min(pt0.y, pt1.y);
        const w = Math.max(pt0.x, pt1.x) - x;
        const h = Math.max(pt0.y, pt1.y) - y;
        const vertices = this.getPoints([x,y,w,h]);
        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = "magenta";
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        ctx.lineTo(vertices[1].x, vertices[1].y);
        ctx.lineTo(vertices[2].x, vertices[2].y);
        ctx.lineTo(vertices[3].x, vertices[3].y);
        ctx.lineTo(vertices[0].x, vertices[0].y);
        ctx.stroke();        
      }

    } catch (ex) {

    }
  }


  paintHotInside = () => {
    try {
      const pt0 = this.insideStart;
      const pt1 = this.insideEnd;
      if (pt0 && pt1) {
        const x = Math.min(pt0.x, pt1.x);
        const y = Math.min(pt0.y, pt1.y);
        const w = Math.max(pt0.x, pt1.x) - x;
        const h = Math.max(pt0.y, pt1.y) - y;
        const vertices = this.getPoints([x,y,w,h]);
        const ctx = this.ctx;
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = "cyan";
        ctx.beginPath();
        ctx.moveTo(vertices[0].x, vertices[0].y);
        ctx.lineTo(vertices[1].x, vertices[1].y);
        ctx.lineTo(vertices[2].x, vertices[2].y);
        ctx.lineTo(vertices[3].x, vertices[3].y);
        ctx.lineTo(vertices[0].x, vertices[0].y);
        ctx.stroke();        
      }
    } catch (ex) {
      console.error('failed to measure rect:', ex);
    }
  }

  /**
   * paint validation plate rect
   * @returns 
   */
  paintPlateRect = () => {
    try{
      if (!this.plateRect)
        return;

      if (common.plates.settings.mode !== 'Validation')
        return;


      const vertices = this.getPoints(this.plateRect);

      const ctx = this.ctx;
      ctx.lineWidth = this.getLineWidth();
      ctx.strokeStyle = "red";
      ctx.beginPath();
      ctx.moveTo(vertices[0].x, vertices[0].y);
      ctx.lineTo(vertices[1].x, vertices[1].y);
      ctx.lineTo(vertices[2].x, vertices[2].y);
      ctx.lineTo(vertices[3].x, vertices[3].y);
      ctx.lineTo(vertices[0].x, vertices[0].y);
      ctx.stroke();

    } catch (ex) {
      console.error('failed to paint plate rect: ', ex);
    }
  }

  paintLanes = () => {
    try {
      if (!common.plates.settings.showLanes || !this.lanes)
        return;

        const ctx = this.ctx;
        const w = this.imageSize[0];
        const h = this.imageSize[1];

        this.lanes.forEach(lane => {
        if (lane && lane.length === 4) {
          const l = lane.map(x => x * w / 100);
          const pt0 = [l[0],0];
          const pt1 = [l[2],h];
          const pt2 = [l[1], 0];
          const pt3 = [l[3], h];
          
          ctx.lineWidth = this.getLineWidth();
          ctx.strokeStyle = "magenta";
          ctx.beginPath();
          ctx.moveTo(...pt0);
          ctx.lineTo(...pt1);
          ctx.stroke();
          
          ctx.beginPath();
          ctx.moveTo(...pt2);
          ctx.lineTo(...pt3);
          ctx.stroke();
        }
      });

      ctx.strokeStyle = "cyan";
      if (this.delimiters && this.delimiters.length === 2) {
        const d = this.delimiters.map(x => x * w / 100);
        const pt0 = [0, d[0]];
        const pt1 = [w, d[0]];
        ctx.beginPath();
        ctx.moveTo(...pt0);
        ctx.lineTo(...pt1);
        ctx.stroke();
        const pt2 = [0, d[1]];
        const p3 = [w, d[1]];
        ctx.beginPath();
        ctx.moveTo(...pt0);
        ctx.lineTo(...pt1);
        ctx.stroke();


        // var l = delimiters.Select(x => x * mat.Height / 100).ToArray();
        // Point p0 = new Point(0, l[0]);
        // Point p1 = new Point(mat.Width, l[0]);
        // mat.Line(p0, p1, new Scalar(255, 0, 255), 2);
        // p0 = new Point(0, l[1]);
        // p1 = new Point(mat.Width, l[1]);
        // mat.Line(p0, p1, new Scalar(255, 0, 255), 2);

      }

        

    } catch (ex) {
      console.error('paint lanes error: ', ex);
    }
  }

  paintBccm = () => {
    try {

      const canvas = this.canvas;
      if (!canvas) {
        console.error('failed to get bccm canvas');
        return;
      }

      const ctx = this.ctx;
      this.setLinewidth();
      const bccm = common.plates?.selectedRecord?.bccm;
      if (!bccm || !bccm.Annotations)
        return;

      if (this.hotPoly)
        return;

      if (this.tags?.length > 0)
        return;

      bccm.Annotations.forEach((a:any) => {
        const keys = [
          "lpp_top_left_col",
          "lpp_top_left_row",
          "lpp_top_right_col",
          "lpp_top_right_row",
          "lpp_bot_right_col",
          "lpp_bot_right_row",
          "lpp_bot_left_col",
          "lpp_bot_left_row"
        ];
        const p = keys.map(k => parseFloat(a[k]?.value));

          ctx.strokeStyle = "red" ;
          ctx.beginPath();
          ctx.moveTo(p[0], p[1]);
          ctx.lineTo(p[2], p[3]);
          ctx.lineTo(p[4], p[5]);
          ctx.lineTo(p[6], p[7]);
          ctx.lineTo(p[0], p[1]);
          ctx.stroke();
        
      });

    } catch (ex) {
      console.error('failed to paint bccm: ', ex);
    }
  }

  /**
   * principle paint method
   */
  paint = (caller: string) => {
    try {
      if (common.plates.painting) {
        console.error('paint recursed');
        return;
      }
      common.plates.painting = true;
      common.plates.paintCounter++;

      const canvas = this.canvas;
      if (!canvas) {
        console.error('failed to get canvas');
        return;
      }

      const ctx = this.ctx;
      ctx.save();
      ctx.setTransform(1,0,0,1,0,0);
      ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
      ctx.restore();
      if (this.img?.src?.endsWith('null'))
        return;
      
      if (!this.img?.src)
        return;
      
      try {
        ctx.drawImage(this.img, 0, 0);
      } catch (ex) {
        console.error('failed on drawImage:', ex);
        // experimental - nullify to generate only a single error.
         this.img.src = 'image-not-found.png';
         ctx.drawImage(this.img, 0, 0);
      }

     
      this.paintPlateRect();
      this.paintMeasureRect();
      this.paintHotVehicleBounding();
      this.paintHotInside();
      this.paintHotLight();
      this.paintHotHazard();
      this.paintHotReflective();

      this.paintBccm();

      //  rest of code regards annotation
      if (common.plates.settings.mode !== 'Annotation')
        return;

      this.paintHotPoly();
      this.paintVehiclesBounds();
      this.paintInsides();
      this.paintLightsBounds();
      this.paintHazards();
      this.paintReflectives();

      const tags = this.tags;
      if (!tags)
        return;

      this.paintCharShadows();
      this.calcInternalSegments();
      tags.forEach((t:any) => {
        this.paintPolyTag(t);
      });

      this.paintLanes();
      this.paintThumbnails();

      this.paintChars();


    } catch (ex) {
      console.error(`failed to paint plates: ${caller}`, ex);
    } finally {
      common.plates.painting = false;
    }
  }

  /**
   * paint char boundings
   * @returns 
   */
  paintChars = () => {
    try {

      if (common.plates.newTagType !== 'Chars')
        return;

      const ant = common.plates.selectedAnnotation;
      if (!ant) return;

      const chars = ant?.CharsPoly?.value;
      if (!chars) return;
      
      // at this stage - expect structure to be normalized
      const selectedIndex = common.plates.selectedCharIndex;
      const editedChars = common.plates.editedChars;

      for (let i = 0; i < chars.length; i++) {
        const char = chars[i];
        const selected = i === selectedIndex;
        const pristine = char.pristine;
        const segment = this.getCharSegments(ant, char.poly);
        if (!selected)
          this.paintCharSegments(segment, false, pristine);
      }

      if (selectedIndex > -1) {
        const segment = this.getCharSegments(ant, chars[selectedIndex].poly);
        this.paintCharSegments(segment, true, false);
      }
   
    } catch (ex) {
      console.error('failed to paint chars:', ex);
    }
  }

  paintCharShadows = () => {
    try {

      if (common.plates.newTagType === 'Chars')
        return;

      const ants = this.tags;
      const ctx = this.ctx;
      ctx.strokeStyle = 'gray';
      ants.forEach((ant:any) => {
        if (ant.CharsPoly) {
          const chars = ant.CharsPoly.value;
          for (let i = 0; i < chars.length; i++) {
            const char = chars[i];
            const segment = this.getCharSegments(ant, char.poly) || [];
            for (let j = 0; j < segment.length; j++) {
              const p = segment[j]
              if (p[0] && p[1]) {
                ctx.beginPath();
                ctx.moveTo(p[0].x, p[0].y);
                ctx.lineTo(p[1].x, p[1].y);
                ctx.stroke();
              }  }
        
          }
    
        }

      });


    

   

     
   
    } catch (ex) {
      console.error('failed to paint chars:', ex);
    }
  }
  

  /**
   *   paint char segments
   */
  paintCharSegments(segment: any, selected: boolean, pristine: boolean) {
    try {
      const ctx = this.ctx;
      const hoverIndex = common.plates.charHoverIndex;
      for (let j = 0; j < segment.length; j++) {
        const p = segment[j]
        if (p[0] && p[1]) {
          // 
          if (pristine && !selected)
            continue;

          ctx.strokeStyle = 'green';
          // if (pristine) ctx.strokeStyle = 'gray';
          if (selected) ctx.strokeStyle = 'blue';
          if (selected && hoverIndex === j) ctx.strokeStyle = 'red';
          ctx.beginPath();
          ctx.moveTo(p[0].x, p[0].y);
          ctx.lineTo(p[1].x, p[1].y);
          ctx.stroke();
        }
      }

    } catch (ex) {
      console.error('failed to paint char segments:', ex);
    }
  }


  

getIntersection(pt0:any, pt1:any, pt2:any, pt3:any)
{
  const x1 = pt0.x;
  const y1 = pt0.y;
  const x2 = pt1.x;
  const y2 = pt1.y;
  const x3 = pt2.x;
  const y3 = pt2.y;
  const x4 = pt3.x;
  const y4 = pt3.y;

    var ua, ub, denom = (y4 - y3)*(x2 - x1) - (x4 - x3)*(y2 - y1);
    if (denom == 0) {
        return null;
    }
    ua = ((x4 - x3)*(y1 - y3) - (y4 - y3)*(x1 - x3))/denom;
    ub = ((x2 - x1)*(y1 - y3) - (y2 - y1)*(x1 - x3))/denom;
    return {
        x: x1 + ua * (x2 - x1),
        y: y1 + ua * (y2 - y1),
        // seg1: ua >= 0 && ua <= 1,
        // seg2: ub >= 0 && ub <= 1
    };
}

  // initializeChars = () => {
  //   try {
  //     const ant = common.plates.selectedAnnotation;
  //     if (!ant) return;

  //     const ip = ant.InternalPoly.value;
  //     common.assert(ip, "No internal poly");

  //     const w = ip[1] - ip[3];
  //     const h = ip[2] - ip[0];
  //     common.assert(w > 0 && h > 0, "Internal poly error");

  //     const plateNumber = ant.PlateNumber.value;
  //     common.assert(plateNumber, "No plate number");

  //     const totalChars = plateNumber.length;
  //     const charWidth = w / totalChars;

  //     const val = [];
  //     for (let i = 0; i < totalChars; i++) {
  //       // top, right, bottom left, as internal poly
  //       const poly = [ip[0], ip[3] + (i+1)*charWidth, ip[2], ip[3] + i*charWidth];
  //       // leave room for other attributes
  //       val.push({poly, "text": ''});
  //     }
  //     ant.CharsPly.value = val;
  //   } catch (ex) {
  //     console.error('failed to initialize chars:', ex);
  //   }
  // }

  imageNotFound = () => {
    try {
      const ctx = this.ctx;
      if (this.img?.src !== null)
        return;

      ctx.font = "30px Arial";
      ctx.fillStyle = "magenta"; 
      ctx.textBaseline = "top";
      ctx.fillText("Image not found", 10, 10);
    } catch (ex) {
      console.error('failed on imageNotFound:', ex);
    }
  }

  /**
   * compensate linewidth to zoom level 
   */
  setLinewidth = () => {
    try {
      const ctx = this.ctx;
      const trans = ctx.getTransform();
      let lineWidth = 3 / trans.a;
      lineWidth = Math.max(lineWidth,1);
      lineWidth = Math.min(lineWidth, 10);
      ctx.lineWidth = lineWidth;
    } catch (ex) {
      console.error('failed to set line width:', ex);
    }
  }

  /**
   * get a list of point vertices of specified rect
   * @param rect 
   */
  getPoints(rect:number[] | null) {
    try {
      if (!rect || rect.length !== 4)
        return [];

        const l = rect[0];
        const t = rect[1];
        const w = rect[2];
        const h = rect[3];
        return [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h}];
    } catch (ex) {
      console.error("failed to get points: " + ex);
      return [];
    }
  }

  /**
   * vertices and midpoints
   * @param rect 
   * @returns 
   */
  getPointsEx(rect:number[] | null) {
    try {
      if (!rect || rect.length !== 4)
        return [];

        const l = rect[0];
        const t = rect[1];
        const w = rect[2];
        const h = rect[3];
        const top = {x:l+w/2, y:t};
        const right = {x:l+w, y:t+h/2 };
        const bottom = {x:l+w/2,y:t+h};
        const left = {x:l,y:t+h/2};
        const pts =  [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h},top, right, bottom, left];
        return pts;
    } catch (ex) {
      console.error("failed to get points: " + ex);
      return [];
    }
  }

  

  /**
   * draw an anchor around a vertex
   * @param ctx 
   * @param pt 
   */
  drawAnchor = (ctx:any, pt:Point) => {
    try {
      const trans = ctx.getTransform();
      let r = this.getAnchorSize();
      const color = "red";
      ctx.strokeStyle = color;
      ctx.beginPath();
      ctx.arc(pt.x, pt.y, r, 0, 2 * Math.PI);
      ctx.stroke();
    } catch (ex) {
      console.error('failed to draw anchor:', ex);
    }
  }

  drawSmallCircle = (ctx:any, pt:Point) => {
    try {
      const trans = ctx.getTransform();
      let r = this.getAnchorSize()/3;
      const color = "red";
      ctx.fillStyle = color;
      ctx.beginPath();
      ctx.arc(pt.x, pt.y, r, 0, 2 * Math.PI);
      ctx.fill();
    } catch (ex) {
      console.error('failed to draw anchor:', ex);
    }
  }


  /**
   * transform a poly to a string
   * @param pts 
   */
  pointsToPoly = (pts:Point[]):string | null => {
    try {
      pts = pts.map(pt => ({x: Math.round(pt.x), y: Math.round(pt.y)}));
      const tokens = pts.map(pt => `${pt.x},${pt.y}`);
      return tokens.join(',');
    } catch (ex) {
      return null;
    }
  }
  

  /**
   * transform a string to a poly
   * @param poly 
   */
  polyToPoints = (poly: string): Point[] | null => {
    try {
      if(!poly)
        return [];

      const tokens = poly.split(',');
      const ints = tokens.map(t => parseInt(t));
      const pts = [];
      for (let i = 0; i < ints.length / 2; i++)
        pts.push({x: ints[i*2], y: ints[i*2+1]});
      return pts;
    } catch (ex) {
      console.error('failed on polyToPoints:', ex);
      return null;
    }
  }

  /**
   * gets the linewidth, factored by zoom
   */
  getLineWidth = (): number => {
    try {
      const trans = this.ctx.getTransform();
      let lineWidth = 3 / trans.a;
      lineWidth = Math.max(lineWidth,1);
      lineWidth = Math.min(lineWidth, 10);
      return lineWidth;
    } catch (ex) {
      return 1;
    }
  }

  getAnchorSize = (): number => {
    try {
      const trans = this.ctx.getTransform();
      let anchorSize = 10 / trans.a;
      anchorSize = Math.max(anchorSize,5);
      anchorSize = Math.min(anchorSize, 50);
      return anchorSize;
    } catch (ex) {
      return 1;
    }
  }

  /**
   * get percentual position in a segment
   * @param pt0 
   * @param pt1 
   * @param percentage 
   */
  getMidPoint = (pt0: Point, pt1: Point, percentage: number): Point | null => {
    try {
      const x = pt0.x + percentage * (pt1.x - pt0.x);
      const y = pt0.y + percentage * (pt1.y - pt0.y);
      return {x, y};
    } catch (ex) {
      console.error('failed to get midPoint:', ex);
      return null;
    }
  }

  /**
   * returns an internal segment
   * @param pts 
   * @param percentage 
   */
  getMidSegment = (pts: Point[], percentage: number): Point[] | null => {
    try {
      const pt0 = this.getMidPoint(pts[0], pts[1], percentage);
      const pt1 = this.getMidPoint(pts[2], pts[3], percentage);
      if (!pt0 || !pt1) throw('failed to get midpoints');
      return [pt0, pt1];
    } catch (ex) {
      return null;
    }
  }

/**
 * paints internal segments
 * @param tag annotation
 */
  paintInternalPoly = (tag: any) => {
    try {

      // WTT-421
      const tagType = common.plates.newTagType;
      if (tagType !== 'Ocr') return;
     
      const poly = tag.InternalSegments;
      if (!poly) throw('no internal segments');

      const ctx = this.ctx;
      ctx.lineWidth = this.getLineWidth();
      for (let p of poly) {
        if (!p[0] || !p[1]) throw('no segment');
        const index = poly.indexOf(p);
        // WTT-265
        ctx.strokeStyle = index === tag.hoverSegIndex ? "red" : tag.polyPlaceholder ? "yellow" : "blue";
      
        ctx.beginPath();
        ctx.moveTo(p[0].x, p[0].y);
        ctx.lineTo(p[1].x, p[1].y);
        ctx.stroke();
      }



    } catch (ex) {
      console.error('failed to paint internal poly:', ex);
    }
  }

  /**
   * paint external poly
   * @param tag annotation
   */
  paintPolyTag = (tag: any) => {
    try {
        if (!tag?.Poly?.value)
          return;

        const ctx = this.ctx;
        const pts = this.polyToPoints(tag.Poly.value);
        if (!pts) throw('failed to get points');
       
        const origin = pts.shift();
        if (!origin) throw('failed to poly origin');

        const color = "green";
        ctx.lineWidth = this.getLineWidth();
        ctx.strokeStyle = color;
        ctx.beginPath();
          ctx.moveTo(origin.x, origin.y)
          pts.forEach(pt => ctx.lineTo(pt.x, pt.y));
          ctx.lineTo(origin.x, origin.y)
        ctx.stroke();

      

        this.paintInternalPoly(tag);
        if (tag.hoverIndex > -1) {
          const pts2 = this.polyToPoints(tag.Poly.value);
          if (!pts2) throw('failed to get points');
          this.drawAnchor(ctx, pts2[tag.hoverIndex]);
        }

          // WTT-436
          if (tag === common.plates.selectedAnnotation) {
            const pts = this.polyToPoints(tag.Poly.value);
            if (pts) {
              for (let pt of pts) {
                this.drawSmallCircle(ctx, pt);
              }
            }
          }

        this.paintCharHeight(tag);


    } catch (ex) {
      console.error('failed to paint poly tag:', ex);
    }
  }

  paintCharHeight = (tag: any) => {
    try {
      if (!tag.CharHeight.value)
        return;

      // WTT-421 - show only on ocr
      if (common.plates.newTagType !== 'Ocr')
        return;

     const pts = this.polyToPoints(tag.Poly.value);
     let r = 0;
     let b = 0;
     pts?.forEach(pt => {
       r = Math.max(r, pt.x);
       b = Math.max(b, pt.y);
     });

     const x = r;
     const y = b - tag.CharHeight.value;
     const h = tag.CharHeight;
     const w = h / 2;
     const pts2 = this.getPoints([x, y, w, h]);
     const color = "magenta";
     const ctx = this.ctx;
     ctx.lineWidth = this.getLineWidth();
     ctx.strokeStyle = color;
     ctx.beginPath();
     ctx.moveTo(pts2[0].x, pts2[0].y);
     ctx.lineTo(pts2[1].x, pts2[1].y);
     ctx.lineTo(pts2[2].x, pts2[2].y);
     ctx.lineTo(pts2[3].x, pts2[3].y);
     ctx.lineTo(pts2[0].x, pts2[0].y);
     ctx.stroke();      

     ctx.font = "30px Arial";
     ctx.fillStyle = "magenta"; 
     ctx.textBaseline = "bottom";
     const charHeight = tag.CharHeight.value.toString();
     ctx.fillText(charHeight, x, y);
    } catch (ex) {
      console.error('failed to paint char height:', ex);
    }
  }

  /**
   * retrieve point of a mouse event
   * @param e mouse event
   */
  getPoint = (e: any): any => {
    try {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      return (this.ctx as any).transformedPoint(x, y);
    } catch (ex) {
      console.error('failed to getPoint', ex);
      return {x: 0, y: 0};
    }
  }

  getWindowPoint = (x: number, y: number): any => {
    try {
      if (!isFinite(x) || !isFinite(y)) {
        return {x:0, y:0};
      }
      return (this.ctx as any).untransformedPoint(x, y);
    } catch (ex) {
      console.error('failed to getWindowPoint:', ex);
      return {x: 0, y: 0};
    }
  }
  

  /**
   * save ctx matrix
   */
  saveMatrix = () => {
    try {
      const m = this.ctx.getTransform();
      this.matrix = [m.a, m.b, m.c, m.d, m.e, m.f];
    } catch (ex) {
      console.error('failed to save matrix:', ex);
    }
  };

  /**
   * restore ctx matrix
   */
  restoreMatrix = () => {
    try {
      const m = this.matrix;
      this.ctx.setTransform(...m);
    }  catch (ex) {
      console.error('failed to restoreMatrix:', ex);
    }
  }

  /**
   * extend ctx for pan and zoom
   * @param ctx 
   */
  trackTransforms = (ctx:any) => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let xform = svg.createSVGMatrix();
    ctx.getTransform = function() { return xform; };
  
    const savedTransforms:any = [];
    const save = ctx.save;
    ctx.save = function() {
      savedTransforms.push(xform.translate(0, 0));
      return save.call(ctx);
    };
    const restore = ctx.restore;
    ctx.restore = function() {
      xform = savedTransforms.pop();
      return restore.call(ctx);
    };
  
    const scale = ctx.scale;
    ctx.scale = function(sx:number, sy:number) {
      xform = (xform as any).scaleNonUniform(sx, sy);
      return scale.call(ctx, sx, sy);
    };
    const rotate = ctx.rotate;
    ctx.rotate = function(radians:number) {
      xform = xform.rotate(radians * 180 / Math.PI);
      return rotate.call(ctx, radians);
    };
    const translate = ctx.translate;
    ctx.translate = function(dx:number, dy:number) {
      xform = xform.translate(dx, dy);
      return translate.call(ctx, dx, dy);
    };
    const transform = ctx.transform;
    ctx.transform = function(a:number, b:number, c:number, d:number, e:number, f:number) {
      const m2 = svg.createSVGMatrix();
      m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
      xform = xform.multiply(m2);
      return transform.call(ctx, a, b, c, d, e, f);
    };
    const setTransform = ctx.setTransform;
    ctx.setTransform = function(a:number, b:number, c:number, d:number, e:number, f:number) {
      xform.a = a;
      xform.b = b;
      xform.c = c;
      xform.d = d;
      xform.e = e;
      xform.f = f;
      return setTransform.call(ctx, a, b, c, d, e, f);
    };
    const pt  = svg.createSVGPoint();
    ctx.transformedPoint = function(x:number , y:number) {
      pt.x = x; pt.y = y;
      return pt.matrixTransform(xform.inverse());
    };
    ctx.untransformedPoint = function(x:number , y:number) {
      pt.x = x; pt.y = y;
      return pt.matrixTransform(xform);
    };
  }

  /**
   * zoom to fit window size
   */
  unzoom = () => {
    try {
      if (!this.imageSize[0] || !this.imageSize[1])
        return;

      if (!this.windowSize[0] || !this.windowSize[1])
        return;

      const canvasAR = this.imageSize[0] / this.imageSize[1];
      const windowAR = this.windowSize[0] / this.windowSize[1];
      const index = canvasAR > windowAR ? 0 : 1;
      const factor = this.windowSize[index] / this.imageSize[index];
      this.ctx.setTransform( factor,0,0,factor,0,0);
      this.saveMatrix();
      this.paint('unzoom');

    } catch (ex) {
      console.error('failed to unzoom: ' + ex.message);
    }

  }


}