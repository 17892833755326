import React, { useEffect, useState} from 'react';
import { Select, MenuItem, IconButton, Tooltip, Tabs, Tab } from "@material-ui/core";
import {AspectRatio, CommuteOutlined } from "@material-ui/icons"
import { makeStyles } from "@material-ui/styles";
import Box from '@material-ui/core/Box'

// store hooks
import platesService from '../../services/platesService';
import common from '../../services/commonService';

/**
 * Plate settings horizontal panel
 * @param props 
 * @returns 
 */
export function PlateSettings(props: any) {

  const [value, setValue] = useState(0); // integer state
  const [tabIndex, setTabIndex] = useState(0);

  /**
   * callbacks
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      const settings = common.plates.settings;
      switch (key) {

        case "SelectedTab":
          common.plates.selectedTab = value;
          setValue(v => v + 1);
          common.notify("PlatesSelectedTabChanged");
          break;

        case "PlatesCleared":
        case "AnnotationDeleted":
        case "AnnotationSelected":
        case "AnnotationChanged":
          const prev = common.plates.selectedTab;
          switch (common.plates.selectedTab) {
            case -1:
              if (!disablePlateFaces())
                common.plates.selectedTab = 0;
              else if (!disableKeyboards()) 
                common.plates.selectedTab = 1;
              break;

            case 0:
              if (disablePlateFaces())
                common.plates.selectedTab = disableKeyboards() ?  -1: 1;
              break;

            case 1:
              if (disableKeyboards())
                common.plates.selectedTab = disablePlateFaces() ? -1 : 0;
              break;
          } 
          setValue(v => v + 1);
          if (common.plates.selectedTab !== prev)
            common.notify("PlatesSelectedTabChanged");
          break;

        
      
      }
    } catch (ex) {
      console.error('failed to handle plateSettings changes: ', ex);
    }
  }

  /**
   * determines if plate face exists for selected annotation
   * @returns 
   */
  const plateFacesExist = () => {
    try {
      const hazard = common.plates.selectedHazard;
      if (hazard) return true;
       
      const ant = common.plates.selectedAnnotation;
      if (!ant) return false;
      const diamond = ant.PlateType?.value === 4;
      // WTT-414 - alwasy show diamonds
      if (diamond) return true;

      const nation = ant?.Nation?.value;
      const region = ant?.Region?.value;
      const faces = common.plates.plateFaces;
      if (!region)
        return faces.some(pf => pf.nation === nation);

      return faces.some(pf => pf.nation === nation && pf.region === region);

    } catch (ex) {
      console.error('plateFacesExist error:', ex);
      return false;
    }
  }

  /**
   * should platefaces be disabled (no platefaces for selected annotation)
   * @returns 
   */
  const disablePlateFaces = () => {
    try {
      return !plateFacesExist();
    } catch (ex) {
      console.error('failed on disable plateFaces', ex);
      return true;
    }
  }

  /**
   * should keyboards be disabled
   * @returns 
   */
  const disableKeyboards = () => {
    try {
      const ant = common.plates.selectedAnnotation;
      if (!ant)
        return true;

      const nation = ant?.Nation?.value || null;
      return (nation !== 'THA' && nation !== 'SRB');
    } catch (ex) {
      console.error('failed on disable keyboard');
      return true;
    }
  }

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  },[]);

  const settings = common.plates.settings;
  const classes = useStyles();
  return <Box display="flex" height={50}  flexDirection="row"
  style={{}}>

    <Box width={40}></Box>
    <Tabs  value={common.plates.selectedTab >= 0 ? common.plates.selectedTab : false } onChange={ (event, newValue) => handleChanges("SelectedTab", newValue)}>
      <Tab disabled={disablePlateFaces()} label="Plate face"></Tab>
      <Tab disabled={disableKeyboards()} label="Keyboard"></Tab>
    </Tabs>
  </Box>
}

const useStyles = makeStyles({
  button: {
  },
  radio: {
    margin: 0,
    padding: 0,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
  },
  combo: {
  }

});