import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { Autocomplete } from '@material-ui/lab';
import { ListItemText, TextField, Input, Box, Button, CircularProgress, Typography } from '@material-ui/core'
import { Timer as TimerIcon, Create as CreateIcon, Check as CheckIcon, PlayArrow as PlayIcon, Save as SaveIcon,
   SkipNext, SkipPrevious, Help, ThumbUp as TestPassedIcon, ThumbDown as TestFailedIcon, Language as BrowserIcon,
   Storage as DatabaseIcon} from '@material-ui/icons';
import common from '../services/commonService';

class ProgressData {
  message: string = '';
  percentage: number = 0;
  icon: string = '';

}

function CircularProgressWithLabel(props:any) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} style={{color:'white'}} />
      <Box style={{color:'white'}}
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {`${Math.round(props.value)}%`}

        {/* <Typography variant="caption"  component="div" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography> */}
      </Box>
    </Box>
  );
}


export function GatewayControl(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 
  const [message, setMessage] = React.useState('');
  const [gatewayValue, setGatewayValue] = React.useState('');
  const [progress, setProgress] = React.useState<ProgressData>();

  const classes = useStyles();

  const icons = [TimerIcon, CreateIcon, CheckIcon, PlayIcon];



  /**
   * all callbacks
   * @param key 
   * @param value 
   */
  const handleCallback = (key: string, data: any = null) => {
    try {
      switch(key) {
        case "SendNotification":
          const gatewayMessage = (document.getElementById("notifyInput") as HTMLInputElement).value;
          common.notify("GatewayMessage", gatewayMessage as any);
          setMessage('');
          break;

        case "GatewayValueArrived":
          setGatewayValue(data);
          break;

        case "GatewayShowProgress":
          setProgress(data);
          break;
      }

    } catch (ex) {
      console.error('failed ')
    }
  }



  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleCallback(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);


  return <Box display="flex" flexDirection="row">    



  <Box display="flex" width="400px" flexDirection="row" flex="1" height="50px" alignItems="center"
  style={{background: '#29367d', color: 'white'}}>
    <Box width="10px"/>
    <CircularProgressWithLabel value={progress?.percentage || 0}/>
    <Box width="10px"/>
      {progress?.icon === 'Wait' && <TimerIcon/> }
      {progress?.icon === 'Check' && <CheckIcon/>}
      {progress?.icon === 'Save' && <SaveIcon/>}
      {progress?.icon === 'Database' && <DatabaseIcon/>}
      {progress?.icon === 'TestPassed' && <TestPassedIcon/>}
      {progress?.icon === 'TestFailed' && <TestFailedIcon/>}
      {progress?.icon === 'Browser' && <BrowserIcon/>}
      <Box width="10px"/>
    <Box display="inline" fontSize="16px">
     { progress?.message}
    </Box>
  </Box>
  


  
  <Box  display="flex" flexDirection="column"  width="50px"  height="50px" margin="0px" padding="0px" >
    <Box display="flex" flexDirection="row" alignItems="center" height="40px" margin="0px"  padding="0px"  >
    <Button className={classes.button} id="sendNotification"
      onClick={() => handleCallback('SendNotification')} 
      style={{fontSize:8, height:'18px', width:'10px', maxWidth:'10px' }}>Set</Button> 
            <input id="notifyInput" 
            style={{color: 'transparent', background:'transparent', borderWidth:'0px', border: 'none'}}/> 
   
  </Box>
  <Box display="flex" flexDirection="row" alignItems="center">
      <Button className={classes.button} 
      style={{fontSize:8, height:'18px', width:'10px', maxWidth:'40px' }}>Get</Button>
          <div id="replyDiv" 
          style={{color:'transparent', background:'transparent', width:'10px', maxWidth:'10px', height:'10px', maxHeight:'10px'}}>{gatewayValue}</div>
  </Box>
  </Box>




  </Box>



}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 4,
    height: '20px',
    fontSize: '10px',
    color: 'transparent',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'transparent',
  },
  '&:disabled': {
    backgroundColor: 'gray',
    color: 'black',
},
},
  formControl: {
    margin: 0,
    padding: 0,
  },

  textField: {
    width:100,
    maxWidth:'100px'
  },
  resize:{
    fontSize:8
  },
}));
