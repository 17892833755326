import React, {useState, useEffect} from 'react';
import  { FormControlLabel, Button, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { PlateEditor } from './plateEditor';
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';


export function BccmEntry(props: any) {
  const field = props.field;
  const value= props.value;
  const checked = props.checked;
  return   <Box display="flex" flexDirection="row" alignItems="center" height="22px">
  <Checkbox checked={checked}></Checkbox>
  <Box display="inline">{field}: <b>{value}</b> </Box>
</Box>
}

export function BccmEntry2(props: any) {
  const field = props.field;
  const value= props.value;
  return   <Box display="flex" flexDirection="row" alignItems="center">
    <Box width="4px"></Box>
  <Box display="inline">{field}: <b>{value}</b> </Box>
</Box>
}

/**
 * editor for plate annotations - contains tag editors
 * handles most editing operations
 * @param props 
 * @returns 
 */
export function BccmViewer(props: any) {

  const [value, setValue] = useState(0); // integer state

  const ant = props.ant;
  

  /**
   * common change handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      var record = common.plates.selectedRecord;
      switch (key) {

    
      }
    } catch (ex) {
      console.error('failed to handle bccm changes: ', ex);
    }
  }

  const getCountry = (raw: string) => {
    return raw ? raw.split(' ')[0] : '';
  }

  /**
   * re
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  /**
   * render
   */
  const classes = useStyles();
  const record = common.plates.selectedRecord;
  const ants = common.plates.selectedRecord?.Annotations;
  const tooltips = common.plates.settings.tooltips;
  const dim = `${ant.lp_lps_dimension_width_mm?.value}x${ant.lp_lps_dimension_height_mm?.value}`;
  const lppPos = [  
    ant.lpp_top_left_col?.value, 
    ant.lpp_top_left_row?.value,
    ant.lpp_top_right_col?.value,
    ant.lpp_top_right_row?.value,
    ant.lpp_bot_left_col?.value, 
    ant.lpp_bot_left_row?.value,
    ant.lpp_bot_right_col?.value,
    ant.lpp_bot_right_row?.value
    ].map(x => parseInt(x,10));
  const lpp = {id: ant.lpp_id?.value, date: ant.lpp_signature_date?.value, detector:ant.lpp_detector_name?.value};

  return ( 
    <Box display="flex" flex="1" flexDirection="column" border="blue solid 3px" margin="4px" width="400px" maxWidth="400px" minWidth="400px" >
        <b>BCCM tag {ant.lp_id?.value}</b>

          <Box display="flex" flexDirection="row">

            <Box display="flex" flexDirection="column">
               <Box display="inline" marginLeft="10px"><b>Car</b></Box>
              <BccmEntry field="Category" checked={ant.car_category_human?.value === 'True'} value={ant.car_category?.value}></BccmEntry>
              <BccmEntry field="Make" checked={ant.car_make_human?.value === 'True'} value={ant.car_make?.value}></BccmEntry>
              <BccmEntry field="Model" checked={ant.car_model_human?.value === 'True'} value={ant.car_model?.value}></BccmEntry>
              <BccmEntry field="Color" checked={ant.car_color_human?.value === 'True'} value={ant.car_color?.value}></BccmEntry>
              <BccmEntry2 field="Ignore" value ={ant.car_ignore?.value} ></BccmEntry2>
              <BccmEntry2 field="Starred" value ={ant.car_starred?.value} ></BccmEntry2>
            </Box>

            <Box display="flex" flexDirection="column">
            <Box display="inline" marginLeft="10px"><b>Plate</b></Box>
            <BccmEntry field="Country" checked={ant.lp_country_human?.value === 'True'} value={ant.lp_country?.value}></BccmEntry>
            <BccmEntry field="Region" checked={ant.lp_country_region_human?.value === 'True'} value={ant.lp_country_region?.value}></BccmEntry>
            <BccmEntry field="Text" checked={ant.lp_text_human.value === 'True'} value={ant.lp_text.value}></BccmEntry>
            {/* <BccmEntry field="Dimension" checked={ant.lp_lps_dimension_human.value === 'True'} value={dim}></BccmEntry>

            <Box display="flex" flexDirection="row" fontSize="10px">
              LPP ID: {lpp.id}
            </Box>
            <Box display="flex" flexDirection="row" fontSize="10px">
              LPP detector: {lpp.detector}, date:{lpp.date}
            </Box>
            <Box display="flex" flexDirection="row" fontSize="10px">
              LPP pos: {lppPos[0]},{lppPos[1]},{lppPos[2]},{lppPos[3]},{lppPos[4]},{lppPos[5]},{lppPos[6]},{lppPos[7]}
            </Box> */}


              </Box>


          </Box>


       

       

       
    </Box>

 
 
  )

}

const useStyles = makeStyles({

  radio: {
    margin: 0,
    padding: 0,
    fontSize: 40,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
    fontSize:40,
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

});