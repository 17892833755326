import React, {useState, useEffect} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import Box from '@material-ui/core/Box'
import { Plate } from '../components/plate/plate';
import common from '../services/commonService';




export function PlatesPage() {

  const [value, setValue] = React.useState(0); 


  const handleCallbacks = (key: string, data: any) => {

    switch(key) {
      case 'LoggedIn':
          setValue(v => v + 1);
          break;
    }
  
  
  }



  const classes = useStyles();


  console.log('Program launched ...');

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallbacks(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);



	return (
		<div className={classes.root}>
        <Box display="flex" flex="1" flexDirection="column">
          { common.loggedIn &&  <Plate/> }
        </Box>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
  },
}));
