import common from './commonService';
class OrderService {

    constructor() {
        common.notifier$.subscribe(msg => {
            switch(msg.name) {
                case 'SettingsDialogClosed':
                    this.handleOrderSettings();
                    break;

                case 'PlateFullScanningCompleted':
                    this.handleScanningCompletion();
                    break;

            }
        })
    }

    initialize() {
        try {

        } catch (ex) {
            console.error('failed to initialize order service:', ex);
        }
    }

    /**
     * full scanning was completed - 
     * @returns 
     */
    async handleScanningCompletion() {
        try {
            var settings = common.plates.settings;
            console.assert(settings, "no plate settings");
            // no ordering - refresh filter to restore normal ordering
            if (!settings.orderBy) {
                common.notify("PlatesUpdateFilter");
                return;
            }

        

            const confirmed = await common.confirm("Plate list order", "Plate list order is set, do you want to apply it ?");
            common.app.orderApply = confirmed === true;
            common.notify('PlatesUpdateFilter');
        } catch (ex) {
            console.error('failed to handle scanning completion');
        }
    }

    async handleOrderSettings() {
        try {
            var settings = common.plates.settings;
            // if order was not changed - and 
            if (!common.app.orderChanged && settings.orderBy === common.app.orderApply)
                return;

            console.assert(settings, "no plate settings");
            // no ordering - refresh filter to restore normal ordering
            if (!settings.orderBy) {
                common.notify("PlatesUpdateFilter");
                return;
            }
            common.notify('PlatesForceScan');
        } catch (ex) {
            console.error('')
        }
    }



}
const orderService: OrderService = new OrderService;
export default orderService;