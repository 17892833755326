import React, {useState, useEffect} from 'react';
import  { } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import Box from '@material-ui/core/Box'
import ResizeObserver from 'react-resize-observer';

// store hooks
import common from '../../services/commonService';

/**
 * Show plate faces selection
 * @param props 
 * @returns 
 */
export function PlateFaces(props: any) {

  const [platesHeight, setPlatesHeight] = useState(200);
  const [value, setValue] = useState(0);


  /**
   * aspect ratio filter
   * @param pf 
   * @returns 
   */
  const passAspectFilter = (pf:any): boolean => {
    try {
      const ant = common.plates.selectedAnnotation;
      if (!ant) return false;

      // no size - allways pass
      if(!pf.size)
        return true;

      var p = ant.Poly.value.split(",").map((x:string) => parseInt(x));
      var minX = Math.min(p[0], p[2], p[4], p[6]);
      var maxX = Math.max(p[0], p[2], p[4], p[6]);
      var minY = Math.min(p[1], p[3], p[5], p[7]);
      var maxY = Math.max(p[1], p[3], p[5], p[7]);
      const ratio = (maxX - minX) / (maxY - minY);
      var faceRatio = pf.size[0] / pf.size[1];

      var low = 2.5;
      var high = 3;

      if (ratio >= low && ratio <= high)
        return true;

      const square = ratio < low;
      if (square)
        return faceRatio < high;

      return faceRatio > low;

    } catch (ex) {
      console.error('failed on passLetterFilter:', ex);
      return false;
    }
  }

  /**
   * content filter
   * @param pf 
   * @returns 
   */
  const passLetterFilter = (pf:any): boolean => {
    try {
      const ant = common.plates.selectedAnnotation;
      if (!ant) return false;
      var plate = ant.PlateNumber?.value || '';
      const nation = ant.Nation.value;
      // lettered means the plate type requires at least one letter or question mark as platenumber
      var lettered = pf.lettered;
      if (!lettered)
        return true;

      // the plateface is lettered - verify that the plate number contains at least one letter or a question mark.
      var regExp = /[a-zA-Z]/g;
      var hasLetters = regExp.test(plate);
      var hasWildcard = plate?.includes("?");

      if (hasLetters && lettered)
        return true;

      if (hasWildcard)
        return true;

      return (hasLetters === lettered);
      
    } catch (ex) {
      console.error('failed on passLetterFilter:', ex);
      return false;
    }
  }

  const passVehicleFilter = (pf:any): boolean => {
    try {
      const ant = common.plates.selectedAnnotation;
      if (!ant) return false;
      const vehicle = ant.VehicleType?.value;
      if (!vehicle)
        return true;

      if (pf.motorbike === undefined)
        return true;

      // 7 - motorbike (vehicle-types.txt)
      if (pf.motorbike === true && vehicle !== 7)
        return false;

        if (pf.motorbike === false && vehicle === 7 )
        return false;

      return true;
      
    } catch (ex) {
      console.error('failed on passLetterFilter:', ex);
      return false;
    }
  }


 

  /**
   * filter
   * @param pf 
   * @returns 
   */
  const plateFaceFilter = (pf:any):boolean => {
    try {

      const hazard = common.plates.selectedHazard;
      if (hazard && pf.type === "hazard") 
        return true;

      // const parsed = parsePlateFace(pf);
      const ant = common.plates.selectedAnnotation;
      if (!ant) return false;

      // annotation can be inside or vehicle
      if (!ant.Nation)
        return false;

      const nation = ant.Nation.value;
      if (pf.nation !== nation)
        return false;

      const region = ant.Region.value;
      if (region && region.length > 0 && pf.region !== region)
        return false;

      if (!common.plates.settings.filterFaces)
        return true;

      const passedVehicle = passVehicleFilter(pf);
      if (!passedVehicle)
        return false;

      const passedLetter =  passLetterFilter(pf);
      if (!passedLetter)
        return false;

      const passedAspect = passAspectFilter(pf);
      if (!passedAspect)
        return false;

      return true;

    } catch (ex) {
      console.error('failed on filtering plate faces:', ex);
      return false;
    }
  }

  const handleImageClick = ( plateFace: any) => {
    try {

      // WTT-421 - disable on char editing
      if (common.plates.newTagType === 'Chars') return;

      const selected = common.plates.plateFaces.find(pf => pf.selected);
      if (selected === plateFace) {
        common.plates.plateFaces.forEach((pf:any) => pf.selected = false);
        common.notify("PlateFaceSelected", null);
        return;
      }
      
      common.plates.plateFaces.forEach((pf:any) => pf.selected = false);
      plateFace.selected = true;     
      common.notify("PlateFaceSelected", plateFace);
    } catch (ex) {
      console.error('failed to handle image click:', ex);
    }

  }

  /**
   * component callback
   * @param key 
   * @param value 
   */
  const handleCallbacks = (key: string, value: any) => {
    try {
      switch(key) {
        case 'SelectedPlateFaceChanged':
        case "AnnotationSelected":
        case "AnnotationChanged":
        case "PlateSettingsChanged":
          setValue(v => v + 1);
          break;
      }
    
    } catch (ex) {
      console.error('failed to handle callback:', ex);
    }
  }

  const getHeight = (pf:any) => {
    try {
      if (hazard)
        return pf?.selected ? "100px": "80px";
      else
        return pf?.selected ? "50px" : "40px";

    } catch (ex) {
        return 0;
    }
  }

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallbacks(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  const classes = useStyles();
  const ant = common.plates.selectedAnnotation;
  const hazard = common.plates.selectedHazard;

  return   <Box display="flex" flex="1" flexDirection="column" style={{background:'white'}}>

{ (ant || hazard) && 
  <Box display="flex" flex="1" flexDirection="column" style={{background:'white', overflow:'auto'}} >

    <Box display="flex" flexWrap="wrap" flexGrow={false}  flex="1" height={platesHeight} maxHeight={platesHeight} >

  

    { common.plates?.plateFaces?.filter((pf:any) => plateFaceFilter(pf)).map((pf:any) => (
           <Box display="flex" flexDirection="column" margin={1}    >
              <img src={pf.path} height={getHeight(pf)} style={{border: pf.selected ?  "solid lawngreen 7px" : "none"}}
             onMouseDown={(e) => handleImageClick(pf)} ></img>
         </Box>
       ))}
    </Box>

  </Box>
}
  </Box>
}

const useStyles = makeStyles({
  button: {

  },
  radio: {
    margin: 0,
    padding: 0,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
  },
  combo: {

  }

});