import React, {useState, useRef} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import {Box, Switch, Slider, Select, MenuItem, Checkbox, FormControlLabel, Tooltip, Button, FormControl, FormLabel,RadioGroup, Radio, Typography } from '@material-ui/core'
import axlesService from '../../services/axlesServices';

export function CabProps(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 
  const canvasRef = useRef(null);

  const handleChanges = (key: string, value: any) => {
    try {
      switch(key) {
        case "Coverage":
          tag.zone = value;
          common.axles.imageDirty = true;
          setValue(v => v + 1);
          common.notify("AxleTagsChanged");
          break;
     
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  React.useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    axlesService.canvas.cabCanvas = canvas;
    common.notify('AxlesCabCanvased');
    return () => {
      axlesService.canvas.cabCanvas = null;
    };
  });

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);

  const classes = useStyles();
  const sequence = common.axles.sequence;
  const codes = common.axles.codes;
  const tag = common.axles.tag;

  const pixelsPerMs = common.axles.sequence?.stitcher?.pixelsPerMs || 0;
  const stitchOffset = common.axles.sequence?.stitcher?.stitchOffset || 0;
  
const w:number = props.width;
const h:number = props.height;
const positions: string[][] = [["front", "Front"],["rear", "Rear"], ["full","Full"]];


  return <Box display="flex" width={w} flexDirection="column" flex="1" style={{}} padding={"4px"} >
    <Box display="flex" flexDirection="column" margin="2px">
      <Typography>Zone:</Typography>
      <RadioGroup  value={tag.zone} onChange={e =>handleChanges("Coverage", e.target.value)}>
        {positions.map(p => <FormControlLabel  value={p[0]} control={<Radio size="small" />} label={p[1]} style={{height:'20px'}} />)}
      </RadioGroup>
    {/* <Select
      onChange={e =>handleChanges("Coverage", e.target.value)}
     value={tag.zone}>{ positions.map(p => <MenuItem value={p[0]}>{p[1]}</MenuItem>)}</Select> */}
    </Box>

  </Box>

}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 9,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
}));
