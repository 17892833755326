import React, {useEffect, useState, useRef } from 'react';
import {Typography, Box, Button, Dialog, DialogTitle, DialogContent,
    DialogActions, FormControlLabel, Checkbox, Slider } from '@material-ui/core'
import { Search,  PieChart as PieChartIcon } from '@material-ui/icons';
import common from '../../services/commonService';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import insideInspection from '../../services/insideInspection';
import panInside from './panInside';
import { InsideEntry, InsideItem } from '../../data/platesData';
import { selectedRowsCountSelector } from '@material-ui/data-grid';





/**
 * select dataset, show dataset statistics
 * @param props
 * @returns
 */
export function PassengerDialog (props:any) {

  const[value, setValue] = useState(0);
  const { onClose, open } = props;
  const canvasRef = useRef(null);

  const handleClose = async () => {

    const passengers = common.plates.selectedInside?.getPassengers() || [];
    const drivers = passengers.filter(p => p.occupied.value && p.driver.value);
    if (drivers.length === 0) {
      common.alert('Inside inspection', 'Please assign a driver');
      return;
    }
    // const notTagged = passengers.filter(p => p.notTagged()) || [];
    // if (notTagged.length > 0) {
    //   common.alert('Inside inspection', 'Not all passengers are tagged');
    //   return;
    // }

    



    onClose();
    common.notify('InsidePassengersClosed');
  }

/**
 * perform statistics on selected dataset
 * @returns
 */

const clearPassenger = (p:InsideItem) => {
  try {
    p.beltVisible.value = false;
    p.beltFastened.value = false;
    p.phoneVisible.value = false;
    p.phoneUsed.value = false;
    p.driver.value = false;
    p.faceRect.value = [];
    p.faceVisible.value = false;

  } catch (ex) {
    console.error('failed to clear passenger:', ex);
  }
}


/**
 * dispatcher
 */
const handleChanges = (arg: string, value: any) => {
  try {
    // const passenger = common.plates.selectedInside?.selectedPassenger;
    // if (!passenger)
    //     return;

      const cnvs = (canvasRef.current as any) as HTMLCanvasElement;

    switch(arg) {

      case 'gain':
        common.plates.insideGain = value;
        setValue(v => v + 1);
        common.notify('InsideGainChanged');
        break;


        case 'RightDriver':
        case 'LeftDriver':
          if (inside) {

            const index = arg === 'RightDriver' ? 2 : 0;
            inside.passengers[1][index].driver.value = true;
            inside.selectedPassenger = inside.passengers[1][index];
            inside.selectDriver = false;
            setValue(v => v + 1);
          }
          break;

        case 'Driver':
          if (inside) {
            if (value)
              inside.getPassengers().forEach(p => p.driver.value = false);

            if (passenger) passenger.driver.value = value;
            setValue(v => v + 1);
          }
          break;

        case 'InsidePassengerChanged':
          setValue(v => v + 1);
          break;

        case "BeltVisible":
           if (passenger) {
             passenger.beltVisible.value = value;
             setValue(v => v + 1);
            }
            break;

        case "BeltFastened":
            if(passenger) {
              passenger.beltFastened.value = value;
              setValue(v => v + 1);
            }
            break;

        case "PhoneVisible":
          if (passenger) {
            passenger.phoneVisible.value = value;
            setValue(v => v + 1);
          }
            break;

        case "PhoneUsed":
          if (passenger) {
              passenger.phoneUsed.value = value;
              setValue(v => v + 1);
            }
            break;

        case "FaceVisible":
            if (passenger) {
              passenger.faceVisible.value = value;
              if (!value) {
                passenger.faceRect.value = [];
                common.notify('InsidePaint');
              }
              setValue(v => v + 1);
            }
            break;

        case 'InsidePassengerOpened':
            const canvas = (canvasRef.current as any) as HTMLCanvasElement;
            insideInspection.setCanvas(canvas);
            common.notify('InsidePaint');
            break;

          case 'previous':
            common.notify('InsidePassengerNavigate', false as any);
            break;

          case 'next':
            common.notify('InsidePassengerNavigate', true as any);
            break;

          case 'PassengerChecked':
            value.passenger.occupied.value = value.checked;
            if (!value.checked)
              clearPassenger(value.passenger);
            common.notify('InsideSelectPassenger', value.passenger as any);
            break;

          case 'PassengerClicked':
            if (value?.occupied.value)
              common.notify('InsideSelectPassenger', value as any);
            break;


    }

  } catch (ex) {
    console.error('failed on handleCallback passengers');
  }
}


  const classes = useStyles();

  /**
   * subscribe, unsubscribe to events
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  useEffect(() => {
      // const canvas = (canvasRef.current as any) as HTMLCanvasElement;
      // insideInspection.setCanvas(canvas);  
    return () => {
    };
  });



  const handleMouseDown = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    panInside.mouseDown(evt);
  }
  const handleMouseWheel = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    panInside.mouseWheel(evt);
  }

  const handleMouseMove = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    panInside.mouseMove(evt);
  }

  const handleMouseUp = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    panInside.mouseUp(evt);
  }
  const handleMouseLeave = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    panInside.mouseLeave(evt);
  }

  const handleMouseClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    panInside.click(evt);
  }

  const handleDoubleClick = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    panInside.doubleClick(evt);
  }

  const getBeltIcon = (item?:InsideItem) => {
    try {
      if (!item)
        return '';
    
      const visible = item?.beltVisible?.value === true;
      const fastened = item?.beltFastened?.value === true;
      if (visible && fastened)
        return 'icons/greenBelt.png';
      else if (visible)
        return 'icons/redBelt.png';
      else if (fastened)
        return 'icons/belt.png';
      
      return '';

    } catch(ex) {
      console.error('failed to get belt icon')
    }
  }

  const getPhoneIcon = (item?:InsideItem) => {
    try {
      if (!item)
        return '';
    
      const visible = item?.phoneVisible?.value === true;
      const used = item?.phoneUsed?.value === true;
      if (visible && used)
        return 'icons/greenCell.png';
      else if (visible)
        return 'icons/redCell.png';
      else if (used)
        return 'icons/cell.png';
      
      return '';

    } catch(ex) {
      console.error('failed to get belt icon')
    }
  }

  const passengerCell = (item?: InsideItem) => {

    const selected = item === common.plates.selectedInside?.selectedPassenger;

    return (<td style={{border:'solid 1px black'}}>
      <Box display="flex" flexDirection="column" flex="1"  minHeight="50px"  style={{background: selected ? 'lightGreen' : 'transparent'}} onClick={() => handleChanges('PassengerClicked', item)} >


          <Box display="flex" flexDirection="row"  alignItems="center" minWidth="40px">
              <Checkbox disabled={disabled('Passenger', item)} size="small" onChange={(e) => handleChanges('PassengerChecked', {passenger:item, checked: e.target.checked})}
              checked={item?.occupied?.value} style={{ margin:'4px', width:'20px', height:'20px'}} />
           </Box>
           <Box display="flex" flexDirection="row" alignItems="center" margin="2px">
              {item?.driver.value && <img style={{width:'16px', height:'16px'}} src='icons/wheel3.png'/>}

              {getBeltIcon(item) && <img  style={{width:'16px', height:'16px'}} src={getBeltIcon(item)}></img>}
              {getPhoneIcon(item) && <img  style={{width:'16px', height:'16px'}} src={getPhoneIcon(item)}></img>}
              {item?.faceVisible.value && <img style={{width:'16px', height:'16px'}} src='icons/person.png'/>}
          </Box>
  </Box>
    </td>)
  }

  const disabled = (id:string, data: any = null) => {
    try {

      const driver = inside?.getPassengers()?.find(p => p.driver.value);
      const selected = data === common.plates.selectedInside?.selectedPassenger;

      switch(id) {
        case 'Previous':
        case 'Next':
        case 'Ok':
          return selectDriver;

        case 'Passenger':
          {
            if (!inside)
              return true;

            const canDrive = data === inside.passengers[1][0] || data === inside.passengers[1][2];
            if (!driver && !canDrive)
              return true;
          }
          break;

        case 'BeltVisible':
          if (!driver || !selected)
            return true;

          return data?.occupied?.value === false;

        case 'BeltFastened':
          if (!driver || !selected)
            return true;

          if (!data?.occupied?.value)
            return true;
          break;

        case 'PhoneVisible':
          if (!driver || !selected)
            return true;
          return data?.occupied?.value === false;

        case 'PhoneUsed':
          if (!driver || !selected)
            return true;
          return (data?.occupied?.value === false);


        case 'FaceVisible':
            if (!driver || !selectedRowsCountSelector)
              return true;
            return data?.faceRect?.value?.length !== 4;
        
        case 'Driver':
          if (data?.occupied?.value === false || !selected)
            return true;

          if(inside) {
            if (driver === data)
              return false;

            const canDrive = data === inside.passengers[1][0] || data === inside.passengers[1][2];
            if (!canDrive)
              return true;

            return !!driver;
          }
            break;
      }
      return false;
    } catch (ex) {
      console.error('failed on disabled:', ex);
    }
  }

  const inside = common.plates.selectedInside;
  const passenger = inside?.selectedPassenger;
  const selectDriver = inside?.selectDriver || false;
  const insideGain = common.plates.insideGain;




  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xl" disableBackdropClick={true} >
      <DialogTitle >
        <Typography>Passenger editor</Typography>
      </DialogTitle>

      <DialogContent>

        <Box display="flex" flexDirection="row">

       

          {!selectDriver &&
            <Box display="flex"  flexDirection="column">

              <table style={{border:'solid 1px black', marginRight:'4px'}}>
                  <tr  style={{border:'solid 1px black'}}>
                    <td rowSpan={2} >
                      <img style={{cursor:'pointer'}} height="70px"  src="icons/carTop.png"></img>
                    </td>
                      {passengerCell(inside?.passengers[0][0])}
                      {passengerCell(inside?.passengers[0][1])}
                      {passengerCell(inside?.passengers[0][2])}
                  </tr>

                  <tr style={{border:'solid 1px black'}}>
                      {passengerCell(inside?.passengers[1][0])}
                      {passengerCell(inside?.passengers[1][1])}
                      {passengerCell(inside?.passengers[1][2])}
                  </tr>
              </table>

            <FormControlLabel   control={<Checkbox checked={passenger?.driver.value || false}
              disabled={disabled('Driver', passenger)}
              onChange={e => handleChanges("Driver", e.target.checked)}/>} label="Driver" />


      
            <FormControlLabel   control={<Checkbox checked={passenger?.beltVisible.value || false}
             disabled={disabled('BeltVisible', passenger)}
                onChange={e => handleChanges("BeltVisible", e.target.checked)}/>} label="Belt visible" />

            <FormControlLabel   control={<Checkbox checked={passenger?.beltFastened.value || false}
                disabled={disabled('BeltFastened', passenger)}
                onChange={e => handleChanges("BeltFastened", e.target.checked)}/>} label="Belt fastened" />

            <FormControlLabel   control={<Checkbox checked={passenger?.phoneVisible.value || false}
                disabled={disabled('PhoneVisible', passenger)}
                onChange={e => handleChanges("PhoneVisible", e.target.checked)}/>} label="Cell visible" />

            <FormControlLabel   control={<Checkbox checked={passenger?.phoneUsed.value || false}
                disabled={disabled('PhoneUsed', passenger)}
                onChange={e => handleChanges("PhoneUsed", e.target.checked)}/>} label="Cell used" />

            <FormControlLabel   control={<Checkbox checked={passenger?.faceVisible.value || false}
                disabled={disabled('FaceVisible', passenger)}
                onChange={e => handleChanges("FaceVisible", e.target.checked)}/>} label="Face visible" />

        

                <Box display="flex" flexDirection="column" flex="1"/>




            </Box>

          }

            <div style={{width:"800px", height:"450px", overflow:'hidden'}}>
                    <canvas ref={canvasRef}
                onMouseDown={handleMouseDown}
                onWheel={handleMouseWheel}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
                onClick={handleMouseClick}
                onDoubleClick={handleDoubleClick}
                width={1920}
                height={1200}
                style={{background:'lightGray'}}/>
            </div>



        </Box>

    </DialogContent>
    <DialogActions>
          <Box display="flex" flex="1" flexDirection="row" alignItems="center">
            <Typography style={{margin:'4px'}}>Gain:</Typography>
          <Slider style={{margin:'4px'}} value={insideGain} onChange={(e,value) => handleChanges('gain', value)} ></Slider>
          <Box flexDirection="column" flex="1"/>
          <Button disabled={disabled('Previous')} className={classes.button} onClick={() => handleChanges('previous', null)} color="primary" autoFocus>Previous</Button>
          <Button disabled={disabled('Next')}  className={classes.button} onClick={() => handleChanges('next', null)} color="primary" autoFocus>Next</Button>
          <Button disabled={disabled('Ok')}  className={classes.button} onClick={() => handleClose()} color="primary" autoFocus>Ok</Button>
          </Box>
        </DialogActions>
</Dialog>
  );
}

PassengerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  '&:disabled': {
    backgroundColor: 'gray',
    color: 'black',
},
  },
  combo: {
    width: 150,
  },
  grid: {

  },
}));