import common from './commonService';
const axios = require ('axios');

class SearchService {

  constructor() {
    common.notifier$.subscribe(msg => {
      switch(msg.name) {
        case 'SearchFind':
          this.handleSearch();
          break;

        case 'SearchOpenChanged':
          this.handleSearchOpened();
          break;
      }
    });

  }

  initialize() {
    try {

    } catch (ex) {
      console.error('failed to initialize search service:', ex);
    }
  }

  wrapSingleSequenceItem(item: any) {
    try {
      // by convention - id '0' - single image seqence.
      this.wrapSingleSequence({_id: '0'});
    } catch (ex) {
      console.error('failed to wrap single sequence item:', ex);
    }
   }

  wrapSingleSequence(rec: any) {
    try {
      rec.id = rec._id;
      rec.index = 1;
      rec.status = "unvisited";
      rec.TagCount = 0;
      rec.state = {
        lastSaved: false, 
        problematic: false,
        bookmark: false,
        vehicleClass: 'data/vehicles/000_UNK_0_100.png'};

      const records = [];
      records.push(rec);
      common.axles.records = [];
      common.notify('AxlesDatasetLoaded');
      common.axles.records = records;
      common.axles.unfiltered = records;
      common.mode = 'AXLES';
      common.notify('ApplicationModeChanged');
      common.notify('AxlesDatasetLoaded');

    } catch (ex) {
      console.error('failed to wrap single record:', ex);
    }
  }  

  delay = (n: number) => {
    return new Promise(function(resolve){
        setTimeout(resolve,n);
    });
  }
  

  async wrapSinglePlateRecord(rec: any) {
    try {
      rec._id = rec._id;
      rec.index = 1;
      rec.match = 0;
      rec.status = "unvisited";
      rec.state = {
        lastSaved: false, 
        problematic: false,
        bookmark: false};

      const records = [];
      records.push(rec);
      common.plates.records = [];
      common.notify('PlatesDatasetLoaded');
      common.plates.records = records;
      common.plates.unfiltered = records;
      common.mode = 'PLATES';
      common.notify('ApplicationModeChanged');
      common.notify('PlatesDatasetLoaded');
      // allow gridapi to load
      await this.delay(200);
      common.notify('SelectPlateByIndex', 1 as any);



    } catch (ex) {
      console.error('failed to wrap single record:', ex);
    }
  }

  /**
   * search by id (ocr, sequence or sequence item)
   * @returns 
   */
  async handleSearch() {
    try {
      const search = common.app.search;
      if (!search.searchText)
        return;

      if (search.searching)
        return;

      search.searching = true;
      const id = search.searchText;
      const url = process.env.REACT_APP_SERVER_URL;
      try {
        const itemUrl = `${url}/items/${id}`;
        const res = await axios.get(itemUrl, {headers: {'Authorization': common.loginToken}})
        const record = res.data;
        await this.wrapSinglePlateRecord(record);
        search.found = true;
        return;
      } catch (ex) {
      }

      try {
        const itemUrl = `${url}/sequence/${id}`;
        const res = await axios.get(itemUrl, {headers: {'Authorization': common.loginToken}})
        common.mode = 'AXLES';
        common.notify('ApplicationModeChanged');
        const record = res.data;
        this.wrapSingleSequence(record);
        search.found = true;
        return;
      } catch (ex) {
      }

      try {
        const itemUrl = `${url}/sequence_items/${id}`;
        const res = await axios.get(itemUrl, {headers: {'Authorization': common.loginToken}})
        common.mode = 'AXLES';
        common.notify('ApplicationModeChanged');
        const record = res.data;
        this.wrapSingleSequenceItem(record);
        search.found = true;
        return;
      } catch (ex) {
      }

      await common.alert('Search item by ID', `Given ID ${id} was not found, please verify typing`);

    } catch (ex) {
      console.error('failed to handle search:', ex);
    } finally {
      common.app.search.searching = false;
    }
  }

  async handleSearchOpened() {
    try {
      const search = common.app.search;
      if (search.open) {
      } else {
        search.searchText = '';
        if (search.found) 
          common.notify('LoadLastDataset');
      }

    } catch (ex) {
      console.error('faile to handle search opened:', ex);
    }
  }

}
const searchService: SearchService = new SearchService();
export default searchService;