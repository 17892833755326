import React, {} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Accordion,  AccordionSummary, TextField } from '@material-ui/core'
import common from '../../services/commonService';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';





export function FieldLine(props: any) {
  const {field, value} = props;
  const bool = (typeof value === "boolean");
  const val = bool ? value ? "TRUE" : "FALSE" : value;
    return (
      <Box display="flex" flexDirection="row">
        <Box display="inline">{field}:&nbsp;</Box>
        <Box display="inline" ><b>{val}</b></Box>
      </Box>
    )
}

export function TitleLine(props: any) {
  const {title} = props;
  return <Box display="flex" flexDirection="row"><b>{title}:</b></Box>
}


/**
 * debug dialog
 * @param props 
 * @returns 
 */
export function ConfigDialog (props:any) {
  const { onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const classes = useStyles();
  const config = common.axles.warpingConfig;
  const geometry = config?.AxleConfiguration?.Geometry;

  return (
    <Dialog onClose={handleClose} 
    maxWidth='lg'
    aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="Axles debug info">
        <Box display="flex" flexDirection="row" width={500}>
          <b>Image configuration</b>
          <Box flex="1"></Box>
         </Box>
      </DialogTitle>

      <DialogContent>

      { config && 

      <Box display="flex" flexDirection="column">


        <Accordion>
          <AccordionSummary  style={{backgroundColor:'gainsboro'}} expandIcon={<ExpandMoreIcon/>}>Camera</AccordionSummary>
            <Box display="flex" flexDirection="column" margin="10px">
              <TitleLine title="Intrinsics"/>
              <FieldLine field="Focal" value={config.Camera.Intrinsics.Focal}/>
              <FieldLine field="PixelSize" value={config.Camera.Intrinsics.PixelSize}/>
            </Box>
        </Accordion>

        <Box display="flex" height="10px"/>

        <Accordion>
          <AccordionSummary  style={{backgroundColor:'gainsboro'}} expandIcon={<ExpandMoreIcon />} >Axle configuration</AccordionSummary>
          <Box display="flex" flexDirection="column" margin="10px">
            <FieldLine field="ZoomFactor" value={config.AxleConfiguration.ZoomFactor}/>
            <FieldLine field="IsWrapped" value={config.AxleConfiguration.IsWarped}/>
              <TitleLine title="geomentry"/>
              <Box display="flex" flexDirection="column" marginLeft="20px">
              <TitleLine title="Lane center"/>
              <FieldLine field="Height" value={geometry.LaneCenter.Height}/>
              </Box>
              <Box display="flex" flexDirection="column" marginLeft="20px">
              <TitleLine title="Lane description"/>
              <FieldLine field="Width" value={geometry.LaneDescription.Width}/>
              <FieldLine field="OffsetFromCenter" value={geometry.LaneDescription.OffsetFromCenter}/>
              </Box>
            <TitleLine title="Angles"/>
            <FieldLine field="Tilt" value={geometry.Angles.Tilt}/>
            <FieldLine field="Rotation" value={geometry.Angles.Rotation}/>
            <FieldLine field="Pan" value={geometry.Angles.Pan}/>
            <TitleLine title="Side view"/>
            <FieldLine field="DistanceFromBottomLane" value={config.AxleConfiguration.SideView.DistanceFromBottomLane}/>
          <FieldLine field="Height" value={config.AxleConfiguration.SideView.Height}/>
          </Box>
        </Accordion>
    
       
    </Box>
        }
  

      </DialogContent>

    
      <DialogActions>
      <Button className={classes.button} variant="contained" onClick={() => handleClose()}>Close</Button>
 
      </DialogActions>
    </Dialog>
  );
}

ConfigDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
  },
}));