import React, {useState, useRef, useEffect, ChangeEvent} from 'react';
import { Box, Paper, Theme, Typography } from "@material-ui/core";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import common from '../../services/commonService';
import { GridOverlay } from '@material-ui/data-grid';

/**
 * List of records
 * @param props 
 * @returns 
 */
export function VideoList(props: any) {


  const [value, setValue] = useState(0); // update component

  const columnDefs = [
    {headerName: 'ID', field: 'id', width: 50},
    {headerName: 'Plate', field: 'Plate', width: 150},
    {headerName: 'S', 
      field: 'Status',
      width: 40, 
      cellRenderer: (data:any) => {
        let color = 'gray';
        if (data && data.value && data.value) {
          switch (data.value as string) {
            case 'OK': color = 'lawngreen'; break;
            case 'NO_OCR': color = 'red'; break;
            case 'NO_OBJECT': color = 'orange'; break;
            default: color = 'black'; break;
          }
        }
        return '<div style="background-color:' + color + ';display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px" ></div>';
    }},
   
  ];

  const handleSelection = (e: any) => {
    try {
      // disable selection while loading plate
      if (common.video.loadingRecord)
        return;

      const selectedRows = e.api.getSelectedRows();
      const selectedRow = selectedRows[0];
      common.plates.selectedRow = selectedRow;
      common.notify("VideoLoadRecord", selectedRow.id);
    } catch (ex) {
      console.error('failed on handle selection');
    }
  }



  const gotoNextRecord = () => {
    try {
      console.log('navigating to next record ...');
      const api = common.video.gridApi as any;
      if (api == null) return;

      // const currentIndex = selectedRowIndex;
      // api.forEachNode((node:any)=> node.rowIndex == currentIndex + 1 ? node.setSelected(true) : node.setSelected(false))
      
    } catch (ex) {
      console.error('failed to gotoNext:', ex);
    }
  }

  function onGridReady(params:any) {
    common.video.gridApi = params.api;
}


  const updateSelectedRecord = (data: any) => {
    try {
      // const selectedRecord = common.plates.records[selectedRowIndex];
      // selectedRecord.Plate = data.Plate;
      // platesActions.update(platesData);
      // (gridApi as any).refreshCells();
      // console.log(data);
    } catch (ex) {
      console.error('failed to update selected record:', ex);
    }
  }

  const selectFirstRow = () => {
    try {
      const api = common.plates.gridApi;
      api.forEachNode((node:any) => {
        // select the node
        node.setSelected(node.data.id === '1');
    });
      
    } catch (ex) {
      console.error('failed to select first row:', ex);
    }
  }

  

  const handleChanges = (name: string, value: any) => {
    try {
      switch (name) {
        case "SelectedRecord":
          handleSelection(value);
          break;

          case "UpdateRecord":
          updateSelectedRecord(name);
          break;

        case "GotoNextRecord":
          gotoNextRecord();
          break;

        case "VideoDatasetLoaded":
          setValue(v => v + 1);
          break;
      }

    } catch (ex) {
      console.error('failed to handle videoList changes:', ex);
    }
  }

/**
 * subscribe to global notifications
 */
 useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleChanges(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});
}, []);




  return (
    <Box display="flex" flex="1" >
              <div
                className="ag-theme-balham"
                style={{ height: '100%', width: '300px' }}
            >
                <AgGridReact
                    rowSelection={'single'}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    onSelectionChanged={ (e:any) => handleChanges("SelectedRecord", e)}
                    rowData={common.video.records || []}>
              </AgGridReact>

            </div>
      </Box>
  );

}