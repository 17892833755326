
import { CompoundBool, InsideEntry, InsideItem } from '../data/platesData';
import common from './commonService';
import platesService from './platesService';
import {InsideCanvas} from './insideCanvas';
import { METHODS } from 'http';

/**
 * manage inside inspection
 */
class InsideInspection {

  insideCanvas:InsideCanvas = new InsideCanvas();

    constructor() {
        common.notifier$.subscribe(msg => {
            switch(msg.name) {
                case 'PlatesDeleteInside':
                    this.deleteInside(msg.data);
                    break;

                case 'InsidePaint':
                  this.paint();
                  break;

                case 'InsidePassengerNavigate':
                  this.passengerNavigate(msg.data);
                  break;

                case 'InsideEditPassengers':
                  this.editPassengers();
                  break;

                case 'InsideSelectPassenger':
                  this.selectPassenger(msg.data);
                  break;

             

            }
        });
    }

    /**
     * creates the singleton
     */
    initialize() {
        
    }

      /**
   * pass a ref to ui canvas 
   * @param canvas 
   */
  setCanvas = (canvas: HTMLCanvasElement) => {
    try {
      if (!canvas) {
        return;
      }
      this.insideCanvas.setCanvas(canvas);
      this.insideCanvas.restoreMatrix();
    } catch (ex) {
      console.error('failed to set canvas: ', ex);
    }
  }

  paint = () => {
    try {
      this.centerWindshield();
      this.insideCanvas.paint();
      } catch (ex) {
        console.error('failed on drawImage:', ex);
     
      }
  }

  centerWindshield = () => {
    try {
      const r = common.plates.selectedInside?.windshield?.value;
      const vertices = r?.split(',')?.map(a => parseInt(a));
      if (!vertices || vertices.length !== 8)
        return;

        const left = Math.min(vertices[0],vertices[2],vertices[4],vertices[6]);
        const right = Math.max(vertices[0],vertices[2],vertices[4],vertices[6]);
        const top =  Math.min(vertices[1],vertices[3],vertices[5],vertices[7]);
        const bottom = Math.max(vertices[1],vertices[3],vertices[5],vertices[7]);

      let cx = (left + right)/2;
      let cy = (top + bottom)/2;

      const ccx = cx;
      const ccy = cy;

      
      // center in dialog window (800px x 450)
      cx -= 400;
      cy -= 225;

    
      const w = right - left;
      const scale = 800/w;

      // move to center
      this.insideCanvas.ctx.setTransform(1,0,0,1,0,0);
      this.insideCanvas.ctx.translate(-cx, -cy);

      // scale
      this.insideCanvas.ctx.translate(ccx, ccy);
      this.insideCanvas.ctx.scale(scale, scale);
      this.insideCanvas.ctx.translate(-ccx, -ccy);
      



  
      this.insideCanvas.saveMatrix();
    } catch (ex) {
      console.error('failed to center windshield:', ex);
    }
  }

  /**
   * navigate forward or backward, direction is determined by driver position
   * @param forward 
   * @returns 
   */
  passengerNavigate = (forward: boolean) => {
    try {
      const inside = common.plates.selectedInside;
      if (!inside || !inside.selectedPassenger)
        return;
      let passengers = inside.getPassengers();
      const driverIndex = passengers.findIndex(p => p.driver.value);
      const driverLeft = driverIndex === 3;
      passengers = driverLeft ? inside.getPassengersCounterClockwise() : inside.getPassengersClockwise();
      passengers = passengers.filter(p => p.occupied.value);
      let index = passengers.indexOf(inside.selectedPassenger);
      index = forward ? index + 1 : index - 1;
      if (index < 0) index = passengers.length - 1;
      index = index % passengers.length;
      inside.selectedPassenger = passengers[index];
      common.notify('InsidePassengerChanged');
      this.insideCanvas.paint();
    } catch (ex) {
      console.error('failed to navigate on passenger');
    }
  }

  /**
   * select the specified passenger
   * @param passenger 
   * @returns 
   */
  selectPassenger(passenger:InsideItem) {
    try {
      const inside = common.plates.selectedInside;
      if (!inside)
        return;

      inside.selectedPassenger = passenger;
      common.notify('InsidePassengerChanged');
      this.insideCanvas.paint();
    } catch (ex) {
      console.error('failed to select passenger:', ex);
    }
  }



    /**
     * delete the specified tagging
     * @param inside 
     * @returns 
     */
    deleteInside = (inside:any) => {
        try {
          const insides = common.plates?.insides;
          const index = insides?.indexOf(inside);
          if (index < 0)
            return;
    
          insides.splice(index,1);
          common.notify('InsideDeleted');
    
        } catch (ex) {
          console.error('failed to delete vehicle:', ex);
        }
      }

      /**
       * get the native version of an inside item
       * @param e 
       */
      getNativeInsideItem(e: InsideItem) {
        try {



        } catch (ex) {
          console.error('failed to get native inside item:', ex);
        }
      }

      /**
       * get the native collection of inside items
       * @returns 
       */
      getNativeInsides(insides: InsideEntry[]) {
        try {
          // const insides = common.plates.insides;
          const natives = insides.map(inside => inside.getNative());
          return natives;
        } catch (ex) {
          console.error('failed to get native insides:', ex);
        }
      }

      /**
       * helper - returns a boolean entry from an native entry
       * @param native 
       * @returns 
       */
      getBoolFromNative(native: any): CompoundBool {
        try {
          const cb = new CompoundBool();
          cb.value = native?.value === '1';
          cb.status = native?.status || 'tagged';
          return cb;
        } catch (ex) {
          console.error('failed to get compound bool:', ex);
          return new CompoundBool();
        }
      }

      /**
       * returns a single tagging from native data
       * @param native 
       * @returns 
       */
      getInsideItemFromNative(native: any): InsideItem | null {
        try {
          if (!native)
            return null;

          const occupied = native.occupied;
          const driver = native.driver;
          const belt = native.belt;
          const phone = native.phone;
          const item = new InsideItem();
          item.occupied = this.getBoolFromNative(occupied);
          item.driver = this.getBoolFromNative(driver);
          item.beltVisible = this.getBoolFromNative(belt);
          item.phoneVisible = this.getBoolFromNative(phone);
          return item;
        } catch (ex) {
          console.error('failed to get inside item from native:', ex);
          return new InsideItem();
        }
      }



      /**
       * retuns inside collection from native record
       * @param nativeRecord 
       * @returns 
       */
      getInsidesFromNative(nativeRecord:any) {
        try {
          let natives = nativeRecord?.image_library?.human?.inside_inspection?.Annotations || [];
          //let natives = nativeRecord?.image_library?.header?.insides;
          // if (!natives)
          //   return [];

          const insides:InsideEntry[] = [];
          // remove null entries
          natives = natives.filter((n:any) => !!n);
          natives.forEach((native:any) => {
            const e = new InsideEntry();
            const n = native.windshield;
            e.windshield.value = `${n.topleft_x},${n.topleft_y},${n.topright_x},${n.topright_y},${n.bottomright_x},${n.bottomright_y},${n.bottomleft_x},${n.bottomleft_y}`;
            e.windshield.status = n.status || 'tagged';
            const passengers = native.passengers || [];
            passengers.forEach((p:any) => {
              let i  = 0; 
              let j = 0;
              switch(p.position) {
                case 'front_left': i = 1; j = 0; break;
                case 'front_center': i = 1; j = 1; break;
                case 'front_right': i = 1; j = 2; break;
                case 'rear_left': i = 0; j = 0; break;
                case 'rear_center': i = 0; j = 1; break;
                case 'rear_right': i = 0; j = 2; break;
              }
              e.passengers[i][j] = this.getInsideItem(p);
            });
            insides.push(e);
          });
          return insides;

        } catch (ex) {
          console.error('failed to get insides from native:', ex);
          return [];
        }
      }

      getInsideItem(p: any): InsideItem {
        try {
          const item = new InsideItem();
          item.occupied.value = true;
          item.driver.value = p.driver || false;
          item.beltVisible.value = p.belt?.visible || false;
          item.beltFastened.value = p.belt?.fastened || false;
          item.phoneVisible.value = p.phone?.visible || false;
          item.phoneUsed.value = p.phone?.in_use || false;
          item.faceVisible.value = p.face?.visible || false;
          const r = item.faceRect;
          r.value = [0,0,0,0];
          r.value[0] = p.face?.topleft_x || 0;
          r.value[1] = p.face?.topleft_y || 0;
          r.value[2] = (p.face.bottomright_x || 0) - r.value[0];
          r.value[3] = (p.face.bottomright_y || 0) - r.value[1];
          item.status = p.status || 'tagged';
          return item;
        } catch (ex) {
          return new InsideItem();
        }

      }

      async editPassengers() {
        try {
          const inside = common.plates.selectedInside;
          if (!inside) {
            return;
          }

          const passengers = inside.getPassengers();
          if (passengers.length === 0) {
            return;
          }

       
          // select nothing or driver
          const driver = inside?.getPassengers()?.find(p => p.driver.value);
          inside.selectedPassenger = driver;
          common.notify('InsideLaunchPassengersDialog');

        } catch (ex) {
          console.error('failed to handle passengers:', ex);
        }
      }
}

const insideInspection:InsideInspection = new InsideInspection();
export default insideInspection;