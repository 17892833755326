
import tags from "../../../services/tags";
import common from '../../../services/commonService';

class Windshield {
  constructor() {

  }

  setWindshield(windshield: any) {
    tags.vehicle.windshield = windshield;
    tags.updateObject();
  }

  clear = () => {
    try {
      this.setWindshield([]);
    } catch (ex) {
      console.error('failed to clear:', ex);
    }
  };


  click = (evt: any) => {
    try {
      const pt = tags.getPoint(evt);
      const windshield = tags.vehicle.windshield;
      if (!windshield)
        return;

      if (windshield.length >= 4)
        return;

      windshield.push(pt);
      tags.updateObject();
      tags.undoAdd();
      common.notify('InsideTagsAdded');

    } catch(ex) {
      console.error('failed on click: ', ex);
    }
  };

  mouseDown = (evt: any) => {
    try {
      tags.dragAnchor = tags.hover;
    } catch(ex) {
      console.error('failed on mouseDown: ', ex);
    }
  };

  mouseMove = (evt: any) => {
    try {

      // drgging an ancor
      if (tags.dragAnchor) {
          const windshield = tags.vehicle.windshield;
          for(let i = 0; i < windshield.length; i++) {
            if (windshield[i] === tags.dragAnchor) {
              const pt = tags.getPoint(evt);
              windshield[i] = pt;
              tags.dragAnchor = pt;
            }
          }
      
        return;
      }

      const ptMouse = tags.getPoint(evt);
      tags.hover = null;
      tags.vehicle.windshield.forEach(pt => {
          if (tags.getDistance(ptMouse, pt) < 5) {
            tags.hover = pt;
            return;
          }
        });

      


    } catch(ex) {
      console.error('failed on mouseMove: ', ex);
    }
  };

  mouseUp = (evt: any) => {
    try {
      if (tags.dragAnchor)
        tags.undoAdd();
        
      tags.dragAnchor = null;
    } catch(ex) {
      console.error('failed on mouseUp: ', ex);
    }
  };
}

const windshield: Windshield = new Windshield();

export default windshield;