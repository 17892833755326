
import tags from "../../services/tags";

class Pan {

  ptDrag: any = null;
  ptMouse: any = null;

  pos2 = [0, 0];

  constructor() {
    this.ptDrag = null;
  }

  clear = () => {
    try {
    } catch (ex) {
      console.error('failed to clear:', ex);
    }
  };


  click = (evt: any) => {
    try {
    } catch(ex) {
      console.error('failed on click: ', ex);
    }
  };

  mouseDown = (evt: any) => {
    try {
      this.ptDrag = tags.getPoint(evt);
    } catch(ex) {
      console.error('failed on mouseDown: ', ex);
    }
  };

  mouseMove = (evt: any) => {
    try {
      const pt = tags.getPoint(evt);
      tags.mousePos = pt;
      this.pos2 = [evt.nativeEvent.offsetX, evt.nativeEvent.offsetY];
      if (this.ptDrag) {
         tags.ctx.translate((pt.x - this.ptDrag.x), (pt.y - this.ptDrag.y));
         tags.saveMatrix();
    }
    } catch (ex) {
      console.error('failed on mouseMove');
    }

  };

  mouseUp = (evt: any) => {
    try {
      this.ptDrag = null;
    } catch(ex) {
      console.error('failed on mouseUp: ', ex);
    }
  };

  zoom = (clicks:number) => {
    try {
        const ctx = tags.ctx;
        const scaleFactor = 1.1;
        // const pt = (ctx as any).transformedPoint(this.pos2[0], this.pos2[1]);
        const pt = tags.mousePos;
        if (!pt)
          return;
          
        ctx.translate(pt.x, pt.y);
        const factor = Math.pow(scaleFactor, clicks);
        ctx.scale(factor, factor);
        ctx.translate(-pt.x, -pt.y);
        tags.saveMatrix();
      } catch (ex) {
        console.error('failed to zoom:', ex);
      }
    };

  mouseWheel = (evt: any) => {
    try {
      const delta = evt.nativeEvent.wheelDelta ? evt.nativeEvent.wheelDelta / 40 : evt.detail ? -evt.detail : 0;
      this.zoom(delta);
    } catch (ex) {
      console.error('failed to handle canvasMouseDown:', ex);
    }
  };


}

const pan: Pan = new Pan();

export default pan;