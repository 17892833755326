import React, {useState, useRef, useEffect} from 'react';
import {Box, IconButton, MenuItem,Tooltip, Typography, Button, Select, Switch, TextField } from '@material-ui/core'
import {Check as CheckIcon, Cancel as CancelIcon, Delete as DeleteIcon, DragHandleRounded, Edit as EditIcon } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import platesService from '../../services/platesService';
import './table.css';
import {  HazardData } from '../../data/platesData';


/**
 * editor for plate annotations - contains tag editors
 * handles most editing operations
 * @param props 
 * @returns 
 */
export function HazardEditor(props: any) {

  const [value, setValue] = useState(0); // integer state
  const canvasRef = useRef(null);

  const ant = props.ant as HazardData;



  /**
   * common change handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      switch (key) {
    
        case 'HazardAnnotationChanged':
        case 'AnnotationSelected':
          setValue(v => v + 1);
          break;

        case 'ImageClicked':
          common.unselectAnnotations();
          common.plates.selectedHazard = ant;
          common.notify("AnnotationSelected", ant as any);
          break;

        case 'DeleteHazard':
            common.notify('PlatesDeleteHazard', value)
            break;

        case 'id':
        case 'class':
        case 'text':
        case 'un':
          if (ant) {
            ant[key] = value;
            common.notify('HazardAnnotationChanged');
          }
          break;

        case 'extra':
          const index = Math.max(extras.indexOf(value), 0);
          if (ant) {
            ant.ExtraType = index;
          }
          setValue(v => v + 1);
          common.notify('HazardAnnotationChanged');
          break;

        




    
      }
    } catch (ex) {
      console.error('failed to handle hazardEditor changes: ', ex);
    }
  }








  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    platesService.canvas.setHazardThumbnail(props.index, canvas);
    return () => {
      platesService.canvas.setHazardThumbnail(props.index, null);
    };
  });

  const getBorder = () => {
    try {
  
      if (selected)
        return "cyan solid 2px";

      return "gray solid 1px";
    } catch (ex) {
      console.error('failed to get border:', ex);
      return "red solid 5px";
    }
  }

  const classes = useStyles();
  const tooltips: boolean = true;
  const selected = ant === common.plates.selectedHazard;
  const id = ant?.id || '';
  const hazardClass = ant?.class || '';
  const text = ant?.text || '';
  const un = ant?.un || '';
  const extraKey = ant?.ExtraType || 0;
  const showDetails = common.plates.newTagType === 'Hazard';

  const extras = ['', 'OCCLUDED', 'AMBIGUOS'];
  const extra = extras[extraKey as number] || '';



  return ( 
    <Box display="flex" flex="1" flexDirection="column"   border={getBorder()} marginRight="10px"  >

        <Box display="flex" flexDirection="row">

        <Box display="flex" flexDirection="column">
      <canvas  ref={canvasRef} 
        onMouseDown={e => handleChanges('ImageClicked', null) }
        width={75} height={75}   style={{backgroundColor: 'teal', cursor: 'pointer', height:'75px', width:'75px', margin:'4px'}}>
        </canvas>
        <Typography style={{margin:'4px', fontWeight: selected ? 'bold' : 'normal'}}>Hazardous</Typography>
        <Box height="40px"/>
        <Box display="flex" flexDirection="row" marginLeft="10px" >
            <Tooltip title={tooltips ? "Delete annotation" : ''}>
            <IconButton  edge="start" 
            onClick = {() =>  handleChanges('DeleteHazard', ant as any)}
              color="primary" >
            <DeleteIcon/>
            </IconButton>
            </Tooltip>
            </Box>
            </Box>

           

            {showDetails && <Box display="flex" flexDirection="column" marginRight="10px">

                <Box display="flex" flexDirection="row" alignItems="center" margin="4px">
                  <Typography style={{width:'60px'}}>ID:</Typography>
                    <TextField style={{width:'50px'}} 
                      InputProps={{readOnly: true}}
                      value={id} onChange = {e => handleChanges('id', e.target.value)}>
                    </TextField>
                  </Box>

                <Box display="flex" flexDirection="row" alignItems="center" margin="4px">
                    <Typography style={{width:'60px'}}>Class:</Typography>
                    <TextField style={{width:'50px'}} 
                      InputProps={{readOnly: true}}
                      value={hazardClass} onChange = {e => handleChanges('class', e.target.value)}>
                    </TextField>
                </Box>

                <Box display="flex" flexDirection="row" alignItems="center" margin="4px">
                <Typography style={{width:'60px'}}>Text:</Typography>
                <TextField style={{width:'50px'}} 
                      value={text} onChange = {e => handleChanges('text', e.target.value)}>
                    </TextField>
                </Box>
          
                <Box display="flex" flexDirection="row" alignItems="center" margin="4px">
                <Typography style={{width:'60px'}}>UN:</Typography>
                <TextField style={{width:'50px'}} 
                      value={un} onChange = {e => handleChanges('un', e.target.value)}>
                    </TextField>
                </Box>

                <Box display="flex" flexDirection="row" alignItems="center" margin="4px">
                <Typography style={{width:'60px'}}>Extra:</Typography>
                <Select style={{width:'100px', fontSize:'11px'}}   label="Plate type:"
          value={extra} 
          onChange={e =>handleChanges("extra", e.target.value)}>
              {extras.map((e:any) => ( <MenuItem value={e}>{e}</MenuItem>))}
        </Select>   
            
                </Box>

            </Box>}

        </Box>
 

    </Box>
  )

}

const useStyles = makeStyles({

  radio: {
    margin: 0,
    padding: 0,
    fontSize: 40,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
    fontSize:40,
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

  combo: {
    width: 90,
  },

});