import React, {useState, useEffect} from 'react';
import {Box, Button, Checkbox, Dialog, DialogTitle, FormControlLabel } from '@material-ui/core'
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import common from '../services/commonService';
import PropTypes from 'prop-types';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import copy from 'copy-to-clipboard';

const  dateFormatter = (params:any) => {
  const time = params.data.time;
  const tm = new Date(time);
  return tm.toLocaleTimeString();
}

export function LoggerDialog (props:any) {
  const { onClose, open } = props;

  const [value, setValue] = useState(0); 
  const classes = useStyles();

  const columnDefs = [
    {headerName: 'Time', field: 'time', valueFormatter: dateFormatter, width:70},
    {headerName: 'S', 
      field: 'severity',
      width: 40, 
      cellRenderer: (data:any) => {
        let color = 'gray';
        if (data && data.value) {
          const status = data.value as number;
          switch (data.value as string) {
            case 'Warning' : color = 'orange'; break;
            case 'Info': color = 'lawngreen'; break;
            case 'Error': color = 'red'; break;
          }
        }
        return '<div style="background-color:' + color + ';display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px" ></div>';
    }},
    {headerName: 'Message', field: 'message', width:2000},
  ];


  const handleChanges = (key: string, value: any ) => {
    try {
      switch(key) {
        case 'GatewayClearErrors':
          handleClear();
          break;
      }
    } catch (ex) {
      console.error('failed to handle changes:', ex);
    }
  }




  const handleClose = () => {
    onClose();
  };

  const handleClear = () => {
    try {
      const logs = common.app.logs;
      logs.logs = [];
      logs.filteredLogs = [];
      logs.errors = 0;
      logs.infos = 0;
      logs.warnings = 0;
      common.notify('LogFilterChanged');
      setValue(v => v + 1);
    } catch (ex) {
      console.error('failed to handle clear: ', ex);
    }
  }

  const handleListItemClick = (value:any) => {
    onClose(value);
  };

  function onGridReady(params:any) {
    common.app.logs.gridApi = params.api;
}



const copyToClipboard = () => {
  try {
    const text = JSON.stringify(common.app.logs?.filteredLogs, null, 2);
    copy(text);
  } catch (ex) {
    console.error('failed to copy to clipboard: ', ex);
  }
}

const filterChanged = (severity: string, value: boolean) => {
  try {
    switch (severity) {
      case "Errors": 
        common.app.logs.showErrors = value;
        break;
        case "Warnings": 
        common.app.logs.showWarnings = value;
        break;
        case "Infos": 
        common.app.logs.showInfos = value;
        break;
    }
    common.notify('LogFilterChanged');
    setValue(v => v + 1);
  } catch (ex) {
    console.error('failed on filterChanged:', ex);
  }

}
  var logs = common.app.logs;

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  
  }, []);


  return (
    <Dialog onClose={handleClose} fullWidth={true}
    aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="Application logger">
        <Box display="flex" flexDirection="column" width={500}>
          <b>Application logger</b>
          <Box display="flex" flex="1" flexDirection="row"></Box>
            <Box display="flex" flexDirection="row">

            <FormControlLabel
          value="start"
          control={<Checkbox color="primary" checked={logs.showErrors} 
          onChange={ev => filterChanged('Errors', ev.target.checked)} />}
          label={<span>Errors:{common.app.logs.errors}</span>}
        />

          <FormControlLabel
          value="start"
          control={<Checkbox color="primary" checked={logs.showWarnings}
          onChange={ev => filterChanged('Warnings', ev.target.checked)}  />}
          label={<span>Warnings:{common.app.logs.warnings}</span>}
        />
               <FormControlLabel
          value="start"
          control={<Checkbox color="primary" checked={logs.showInfos}
          onChange={ev => filterChanged('Infos', ev.target.checked)}  />}
          label={<span>Infos:{common.app.logs.infos}</span>}
        />

          <Button className={classes.button}
          style={{margin:4, fontSize:10}} variant="contained" onClick={() => copyToClipboard()}>Copy</Button>
          <Button  className={classes.button}
          style={{margin:4, fontSize:10}} variant="contained" onClick={() => handleClear()}>Clear</Button>       
          <Button  className={classes.button}
          style={{margin:4,fontSize:10}} variant="contained" onClick={() => handleClose()}>Close</Button>

          
            </Box>

          <Box display="flex" flexDirection="row">
  
          </Box>
          

         </Box>
      </DialogTitle>




      <Box display="flex" flexDirection="column"
                className="ag-theme-balham"
                style={{ height: '500px', width: '100%' }}>
                <AgGridReact
                    rowSelection={'single'}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    rowData={common.app.logs?.filteredLogs || []}>
              </AgGridReact>
       
      </Box>
    </Dialog>
  );
}

LoggerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
}));