import React, {useState, useEffect} from 'react';
import { Box, Button, Typography } from '@material-ui/core'
import common from '../../services/commonService';
import { makeStyles } from "@material-ui/styles";
import arabicConverter from '../../services/arabicConverter';


/**
 * latin to arabic panel
 * @param props 
 * @returns 
 */
export function PlateArabic(props: any) {

  // platenumber passed via props
  const plateNumber = props.plateNumber;
  const nation = props.nation;
  let arabic: string[] = [];
  

  /**
   * returns the corresponding arabic char
   * @param ch 
   * @returns 
   */
  const getArabicChar = (ch:string) => {
    try {
      ch = ch?.toLocaleLowerCase();
      const entry = common.plates.latinToArabic.find( l => l.latin === ch);
         // used WTT-76 - replaced '?'
         return entry?.arabic || ch.toUpperCase();
    } catch (ex) {
      console.error('failed to get arabic char:', ex);
      return '';
    }
  }

  /**
   * get iranian char
   * @param ch 
   * @returns 
   */
  const getIranianChar = (ch:string) => {
    try {
      ch = ch?.toLocaleLowerCase();
      const entry = common.plates.latinToIranian.find( l => l.latin === ch);
      // used WTT-76 - replaced '?'
      return entry?.arabic || ch.toUpperCase();
    } catch (ex) {
      console.error('failed to get arabic char:', ex);
      return '';
    }
  }

  const getSauChar = (ch:string) => {
    try {
      ch = ch?.toLocaleLowerCase();
      const entry = common.plates.latinToSau.find( l => l.latin === ch);
        // used WTT-76 - replaced '?'
        return entry?.arabic || ch.toUpperCase();
    } catch (ex) {
      console.error('failed to get arabic char:', ex);
      return '';
    }
  }

  /**
   * 
   * @param ch - latin char
   * @returns - oman char
   */
  const getOmanChars = (ch:string) => {
    try {
      ch = ch?.toLocaleLowerCase();
      const entry = common.plates.latinToOman.find( l => l.latin === ch);
      // used WTT-76 - replaced '?'
      return entry?.arabic || ch.toUpperCase();
    } catch (ex) {
      console.error('failed to get arabic char:', ex);
      return '';
    }
  }

  /**
   * convert the given plate number, char by char
   * @returns 
   */
  const plateToArabic = () => {
    try {
      if (!plateNumber) {
        arabic = [];
        return;
      }

    const getChar = (c:any) => {
      try {
        switch(nation) {
          case 'IRN': return getIranianChar(c);
          case 'SAU': return getSauChar(c);
          case 'OMN': return getOmanChars(c);
          default: return getArabicChar(c);
        } 
      } catch (ex) {
        console.error('failed to get char:', ex);
      }
    }

      const translated:string[] = [];
      for (const c of plateNumber) {
        // iranian or default arabic
        const ar = getChar(c);
        translated.push(ar);
      }
      arabic = translated;
    } catch (ex) {
      console.error('failed on plateToArabic:', ex);
    }
  }

    // eslint-disable-next-line
  const [value, setValue] = useState(0); // integer state

  const handleCallback = (arg: string, value: any) => {
    try {
   

    } catch (ex) {
      console.error('failed to handle callback: ', ex);
    }
  }

  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleCallback(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);


  const classes = useStyles();

  // convert
  plateToArabic();

  const egyptian = arabicConverter.latinToEgyptian(plateNumber);
  const EGY = nation === 'EGY';

  return (
    <Box display="flex" flexDirection="column">

{!EGY &&
    <Box display="flex" flex="1" flexDirection="row" minHeight={20} style={{background:'silver'}} >
      <Box><Typography style={{fontSize:20}}>{arabic[0]}</Typography></Box> 
      <Box><Typography style={{fontSize:20}}>{arabic[1]}</Typography></Box>
      <Box><Typography style={{fontSize:20}}>{arabic[2]}</Typography></Box>
      <Box><Typography style={{fontSize:20}}>{arabic[3]}</Typography></Box>
      <Box><Typography style={{fontSize:20}}>{arabic[4]}</Typography></Box>
      <Box><Typography style={{fontSize:20}}>{arabic[5]}</Typography></Box>
      <Box><Typography style={{fontSize:20}}>{arabic[6]}</Typography></Box>
      <Box><Typography style={{fontSize:20}}>{arabic[7]}</Typography></Box>
      <Box><Typography style={{fontSize:20}}>{arabic[8]}</Typography></Box> 
      </Box>
}
      {EGY && 
      <Box style={{background:'silver'}}>
        <Typography style={{fontSize:'20px'}}>{egyptian}</Typography>
      </Box>
      }
    </Box>
  );

}


const useStyles = makeStyles({
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
},
checkbox: {
  margin: 0,
  padding: 0,
},

formControl: {
  margin: 0,
  padding: 0,
}}


);