import common from './commonService';


/**
 * Point
 */
interface Point {
  x: number;
  y: number;
}

/**
 * canvas wrapperr for axles counter
 */
export class AxlesCanvas {

  canvas:any;
  ctx: any;
  matrix: number[];
  base64: string | null;
  img: any;
  mousePos: Point | null;
  imageSize: number[];
  hotRect: number[] | null;
  hotCabRect: number[] | null = null;
  hover: any;
  resizeIndex: number | null;
  cabResizeIndex: number | null = null;
  windowSize: number[];
  pendingRestore: boolean = false;
  cabCanvas: any = null;
 

  constructor() {
    this.canvas = null;
    this.ctx = null;
    this.matrix = [1,0,0,1,0,0];
    this.base64 = null;
    this.img = new Image();
    this.mousePos = null;
    this.imageSize = [0, 0];
    this.hotRect = null;
    this.hover = null;
    this.resizeIndex = null;
    this.windowSize = [0, 0];
  }

  /**
   * set frame image for display
   * @param url image in base64 format
   */
  setImage = (url: string) => {
    try {
    if (!url) {
      this.img.setAttribute("src", 'null');
      this.paint('null image');
      return;
    }
    if (url.endsWith('null')) {
      console.warn('null image request');
      return;
    }
    this.img.setAttribute("src", url);
    // this.img.setAttribute("src", "./car2.jpg");
    // placeholder
    this.img.onload = () => {
      this.imageSize = [this.img.naturalWidth, this.img.naturalHeight];
      common.axles.imageSize =  [this.img.naturalWidth, this.img.naturalHeight];
      this.unzoom();
      // this.paint();
    };
    } catch (ex) {
      console.error('failed to set image:', ex);
    }
  }

  /**
   * distance between two points
   * @param pt0 
   * @param pt1 
   */
  getDistance = (pt0:Point, pt1: Point) => {
    try {
      const dx = pt0.x - pt1.x;
      const dy = pt0.y - pt1.y;
      return Math.sqrt(dx*dx + dy*dy);
    } catch (ex) {
      console.error('failed to get point: ', ex);
      return 0;
    }
  };

  /**
   * initialize canvas reference
   * @param canvas 
   */
  setCanvas = (canvas: HTMLCanvasElement) => {
    try {
      this.canvas = canvas;
      this.ctx = canvas.getContext('2d') as any;
      this.trackTransforms(this.ctx);
    } catch (ex) {
      console.error('failed to set canvas: ', ex);
    }
  }

  getTags = () => {
    return common.axles?.record?.Images[common.axles.imageIndex]?.Tags;
  }

    /**
   * clear the canvas
   */
     clear = () => {
      try {
        const ctx = this.ctx;
        ctx.save();
        ctx.setTransform(1,0,0,1,0,0);
        ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
        ctx.restore();
        this.img.setAttribute("src", null);
      } catch (ex) {
        console.error('failed to clear canvas:');
      }
    }

    getCanvasSize = () => {
      try {
        return [this.ctx.canvas.width, this.ctx.canvas.height];
      } catch (ex) {
        console.error('failed to get canvas size: ', ex);
      }
    }

  /**
   * principle paint function
   */
  paint = (source: string) => {
    try {
      const canvas = this.canvas;
      if (!canvas) {
        console.error('failed to get axles canvas');
        return;
      }
      const ctx = this.ctx;
      ctx.save();
      ctx.setTransform(1,0,0,1,0,0);
      // Will always clear the right space
      ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);
      ctx.restore();


      const src = this.img.src as string;
      // todo - verify the source 
      if (!src.endsWith('null'))
        ctx.drawImage(this.img, 0, 0);
  
      const index = common.axles.imageIndex;
      const tags = common.axles?.tags.filter((t:any) => t.ImageIndex === index);
      tags?.forEach((t:any) => {
        this.paintTag(t);
      });

   

      if (this.hotRect) {
        this.paintHotRect(this.hotRect, 'lawnGreen');
      }

      if (this.hover) {
        let r = this.getAnchorSize();
        const color = "red";
        ctx.strokeStyle = color;
        ctx.beginPath();
        ctx.arc(this.hover.x, this.hover.y, r, 0, 2 * Math.PI);
        ctx.stroke();
      }

      this.paintThumbnail();

    } catch (ex) {
      console.error('failed to paint axles:', ex);
    }
  }

  /**
   * maintain line width fixed with respect to zoom
   */
  setLinewidth = () => {
    try {
      const ctx = this.ctx;
      const trans = ctx.getTransform();
      let lineWidth = 3 / trans.a;
      lineWidth = Math.max(lineWidth,1);
      lineWidth = Math.min(lineWidth, 10);
      ctx.lineWidth = lineWidth;
    } catch (ex) {
      console.error('failed to set line width:', ex);
    }
  }

  /**
   * the rectangle being currently marked by user
   * @param rect 
   */
  paintHotRect = (rect: number[], color: string) => {
    try {
      const ctx = this.ctx;
      ctx.strokeStyle = color;
      this.setLinewidth();
      ctx.beginPath();
      ctx.rect(...rect);
      ctx.stroke();      
      var points = this.getPoints(rect);
      points.forEach(pt => this.drawAnchor(ctx, pt));
    } catch (ex) {
      console.error('failed to paint hot rect:', ex);
    }
  }

  /**
   * obtain 4 vertices of a rectangle
   * @param rect 
   */
  getPoints(rect:number[] | null) {
    try {
      if (!rect || rect.length !== 4)
        return [];

        const l = rect[0];
        const t = rect[1];
        const w = rect[2];
        const h = rect[3];
        return [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h}];
    } catch (ex) {
      console.error("failed to get points: " + ex);
      return [];
    }
  }

    /**
   * vertices and midpoints
   * @param rect 
   * @returns 
   */
     getPointsEx(rect:number[] | null) {
      try {
        if (!rect || rect.length !== 4)
          return [];
  
          const l = rect[0];
          const t = rect[1];
          const w = rect[2];
          const h = rect[3];
          const top = {x:l+w/2, y:t};
          const right = {x:l+w, y:t+h/2 };
          const bottom = {x:l+w/2,y:t+h};
          const left = {x:l,y:t+h/2};
          const pts =  [{x:l,y:t},{x:l+w,y:t},{x:l+w,y:t+h},{x:l,y:t+h},top, right, bottom, left];
          return pts;
      } catch (ex) {
        console.error("failed to get points: " + ex);
        return [];
      }
    }

  /**
   * get the points of th selected tag rect
   * @returns 
   */
  getSelectedTagPoints() {
    try {
      const tag = common.axles.tag;
      if (!tag)
        return null;

      return this.getPointsEx(tag.rect);
    } catch(ex) {
      console.error('failed to get selectedTagPoints:', ex);
    }
  }

  contains(rect: number[], pt: any) {
    try {
      const contains =  rect[0] < pt.x && rect[0] + rect[2] > pt.x &&
        rect[1] < pt.y && rect[1] + rect[3] > pt.y;
        return contains;
    } catch (ex) {
      console.error('failed on contains: ', ex);
      return false;
    }
  }

  /**
   * unzoom image
   * @returns 
   */
  unzoom = () => {
    try {
      if (!this.imageSize[0] || !this.imageSize[1])
        return;

      if (!this.windowSize[0] || !this.windowSize[1])
        return;

      const canvasAR = this.imageSize[0] / this.imageSize[1];
      const windowAR = this.windowSize[0] / this.windowSize[1];
      const index = canvasAR > windowAR ? 0 : 1;
      const factor = this.windowSize[index] / this.imageSize[index];
      this.ctx.setTransform( factor,0,0,factor,0,0);
      this.saveMatrix();
      this.paint('unzoom');

    } catch (ex) {
      console.error('failed to unzoom: ' + ex.message);
    }

  }

  /**
   * returns tag hit by mouse (if any)
   * @param evt 
   * @returns 
   */
  getClickedTag(evt: any): any {
    try {
      const pt = this.getPoint(evt);
      const tags = common.axles?.tags.filter((t:any) => t.ImageIndex === common.axles.imageIndex);
      if (!tags)
        return null;
      const contained = tags.find((t:any) => this.contains(t.rect, pt));
      return contained;
    } catch (ex) {
      console.error('failed to get clicked tag:', ex);
    }
  }



  /**
   * draw an anchor (circle) around a vertex
   * @param ctx 
   * @param pt 
   */
  drawAnchor = (ctx:any, pt:Point, color = 'lawngreen') => {
    try {
      const trans = ctx.getTransform();
      let r = 8 / trans.a;
      r = Math.max(r,5);
      r = Math.min(r, 40);
      ctx.strokeStyle = color;
      ctx.beginPath();
      ctx.arc(pt.x, pt.y, r, 0, 2 * Math.PI);
      ctx.stroke();
    } catch (ex) {
      console.error('failed to draw anchor:', ex);
    }
  }

  getAnchorSize = (): number => {
    try {
      const trans = this.ctx.getTransform();
      let anchorSize = 10 / trans.a;
      anchorSize = Math.max(anchorSize,5);
      anchorSize = Math.min(anchorSize, 50);
      return anchorSize;
    } catch (ex) {
      return 1;
    }
  }

  paintAnchorPositions = (pts:any[], color: string) => {
    try {
      const ctx = this.ctx;
      ctx.fillStyle = color;
      ctx.globalAlpha = 0.6;
      let r = this.getAnchorSize();
      r = r / 2;
      pts.forEach(pt => {
        ctx.fillRect(pt.x - r, pt.y - r, r * 2, r * 2);
      });
      ctx.globalAlpha = 1.0;

    } catch (ex) {
      console.error('failed to paint anchor positions: ', ex);
    }
  }

  getAnchorRadius = (): number => {
    try {
      if (!this.ctx)
        return 0;
      const trans = this.ctx.getTransform();
      let r = 8 / trans.a;
      r = Math.max(r,5);
      r = Math.min(r, 40);
      return r;


    } catch (ex) {
      console.error('failed to get anchor radius:', ex);
      return 0;
    }
  }

  /**
   * draw a rectangular fora given wheel tag
   * @param tag axle tags (rects)
   */
  paintTag = (tag: any) => {
        try {
          const selected = tag === common.axles.tag;
          const ctx = this.ctx;
          const rect = tag.rect;
          if (!rect)
            return;
          ctx.strokeStyle= selected ? 'lawnGreen' : 'darkgreen';
          if (tag.Cab)
            ctx.strokeStyle = 'magenta';

          this.setLinewidth();
          ctx.beginPath();
          ctx.rect(...rect);
          ctx.stroke();
          if (selected) {
            var points = this.getPointsEx(rect);
            this.paintAnchorPositions(points, ctx.strokeStyle);
            // points.forEach(pt => this.drawAnchor(ctx, pt, ctx.strokeStyle));
          }

          if (!tag.Cab)
            this.paintTagDecorations(tag);

        } catch (ex) {
          console.error('failed to paint tag: ', ex);
        }
  }

  paintThumbnail = () => {
    try {
    
      const tag = common.axles.tag;
      if(!tag?.Cab || !this.cabCanvas)
        return;

      const canvas = this.cabCanvas;
      const rect = tag.rect;
      const pts = this.getPoints(rect);
      const xx = pts?.map(pt => pt.x) as number[];
      const yy = pts?.map(pt => pt.y) as number[];
      const left = Math.min(...xx);
      const top = Math.min(...yy);
      const right = Math.max(...xx);
      const bottom = Math.max (...yy);
      const ctx = canvas.getContext('2d') as any;
      ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height);

      const ar0 = (right - left) / (bottom - top);
      const ar1 = canvas.width / canvas.height;

      let targetWidth = 0;
      let targetHeight = 0;
      if (ar0 > ar1) {
        targetWidth = canvas.width;
        targetHeight = canvas.width / ar0;
      } else {
        targetHeight = canvas.height;
        targetWidth = canvas.height * ar0;
      }
      


      ctx.drawImage(this.img, left, top, right - left, bottom - top, 0, 0,  targetWidth, targetHeight);
    } catch (ex) {
      console.error('failed to paint thumbnail:', ex);
    }
  }

  /**
   * obtain coordinates from mouse click event
   * @param e mouse click event
   */
  getPoint = (e: any): any => {
    try {
      const x = e.nativeEvent.offsetX;
      const y = e.nativeEvent.offsetY;
      return (this.ctx as any).transformedPoint(x, y);
    } catch (ex) {
      console.error('failed to getPoint');
      return {x: 0, y: 0};
    }
  }

  paintTagDecorations = (tag: any) => {
    try {

      let x = tag.rect[0];
      const w = 20;
      const y = tag.rect[1] + tag.rect[3] + w * 1.5;
      const ctx = this.ctx;
      const leftToRight = (common.axles?.sequence?.transitDirection === 'LeftToRight') || false;
   
      const decorations = this.getDecorations(tag);
      decorations.forEach(dec => {
        switch(dec) {
          case "Front":
            {
              ctx.fillStyle="lawngreen";
              ctx.beginPath();
              if (leftToRight) {
                ctx.moveTo(x, y + w);
                ctx.lineTo(x, y - w);
                ctx.lineTo(x + w, y);
              } else {
                ctx.moveTo(x, y);
                ctx.lineTo(x + w, y - w);
                ctx.lineTo(x + w, y + w );
              }
              ctx.fill();
              x += w * 1.5;
            }
            break;

          case "Twin":
            {
              const r = w / 2;
              ctx.strokeStyle="lawnGreen";
              ctx.beginPath();
              ctx.arc(x + r, y, r, 0, 2 * Math.PI);
              ctx.stroke();
              x += r;
              ctx.beginPath();
              ctx.arc(x + r, y, r, 0, 2 * Math.PI);
              ctx.stroke();
              x += r * 3;
            }
            break;

          case "Lifted":
            {
              ctx.fillStyle="lawngreen";
              ctx.beginPath();
              ctx.moveTo(x, y + w);
              ctx.lineTo(x + w / 2, y - w);
              ctx.lineTo(x + w, y + w );
              ctx.fill();
              x += w * 1.5;
            }
            break;

          case "Warning":
            {
              const h = 2 * w / 3;
              ctx.fillStyle="orangered";
              ctx.beginPath();
              ctx.moveTo(x, y + h);
              ctx.lineTo(x + w, y - h);
              ctx.lineTo(x + 2 * w, y + h );
              ctx.fill();
              x += w * 2.5;
            }
            break;

          case "Rear":
            {
              ctx.fillStyle="lawngreen";
              ctx.beginPath();
              if (leftToRight) {
                ctx.moveTo(x, y);
                ctx.lineTo(x + w, y - w);
                ctx.lineTo(x + w, y + w );
              } else {
                ctx.moveTo(x, y + w);
                ctx.lineTo(x, y - w);
                ctx.lineTo(x + w, y);
              }
           
              ctx.fill();
              x += w * 1.5;
            }
            break;
        }
      });

    } catch (ex) {
      console.error('failed to paint tag decorations:', ex);
    }
  }

  getDecorations = (tag:any): string[] => {
    try {
      const decorations:string[]  = [];
      return decorations;

      // TODO -update
      if (tag.WheelPosition === "F")
          decorations.push("Front");

      if (tag.Twin === "1")
          decorations.push("Twin");

      if (tag.Lifted === "1")
          decorations.push("Lifted");

      if (tag.WheelOrientation !== "U" || tag.WheelPosition === "UP" || tag.Lifted === "2" || tag.Partial === "1")
          decorations.push("Warning");

      if (tag.WheelPosition === "R")
          decorations.push("Rear");

      return decorations;
    } catch (ex) {
      console.error('failed to get decorations:', ex);
      return [];
    }
  }

  /**
   * cache trans matrix
   */
  saveMatrix = () => {
    try {
      const m = this.ctx.getTransform();
      this.matrix = [m.a, m.b, m.c, m.d, m.e, m.f];
    } catch (ex) {
      console.error('failed to save matrix:', ex);
    }
  };

  /**
   * restore trans matrix
   */
  restoreMatrix = () => {
    try {
      const m = this.matrix;
      this.ctx.setTransform(...m);
    }  catch (ex) {
      console.error('failed to restoreMatrix:', ex);
    }
  }

  /**
   * extends context to pan and zoom
   * @param ctx canvas context
   */
  trackTransforms = (ctx:any) => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let xform = svg.createSVGMatrix();
    ctx.getTransform = function() { return xform; };
  
    const savedTransforms:any = [];
    const save = ctx.save;
    ctx.save = function() {
      savedTransforms.push(xform.translate(0, 0));
      return save.call(ctx);
    };
    const restore = ctx.restore;
    ctx.restore = function() {
      xform = savedTransforms.pop();
      return restore.call(ctx);
    };
  
    const scale = ctx.scale;
    ctx.scale = function(sx:number, sy:number) {
      xform = (xform as any).scaleNonUniform(sx, sy);
      return scale.call(ctx, sx, sy);
    };
    const rotate = ctx.rotate;
    ctx.rotate = function(radians:number) {
      xform = xform.rotate(radians * 180 / Math.PI);
      return rotate.call(ctx, radians);
    };
    const translate = ctx.translate;
    ctx.translate = function(dx:number, dy:number) {
      xform = xform.translate(dx, dy);
      return translate.call(ctx, dx, dy);
    };
    const transform = ctx.transform;
    ctx.transform = function(a:number, b:number, c:number, d:number, e:number, f:number) {
      const m2 = svg.createSVGMatrix();
      m2.a = a; m2.b = b; m2.c = c; m2.d = d; m2.e = e; m2.f = f;
      xform = xform.multiply(m2);
      return transform.call(ctx, a, b, c, d, e, f);
    };
    const setTransform = ctx.setTransform;
    ctx.setTransform = function(a:number, b:number, c:number, d:number, e:number, f:number) {
      xform.a = a;
      xform.b = b;
      xform.c = c;
      xform.d = d;
      xform.e = e;
      xform.f = f;
      return setTransform.call(ctx, a, b, c, d, e, f);
    };
    const pt  = svg.createSVGPoint();
    ctx.transformedPoint = function(x:number , y:number) {
      pt.x = x; pt.y = y;
      return pt.matrixTransform(xform.inverse());
    };


  }


}