import React, {useState, useEffect} from 'react';
import Box from '@material-ui/core/Box'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import common from '../../services/commonService';



export function InsideList(props: any) {

  // eslint-disable-next-line
  const [value, setValue] = useState(0);
  
  const columnDefs = [
    {headerName: 'ID', field: 'id', width: 50},
    {headerName: 'S', 
    field: 'Status',
    width: 40, 
    cellRenderer: (data:any) => {
      let color = 'gray';
      if (data && data.value) {
        const status = data.value as number;
        switch (status) {
          case 0: color = 'gray'; break;
          case 1: color = 'lawngreen'; break;
          case 2: color = 'red'; break;
        }
      }
      return '<div style="background-color:' + color + ';display: table-cell;vertical-align:middle; width:12px; min-width:12px; max-width:12px; height:12px" ></div>';
  }},
  {headerName: 'Tags', field: 'TagCount', width: 50},
  ];

  /**
   * user has selected a row
   * @param e 
   */
  const handleSelection = (e: any) => {
    try {
     // disable selection while loading plate
     if (common.inside.loadingRecord)
     return;

      const selectedRows = e.api.getSelectedRows();
      const selectedRow = selectedRows[0];
      common.inside.selectedRow = selectedRow;
      common.notify("InsideLoadRecord", selectedRow.id);
    } catch (ex) {
      console.error('failed on handle selection');
    }
  }

/**
 * store the pointer to gridApi
 * @param params 
 */  
function onGridReady(params:any) {
  common.inside.gridApi = params.api;
}

/**
 * update the grid
 */
const updateCells = () => {
  try {
    const gridApi = common.inside.gridApi;
    (gridApi as any).refreshCells({force: true});
  } catch (ex) {
    console.error('failed to update validation state:', ex);
  }
}

  /**
   * change handler
   * @param arg 
   * @param value 
   */
  const handleChanges = (arg: string, value: any) => {
    try {
      switch (arg) {
        case 'InsideDatasetLoaded':
          setValue(value => value + 1);
          break;

        case 'InsideTagCount':
          updateCells();
          break;
      }
    } catch (ex) {
      console.error('failed to handle callback:', ex);
    }
  }


  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
    handleChanges(msg.name as string, msg.data);
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []);




  return (
    <Box display="flex" flex="1" >
              <div
                className="ag-theme-balham"
                style={{ height: '100%', width: '200px' }}
            >
                <AgGridReact
                    rowSelection={'single'}
                    onGridReady={onGridReady}
                    columnDefs={columnDefs}
                    onSelectionChanged={ (e:any) => handleSelection(e)}
                    rowData={common.inside?.records || []}>
              </AgGridReact>

            </div>
      </Box>
  );

}